import { Card } from 'antd'
import { TPreConSmPros } from 'features/property'
import { FC, useState } from 'react'
import { Navigation } from 'swiper'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
import { utils } from 'utils'
import { SvgBed, SvgCondoSqft } from 'components/icons'
import PropertyDetailsModal from '../../PropertyDetailsModal'

const PreSimilarPropBlock: FC<{
 data?: TPreConSmPros[]
}> = ({ data }) => {
 const [proSlug, setSlug] = useState<string | null>(null)
 if (!data || data?.length === 0) {
  return null
 }
 return (
  <div>
   <h3>Featured Developments</h3>
   <Swiper
    spaceBetween={15}
    slidesPerView={3}
    navigation
    modules={[Navigation]}
    breakpoints={{
     678: {
      slidesPerView: 2,
     },
     1200: {
      slidesPerView: 3,
     },
     1600: {
      slidesPerView: 3,
     },
    }}
   >
    {data?.map((item, index) => (
     <SwiperSlide key={index}>
      <Card
       className="similar-pre-condo-card"
       onClick={() => setSlug(item?.slug)}
      >
       <div className="similar-pre-condo-card-details">
        <img
         src={
          item?.images === null ||
          item?.images?.images === null ||
          item?.images?.images === ''
           ? '/images/CondoApt.png'
           : `${item?.images?.directory}Photo${item?.images?.ml_num}-${item?.images?.images}.jpeg`
         }
         alt={item?.property_name}
         width="100%"
         height="100%"
         className="similar-pre-condo-card-img"
        />
        <div className="similar-pre-condo-card-overlay" />
        <div className="similar-pre-condo-card-prop">
         {(item?.bed_min || item?.bed_max) && (
          <span
           style={{
            marginRight: '5px',
           }}
          >
           <span className="me-1">
            <SvgBed color="white" />
           </span>
           <span>
            {' '}
            {`${item?.bed_min ?? ''}${
             item?.bed_min !== null && item?.bed_max !== null ? '-' : ''
            }${item?.bed_max ?? ''} Bed`}
           </span>
          </span>
         )}
         {item?.sqft_min && (
          <span className="sqft-sec">
           <span className="me-1">
            <SvgCondoSqft fill="white" />
           </span>
           <span>
            {`${item?.sqft_min}${
             item?.sqft_max ? ` - ${item?.sqft_max}  Sqft` : ' Sqft'
            }`}
           </span>
          </span>
         )}
        </div>
        {item?.construction_status && (
         <div className="similar-pre-condo-card-status">
          <span>{item?.construction_status}</span>
         </div>
        )}
       </div>
       <div className="similar-pre-condo-card-info">
        <div style={{ fontSize: '1rem', fontWeight: '500' }}>
         <label htmlFor="" className="me-1">
          From:
         </label>
         <span className="text-primary">{`${utils.pricefy(
          item?.price_range_from,
         )}${
          item?.price_range_to
           ? ` - ${utils.pricefy(item?.price_range_to)}`
           : ''
         }`}</span>
        </div>
        <div style={{ color: '#333' }}>{item?.property_name}</div>
        <div className="similar-pre-condo-card-info-location">
         <span className="me-1">
          <i className="fas fa-map-marker-alt" />
         </span>
         {item?.full_address}
        </div>
       </div>
      </Card>
     </SwiperSlide>
    ))}
   </Swiper>
   {proSlug !== null && (
    <PropertyDetailsModal
     visible
     onHide={() => setSlug(null)}
     value={proSlug}
     isPreCon
    />
   )}
  </div>
 )
}

export default PreSimilarPropBlock
