/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import InnerContentLayout from 'layout/InnerContentLayout'
import { FC, ReactNode, useEffect, useState } from 'react'
import {
 useDeleteSignatureMutation,
 useGetAllSignaturesQuery,
 useUpdateSignatureStatusMutation,
} from 'features/settings/api'
import { Col, Row, Select, Spin, Card, Tag } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import { TGetAllSignatures } from 'features/settings/types'
import { globalConfirm } from 'components/elements/global'
import { BaseButton } from 'components/elements/BaseButton'
import useAuth from 'hooks/useAuth'
import SignatureEditor from './components/SignatureEditor'

const Signature: FC = () => {
 const { data, isLoading, refetch, isFetching } = useGetAllSignaturesQuery()
 const [deleteSignature] = useDeleteSignatureMutation()
 const [updateSignatureStatus] = useUpdateSignatureStatusMutation()
 const [selectedValue, setSelected] = useState<TGetAllSignatures | undefined>()
 const defaultSignatureId = data?.find?.((i) => i?.is_default)?.id ?? null
 const [newDefaultSignature, setDefaultSignature] = useState<number | null>()
 const [editItem, setEditItem] = useState<TGetAllSignatures | undefined>()

 useEffect(() => {
  setDefaultSignature(defaultSignatureId)
 }, [defaultSignatureId])

 const { changeSignature, user } = useAuth()

 useEffect(() => {
  if ((data?.length ?? 0) > 0) {
   if (editItem) {
    setSelected(editItem)
   } else if (defaultSignatureId) {
    setSelected(data?.find?.((i) => i?.is_default))
   } else {
    setSelected(data?.[0])
   }
   setDefaultSignature(defaultSignatureId)
  }
 }, [data, defaultSignatureId])

 useEffect(() => {
  if (editItem) {
   setSelected(editItem)
  }
 }, [editItem])

 const onDelete = (id: number): void => {
  globalConfirm({
   content: 'Are you sure you delete this signature?',
   onOk: async () => {
    setEditItem(undefined)
    await deleteSignature({ id: [id] }).unwrap()
    changeSignature?.(null)
    refetch?.()
   },
  })
 }

 const updateDefaultSign = (): void => {
  globalConfirm({
   content: 'Are you sure you want update the default signature?',
   onOk: async () => {
    setEditItem(undefined)
    await updateSignatureStatus({
     ...(newDefaultSignature
      ? {
         is_default: true,
         id: newDefaultSignature,
        }
      : { is_default: false }),
    }).unwrap()
    refetch()

    changeSignature?.({
     ...user?.signature,
     signature: newDefaultSignature
      ? data?.find((i) => i?.id === newDefaultSignature)?.signature ?? ''
      : '',
    })
   },
  })
 }

 const handleData = (): ReactNode => {
  if (isLoading) {
   return <BlockLoader />
  }
  return (
   <div>
    <Spin spinning={isLoading || isFetching}>
     <Row gutter={16}>
      <Col span={6}>
       <Card
        title={<div style={{ fontSize: '20px' }}>Signatures</div>}
        extra={[
         <BaseButton
          key={1}
          onClick={() => setSelected(undefined)}
          // buttonType="danger"
          size="small"
          // buttonStyle="solid"
          style={{ border: 'none' }}
          icon={<i className="fas fa-plus" />}
         />,
        ]}
       >
        {/* <div
         className={`signature-item my-3 ${!selectedValue ? 'active' : ''}`}
         onClick={() => setSelected(undefined)}
        >
         Add New Signature
        </div> */}
        {data?.map((item, n) => (
         <div key={n}>
          <div
           className={`signature-item my-3 ${
            selectedValue?.id === item?.id ? 'active' : ''
           }`}
           onClick={() => setSelected(item)}
          >
           <span style={{ wordBreak: 'break-word' }}> {item?.name}</span>{' '}
           <span
            className="d-flex align-items-center"
            style={{ paddingLeft: '10px' }}
           >
            {item?.is_default ? (
             <Tag color="red" style={{ height: 'max-content' }}>
              Default
             </Tag>
            ) : (
             ''
            )}
            <i
             className="far fa-trash-alt ms-1"
             onClick={() => onDelete(item?.id as number)}
            />
           </span>
          </div>
         </div>
        ))}
        {(data?.length ?? 0) > 0 && (
         <div style={{ marginTop: '48px' }}>
          <label style={{ fontWeight: '500', fontSize: '17px' }}>
           Signature Defaults
          </label>
          <Select
           value={newDefaultSignature}
           onChange={(i: number) => {
            setDefaultSignature(i)
           }}
           className="w-100 my-2"
           options={[
            { label: 'No Signature', value: null },
            ...(data?.map?.((i) => ({ label: i?.name, value: i?.id })) ?? []),
           ]}
          />
          <div className="d-flex justify-content-end">
           <BaseButton
            customClass="me-2"
            disabled={defaultSignatureId === newDefaultSignature}
            onClick={() => {
             setDefaultSignature(defaultSignatureId)
            }}
           >
            Cancel
           </BaseButton>
           <BaseButton
            buttonType="primary"
            type="primary"
            disabled={defaultSignatureId === newDefaultSignature}
            onClick={() => updateDefaultSign()}
           >
            Save changes
           </BaseButton>
          </div>
         </div>
        )}
       </Card>
      </Col>
      <Col span={18}>
       <SignatureEditor
        mode={!selectedValue || data?.length === 0 || !data ? 'add' : 'edit'}
        values={selectedValue}
        onSuccess={refetch}
        setEditItem={setEditItem}
        onClickCancelOnAdd={() => {
         if ((data?.length ?? 0) > 0) {
          setSelected(data?.[0])
         }
        }}
        setSelected={setSelected}
       />
      </Col>
     </Row>
    </Spin>
   </div>
  )
 }

 return (
  <InnerContentLayout title="Signature">
   <div className="signatures">{handleData()}</div>
  </InnerContentLayout>
 )
}

export default Signature
