import { Empty } from 'antd'
import { FC } from 'react'

const MTEmptyBlock: FC<{ columCount: number }> = ({ columCount }) => {
 return (
  <tbody className="m-table-tbody">
   <tr>
    <td colSpan={columCount}>
     <div className="m-table-placeholder">
      <Empty />
     </div>
    </td>
   </tr>
  </tbody>
 )
}

export default MTEmptyBlock
