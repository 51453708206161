import { TLeadsNames } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import {
 Dispatch,
 FC,
 SetStateAction,
 useEffect,
 useMemo,
 useState,
} from 'react'
import { TProLeadsSelectAll } from 'components/shared/SendWidget/EmailSendWidget'
import BaseSelector, { BaseSelectorOption } from '../BaseSelector'

const LeadSelector: FC<{
 type: 'phoneNumber' | 'email'
 onChange?: (v?: BaseSelectorOption[]) => void
 value?: BaseSelectorOption[]
 setProLeadsSelectAll?: Dispatch<SetStateAction<TProLeadsSelectAll | undefined>>
 proLeadsSelectAll?: TProLeadsSelectAll
}> = ({ onChange, value, type, proLeadsSelectAll, setProLeadsSelectAll }) => {
 const [searchLeadKey, setLeadKey] = useState('')
 const [selected, setSelected] = useState<BaseSelectorOption[]>(value || [])

 const { flatData, fetchMore, hasMore, isLoading, total } =
  useFetchPage<TLeadsNames>('get-leads-name', {
   method: 'post',
   body: useMemo(() => ({ search: searchLeadKey }), [searchLeadKey]),
  })
 useEffect(() => {
  setLeadKey(proLeadsSelectAll?.searchKey ?? '')
 }, [])

 useEffect(() => {
  if (proLeadsSelectAll?.show && proLeadsSelectAll?.selectAll) {
   const result =
    flatData
     ?.map((i) =>
      type === 'phoneNumber'
       ? i?.phone_number?.number
       : i?.email_address?.email ?? '',
     )
     .filter((k) => !proLeadsSelectAll?.unSelected?.includes(k)) ?? []
   setSelected(result)
   onChange?.(result)

   setProLeadsSelectAll?.((prv: any) => ({
    ...prv,
    selectedCount: !hasMore
     ? result?.length ?? 1
     : (total ?? 0) - (proLeadsSelectAll?.unSelected?.length ?? 0),
    showMoreLabel: hasMore,
   }))
  }
 }, [
  proLeadsSelectAll?.show,
  proLeadsSelectAll?.selectAll,
  flatData,
  proLeadsSelectAll?.unSelected,
 ])
 useEffect(() => {
  if (value) {
   setSelected?.(value)
  }
 }, [value])

 return (
  <BaseSelector
   totalCount={total}
   isLoading={isLoading}
   hasMore={hasMore}
   fetchMore={fetchMore}
   value={selected}
   showSearch
   onChange={(val) => {
    setSelected?.(val?.map?.((i) => i?.subTitle ?? i))
    onChange?.(val?.map?.((i) => i?.subTitle ?? i))
   }}
   options={flatData
    ?.map((v) => ({
     id: v?.id ?? Math.random(),
     subTitle:
      type === 'phoneNumber'
       ? v?.phone_number?.number
       : v?.email_address?.email ?? '',
     title: v?.name ?? '',
     extra: v ?? '',
    }))
    ?.filter?.((v) => v?.subTitle)}
   onSearch={setLeadKey}
   valueBy="name"
   proLeadsSelectAll={proLeadsSelectAll}
   setProLeadsSelectAll={setProLeadsSelectAll}
  />
 )
}

export default LeadSelector
