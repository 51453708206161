/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import { Upload, UploadFile } from 'antd'
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload'
import { FC, ReactNode } from 'react'
import { TFileExt } from 'types'
import { globalToaster } from '../global'
import { checkIsSupportedFile } from './functions'

const { Dragger } = Upload

type BaseUploadProps = {
 fileList: UploadFile[] | undefined
 type: 'dragger' | 'button'
 supporttedFiles: TFileExt[]
 onChange?: (v: UploadFile[]) => void
 multiple?: boolean
 buttonLabel?: ReactNode
}

const BaseUpload: FC<BaseUploadProps> = ({
 type,
 supporttedFiles,
 onChange,
 multiple,
 fileList,
 buttonLabel,
}) => {
 const beforeUpload = (file: RcFile) => {
  if (!checkIsSupportedFile(file, supporttedFiles)) {
   globalToaster({ content: `${file.name} is Not Supported`, type: 'error' })
   return false
  }
  return false
 }

 const handleChange = ({ fileList }: UploadChangeParam) => {
  let cloneData = [...fileList]
  cloneData = cloneData.filter((v) => checkIsSupportedFile(v, supporttedFiles))
  onChange?.(cloneData)
 }

 const uploadProps: UploadProps = {
  accept: supporttedFiles.map((v) => `.${v}`).join(','),
  multiple,
  onChange: handleChange,
  beforeUpload,
  fileList,
 }

 if (type === 'dragger') {
  return (
   <Dragger listType="picture" {...uploadProps}>
    <p className="ant-upload-drag-icon">
     <i className="fal fa-cloud-upload" />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
     Supported Files : {supporttedFiles.join(', ')}
    </p>
   </Dragger>
  )
 }
 return <Upload {...uploadProps}>{buttonLabel}</Upload>
}

export default BaseUpload
