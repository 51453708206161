/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import { http } from 'lib/http'
import { queryClient } from 'lib/react-query'
import { useEffect, useMemo, useState } from 'react'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { ApiResponce, InfinityNewType, PaginationType } from 'types'

type UseFetchPageRes<T> = {
 data?: InfiniteData<InfinityNewType<T>>
 isLoading: boolean
 fetchMore: () => void
 hasMore?: boolean
 refetch: () => void
 isRefetching?: boolean
 flatData?: T[]
 total?: any
 totalCount?: number
 isFetching?: boolean
 isFetchingNextPage?: boolean
 //  to?: number
}

type UseFetchPageProps = {
 params?: string
 body?: any
 method?: 'get' | 'post'
 enable?: boolean
 key?: string
 customApiKey?: string
 onSettled?: () => void
}

const spllitUrl = (val: string): any => val.split('page=')?.[1]

const useFetchPage = <T>(
 key: string,
 options?: UseFetchPageProps,
): UseFetchPageRes<T> => {
 const [totalCount, setTotalCount] = useState<number>(0)
 const fetchData = async (
  props: UseFetchPageProps & { url: string },
 ): Promise<InfinityNewType<any>> => {
  try {
   const { data }: AxiosResponse<ApiResponce<PaginationType<any>>> =
    props.method === 'post'
     ? await http.post(`user/${props.url}`, props.body)
     : await http.get(`user/${props.url}`)
   if (data && data.status === 'success') {
    setTotalCount(data?.data?.total ?? 0)
    return {
     data: data.data.data ?? [],
     next: data?.data.next_page_url
      ? spllitUrl(data?.data.next_page_url)
      : undefined,
     total: data?.data?.total,
    }
   }
   return {
    data: [],
    next: undefined,
   }
  } catch (error) {
   return {
    data: [],
    next: undefined,
   }
  }
 }

 const {
  data,
  isLoading,
  fetchNextPage,
  hasNextPage,
  refetch,
  isRefetching,
  isFetching,
  isFetchingNextPage,
 } = useInfiniteQuery<InfinityNewType<T>>(
  options?.customApiKey ?? [
   `${options?.key ?? key}`,
   options?.body,
   ...(options?.params ? [options?.params] : []),
  ],
  ({ pageParam = 1 }) => {
   if (pageParam) {
    return fetchData({
     method: options?.method || 'get',
     url: `${key}?page=${pageParam}${
      options?.params ? `&${options.params}` : ''
     }`,
     body: options?.body,
    })
   }
   return { data: [], next: undefined }
  },
  {
   getNextPageParam: (lastPage) =>
    lastPage && lastPage.next && lastPage.next !== null ? lastPage.next : false,
   enabled: options?.enable,
   onSettled: options?.onSettled,
  },
 )

 const flatData = useMemo(
  () => (data && data.pages ? data?.pages.map((v) => v.data).flat() : []),
  [data],
 )

 useEffect(() => {
  if (
   data &&
   data?.pages &&
   data?.pageParams &&
   data?.pageParams?.at(-1) === false
  ) {
   queryClient.setQueryData<InfiniteData<InfinityNewType<T>>>(
    [`${options?.key ?? key}`, options?.body, options?.params],
    () => ({
     pageParams: data?.pageParams.filter((param) => param !== false) || [],
     pages:
      data?.pages?.filter(
       (page) => (page?.data?.length ?? 0) > 0 || page.next,
      ) ?? [],
    }),
   )
  }
 }, [data])

 const total = useMemo(
  () =>
   data && data.pages && data.pages.length > 0 ? data.pages[0].total || 0 : 0,
  [data],
 )

 return {
  data,
  isLoading,
  fetchMore: fetchNextPage,
  hasMore: hasNextPage,
  refetch,
  isRefetching,
  flatData: flatData as T[],
  total,
  totalCount,
  isFetching,
  isFetchingNextPage,
  // to,
 }
}

export default useFetchPage
