/* eslint-disable no-nested-ternary */
import { Input, Modal } from 'antd'
import { LoadMoreSelect, MultiSelector } from 'components/elements/Form'
import {
 TGetActionPlansName,
 useAssignBulkNewsLetterMutation,
} from 'features/automation'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useAssignActionPlanLeadMutation,
} from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import useFetchPage2 from 'hooks/useFetchPage2'
import { FC, useMemo, useState } from 'react'

type AssignAlertProp = TSelectAllP & {
 activeLeadId?: string
 lead_id?: number[]
 visible: boolean
 onCancel: () => void
 onSuccess?: () => void
 extraBody?: any
 from?: 'action-plan' | 'real-estate-news' | 'market-updates'
 customtSelectAll?: TLeadCustomSelectAll
}

const AssignAutomation: FC<AssignAlertProp> = ({
 visible,
 onCancel,
 lead_id,
 activeLeadId,
 onSuccess,
 selectAll,
 unSelected,
 filterInfo,
 extraBody = {},
 from = 'action-plan',
 customtSelectAll,
}) => {
 const [selectedAP, setAP] = useState<any[]>([])
 const [searchKey, setSearch] = useState('')

 const {
  flatData: apData,
  isLoading: apIsLoading,
  hasMore: apHasMore,
  fetchMore: apFetchMore,
  isRefetching: apIsRefetching,
 } = useFetchPage<TGetActionPlansName>('get-action-plans-name', {
  method: 'post',
  body: useMemo(
   () => ({ search: searchKey, lead_id: activeLeadId ?? '' }),
   [activeLeadId, searchKey],
  ),
  enable: from === 'action-plan',
 })

 const {
  flatData: newsData,
  isLoading: newsLoading,
  hasMore: newsHasmore,
  fetchMore: newsFetchMore,
  isRefetching: newsIsRefetching,
 } = useFetchPage2<TGetActionPlansName>('newsletters', {
  params: `type=${
   from === 'market-updates' ? 'market-update' : 'Real-Estate-News-Letter'
  }${searchKey ? `&search=${searchKey}` : ''}`,
  enable: from !== 'action-plan',
 })

 const [assignActionPlan, { isLoading: apLoading }] =
  useAssignActionPlanLeadMutation()
 const [assingnNewsLetterBulk, { isLoading: newsLetterLoading }] =
  useAssignBulkNewsLetterMutation()

 const handleSubmit = async (): Promise<void> => {
  const body: TSelectAllB & { customtSelectAll?: TLeadCustomSelectAll } = {}
  if (selectAll || customtSelectAll) {
   if (selectAll) {
    body.select_all = selectAll
   } else if (customtSelectAll) {
    body.customtSelectAll = customtSelectAll
   }
   body.unselected = unSelected
   body.filter_info = filterInfo
  }
  if (from === 'action-plan') {
   await assignActionPlan({
    ...body,
    actionplan_id: [selectedAP],
    lead_id: selectAll || customtSelectAll ? [] : lead_id,
    type: 'lead',
    ...extraBody,
   }).unwrap()
  } else {
   await assingnNewsLetterBulk({
    ...body,
    newsletter_id: [selectedAP as any],
    newsletter_type:
     from === 'market-updates' ? 'market-update' : 'Real-Estate-News-Letter',
    type: 'lead',
    lead_id: selectAll || customtSelectAll ? [] : lead_id,
    selectall_type: 'lead',
    ...extraBody,
   }).unwrap()
  }

  onSuccess?.()
  handleClose()
 }

 const handleClose = () => {
  onCancel?.()
  setAP([])
 }

 const label = useMemo(() => {
  if (from === 'action-plan') {
   return 'Action Plan'
  }
  if (from === 'market-updates') {
   return 'Market Updates'
  }
  if (from === 'real-estate-news') {
   return 'Real Estate Newsletter'
  }
 }, [from])

 return (
  <Modal
   okButtonProps={{ disabled: selectedAP.length === 0 }}
   //    width={400}
   confirmLoading={apLoading || newsLetterLoading}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title={`Assign to ${label}`}
  >
   <LoadMoreSelect
    onChange={(val) => setAP(val as any)}
    // mode="multiple"
    data={(from === 'action-plan' ? apData : newsData)?.map((v) => ({
     value: v?.id,
     label: from === 'action-plan' ? `${v?.name} (${v?.owner})` : `${v?.name}`,
    }))}
    onSearch={setSearch}
    placeholder={`Select ${label}`}
    hasMore={apHasMore || newsHasmore}
    fetchMore={from === 'action-plan' ? apFetchMore : newsFetchMore}
    loading={apIsLoading || newsLoading || apIsRefetching || newsIsRefetching}
   />
  </Modal>
 )
}

export default AssignAutomation
