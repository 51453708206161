import { Card } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { TDetailRooms, TPropertyListingDetailRooms } from 'features/property'

import { FC, useState } from 'react'

const RoomsBlock: FC<{ rooms?: TPropertyListingDetailRooms[] }> = ({
 rooms,
}) => {
 const [limit, setLimit] = useState(2)
 if (!rooms || rooms.length === 0) {
  return null
 }
 return (
  <div>
   <Card className="m-0" title="Rooms">
    <div className="room-blocks">
     {rooms?.length > 0 &&
      rooms?.slice?.(0, limit)?.map((i, n) => (
       <div className="room-block" key={n}>
        <div className="row">
         <div className="col-sm-7">
          {i?.type == null && i?.dimension == null ? null : (
           <h5>
            {i?.type} {i?.dimension ? `(${i?.dimension})` : ''}
           </h5>
          )}
          {i?.description && <h6>{i.description}</h6>}
         </div>
         {i?.level && (
          <div className="col-sm-5 my-auto">
           <div className="level">Level: {i?.level}</div>
          </div>
         )}
        </div>
       </div>
      ))}
    </div>
    {rooms && rooms?.length > 2 && (
     <div>
      {rooms?.length > limit ? (
       <BtnAnch
        className="btnstyle"
        onClick={() => setLimit(rooms?.length || 0)}
       >
        View more
       </BtnAnch>
      ) : (
       <BtnAnch className="btnstyle" onClick={() => setLimit(2)}>
        View Less
       </BtnAnch>
      )}
     </div>
    )}
   </Card>
  </div>
 )
}

export default RoomsBlock
