import { Badge, Menu } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { useGetLeadTilesQuery } from 'features/leads/api'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import { TLeadTabType } from 'features/leads/types'
import useAuth from 'hooks/useAuth'
import React, { FC, useEffect, useState } from 'react'

const LeadTileBlock: FC<{
 onChangeTile?: () => void
 selectedLeadsType?: 'all-leads' | 'my-leads'
 //  selectedKey, setKey
 selectedKey: TLeadTabType
 setKey: React.Dispatch<React.SetStateAction<TLeadTabType>>
}> = ({ onChangeTile, selectedLeadsType, selectedKey, setKey }) => {
 const { user } = useAuth()

 const { data } = useGetLeadTilesQuery({
  ...(selectedLeadsType === 'my-leads' ? { type: 'myleads' } : {}),
 })
 //  const [selectedKey, setKey] = useState<TLeadTabType>('totallead')

 const { filter, handleUpdateTab } = useLeadFilter()

 useEffect(() => {
  if (selectedKey && selectedKey.length > 0) {
   handleUpdateTab(selectedKey)
   //    onChangeTile?.()
  }
 }, [selectedKey])

 const menuGetItem = (label: React.ReactNode, key: any, count?: number) => {
  return {
   label: (
    <div>
     {label}
     <Badge
      className="ms-2"
      count={count}
      showZero={key === 'totallead' || false}
      overflowCount={99999}
     />
    </div>
   ),
   key,
   onClick: () => {
    setKey(key)
    onChangeTile?.()
   },
  }
 }

 const menuItems = [
  menuGetItem('Total Lead', 'totallead', data?.totallead),
  menuGetItem('Last 7 Days', 'weeklead', data?.weeklead),
  //   ...(user?.account_type === 'Realtor' ||
  //   (user?.account_type === 'Brokerage' && selectedLeadsType === 'all-leads')
  //    ? menuGetItem('Recent Active', 'activeLead', data?.activeLead)
  //    : menuGetItem('Hot', 'hot_lead', data?.hot_lead)),
  //   menuGetItem('Recent Active', 'activeLead', data?.activeLead),
  menuGetItem('Hot', 'hot_lead', data?.hot_lead),
  menuGetItem('Buyer', 'buyer_lead', data?.buyer_lead),
  menuGetItem('Seller', 'seller_lead', data?.seller_lead),
  menuGetItem('Closed', 'closed_lead', data?.closed_lead),
 ]

 if (
  user?.account_type === 'Realtor' ||
  (user?.account_type === 'Brokerage' && selectedLeadsType === 'my-leads')
 ) {
  menuItems.splice(
   2,
   0,
   menuGetItem('Recent Active', 'activeLead', data?.activeLead),
  )
 }
 //  const isEnableClearFilterBtn = () => {
 //   const values = {
 //    weeklead: { created: 7 },
 //    activeLead: { recentactivity: true },
 //    hot_lead: { stage: [2] },
 //    buyer_lead: { stage: [3] },
 //    seller_lead: { stage: [4] },
 //    closed_lead: { stage: [5] },
 //   }
 //  }

 return (
  <div className="lead-tile-blk d-block">
   <Menu
    items={menuItems}
    mode="horizontal"
    defaultSelectedKeys={['totallead']}
    selectedKeys={[selectedKey]}
   />
   {/* <BaseButton
    buttonType="danger"
    onClick={() => {
     setKey('totallead')
     handleUpdateTab('totallead')
    }}
    disabled={
     Object.keys(filter).length === 0 ||
     Object.values(filter).every((val) => val === null)
    }
   >
    Clear Filter
   </BaseButton> */}
  </div>
 )
}

export default LeadTileBlock
