import { Avatar, Badge } from 'antd'
import {
 SvgNoteIcon,
 SvgEmailIcon,
 SvgTextIcon,
 SvgLeadProfileAllIcon,
} from 'components/icons'
import { TActivityType, TContentNote } from 'features/leads/types'
import React, { FC, useMemo } from 'react'

const ActivityMenuItem: FC<{
 type?: TActivityType
}> = ({ type }) => {
 const buildItem = useMemo(() => {
  switch (type) {
   case 'all':
    return <AllBlock />
   case 'note':
    return <NoteBlock />
   case 'email':
    return <EmailBlock />
   case 'web':
    return <EnquiryBlock />
   case 'text':
    return <TextBlock />
   case 'call':
    return <CallBlock />
   default:
    return <AllBlock />
  }
 }, [type])
 return <>{buildItem}</>
}

export default ActivityMenuItem

const AllBlock: FC = () => (
 <>
  <Badge>
   <Avatar icon={<SvgLeadProfileAllIcon />} size={50} />
  </Badge>
  <div className="act-content">
   <h4>ALL</h4>
  </div>
 </>
)

const NoteBlock: FC<{
 date?: string
 data?: TContentNote
}> = () => (
 <>
  <Badge>
   <Avatar icon={<SvgNoteIcon />} size={50} />
  </Badge>
  <div className="act-content">
   <h4>Notes</h4>
  </div>
 </>
)

const EnquiryBlock: FC = () => (
 <>
  <Badge>
   <Avatar
    size={50}
    icon={
     <i
      className="fas fa-solid fa-globe "
      style={{ color: 'black', fontSize: '35px' }}
     />
    }
   />
  </Badge>
  <div className="act-content">
   <h4>WEB Activity</h4>
  </div>
 </>
)

const EmailBlock: FC = () => (
 <>
  <Badge>
   <Avatar icon={<SvgEmailIcon />} size={50} />
  </Badge>
  <div className="act-content">
   <h4 className="text-capitalize">Email</h4>
  </div>
 </>
)

const TextBlock: FC = () => (
 <>
  <Badge>
   <Avatar icon={<SvgTextIcon />} size={50} />
  </Badge>
  <div className="act-content">
   <h4 className="text-capitalize">SMS</h4>
  </div>
 </>
)

const CallBlock: FC = () => (
 <>
  <Badge>
   <Avatar
    size={50}
    icon={
     <i
      className="fas fa-phone-alt font-icon"
      style={{ color: 'black', fontSize: '30px' }}
     />
    }
   />
  </Badge>
  <div className="act-content">
   <h4>Call Log</h4>
  </div>
 </>
)
