import { Modal, Select, Upload } from 'antd'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { BaseButton } from 'components/elements/BaseButton'
import { globalToaster } from 'components/elements/global'
import { useLeadImportMutation } from 'features/leads'
import { useGlobal } from 'raducer/global'
import React, { CSSProperties, FC, useState } from 'react'
import { utils } from 'utils'
import MessageModal from './MessageModal'

const labelStyle: CSSProperties = {
 display: 'block',
 fontSize: '14px',
 marginBottom: '6px',
 color: '#444',
}

const ImportLeadModal: FC<{
 visible?: boolean
 onCancel?: () => void
 refresh?: () => void
}> = ({ onCancel, refresh, visible }) => {
 const [leadImport, { isLoading }] = useLeadImportMutation()
 const [fileList, setFileList] = useState<UploadFile[]>([])
 const [selectedMode, setMode] = useState<number>(0)
 const [showImportmsg, setShowImportmsg] = useState(false)
 const [rspmessage, setRspmessage] = useState<any>()
 const { global } = useGlobal()

 const beforeUpload = (file: RcFile) => {
  const isCSV = file.type === 'text/csv'
  if (!isCSV) {
   globalToaster({
    type: 'error',
    content: `${file.name} is not a CSV file`,
   })
  } else {
   setFileList([file])
  }
  return false
 }

 const handleImport = async () => {
  const formData = new FormData()
  formData.append('attachment', fileList[0] as any)
  const { message } = await leadImport({
   formData,
   mode: selectedMode === 1 ? 'dir' : 'fb',
  }).unwrap()

  setRspmessage(message)
  setShowImportmsg(true)
 }

 return (
  <Modal
   confirmLoading={isLoading}
   okButtonProps={{ disabled: !fileList.length }}
   onOk={handleImport}
   okText="Import"
   visible={visible}
   title="Import Lead"
   onCancel={onCancel}
  >
   <div className="mb-2">
    <label style={labelStyle}>Select From </label>
    <Select
     onSelect={(val: any) => {
      setMode(val as any)
     }}
     placeholder="Select From"
     options={[
      { label: 'Direct Import', value: 1 },
      { label: 'Import from FB Ad', value: 2 },
     ]}
     className="w-100"
    />
   </div>
   {selectedMode !== 0 && (
    <div>
     <label style={labelStyle}>
      Upload .csv file prepared in the{' '}
      <a
       onClick={() =>
        utils.downloadFile(
         selectedMode === 1
          ? (global.agentroof_importlead as string)
          : (global.agentroof_importfblead as string),
         'Agentroof-FB-Lead-sample',
        )
       }
       download="csv file"
       target="_blank"
       rel="noreferrer"
       style={{ color: 'blue' }}
      >
       given format
      </a>
     </label>
     <Upload
      onRemove={() => setFileList([])}
      fileList={fileList}
      beforeUpload={beforeUpload}
      className=""
     >
      <BaseButton
       buttonStyle="outline"
       buttonType="primary"
       block
       className="w-100"
       icon={<i className="fas fa-upload me-2" />}
      >
       Upload File
      </BaseButton>
     </Upload>
    </div>
   )}
   {showImportmsg && (
    <MessageModal
     visible={showImportmsg}
     onHide={() => {
      setShowImportmsg(false)
      onCancel?.()
      refresh?.()
     }}
     message={rspmessage}
    />
   )}
  </Modal>
 )
}

export default ImportLeadModal
