import { flexRender, Row } from '@tanstack/react-table'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { FC } from 'react'
import {
 SortableContext,
 horizontalListSortingStrategy,
 useSortable,
} from '@dnd-kit/sortable'
import MTCell from '../Cell/MTCell'

const MTBodyRow: FC<{
 row: Row<any>
 onRowClick?: () => void
 columnOrder: string[]
 isDraggableColumn: boolean
}> = ({ row, onRowClick, columnOrder, isDraggableColumn }) => {
 return (
  <tr
   onClick={onRowClick}
   className={classNames('m-table-row', {
    'm-table-row-is-selected': row.getIsSelected(),
   })}
  >
   {row.getVisibleCells().map((cell: any, index) => {
    return isDraggableColumn ? (
     <SortableContext
      key={cell.id}
      items={columnOrder}
      strategy={horizontalListSortingStrategy}
     >
      <MTBodyRowCell
       cell={cell}
       index={index}
       isDraggableColumn={isDraggableColumn}
      />
     </SortableContext>
    ) : (
     <MTBodyRowCell cell={cell} index={index} />
    )

    //  <SortableContext
    //   key={cell.id}
    //   items={columnOrder}
    //   strategy={horizontalListSortingStrategy}
    //  >
    //   {/* <MTCell key={index} column={cell.column}>
    //    {cell.column.columnDef.meta?.ellipsis ? (
    //     <Tooltip
    //      title={flexRender(cell.column.columnDef.cell, cell.getContext())}
    //     >
    //      <span className="overflow-hidden d-block">
    //       {flexRender(cell.column.columnDef.cell, cell.getContext())}
    //      </span>
    //     </Tooltip>
    //    ) : (
    //     <span className="m-table-cell-span">
    //      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    //     </span>
    //    )}
    //   </MTCell> */}
    //   <MTBodyRowCell cell={cell} index={index} />
    //  </SortableContext>
   })}
  </tr>
 )
}

export default MTBodyRow

const MTBodyRowCell: FC<{
 cell: any
 index: number
 isDraggableColumn?: boolean
}> = ({ cell, index, isDraggableColumn }) => {
 const { isDragging, setNodeRef, transform } = useSortable({
  id: cell.column.id,
 })
 return (
  <MTCell
   key={index}
   column={cell.column}
   {...(isDraggableColumn ? { setNodeRef, isDragging, transform } : {})}
   //    setNodeRef={setNodeRef}
   //    isDragging={isDragging}
   //    transform={transform}
  >
   {cell.column.columnDef.meta?.ellipsis ? (
    <Tooltip title={flexRender(cell.column.columnDef.cell, cell.getContext())}>
     <span className="overflow-hidden d-block">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
     </span>
    </Tooltip>
   ) : (
    <span className="m-table-cell-span">
     {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </span>
   )}
  </MTCell>
 )
}
