import { Input, Form, Modal, Select } from 'antd'
import { InputPhoneNumber } from 'components/elements/Form'
import { FC, useEffect } from 'react'
import { LabelList } from 'static-data'
import { TMode } from 'types'
import { useAddLeadPhoneMutation } from '../api'
import { AddPhoneType } from '../types'

const initialValues: AddPhoneType = {
 lead_id: '',
 label: 'Good',
 number: '',
}

const AddPhoneModal: FC<{
 visible?: boolean
 onCancel?: () => void
 editData?: AddPhoneType
 mode?: TMode
 leadID?: any
}> = ({ visible, onCancel, editData, mode = 'ADD', leadID }) => {
 const [addLeadPhone, { isLoading }] = useAddLeadPhoneMutation()
 const [form] = Form.useForm<AddPhoneType>()
 //  const unformatted = editData?.number
 const unformatted = editData?.phone

 const formatted = unformatted?.replace(/[()\s-]/g, '')

 const HandleSubmit = async () => {
  const values = await form.validateFields()
  if (mode === 'EDIT' && leadID) {
   await addLeadPhone({
    ...values,
    lead_id: leadID,
    id: editData?.id,
   }).unwrap()
   onCancel?.()
  } else if (leadID) {
   await addLeadPhone({
    ...values,
    lead_id: leadID,
   }).unwrap()
   onCancel?.()
  }
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    number: formatted,
    label: editData.label,
   })
  }
  return () => {
   form.resetFields()
  }
 }, [editData, form, mode])

 return (
  <Modal
   visible={visible}
   onCancel={onCancel}
   onOk={HandleSubmit}
   okText={mode === 'EDIT' ? 'Update' : 'Add'}
   confirmLoading={isLoading}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} new phone`}
  >
   <Form layout="vertical" form={form} initialValues={initialValues}>
    <Form.Item
     name="number"
     label="Number"
     rules={[
      { message: 'please enter a valid phone number' },
      { min: 11, message: 'please enter a valid phone no' },
      { max: 13 },
     ]}
    >
     <InputPhoneNumber />
    </Form.Item>
    <Form.Item name="label" label="Label">
     <Select options={LabelList} />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddPhoneModal
