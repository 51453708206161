import { Card } from 'antd'
import { globalConfirm } from 'components/elements/global'
import { SVGDeleteLead } from 'components/icons'
import React, { FC } from 'react'
import { useDeleteLeadsMutation } from 'features/leads/api'
import { queryClient } from 'lib/react-query'
import { BaseButton } from 'components/elements/BaseButton'
import ProInnerCard from '../../ProInnerCard'

const DeleteLeadBlock: FC<{ leadId?: number; onClose?: () => void }> = ({
 leadId,
 onClose,
}) => {
 const [deleteLead] = useDeleteLeadsMutation()

 const onDelete = () => {
  globalConfirm({
   content: 'Are you sure want to delete this lead?',
   onOk: async () => {
    await deleteLead({ id: [leadId as any] }).unwrap()
    queryClient.invalidateQueries('get-leads')
    onClose?.()
   },
  })
 }
 return (
  //   <div className="">
  //    <Card type="inner">
  //     <div
  //      className="align-items-center"
  //      style={{ display: 'grid', gridTemplateColumns: '85% 15%' }}
  //     >
  //      <div>
  //       <h4 style={{ color: 'red' }}>Delete Lead</h4>
  //       <p>Delete Lead Delete Lead Delete Lead</p>
  //      </div>
  //      <div className="ms-auto">
  //       <SVGDeleteLead style={{ cursor: 'pointer' }} />
  //      </div>
  //     </div>
  //    </Card>
  //   </div>

  //   <ProInnerCard title="Delete Lead">
  //    <div
  //     className="align-items-center"
  //     style={{ display: 'grid', gridTemplateColumns: '85% 15%' }}
  //    >
  //     <div>
  //      <p>
  //       When you remove the lead, all the data linked to that lead will be lost.
  //      </p>
  //     </div>
  //     <div className="ms-auto">
  //      <span onClick={onDelete}>
  //       <SVGDeleteLead style={{ cursor: 'pointer' }} />
  //      </span>
  //     </div>
  //    </div>
  //   </ProInnerCard>
  <BaseButton
   className="w-100 fs-6 h-auto rounded-3"
   type="primary"
   onClick={onDelete}
  >
   Delete Lead
  </BaseButton>
 )
}

export default DeleteLeadBlock
