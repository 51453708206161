import { TSendType } from 'features/leads'
import { FC } from 'react'
import { TMsgMode, TSendWidget } from 'types'
import EmailSendWidget from './EmailSendWidget'
import SmsSendWidget from './SMSSendWidgert'

export type SendWidgetProps = {
 wFrom: 'lead' | 'listing'
 widget?: TSendWidget
 type?: TSendType
 mode?: TMsgMode
 to?: string | string[]
 onSendSuccess?: () => void
}

const SendWidget: FC<SendWidgetProps> = ({ widget, ...rest }) => {
 return widget === 'sms' ? (
  <SmsSendWidget {...rest} />
 ) : (
  <EmailSendWidget {...rest} />
 )
}

export default SendWidget

SendWidget.defaultProps = {
 widget: 'email',
}
