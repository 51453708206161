/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */

import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BtnAnch } from 'components/elements/global'
import { TSSBlockList } from 'components/shared/Common'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { Tooltip } from 'antd'
import { baseTime } from 'lib'
import {
 TLeadFilterBody,
 TGetLeads,
 TGetLeadFilter,
 TLeadTabType,
} from '../types'
import CustomDateFilter from './CustomDateFilter'

type LeadTableProps = {
 activeFilter?: TLeadFilterBody
 filter?: TGetLeadFilter
 onClickLead?: (id?: any) => void
 account_type?: 'Brokerage' | 'Realtor'
 from: 'lead' | 'transfer-lead'
 onSubmitCustomDate?: (val: {
  value: any
  range?: TRangeDate | undefined
 }) => void
 tileKey: TLeadTabType
}
type TRangeDate = {
 from_date?: string
 to_date?: string
}
export type CustomDateSubmitData = {
 value: any
 range?: TRangeDate
}

const LeadTableColumns = ({
 filter,
 onClickLead,
 activeFilter,
 account_type,
 from,
 onSubmitCustomDate,
 tileKey,
}: LeadTableProps): MDataTableColumns<TGetLeads>[] => {
 const convertFIlterOption = (
  filters?: {
   id?: number
   name?: string
  }[],
  key?: 'id' | 'name',
  format?: (label?: any) => string,
 ): { label?: string; value?: any }[] => {
  if (!filters) return []
  return filters?.map((v) => ({
   label: format ? format(v.name) : v.name,
   value: `${v[key ?? 'id'] || v.name}`,
  }))
 }

 return [
  {
   title: 'Name',
   dataIndex: 'name',
   size: 250,
   fixed: 'left',
   render: (val, record, index) => (
    <BtnAnch onClick={() => onClickLead?.(index)}>
     <AvatarStatus
      title={`${val} ${
       from === 'lead'
        ? record?.transferowner
          ? `(${account_type === 'Brokerage' ? 'Assigned To' : 'Assigned By'} ${
             record?.transferowner
            })`
          : ''
        : ''
      }`}
      showTooltip
     />
    </BtnAnch>
   ),
  },
  {
   title: 'Phone',
   dataIndex: 'phone_number',
   size: 200,
   render: (val, r) =>
    val?.number ? (
     <div className={r.phone_number?.label === 'Bad' ? 'bad-label' : ''}>
      {`+1 ${val?.number}`}
     </div>
    ) : (
     ''
    ),
   filter: {
    mode: 'single',
    options: convertFIlterOption(filter?.phone_filter, 'name'),
    showFilteCustomDot: true,
    filterDotExcludeValues: ['All'],
   },
  },
  {
   title: 'Email',
   dataIndex: 'email_address',
   size: 200,
   ellipsis: true,
   filter: {
    mode: 'single',
    options: convertFIlterOption(filter?.email_filter, 'name'),
    showFilteCustomDot: true,
    filterDotExcludeValues: ['All'],
   },

   render: (val, r) => (
    <div className={r?.email_address?.label === 'Bad' ? 'bad-label' : ''}>
     {val?.email ?? ''}
    </div>
   ),
  },
  {
   title: 'Latest Activity',
   dataIndex: 'activity',
   size: 200,
   ellipsis: true,
  },
  ...(account_type === 'Brokerage'
   ? [
      {
       title: 'Transferred on',
       size: 130,
       ellipsis: true,
       render: (v: any, r: TGetLeads) => (
        <div>
         {r?.transferowner
          ? baseTime(r?.transaction?.created_at).format('DD MMM YYYY')
          : ''}
        </div>
       ),
      },
     ]
   : []),
  {
   title: 'Created On',
   dataIndex: 'created_at',
   size: 140,
   ...(tileKey !== 'weeklead'
    ? {
       filter: {
        mode: 'single',
        customNode: (cProps) => (
         <CustomDateFilter
          value={activeFilter?.created}
          rangeValue={{
           from_date: activeFilter?.from_date,
           to_date: activeFilter?.to_date,
          }}
          onHide={cProps?.onHide}
          onClickSubmit={onSubmitCustomDate}
          data={convertFIlterOption(filter?.day_filter)}
         />
        ),
        showFilteCustomDot: true,
        filterDotExcludeValues: ['All'],
       },
      }
    : {}),
   render: (val) => baseTime(val).format('DD MMM YYYY'),
  },
  ...(account_type === 'Realtor'
   ? [
      {
       title: 'Tags',
       dataIndex: 'tags',
       size: 250,

       render: (v: any, r: TGetLeads) => (
        <TSSBlockList
         closable
         type="lead"
         mode="tag"
         typeId={r.id ?? ''}
         data={r.tags}
         tooltiOptions={{
          getPopupContainer: (e) =>
           e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
            ?.parentNode as any,
         }}
        />
       ),
       filter: {
        searchPlaceholder: 'Search Tags',
        showSearch: true,
        showSelectAll: true,
        mode: 'multiple' as const,
        options: convertFIlterOption(filter?.tag_filter),
       },
      },
      {
       title: 'Stage',
       dataIndex: 'stages',
       size: 200,
       render: (v: any, r: TGetLeads) =>
        r.stages && (
         <TSSBlockList
          type="lead"
          mode="stage"
          typeId={r.id ?? ''}
          data={[r.stages]}
          tooltiOptions={{
           getPopupContainer: (e) =>
            e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
             ?.parentNode as any,
          }}
         />
        ),
       filter: {
        searchPlaceholder: 'Search Stages',
        showSearch: true,
        showSelectAll: true,
        mode: 'multiple' as const,
        options: convertFIlterOption(filter?.stage_filter),
       },
      },
     ]
   : []),

  {
   title: 'Latest Source',
   dataIndex: 'sources',
   size: 200,
   ellipsis: false,

   render: (_, r) => {
    if (r.sources) {
     return (
      <TSSBlockList
       type="lead"
       mode="source"
       typeId={r.id ?? ''}
       data={[r.sources]}
       tooltiOptions={{
        getPopupContainer: (e) =>
         e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
          ?.parentNode as any,
       }}
      />
     )
    }
   },
   filter: {
    searchPlaceholder: 'Search Sources',
    showSearch: true,
    showSelectAll: true,
    mode: 'multiple',
    options: convertFIlterOption(filter?.source_filter),
   },
  },
 ]
}

export default LeadTableColumns
