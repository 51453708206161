import { globalToaster } from 'components/elements/global'
import SendTextMsgForm from 'components/shared/SendTextMsgForm'
import { ADMIN_PREFIX_PATH } from 'config'
import InnerContentLayout from 'layout/InnerContentLayout'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const LeadSmsPage: FC = () => {
 const navigate = useNavigate()
 const location = useLocation()
 const { state }: any = location
 let toData = state
 if (state?.includes?.(null) || state?.includes?.(undefined)) {
  globalToaster({
   type: 'error',
   content: 'Lead(s) without valid Phone Number skipped!',
  })
  toData = state?.filter((i: any) => i !== null && i !== undefined)
 }
 return (
  <InnerContentLayout title="Send SMS">
   {toData && (
    <SendTextMsgForm
     onSendSuccess={() => navigate(`${ADMIN_PREFIX_PATH}/leads`)}
     to={toData ?? []}
    />
   )}
  </InnerContentLayout>
 )
}

export default LeadSmsPage
