import { Modal, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { globalToaster } from 'components/elements/global'
import { useUploadLeadFileMutation } from 'features/leads'
import React, { FC, useState } from 'react'

const { Dragger } = Upload

const UploadLeadFileModal: FC<{
 leadId?: any
 visible?: boolean
 onCancel?: () => void
}> = ({ onCancel, visible, leadId }) => {
 const [uploadFile, { isLoading }] = useUploadLeadFileMutation()
 const [fileList, setFileList] = useState<UploadFile[]>()
 const handleBeforeUpload = (f: RcFile) => {
  const allowedExtensions =
   /(\.doc|\.docx|\.csv|\.pdf|\.xls|\.txt|\.jpg|\.jpeg|\.png|\.JPG)$/i

  if (!allowedExtensions.exec(f.name)) {
   globalToaster({
    type: 'error',
    content: `${f.name} is not a Supported!`,
   })
   return false
  }
  setFileList([f])
  return false
 }

 const handleUploadFile = async () => {
  if (fileList) {
   const formData = new FormData()
   formData.append('file', fileList[0] as any)
   formData.append('lead_id', `${leadId}`)
   await uploadFile(formData).unwrap()
   onCancel?.()
  }
 }

 return (
  <Modal
   confirmLoading={isLoading}
   okText="Upload File"
   okButtonProps={{ disabled: !fileList?.length }}
   onOk={handleUploadFile}
   title="Upload File"
   onCancel={onCancel}
   visible={visible}
  >
   <Dragger
    onRemove={() => setFileList([])}
    fileList={fileList}
    multiple={false}
    beforeUpload={handleBeforeUpload}
   >
    <p className="ant-upload-drag-icon">
     <i className="fal fa-upload fa-5x" />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
     Supported Upload files are pdf, csv, txt, xls, jpg, jpeg, png, docx, doc
    </p>
   </Dragger>
  </Modal>
 )
}

export default UploadLeadFileModal
