/* eslint-disable prettier/prettier */
import { Col, Row, Form, Input } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { useLogoutMutation } from 'features/auth'
import { useResetPasswordMutation } from 'features/settings/api'
import InnerContentLayout from 'layout/InnerContentLayout'
import React, { FC } from 'react'

export type TResetPass = {
 old_password: string
 new_password: string
 confirm?: string
}
const ResetPassword: FC = () => {
 const [form] = Form.useForm<TResetPass>()
 const [resetPassword, { isLoading }] = useResetPasswordMutation()
 const [logout] = useLogoutMutation()

 const formInit: TResetPass = {
  old_password: '',
  new_password: '',
  confirm: '',
 }

 const onSubmitForm = async () => {
  const values = await form.validateFields()
  delete values.confirm
  await resetPassword(values).unwrap()
  logout()
 }

 const regex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/

 return (
  <InnerContentLayout title="Reset Password">
   <Form
    form={form}
    layout="vertical"
    initialValues={formInit}
    onFinish={onSubmitForm}
    className="px-md-4 py-2 "
   >
    <Col span={24} md={16} lg={8}>
     <Form.Item
      name="old_password"
      rules={[{ required: true, message: 'Current password can\'t be empty' }]}
      label="Current Password"
     >
      <Input.Password placeholder='Current Password'/>
     </Form.Item>
    </Col>
    <Col span={24} md={16} lg={8}>
     <Form.Item
      name="new_password"
      label="New Password"
      rules={[
       { required: true, message: 'New password can\'t be empty' },
       //    { max: 16, message: 'Password should maximum 12 characters' },
       //    { min: 6, message: 'Password should minimum 6 characters' },
       {
        pattern: regex,
        message:
         'Password should contain 8-16 chars with uppercase, lowercase, digit and special characters',
       },
       ({ getFieldValue }) => ({
        validator(_, value) {
         if (!value || getFieldValue('old_password') !== value) {
          return Promise.resolve()
         }
         return Promise.reject(
          new Error(
           'The current password and new password should not be equal!',
          ),
         )
        },
       }),
      ]}
      hasFeedback
     >
      <Input.Password placeholder='New Password'/>
     </Form.Item>
    </Col>
    <Col span={24} md={16} lg={8}>
     <Form.Item
      name="confirm"
      label="Confirm New Password"
      dependencies={['new_password']}
      hasFeedback
      rules={[
       { required: true, message: 'Confirm New password can\'t be empty' },
       //    { max: 16, message: 'Password should maximum 12 characters' },
       //    { min: 6, message: 'Password should minimum 6 characters' },
       {
        pattern: regex,
        message:
         'Password should contain 8-16 chars with uppercase, lowercase, digit and special characters',
       },
       ({ getFieldValue }) => ({
        validator(_, value) {
         if (!value || getFieldValue('new_password') === value) {
          return Promise.resolve()
         }
         return Promise.reject(
          new Error('The new password that you entered do not match!'),
         )
        },
       }),
      ]}
     >
      <Input.Password placeholder='Confirm New Password'/>
     </Form.Item>
    </Col>

    <Col span={24} md={16} lg={8} className="d-flex justify-content-end">
     <BaseButton loading={isLoading} htmlType="submit" buttonType="primary">
     Update
     </BaseButton>
    </Col>
   </Form>
  </InnerContentLayout>
 )
}

export default ResetPassword
