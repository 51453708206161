import { useMemo } from 'react'
import { useLocation } from 'react-router'

export type HashRouteType = 'lead-details'

export const allowedHashRoute: HashRouteType[] = ['lead-details']

export type useHashRouterReturn = {
 isHash?: boolean
 params?: string[]
 hashName?: HashRouteType
}

export const checkisAllowedHashRoute = (hash?: string) => {
 if (!hash) return false
 const hashSplit = hash?.split('/')
 const noneEmptyArray = hashSplit?.filter?.((v) => v !== '')
 if ((noneEmptyArray?.length ?? 0) > 1) {
  return allowedHashRoute.some((v) => hash?.includes(v))
 }
 return false
}

export const getHashParams = (hash: string) => {
 const hashSplit = hash.split('/')
 const noneEmptyArray = hashSplit.filter((v) => v !== '')
 noneEmptyArray.shift()
 return noneEmptyArray
}

export const getHashName = (hash: string) => {
 const hashSplit = hash.split('/')
 const noneEmptyArray = hashSplit.filter((v) => v !== '')
 return noneEmptyArray.shift()?.replace('#', '')
}

const useHashRouter = (): useHashRouterReturn => {
 const { hash } = useLocation()
 const isHash = useMemo(() => checkisAllowedHashRoute(hash), [hash])
 const params = useMemo(() => getHashParams(hash), [hash])
 const hashName = useMemo(() => getHashName(hash), [hash]) as HashRouteType

 return { isHash, hashName, params }
}

export default useHashRouter
