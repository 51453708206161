import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TUserActivityCall } from 'features/leads/types'
import { FC, useState } from 'react'
import useFetchPageNew from 'hooks/useFetchpageNew'
import ActivityLayout from './components/ActivityLayout'

const CallActivity: FC = () => {
 const { leadId, realtorId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityCall>(`lead/profile/${leadId}`, {
   body: { type: 'calllog', ...(realtorId ? { realtor_id: realtorId } : {}) },
   method: 'post',
  })
 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({
     content: flatData?.[val?.index]?.content ?? '',
    })
   }
   totalCount={total}
   totalTitle="Call Log"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    titleNode: <h3 className="mb-0 mt-0">{val?.title}</h3>,
    description: val?.content ?? '',
    updated: val?.updated_at,
   })}
  />
 )
}
export default CallActivity
