import { Form, Input, Modal, Select } from 'antd'
import { FC, useEffect } from 'react'
import { LabelList } from 'static-data'
import { TMode } from 'types'
import { useAddLeadEmailMutation } from '../api'
import { AddEmailType } from '../types'

const initValues: AddEmailType = {
 lead_id: '',
 email: '',
 label: 'Good',
}

const AddEmailModal: FC<{
 editData?: AddEmailType
 onCancel?: () => void
 editID?: any
 leadId?: any
 mode?: TMode
 visible: boolean
}> = ({ mode = 'ADD', leadId, onCancel, visible, editData }) => {
 const [addEmail, { isLoading }] = useAddLeadEmailMutation()
 const [form] = Form.useForm<AddEmailType>()

 const handleSubmit = async () => {
  const values = await form.validateFields()
  if (mode === 'EDIT' && leadId) {
   await addEmail({
    ...values,
    id: editData?.id,
    lead_id: leadId,
   }).unwrap()
   onCancel?.()
  } else if (leadId) {
   await addEmail({
    ...values,
    lead_id: leadId,
   }).unwrap()
   onCancel?.()
  }
  form.resetFields()
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    email: editData.email,
    label: editData.label,
   })
  }
  return () => {
   form.resetFields()
  }
 }, [editData, form, mode])

 return (
  <Modal
   okText={mode === 'EDIT' ? 'Update' : 'Add'}
   confirmLoading={isLoading}
   onCancel={onCancel}
   visible={visible}
   title={`${mode === 'EDIT' ? 'Edit ' : 'Add '} New Email`}
   onOk={handleSubmit}
  >
   <Form initialValues={initValues} form={form} layout="vertical">
    <Form.Item
     name="email"
     label="Email"
     rules={[{ type: 'email', message: 'Invalid Email' }]}
    >
     <Input type="email" placeholder="Enter Email Address" />
    </Form.Item>
    <Form.Item name="label" label="Label">
     <Select options={LabelList} />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddEmailModal
