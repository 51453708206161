import {
 baseAPI,
 baseTransformResponse,
 baseTransformResponse2,
} from 'api/baseApi'
import { ApiResponce } from 'types'
import { TIntegrationFBPages, TIntegrationItem } from '../types'

export const settingApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  integration: query<TIntegrationItem[], void>({
   query: (body) => ({
    url: 'user/integration',
    method: 'get',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  fbPages: mutation<void, TIntegrationFBPages>({
   query: (body) => ({
    url: 'user/facebook/pages',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse2,
  }),
  unlinkFbPage: mutation<void, { page_id?: string }>({
   query: (body) => ({
    url: 'user/facebook-page-unlink',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useIntegrationQuery,
 useFbPagesMutation,
 useUnlinkFbPageMutation,
} = settingApi
