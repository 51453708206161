import React, { memo, useRef, useLayoutEffect } from 'react'
import { Input, InputRef, InputProps } from 'antd'
import usePrevious from './usePrevious'

export interface SingleOTPInputProps extends InputProps {
 focus?: boolean
}

export const SingleOTPInputComponent = (props: SingleOTPInputProps) => {
 const { focus, autoFocus, ...rest } = props
 const inputRef = useRef<InputRef>(null)
 const prevFocus = usePrevious(!!focus)
 useLayoutEffect(() => {
  if (inputRef.current) {
   if (focus && autoFocus) {
    inputRef.current.focus()
   }
   if (focus && autoFocus && focus !== prevFocus) {
    inputRef.current.focus()
    inputRef.current.select()
   }
  }
 }, [autoFocus, focus, prevFocus])

 return <Input ref={inputRef} {...rest} />
}

const SingleOTPInput = memo(SingleOTPInputComponent)
export default SingleOTPInput
