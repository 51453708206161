import React, { FC } from 'react'
import { utils } from 'utils'
import { Modal } from 'antd'
import { OtpModal } from 'components/shared/Common/OtpModal'
import { useLeadExportMutation } from '../api'
import { TLeadCustomSelectAll } from '../types'

const ExportLead: FC<{
 selectedKeys: any[]
 selectedLeadsType?: 'all-leads' | 'my-leads'
 //  selectAll: boolean
 customtSelectAll?: TLeadCustomSelectAll
 unSelected?: any[]
 filterInfo?: string
 onCancel: () => void
}> = ({
 selectedLeadsType,
 selectedKeys,
 //  selectAll,
 customtSelectAll,
 unSelected,
 filterInfo,
 onCancel,
}) => {
 const [exportLead, { isLoading }] = useLeadExportMutation()

 const handleExportLead = async (): Promise<void> => {
  const data = await exportLead({
   ...(!customtSelectAll
    ? { lead_id: selectedKeys }
    : {
       unselected: unSelected,
       customtSelectAll,
       filter_info: filterInfo,
      }),
   crm_list_type: selectedLeadsType === 'my-leads' ? 'myleads' : 'AllLeads',
  }).unwrap()
  if (data) {
   utils.downloadFile(data)
  }
  onCancel()
 }
 return (
  <Modal
   visible
   footer={null}
   onCancel={onCancel}
   className="export-lead__modal"
   title="Export Lead"
  >
   <OtpModal handleClickVerify={handleExportLead} />
  </Modal>
 )
}

export default ExportLead
