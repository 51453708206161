/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react'
import { Table } from '@tanstack/react-table'
import {
 SortableContext,
 horizontalListSortingStrategy,
} from '@dnd-kit/sortable'
import MTColGroup from '../MTColGroup'
import MTHeaderCell from './MTHeaderCell'

const MTHeader: FC<{
 table: Table<any>
 columnOrder?: string[]
 isDraggableColumn: boolean
}> = ({ table, columnOrder, isDraggableColumn }) => {
 const lastColumn: any = table.getAllColumns()[table.getAllColumns().length - 1]
 return (
  <table>
   <MTColGroup type="header" isDraggableColumn={isDraggableColumn} />
   <thead className="m-table-thead">
    {table.getHeaderGroups().map((headerGroup) => (
     <tr key={headerGroup.id}>
      {/* {isDraggableColumn ? ( */}
      {/* <SortableContext
       items={columnOrder ?? []}
       strategy={horizontalListSortingStrategy}
       disabled={!isDraggableColumn}
      > */}
      {headerGroup.headers.map((header: any) =>
       isDraggableColumn &&
       header?.id !== 'selection' &&
       header?.id !== 'name' ? (
        <SortableContext
         items={columnOrder ?? []}
         strategy={horizontalListSortingStrategy}
         //  disabled={!isDraggableColumn && header?.id !== 'selection'}
         key={header?.id}
        >
         <MTHeaderCell
          isScroll={lastColumn.columnDef.meta?.fixed === 'right'}
          filter={header.column.columnDef.meta?.filter}
          key={header?.id}
          columnKey={header?.id}
          header={header}
          isDraggableColumn={isDraggableColumn}
         />
        </SortableContext>
       ) : (
        <MTHeaderCell
         isScroll={lastColumn.columnDef.meta?.fixed === 'right'}
         filter={header.column.columnDef.meta?.filter}
         key={header.id}
         columnKey={header?.id}
         header={header}
         isDraggableColumn={isDraggableColumn}
        />
       ),
      )}
      {/* </SortableContext> */}
      {/* //   ) : (
    //    headerGroup.headers.map((header: any) => {
    //     return (
    //      <MTHeaderCell
    //       isScroll={lastColumn.columnDef.meta?.fixed === 'right'}
    //       filter={header.column.columnDef.meta?.filter}
    //       key={header.id}
    //       header={header}
    //      />
    //     )
    //    })
    //   )} */}

      {!isDraggableColumn && (
       <th className="m-table-cell" style={{ position: 'sticky', right: 0 }} />
      )}
     </tr>
    ))}
   </thead>
  </table>
 )
}

export default MTHeader
