import React, { FC } from 'react'
import P, { PhoneInputProps } from 'react-phone-input-2'

const PhoneInput = (P as any).default ? (P as any).default : P
const InputPhoneNumber: FC<PhoneInputProps> = (props) => (
 <PhoneInput
  inputClass="ant-input w-100"
  {...props}
  // isValid={false}
  countryCodeEditable={false}
  // placeholder='000000000000'
  country="ca"
  onlyCountries={['ca']}
 />
)

export default InputPhoneNumber
