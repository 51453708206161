import { Divider } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { TGetTextTemplates } from 'features/settings'
import React, { FC } from 'react'

const TextTemplateViewModal: FC<{
 data?: TGetTextTemplates
 visible?: boolean
 onCancel?: () => void
}> = ({ visible, onCancel, data }) => (
 <Modal
  footer={null}
  title="Text Template Preview"
  visible={visible}
  onCancel={onCancel}
 >
  <h3>
   <strong>Name:</strong> {data?.name}
  </h3>
  <Divider className="my-2" plain />
  <p className="mb-0">{data?.body}</p>
 </Modal>
)

export default TextTemplateViewModal
