import { TLeadCustomSelectAll, TSelectAllB } from 'features/leads'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

type TRowSelectionState = {
 selection?: any[]
 onChangeSelection: (v?: any[]) => void
 onSelectedAll: (v: boolean) => void
}

type TUseSelectAllRes<T> = {
 selectAll: boolean
 setSelectAll: Dispatch<SetStateAction<boolean>>
 unSelected: any[]
 setUnSelected: Dispatch<SetStateAction<any[]>>
 selectedKeys: any[]
 handleBody: (values: any) => TSelectAllB | any
 setSelectedRow: Dispatch<SetStateAction<any[]>>
 selectedRows: any[]
 rowSelectionState: TRowSelectionState
 onSelectRow: (data: T, checked?: boolean) => void
 selectAllType: TLeadCustomSelectAll
 setSelectAllType: Dispatch<SetStateAction<TLeadCustomSelectAll>>
}

const useSelectAll = <T>(
 filterInfo: string,
 flatData?: T[],
): TUseSelectAllRes<T> => {
 const [selectAll, setSelectAll] = useState(false)
 const [unSelected, setUnSelected] = useState<any[]>([])
 const [selectedRows, setSelectedRow] = useState<any[]>([])
 const [selectAllType, setSelectAllType] = useState<TLeadCustomSelectAll>(null)

 const selectedKeys = useMemo(
  () => selectedRows?.map((i) => i?.id),
  [selectedRows],
 )

 const handleBody = (values?: any): TSelectAllB | any => {
  if (!selectAll) {
   return values
  }

  return {
   unselected: unSelected,
   select_all: selectAll,
   filter_info: filterInfo,
  }
 }
 const rowSelectionState: TRowSelectionState = {
  selection: selectedRows,
  onChangeSelection: (val) => {
   setSelectedRow(val ?? [])
  },
  onSelectedAll: (b) => {
   setUnSelected([])
   setSelectAll?.(b)
  },
 }

 const onSelectRow = (data: any, checked?: boolean): void => {
  if (selectAll) {
   setUnSelected((prv) => {
    let array = prv ?? []
    if (checked === false) {
     array = [...array, data?.id]
    } else {
     array = prv.filter((m) => m !== data?.id)
    }
    return [...array]
   })
  } else {
   setUnSelected([])
  }
 }

 return {
  selectAll,
  setSelectAll,
  unSelected,
  setUnSelected,
  selectedKeys,
  handleBody,
  selectedRows,
  setSelectedRow,
  rowSelectionState,
  onSelectRow,
  selectAllType,
  setSelectAllType,
 }
}

export default useSelectAll
