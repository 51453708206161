import { Spin, Modal } from 'antd'
import { useGetNewsLetterQuery } from 'features/automation/api'
import { TAutomationTypes } from 'features/automation/types'
import React, { FC } from 'react'

const MarketUpdatesViewModal: FC<{
 visible: boolean
 onToggle: () => void
 id: number
 type: TAutomationTypes
}> = ({ onToggle, visible, id, type }) => {
 const { data, isLoading } = useGetNewsLetterQuery({ id, type })
 return (
  <Modal
   title={
    type === 'Real-Estate-News-Letter'
     ? 'Real Estate Newsletter'
     : 'Market Updates'
   }
   visible={visible}
   onCancel={onToggle}
   footer={null}
   className="market-updates-modal"
  >
   <Spin spinning={isLoading}>
    {data?.name && (
     <h3>
      <strong>Name: </strong>
      {data?.name}
     </h3>
    )}
    {data?.subject && (
     <h3>
      <strong>Subject: </strong>
      {data?.subject}
     </h3>
    )}
    <div
     className="__body"
     dangerouslySetInnerHTML={{ __html: data?.body ? `${data?.body}` : '' }}
    />
   </Spin>
  </Modal>
 )
}

export default MarketUpdatesViewModal
