import { baseTime } from 'lib'
import { TInboxEmail, TInboxItemMsgs, TInboxSMS } from './types'

export const dateFormat = (date: string, withTime = false): string => {
 if (baseTime(date).isSame(baseTime(), 'day')) {
  if (withTime) {
   return `Today ${baseTime(date).format('hh:mm A')}`
  }
  return 'Today'
 }
 if (baseTime(date).isSame(baseTime().subtract(1, 'day'), 'day')) {
  if (withTime) {
   return `Yesterday ${baseTime(date).format('hh:mm A')}`
  }
  return 'Yesterday'
 }
 if (withTime) {
  return baseTime(date).format('DD MMM YY hh:mm A')
 }
 return baseTime(date).format('ddd, DD MMM')
}

export const groupMailByDate = (
 type: 'emails' | 'sms',
 list?: TInboxItemMsgs[],
): TInboxEmail[] & TInboxSMS[] => {
 if (!list || (list && list.length === 0)) return []
 const msgList: TInboxEmail[] & TInboxSMS[] = []

 list.forEach((item) => {
  if (item.updated_at) {
   const date = dateFormat(item.updated_at)
   const index = msgList.findIndex((group) => group.date === date)

   if (index !== -1) {
    if (type === 'sms') {
     msgList[index].sms?.unshift(item)
    } else if (type === 'emails') {
     msgList[index].emails?.push(item)
    }
   } else if (type === 'sms') {
    const newGroup = { date, sms: [item] }
    msgList.push(newGroup)
   } else {
    const newGroup = { date, emails: [item] }
    msgList.push(newGroup)
   }
  }
 })

 return msgList
}
