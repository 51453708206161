/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import {
//  FIlterObjectType,
//  TableFixedProps,
// } from 'components/shared/DataTable/VirtualizedTable/type'

import {
 ColumnDef,
 ColumnFilter,
 ColumnFiltersState,
 RowSelectionState,
} from '@tanstack/react-table'
import { CSSProperties } from 'react'
import {
 MDataTableColumnsNew,
 MDataTableFixedProps,
 MFIlterObjectType,
} from '../type'

type stickyColumnProps = {
 fixed?: MDataTableFixedProps
 el?: 'td' | 'th'
 scrollWidth?: number
 hasSelection?: boolean
}

export const stickyColumn = ({
 el,
 fixed,
 hasSelection,
 scrollWidth,
}: stickyColumnProps): CSSProperties => {
 if (!fixed) return {}
 if (fixed === 'left') {
  return {
   position: 'sticky',
   left: hasSelection ? 45 : 0,
   //    boxShadow: '1px 1px 10px #0004',
  }
 }
 if (fixed === 'right') {
  return {
   position: 'sticky',
   right: el === 'th' ? 0 + Number(scrollWidth || 0) : 0,
  }
 }
 return {}
}

export const convertRowObject = (
 arry?: any[],
 rowId = 'id',
): RowSelectionState => {
 if (!arry) return {}
 const value: RowSelectionState = {}
 arry.forEach((v) => {
  if (v[rowId]) {
   value[v[rowId]] = true
  }
 })
 return value
}

export const filterArray2Object = (
 arry?: ColumnFiltersState,
 keys?: { [key: string]: any },
) => {
 const newVal: MFIlterObjectType = {}
 //  const keys = {}
 if (arry && arry.length > 0) {
  arry.forEach((obj) => {
   newVal[keys ? keys[obj.id] : obj.id] = obj.value
  })
 }
 return newVal
}

export const filterObject2Array = (
 object?: MFIlterObjectType,
 keys?: { [key: string]: any },
) => {
 const newVal: ColumnFiltersState = []
 if (object) {
  for (const key in object) {
   if (Object.prototype.hasOwnProperty.call(object, key)) {
    if (object[key]) {
     const element: ColumnFilter = {
      id: keys ? keys[key] : key,
      value: object[key],
     }
     newVal.push(element)
    }
   }
  }
 }

 return newVal
}
export const updateFilterArray = (
 prev?: ColumnFilter[],
 next?: ColumnFilter[],
) => {
 let newFilter: ColumnFilter[] = prev || []
 if (newFilter && newFilter.length > 0 && next && next.length > 0) {
  next.forEach((item, i) => {
   if (newFilter.find((v) => v.id === item.id)) {
    const index = newFilter.findIndex((v) => v.id === item.id)
    if (
     (typeof item.value === 'string' && item.value !== 'All') ||
     (Array.isArray(item.value) && item.value.length > 0)
    ) {
     newFilter[index].value = item.value
    } else {
     newFilter = newFilter.filter((v) => v.id !== item.id)
    }
   } else {
    newFilter.push(item)
   }
  })
 } else if (next && next.length > 0) {
  newFilter = newFilter.concat(next)
 }

 return newFilter
}

export const formatCols = (cols: MDataTableColumnsNew[]): ColumnDef<any>[] => {
 return cols.map((item) => ({
  minSize: item.minSize,
  size: item.size,
  accessorKey: item.dataIndex,
  ...(item.render
   ? {
      cell: ({ getValue, row: { original, index } }) =>
       item.render?.(getValue(), original, index),
     }
   : {}),
  header: item.title,
  // filterId: item?.filterId,
  meta: {
   ellipsis: item.ellipsis,
   fixed: item.fixed,
   filter: item.filter,
   dragId: `${item?.dragId}`,
  },
 }))
}
