import { Avatar, Card, Checkbox, Col, Row, Space } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { TPropertyListing } from 'features/property'
import { FC, ReactNode, useMemo, useState } from 'react'
import { TListingType } from 'types'
import { utils } from 'utils'
import PropertyBBC from '../Property/PropertyBBC'
import PropertyTag from '../Property/PropertyTag'
import PropertyDetailsModal from '../PropertyDetails/PropertyDetailsModal'

const PropertyCard: FC<{
 loading?: boolean
 checked?: boolean
 selection?: boolean
 type?: TListingType
 item?: TPropertyListing
 onChange?: () => void
 cardType?: 'normal' | 'inside-mail-modal' | 'inside-sms-modal'
 dataState?: TPropertyListing[]
 handleDataState?: (val?: TPropertyListing[]) => void
 customCloseBtn?: ReactNode
 onClose?: () => void
 shouldRedirect?: boolean
}> = ({
 item,
 selection = false,
 checked = false,
 loading = false,
 onChange,
 cardType = 'normal',
 dataState,
 handleDataState,
 customCloseBtn = '',
 onClose,
 shouldRedirect = true,
}) => {
 const [show, setShow] = useState(false)
 const removeHandler = (mlNum?: string) => {
  const filtered = dataState?.filter((x) => x.ml_num !== mlNum)
  handleDataState?.(filtered)
 }

 const isPreCon = item?.listing_type === 'preconstruction'
 const { listing_type } = item || {}

 const buildPrice = useMemo(() => {
  if (isPreCon) {
   return (
    <div>
     From :{' '}
     <span className="pro-crd-label text-primary">
      {utils.pricefy(item?.price_range_from)}
      {item?.price_range_to ? ` - ${utils.pricefy(item?.price_range_to)}` : ''}
     </span>
    </div>
   )
  }
  if (listing_type === 'sale' || listing_type === 'rent' || isPreCon) {
   return (
    <div>
     Listed Price :{' '}
     <span className="pro-crd-label">{utils.pricefy(item?.listed_price)}</span>
    </div>
   )
  }
  if (listing_type === 'sold' || listing_type === 'leased') {
   return (
    <div>
     {listing_type === 'leased' ? 'Leased' : 'Sold'} Price :{' '}
     <span
      className="pro-crd-label"
      style={{ marginRight: '5px', color: 'red' }}
     >
      {utils.pricefy(item?.sold_price)}
     </span>
     Listed Price :{' '}
     <span className="pro-crd-label" style={{ textDecoration: 'line-through' }}>
      {utils.pricefy(item?.listed_price)}
     </span>
    </div>
   )
  }
  return ''
 }, [item])

 return (
  <>
   <Card
    loading={loading}
    className={`pro--card ${checked ? 'pro--card-checked' : ''}`}
   >
    {item?.open_house && <div className="open-house-tag">Open House</div>}
    <BtnAnch
     onClick={() => {
      if (shouldRedirect) {
       setShow(true)
      }
     }}
    >
     <div className="pro-card1">
      <div className="img-parent">
       {item?.images && item.images.images ? (
        <Avatar
         src={`${item?.images.directory}Photo${item?.images.ml_num}-${item?.images.images}.jpeg`}
         size={80}
        />
       ) : (
        <Avatar size={80} src="/images/CondoApt.png" />
       )}
      </div>
      <div className="ul-parent">
       <ul className="pro--card-ul">
        <li>
         <p className="pro-crd-label p-0">
          {isPreCon ? item?.property_name : item?.addr}
         </p>
        </li>
        <li>
         <p>
          <i className="fas fa-map-marker-alt me-1" />
          {isPreCon ? item?.full_address : `${item?.city}, ${item?.province}`}
         </p>
        </li>
        <li className="pro-crd-bbctt-wrap">
         <PropertyBBC
          bath={item?.bath}
          bed={item?.bed}
          maxBed={item?.bed_plus}
          garage={item?.garage_space}
          bedMax={item?.bed_max}
          bedMin={item?.bed_min}
          sqftMax={item?.sqft_max}
          sqftMin={item?.sqft_min}
          isPrecon={isPreCon}
         />
         <PropertyTag
          tag={item?.listing_type}
          constructionStatus={item?.construction_status}
          isPreCon={isPreCon}
         />
         <span title={item?.building_type}>{item?.building_type}</span>
        </li>

        <li>{buildPrice}</li>
        {!isPreCon && (
         <li>
          <p>
           MLS® # : <span className="pro-crd-label"> {item?.ml_num}</span>
          </p>
         </li>
        )}
        {item?.brokerage && (
         <li>
          <p className="pro-card-brokerage" title={item?.brokerage}>
           Listed Brokerage :{' '}
           <span className="pro-crd-label">{item?.brokerage}</span>
          </p>
         </li>
        )}
       </ul>
      </div>
     </div>
    </BtnAnch>
    {cardType !== 'normal' && dataState && dataState?.length > 1 && (
     <div className="pro-selction">
      <BtnAnch onClick={() => removeHandler(item?.ml_num)}>
       <i className="fad fa-times-circle" />
      </BtnAnch>
     </div>
    )}
    {selection && (
     <div className="pro-selction">
      <Checkbox onChange={onChange} checked={checked} value={item?.ml_num} />
     </div>
    )}
    {onClose && (
     <div className="pro-selction">
      <BtnAnch onClick={onClose}>
       <i className="fas fa-times" />
      </BtnAnch>
     </div>
    )}
    {customCloseBtn}
   </Card>
   {item?.slug && show && (
    <PropertyDetailsModal
     value={item?.slug}
     visible={show}
     onHide={() => setShow(false)}
     isPreCon={item?.listing_type === 'preconstruction'}
    />
   )}
  </>
 )
}

export default PropertyCard
