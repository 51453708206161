/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Avatar, AvatarProps, Tooltip } from 'antd'
import React, { FC, ReactNode } from 'react'
import Utils from 'utils/helper'
import style from './AvatarStatus.module.scss'

export const AvatarStatus: FC<{
 titleNode?: ReactNode
 className?: string
 titleSize?: number
 avatar?: AvatarProps
 action?: ReactNode
 title?: string
 subTitle?: any
 content?: ReactNode
 theme?: 'dark' | 'light'
 showAvatar?: boolean
 titleClassName?: string
 showTooltip?: boolean
 //  avatarIconValue?: ReactNode
}> = ({
 titleNode,
 action,
 avatar,
 subTitle,
 title,
 theme = 'light',
 titleSize = 12,
 content,
 className,
 showAvatar = true,
 titleClassName,
 showTooltip = false,
 //  avatarIconValue,
}) => (
 <div
  className={`avatar-status ${className ?? ''}${action ? style.isAction : ''} ${
   style.avatat
  } ${theme === 'dark' ? style.avatarDark : ''}`}
 >
  {showAvatar && (
   <Avatar
    className="bg-primary"
    shape="square"
    size={40}
    style={{ fontSize: '15px' }}
    icon={
     // avatarIconValue ??
     Utils.shortName(title || '')
    }
    {...avatar}
   />
  )}
  <div
   className={` ${style.avt_content} ${style['avatar-status-title']} ${titleClassName}`}
  >
   {titleNode ||
    (title ? (
     showTooltip ? (
      <Tooltip placement="topLeft" title={title}>
       <h5
        style={{
         fontSize: `${titleSize}px`,
        }}
       >
        {title}
       </h5>
      </Tooltip>
     ) : (
      <h5
       title={title}
       style={{
        fontSize: `${titleSize}px`,
       }}
      >
       {title}
      </h5>
     )
    ) : null)}
   {subTitle && <h6>{subTitle}</h6>}
   {content && <p className="mb-0">{content}</p>}
  </div>
  {action && <div className={style.avtaction}>{action}</div>}
 </div>
)

AvatarStatus.defaultProps = {
 titleSize: 16,
}
