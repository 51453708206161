import { Editor } from '@tinymce/tinymce-react'
import {
 useGetEmailTemplateNamesQuery,
 useUploadTinyFileMutation,
} from 'features/settings'
import { useGlobal } from 'raducer/global'
import { FC } from 'react'

const toolbars =
 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | fullscreen  preview save print code image fullpage link | casechange permanentpen formatpainter | charmap insertfile  pageembed   anchor codesample | a11ycheck ltr rtl | showcomments addcomment | inserttable'

const EmailTextArea: FC<{
 value?: string
 emailTemplate?: boolean
 mergfield?: boolean
 height?: number
 plugins?: string | string[]
 toolbar?: string
 ref?: any
 onChange?: (val: any) => void
 setup?: (editor: Editor) => void
 onSelectTemplate?: (id?: string) => void
 disabled?: boolean
 rmMergeFields?: string[]
}> = ({
 plugins,
 emailTemplate = false,
 mergfield = false,
 height = 300,
 ref,
 onChange,
 onSelectTemplate,
 value,
 disabled,
 rmMergeFields = [],
}) => {
 const { data, isLoading } = useGetEmailTemplateNamesQuery(null, {
  skip: !emailTemplate,
 })

 const { global } = useGlobal()
 // const [updatedValue, setValue] = useState('')
 const [uploadImg] = useUploadTinyFileMutation()

 if ((emailTemplate && isLoading) || global.body_mergefield?.length === 0) {
  return <>Email Template Fetching...</>
 }

 return (
  <Editor
   key="Editor-email"
   value={value}
   onEditorChange={onChange}
   ref={ref}
   apiKey={global?.tiny_mce_key ?? ''}
   disabled={disabled}
   init={{
    menubar: false,
    body_class: 'w-100',
    height,
    plugins: [
     'code',
     'fullscreen',
     'preview',
     'image',
     'link',
     'fullpage',

     'pagebreak',
     'searchreplace',
     'visualblocks',
     'visualchars',
     'insertdatetime',
     'nonbreaking',
     'table',
     'paste',
     'help',

     'lists advlist',
     'charmap',
     'hr',
    ],
    toolbar1: `mergfield template  ${toolbars}`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    images_upload_handler: async (
     blobInfo: any,
     success: any,
     failure: any,
    ) => {
     const formData = new FormData()
     formData.append('file', blobInfo.blob(), blobInfo.filename())
     try {
      const data = await uploadImg(formData).unwrap()
      success(data) as any
     } catch (error) {
      failure('Upload Image Error') as any
     }
    },
    setup: (editor) => {
     if (mergfield) {
      editor.ui.registry.addMenuButton('mergfield', {
       text: 'Merge Field',
       fetch: (callback) => {
        callback(
         global && global.body_mergefield && global.body_mergefield.length > 0
          ? global?.body_mergefield
             ?.filter((obj) => !rmMergeFields?.includes?.(obj.name ?? ''))
             .map((v) => ({
              onAction: () =>
               editor.editorCommands.execCommand(
                'mceInsertContent',
                false,
                v.id,
               ),
              type: 'menuitem',
              value: `${v.id}`,
              text: v.name,
             }))
          : [],
        )
       },
      })
     }
     if (emailTemplate && data && data?.length > 0) {
      editor.ui.registry.addMenuButton('template', {
       text: 'Templates',
       fetch: (callback) => {
        callback(
         data?.map((v) => ({
          onAction: () => {
           onSelectTemplate?.(v.id)
          },
          text: v.name,
          value: v.id,
          type: 'menuitem',
         })),
        )
       },
      })
     }
    },
   }}
  />
 )
}

export default EmailTextArea
