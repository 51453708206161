import { FC } from 'react'
import { useMTTable } from './MTContext'

const MTColGroup: FC<{
 colWidths?: any[]
 type?: 'header' | 'body'
 isDraggableColumn: boolean
}> = ({ type, isDraggableColumn }) => {
 const { table } = useMTTable()

 return (
  <colgroup>
   {table?.getAllColumns().map((header, index) => (
    <col style={{ width: `${header.getSize()}px` }} key={index} />
   ))}

   {type === 'header' && !isDraggableColumn && (
    <col style={{ width: '15px' }} />
   )}
  </colgroup>
 )
}

export default MTColGroup

MTColGroup.defaultProps = {
 type: 'body',
}
