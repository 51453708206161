import { Row, Card } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import {
 useGetScheduleEmailByIdQuery,
 usePreviewScheduleSmsQuery,
} from 'features/inbox/api'
import { FC } from 'react'
import { useParams } from 'react-router'

const InboxScheduleMsg: FC = () => {
 const params = useParams()

 if (params.mode === 'email') {
  return <EmailViewBlock id={params.id} />
 }
 return <SMSViewBlock id={params.id} />
}

const handleToFieldMessage = (val?: string[]): string => {
 return `To: ${val?.[0] ?? ''} ${
  (val?.length ?? 0) > 1 ? `and ${(val?.length ?? 1) - 1} more recipients` : ''
 }`
}

export default InboxScheduleMsg

const EmailViewBlock: FC<{
 id: any
}> = ({ id }) => {
 const { data, isFetching } = useGetScheduleEmailByIdQuery({ id })

 if (isFetching) {
  return <BlockLoader mode="full" />
 }
 return (
  <Card
   title={
    data?.email_to?.length !== 0 && (
     <div className="d-flex flex-column">
      {/* <span>To:</span> */}
      {/* {data?.email_to?.map((i, n) => (
       <span className="ms-2" key={n}>
        {i}
        {n + 1 !== data?.email_to?.length ? ',' : ''}{' '}
       </span>
      ))} */}
      {handleToFieldMessage(data?.email_to)}
     </div>
    )
   }
  >
   <h4>
    Subject:
    {data?.subject ?? ''}
   </h4>
   {data?.body && (
    <div
     dangerouslySetInnerHTML={{
      __html: `${data?.body}`,
     }}
    />
   )}
   <Row gutter={[10, 10]} className="attachments-wrapper">
    {data?.attachment?.map?.((v: any, index) => (
     <a
      target={
       ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
        v?.split('.').at(-1),
       )
        ? '_blank'
        : '_self'
      }
      href={v}
      key={index}
      rel="noreferrer"
     >
      <div className="attachment">
       <i className="fas fa-file" />
      </div>
     </a>
    ))}
   </Row>
  </Card>
 )
}

const SMSViewBlock: FC<{
 id: any
}> = ({ id }) => {
 const { data, isLoading, isFetching } = usePreviewScheduleSmsQuery({
  id,
 })

 if (isLoading || isFetching) {
  return <BlockLoader mode="full" />
 }
 return (
  <Card
   title={
    data?.number?.length !== 0 && (
     <div className="d-flex flex-column">
      {/* <span>To:</span>
      {data?.number?.map((i, n) => (
       <span className="ms-2" key={n}>
        {i}
        {n + 1 !== data?.number?.length ? ',' : ''}{' '}
       </span>
      ))} */}
      {handleToFieldMessage(data?.number)}
     </div>
    )
   }
  >
   <p>{data?.body}</p>
  </Card>
 )
}
