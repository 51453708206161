/* eslint-disable no-nested-ternary */
import { Card, Collapse, Empty } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TUserActivityWeb } from 'features/leads/types'
import useFetchPage from 'hooks/useFetchPage'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { formatDate } from 'utils/functions'
import useFetchPageNew from 'hooks/useFetchpageNew'
import { baseTime } from 'lib'
import AllActivity from './AllActivity'
import ActivityLayout from './components/ActivityLayout'

const Enquiry: FC = () => {
 const { leadId, realtorId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityWeb>(`lead/profile/${leadId}`, {
   body: { type: 'web', ...(realtorId ? { realtor_id: realtorId } : {}) },
   method: 'post',
  })
 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({
     ...flatData?.[val?.index],
    })
   }
   totalCount={total}
   totalTitle="WEB Activity"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => {
    return {
     title:
      val?.web?.length > 1
       ? `${formatDate?.(val?.web?.at(-1)?.wcreated_at)} - ${baseTime(
          val?.web?.[0]?.wcreated_at,
         ).format('hh:mm A')}`
       : val?.web?.length === 1
       ? formatDate?.(val?.web?.[0]?.wcreated_at)
       : '',
    }
   }}
  />
 )
}
export default Enquiry

// const Enquiry: FC = () => {
//  const { leadId } = useLeadDetails()
//  const { Panel } = Collapse
//  const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
//   useFetchPageNew<TWebDetails>(`lead/profile/${leadId}`, {
//    body: { type: 'web' },
//    method: 'post',
//   })
//  // useFetchPage<TWebDetails>(`lead/profile/react/${leadId}`, {
//  //  method: 'post',
//  //  body: { type: ['web'] },
//  // })

//  if (isLoading) {
//   return <BlockLoader />
//  }
//  if (!flatData || flatData.length === 0) {
//   return (
//    <div className="no-data-empty-compo">
//     <Empty
//      image={<i className="fas fa-clipboard no-data-image" />}
//      description={<span className="no-data-heading">No Latest Activities</span>}
//     >
//      <p className="no-data-description">
//       No results found related to leads latest activities
//      </p>
//     </Empty>
//    </div>
//   )
//  }
//  return (
//   <Card
//    //  title="Web"
//    //  title={`Web ${flatData?.length > 0 ? `(${flatData?.length})` : ''}`}
//    title={`Web (${total})`}
//    loading={isLoading}
//   >
//    <LoeadMorePagination
//     loading={isRefetching}
//     hasMore={hasMore}
//     fethMore={fetchMore}
//    >
//     <Collapse className="mts-collapse activity-collaps">
//      {flatData &&
//       flatData.map((item, i) => (
//        <Panel
//         showArrow={false}
//         key={i}
//         header={
//          <AvatarStatus
//           titleNode={
//            item?.updated ? (
//             <h5 className="text-capitalize">
//              {/* {moment(item.updated).format('ll')} */}
//              {/* {moment(item.updated).format('DD-MM-YYYY hh:mm A')} */}
//              {formatDate?.(item.updated)}
//             </h5>
//            ) : (
//             ''
//            )
//           }
//           avatar={{
//            size: 40,
//            icon: <SvgEnquiryIcon />,
//           }}
//          />
//         }
//        >
//         <div style={{ display: 'flex' }}>
//          <div>
//           <ul>
//            {item.web &&
//             item.web.length > 0 &&
//             item.web.map((web, index) => (
//              <div key={index}>
//               {web.wurl ? (
//                <li style={{ marginBottom: '1em' }}>
//                 <a
//                  target="_blank"
//                  href={web.wurl}
//                  rel="noreferrer"
//                  style={{ color: 'blue' }}
//                 >
//                  {web.Wcontent}
//                 </a>
//                </li>
//               ) : (
//                <li>
//                 {' '}
//                 <p>{web.Wcontent}</p>
//                </li>
//               )}
//              </div>
//             ))}
//           </ul>
//          </div>
//         </div>
//        </Panel>
//       ))}
//     </Collapse>
//    </LoeadMorePagination>
//   </Card>
//  )
// }

// export default Enquiry
