import {
 Col,
 Form,
 Input,
 InputNumber,
 Row,
 Select,
 Space,
 Slider,
 Checkbox,
} from 'antd'
import {
 useGetCityListQuery,
 //  useGetCityV1Query,
 //  useGetCityV2Query,
} from 'api/baseApi'

import { BaseButton } from 'components/elements/BaseButton'
import PriceRangerDropDown from 'components/shared/Property/PriceRangerDropDown'
// import useAuth from 'hooks/useAuth'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FilterBedLists, Frequencys } from 'static-data'
import { TMode } from 'types'
import { FormInstance } from 'antd/es/form/Form'
import { useGetCrmFiltersQuery } from 'features/property'
import BaseNumberInput from 'components/elements/BaseNumberInput'
import { useAddNewAlertMutation, useEditNewAlertMutation } from '../api'
import { TAlertFeedType, TPutAlertType } from '../types'

const formInit: TPutAlertType & { priceRange?: { min?: any; max?: any } } = {
 name: '',
 area: [],
 frequency: 'Daily',
 bed: undefined,
 bath: undefined,
 min_price: 0,
 max_price: 5000000,
 count: 1,
 fead: 'forsale',
 priceRange: { min: 0, max: 5000000 },
 price_drop: [1, 50],
}

const AlertFormSection: FC<{
 show: boolean
 mode?: TMode
 type: TAlertFeedType
 editData?: TPutAlertType
 onCancel?: () => void
 refresh?: () => void
 form: FormInstance<
  TPutAlertType & {
   priceRange?:
    | {
       min?: any
       max?: any
      }
    | undefined
  }
 >
 isReadOnly?: boolean
}> = ({
 mode = 'ADD',
 type = 'forsale',
 editData,
 onCancel,
 refresh,
 form,
 show,
 isReadOnly = false,
}) => {
 const [showEdit, setShowEdit] = useState(show)
 const [dynamicSelectAll, setDynamicSelectAll] = useState<any>({})
 //  const { data: v1Data } = useGetCityV1Query()
 //  const { data: v2Data } = useGetCityV2Query()
 const { data: cityList } = useGetCityListQuery()

 const [addAlert, { isLoading: addLoading }] = useAddNewAlertMutation()
 const priceDropValue = Form.useWatch('price_drop', form)

 //  const { user } = useAuth()
 //  const { api_version } = user || {}

 const [editAlert, { isLoading: editLOading }] = useEditNewAlertMutation()
 const propertyType = Form.useWatch('property_type', form)

 const filterQuery = useMemo(() => {
  return `${
   propertyType && propertyType?.length !== 0
    ? `?property_type=${JSON.stringify(propertyType)}`
    : ''
  }${
   (propertyType && propertyType?.length === 0) ||
   propertyType === undefined ||
   propertyType === null
    ? '?'
    : '&'
  }from=alert&listing=${type === 'openhouse' ? 'open-house' : type}
  `
 }, [propertyType])

 const { data: propertyFilterData, refetch } = useGetCrmFiltersQuery(
  //   {
  //   ...(filterQuery ? { val: filterQuery } : { val: '' }),
  //  }
  filterQuery,
 )
 useEffect(() => refetch(), [filterQuery])

 const handleSubmitForm = async (): Promise<void> => {
  const values = await form.validateFields()

  const finalValues: any = values

  finalValues.min_price = values.priceRange?.min
  finalValues.max_price = values.priceRange?.max
  finalValues.fead = type
  finalValues.id = editData?.id

  // finalValues.property_status =
  //  values?.property_status && values?.property_status?.length > 0
  //   ? values?.property_status?.toString().split(',')
  //   : null

  // finalValues.property_sub_type =
  //  values?.property_sub_type && values?.property_sub_type?.length > 0
  //   ? values?.property_sub_type?.toString().split(',')
  //   : null

  finalValues.price_drop_min = values?.price_drop?.[0]
  finalValues.price_drop_max = values?.price_drop?.[1]

  delete finalValues.price_drop
  delete finalValues.priceRange

  if (mode === 'EDIT') {
   await editAlert(finalValues).unwrap()
  } else {
   await addAlert(finalValues).unwrap()
  }

  refresh?.()
  form.resetFields()
  setShowEdit(!showEdit)
  onCancel?.()
 }

 useEffect(() => {
  if (
   mode === 'EDIT' &&
   editData &&
   (!propertyType || propertyType?.length === 0)
  ) {
   // prettier-ignore
   const { name, area, frequency, count, bed, bath, min_price, max_price, price_drop_min, price_drop_max, dynamic_filters } =
    editData || {}

   if (propertyFilterData?.length) {
    propertyFilterData
     ?.filter((i) => i?.type === 'multi-select')
     ?.forEach((i) => {
      if (
       (dynamic_filters as any)?.find?.((k: any) => k?.key === i?.name)?.values
        ?.length === i?.options?.length
      ) {
       setDynamicSelectAll((prv: any) => ({
        ...prv,
        [i.name]: true,
       }))
      }
     })
   }
   const dF = (dynamic_filters as any)?.reduce?.((acc: any, item: any) => {
    acc[item.key] = item.values
    return acc
   }, {})

   const dynamicValues = Object.assign(
    {},
    ...(
     propertyFilterData?.map((i) => ({ key: i?.name, type: i?.type })) ?? []
    ).map((j: any) => {
     const key: 'id' = j?.key
     return {
      [j?.key]: dF?.[key] ? dF?.[key] : j?.type === 'multi-select' ? [] : null,
     }
    }),
   )
   form.setFieldsValue({
    name,
    area,
    frequency,
    count,
    bed,
    bath,
    priceRange: { min: min_price, max: max_price },
    price_drop: [Number(price_drop_min ?? '1'), Number(price_drop_max ?? '50')],
    ...dynamicValues,
   })
  }
 }, [editData, form, mode, showEdit, propertyFilterData, propertyType])

 //  const cityData =
 //   api_version === 'v1'
 //    ? v1Data?.data
 //    : api_version === 'v2'
 //    ? v2Data?.data
 //    : cityList

 const onChangePropertyType = (clearFields: string[]): void => {
  const result = Object.fromEntries(
   clearFields?.map((field) => [
    field,
    propertyFilterData?.find((i) => i?.name === field)?.type === 'multi-select'
     ? []
     : null,
   ]),
  )
  form.setFieldsValue(result)
 }

 return (
  <Form
   form={form}
   initialValues={formInit}
   layout="vertical"
   className={`alert-form ${isReadOnly ? 'disabled-form' : ''}`}
  >
   <Row gutter={[15, 15]}>
    <Col span={12}>
     <Form.Item
      className="mb-0"
      label="Alert Name"
      name="name"
      rules={[{ required: true, message: 'Alert Name is required' }]}
     >
      <Input placeholder="Enter the name of the Alert" readOnly={isReadOnly} />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item
      className="mb-0"
      label="Area"
      name="area"
      rules={[{ required: true, message: 'Area is required' }]}
     >
      <Select
       placeholder="Select Area"
       className="w-100"
       mode="multiple"
       disabled={isReadOnly}
       options={cityList?.map((item) => ({
        value: item,
        label: item,
       }))}
       getPopupContainer={(trigger) => trigger.parentNode}
      />
     </Form.Item>
    </Col>

    {propertyFilterData?.map((v, n) =>
     v?.options?.length ? (
      <Col span={12} key={n}>
       <Form.Item
        className={`mb-0 ${
         v?.type === 'multi-select' ? 'label-with-select-all' : ''
        }`}
        label={
         v?.type === 'multi-select' ? (
          <div className="d-flex align-items-center justify-content-between w-100">
           <span>{v?.label}</span>
           <span>
            <Checkbox
             onChange={({ target }) => {
              if (!isReadOnly) {
               form.setFieldsValue({
                [v.name]: target?.checked
                 ? v?.options?.map((opt) => opt?.value)
                 : [],
               })
               if (v?.name === 'property_type') {
                onChangePropertyType(v?.clear_fields ?? [])
                setDynamicSelectAll({ [v.name]: target?.checked })
               } else {
                setDynamicSelectAll((prv: any) => ({
                 ...prv,
                 [v.name]: target?.checked,
                }))
               }
              }
             }}
             checked={dynamicSelectAll?.[v?.name] ?? false}
            >
             All
            </Checkbox>
           </span>
          </div>
         ) : (
          v?.label
         )
        }
        name={v?.name}
        getValueFromEvent={(e) => {
         if (v?.name === 'property_type') {
          onChangePropertyType(v?.clear_fields ?? [])
          setDynamicSelectAll({ [v?.name]: e?.length === v?.options?.length })
         } else if (v?.type === 'multi-select') {
          setDynamicSelectAll((prv: any) => ({
           ...prv,
           [v?.name]: e?.length === v?.options?.length,
          }))
         }
         return e
        }}
       >
        <Select
         allowClear
         disabled={isReadOnly}
         placeholder={`Select ${v?.label}`}
         className="w-100"
         getPopupContainer={(trigger) => trigger.parentNode}
         options={v?.options?.map?.((j, k) => ({
          label: j?.label,
          value: j?.value,
         }))}
         {...(v?.type === 'multi-select' && {
          mode: 'multiple',
         })}
        />
       </Form.Item>
      </Col>
     ) : (
      ''
     ),
    )}

    <Col span={12}>
     <Form.Item className="mb-0" label="Frequency" name="frequency">
      <Select
       disabled={isReadOnly}
       placeholder="Select Frequency"
       className="w-100"
       options={Frequencys}
       defaultValue="Daily"
       allowClear
       getPopupContainer={(trigger) => trigger.parentNode}
      />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item
      className="mb-0"
      label="Number of Properties to Send"
      name="count"
     >
      <InputNumber
       min={1}
       max={10}
       placeholder="Enter Number of Properties to Send"
       className="w-100"
       //  type="number"
       readOnly={isReadOnly}
       //  onKeyDown={({ key, stopPropagation, preventDefault }) => {
       //   console.log({ key })
       //   if (key === '+' || key === '-' || key === '.') {
       //    stopPropagation()
       //    //  preventDefault()
       //   }
       //  }}
       onKeyDown={(event) => {
        if (
         event.key !== 'Backspace' &&
         event.key !== 'ArrowLeft' &&
         event.key !== 'ArrowRight'
        ) {
         if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
         }
        }
        return event
       }}
      />

      {/* <BaseNumberInput
       min={1}
       max={10}
       placeholder="Enter Number of Properties to Send"
       className="w-100"
       //  type="number"
       readOnly={isReadOnly}
      /> */}
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item className="mb-0" label="Bedrooms" name="bed">
      <Select
       disabled={isReadOnly}
       options={FilterBedLists}
       placeholder="Select Bedrooms"
       className="w-100"
       allowClear
       getPopupContainer={(trigger) => trigger.parentNode}
      />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item className="mb-0" label="Bathrooms" name="bath">
      <Select
       disabled={isReadOnly}
       options={FilterBedLists}
       placeholder="Select Bathrooms"
       className="w-100"
       allowClear
       getPopupContainer={(trigger) => trigger.parentNode}
      />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item className="mb-0" label="Price Range" name="priceRange">
      <PriceRangerDropDown isReadOnly={isReadOnly} />
     </Form.Item>
    </Col>
    {type === 'price-drop' && (
     <Col span={12}>
      <Form.Item
       className="mb-0"
       label={`Price Drop (${priceDropValue?.[0] ?? 0}% - ${
        priceDropValue?.[1] ?? 100
       }%)`}
       name="price_drop"
      >
       <Slider
        marks={{
         1: '1%',
         10: '10%',
         20: '20%',
         30: '30%',
         40: '40%',
         50: 'Max',
        }}
        range
        defaultValue={[1, 50]}
        min={1}
        max={50}
        tipFormatter={(v) => `${v}%`}
       />
      </Form.Item>
     </Col>
    )}

    {!isReadOnly && (
     <Col span={24}>
      <Space>
       <BaseButton
        buttonType="secondary"
        onClick={() => {
         setShowEdit(!showEdit)
         onCancel?.()
        }}
        className="secondary-btn"
        style={{ color: 'white' }}
       >
        Cancel
       </BaseButton>
       <BaseButton
        buttonType="primary"
        onClick={handleSubmitForm}
        loading={mode === 'EDIT' ? editLOading : addLoading}
       >
        {mode === 'EDIT' ? 'Update Alert' : 'Create Alert'}
       </BaseButton>
      </Space>
     </Col>
    )}
   </Row>
  </Form>
 )
}

export default AlertFormSection
