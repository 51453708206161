import baseTime from 'lib/baseTime'

export class utils {
 static pricefy = (val: any): string =>
  `$${Number(val ?? 0).toLocaleString('en-CA')}`

 static isValidURL = (string: string) => {
  const regexp =
   /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  // var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return !!regexp.test(string)
 }

 static formatPhoneNumber = (str?: string) => {
  if (str) {
   // Filter only numbers from the input
   const cleaned = `${str}`.replace(/\D/g, '')

   // Check if the input is of correct
   const match =
    cleaned.length === 11
     ? cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
     : cleaned.length === 12
     ? cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/)
     : cleaned.length === 13
     ? cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/)
     : cleaned.match(/^(\d{10})(\d{3})(\d{3})(\d{4})$/)

   if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? `+${match[1]} ` : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
   }

   return null
  }
  return null
 }

 static downloadFile = (src: string, filename?: any): void => {
  if (src && this.isValidURL(src)) {
   const element = document.createElement('a')
   element.setAttribute('href', `${src}`)
   element.setAttribute('download', filename ?? 'Export Lead')
   document.body.appendChild(element)
   element.click()
   document.body.removeChild(element)
  }
 }
}
