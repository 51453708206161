import { Col, Row } from 'antd'
import AssignAutomation from 'components/shared/Modals/AssignAutomation'
import { leadApi } from 'features/leads/api'
import { TPlanSimple } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useMemo, useState } from 'react'
import AutomationBlockItem from '../../AutomationBlockItem'
import ProInnerCard from '../../ProInnerCard'

const AutomationBlockBox: FC<{
 plans?: TPlanSimple[]
 leadId?: any
 isTransferred?: boolean
 from?: 'action-plan' | 'real-estate-news' | 'market-updates'
}> = ({ plans, leadId, isTransferred, from }) => {
 const [showActionPlan, setShowActionPlan] = useState<boolean>(false)
 const d = useAppDispatch()
 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))

 const title = useMemo(() => {
  if (from === 'action-plan') {
   return 'Action Plans'
  }
  if (from === 'market-updates') {
   return 'Market Updates'
  }
  if (from === 'real-estate-news') {
   return 'Real Estate Newsletter'
  }
 }, [from])

 return (
  <div>
   <ProInnerCard
    emptyText={`${title} is Empty`}
    isEmpty={!plans || plans?.length === 0}
    onCLickAdd={isTransferred ? () => setShowActionPlan(true) : undefined}
    title={title}
   >
    <Row gutter={[15, 15]}>
     {plans &&
      plans.map((item, i) => (
       <Col span={24} key={i}>
        <AutomationBlockItem
         leadId={leadId}
         status={
          from === 'action-plan'
           ? item.status
           : (item as any)?.status === 'Active'
           ? 'Running'
           : 'Pause'
         }
         id={from === 'action-plan' ? item.action_plan.id : item?.id}
         text={
          from === 'action-plan'
           ? `${item.action_plan.name} (${item?.action_plan?.owner})`
           : item?.name ?? ''
         }
         isTransferred={isTransferred}
         from={from}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   {showActionPlan && (
    <AssignAutomation
     onSuccess={refreshLead}
     activeLeadId={leadId}
     lead_id={[leadId]}
     onCancel={() => setShowActionPlan(false)}
     visible={showActionPlan}
     from={from}
    />
   )}
  </div>
 )
}

export default AutomationBlockBox
