/* eslint-disable quotes */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import { Row, Col, Result, Input, Empty } from 'antd'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import { BlockLoader } from 'components/elements/Loader'
import { ADMIN_PREFIX_PATH } from 'config'
import { TInboxItem } from 'features/inbox/types'
import { FC, useEffect, useState } from 'react'
import {
 Routes,
 Route,
 useParams,
 NavLink,
 useNavigate,
} from 'react-router-dom'
import useFetchPage2 from 'hooks/useFetchPage2'
import InboxItem from 'features/inbox/components/InboxItem'
import InboxScheduleMsg from 'features/inbox/components/InboxScheduleMsg'
import { useDebounce } from 'react-use'
import InboxMessages from './InboxMessages'

const emptyDatas = {
 email: {
  inbox: {
   heading: 'Welcome to Your Inbox',
   body:
    "This is where you'll find all incoming emails sent to your CRM email by your leads. As soon as you start receiving messages, they’ll appear here. Stay tuned!",
  },
  send: {
   heading: 'Your Sent Folder is Empty',
   body:
    "This is where you'll find all the emails you've sent to your leads. Once you start reaching out, your sent messages will appear here. Keep the conversation going!",
  },
  scheduled: {
   heading: 'No Scheduled Emails',
   body:
    "You haven’t scheduled any emails yet. This is where you'll see all the emails planned to be sent in the future.",
  },
 },
 sms: {
  inbox: {
   heading: 'Your SMS Inbox is Empty',
   body:
    "This is where you'll receive SMS messages sent to your CRM number by your leads. As soon as messages start coming in, they'll appear here.",
  },
  send: {
   heading: 'No Sent SMS Messages',
   body:
    "This is where you'll find all the SMS messages you've sent to your leads. Once you start sending messages, they’ll show up here.",
  },
  scheduled: {
   heading: 'No Scheduled SMS Messages',
   body:
    "You haven’t scheduled any SMS messages yet. This is where you'll see all the messages planned to be sent in the future.",
  },
 },
}

const InboxType: FC = () => {
 const params = useParams()
 const navigate = useNavigate()

 const type: 'scheduled' | 'inbox' | 'send' = params?.type as any
 const mode: 'email' | 'sms' = params?.mode as any
 const id = params?.id as any

 const [searchKey, setSearchKey] = useState('')
 const [apiSearchKey, setApiSearchKey] = useState('')
 const { flatData, hasMore, isLoading, fetchMore, refetch } =
  useFetchPage2<TInboxItem>(`inbox/${mode === 'sms' ? 'text' : mode}`, {
   method: 'get',
   params: `type=${type}&search=${apiSearchKey}`,
   customKey: `inbox-${mode === 'sms' ? 'text' : mode}-${type}-${apiSearchKey}`,
  })

 useEffect(() => {
  setSearchKey('')
  setApiSearchKey('')
 }, [mode, type])

 useDebounce(
  () => {
   setApiSearchKey(searchKey)
  },
  300,
  [searchKey],
 )

 useEffect(() => {
  if ((flatData?.length ?? 0) > 0 && !id) {
   navigate(
    `${ADMIN_PREFIX_PATH}/inbox/${mode}/${type}/${
     type === 'scheduled' ? flatData?.[0]?.id : flatData?.[0]?.lead_id
    }`,
   )
  }
 }, [flatData])

 return (
  <div className="inbox-container">
   <Row gutter={15}>
    {isLoading && apiSearchKey?.length === 0 ? (
     <BlockLoader mode="block" className="d-flex align-items-center mx-auto" />
    ) : (flatData?.length ?? 0) > 0 ||
      (flatData?.length === 0 && (apiSearchKey?.length ?? 0) > 0) ? (
     <>
      <Col className="inbox-sidenav">
       <ul className="left-inbox-links">
        {type !== 'scheduled' && (
         <div className="my-2">
          <Input
           placeholder="Enter Lead Name"
           value={searchKey}
           onChange={({ target }) => setSearchKey(target?.value)}
          />
         </div>
        )}
        {isLoading ? (
         <BlockLoader mode="block" />
        ) : flatData?.length === 0 && !isLoading ? (
         <Empty />
        ) : (
         (flatData?.length ?? 0) > 0 && (
          <InfinityPagination
           hasMore={hasMore}
           loadMore={fetchMore}
           useWindow={false}
          >
           {flatData?.map?.((item, i) => (
            <li key={i}>
             <NavLink
              to={`${ADMIN_PREFIX_PATH}/inbox/${mode}/${type}/${
               type === 'scheduled' ? item?.id : item?.lead_id
              }`}
             >
              <InboxItem
               item={item}
               refetchList={refetch}
               refetchListKey={`inbox-${
                mode === 'sms' ? 'text' : mode
               }-${type}-${apiSearchKey}`}
              />
             </NavLink>
            </li>
           ))}
          </InfinityPagination>
         )
        )}
       </ul>
      </Col>
      <Col className="inbox-content-area">
       {(flatData?.length ?? 0) > 0 && (
        <Routes>
         {/* <Route
          path=""
          element={
           <div className="h-100 d-flex align-items-center justify-content-center">
            <Result
             subTitle={`Please select any ${
              params?.mode === 'sms' ? 'SMS' : 'Email'
             } box`}
             title="Lets Start !"
             status="info"
            />
           </div>
          }
         /> */}
         <Route
          path=":id"
          element={
           type === 'scheduled' ? <InboxScheduleMsg /> : <InboxMessages />
          }
         />
        </Routes>
       )}
      </Col>
     </>
    ) : (
     <Col
      span={16}
      className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
     >
      <h2 style={{ fontSize: '26px' }}>
       {emptyDatas?.[mode]?.[type]?.heading}
      </h2>
      <p style={{ fontSize: '16px', marginTop: '10px' }}>
       {emptyDatas?.[mode]?.[type]?.body}
      </p>
     </Col>
    )}
   </Row>
  </div>
 )
}

export default InboxType
