import { Modal } from 'antd'
import { useGetEmailTemplateByIdQuery } from 'features/settings'
import EmailTemplateForm from 'features/settings/components/EmailTemplateForm'
import { FC } from 'react'
import { TMode } from 'types'

const EmailTemplateModal: FC<{
 editId?: any
 visible?: boolean
 onCancel?: () => void
 mode?: TMode
}> = ({ visible, onCancel, mode = 'ADD', editId }) => {
 const { data } = useGetEmailTemplateByIdQuery(
  { id: editId },
  { refetchOnMountOrArgChange: true },
 )
 return (
  <Modal
   footer={null}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} Email Template`}
   width={1000}
   onCancel={onCancel}
   visible={visible}
  >
   {/* <pre>{JSON.stringify(editId, null, 2)}</pre> */}
   <EmailTemplateForm
    onSuccess={() => {
     onCancel?.()
    }}
    mode={mode}
    editData={data}
   />
  </Modal>
 )
}

export default EmailTemplateModal
