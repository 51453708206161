export const APP_NAME = 'Alacrity'
export const IMAGE_BASE_URL = `${process.env.REACT_APP_IMG_PATH}`
export const API_BASE_URL = `${process.env.REACT_APP_API}/api`
export const BASE_URL = `${process.env.REACT_APP_API}`
export const PRECON_LISTNG = `${process.env.REACT_APP_PRECON_API}`
export const API_PRECON_LISTNG = `${process.env.REACT_APP_PRECON_API}/api/`
export const APP_PREFIX_PATH = '/admin'
export const ADMIN_PREFIX_PATH = '/admin'
export const AUTH_PREFIX_PATH = '/auth'
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID || ''

// Path Cont

export const PATH_LEAD_PROFILE = `${ADMIN_PREFIX_PATH}/leads/profile`
export const RECAPTCHA_SITE_KEY = `${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
