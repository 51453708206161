import { Col, Row, Modal, Form } from 'antd'
import { globalConfirm } from 'components/elements/global'
import AssignAlertModal from 'components/shared/Modals/AssignAlertModal'
import { leadApi, useWithdrawAlertLeadMutation } from 'features/leads/api'
import { TALertSimple } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useMemo, useState } from 'react'
import { TAlertFeedType, TPutAlertType } from 'features/alert'
import AlertFormSection from 'features/alert/components/AlertFormSection'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

const AlertBox: FC<{
 nla?: TALertSimple[]
 leadId?: any
 isTransferred?: boolean
 type: TAlertFeedType
}> = ({ nla, leadId, isTransferred, type }) => {
 const [showModal, setShowModal] = useState(false)
 const [showViewModal, setShowViewModal] = useState<{
  show: boolean
  data?: TALertSimple
 }>({ show: false })

 const d = useAppDispatch()

 const [withdrawAlertLead] = useWithdrawAlertLeadMutation()

 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))

 const handleRemoveAlert = (id: any) => {
  globalConfirm({
   content: 'Are you sure want remove Alert from this Lead?',
   onOk: async () => {
    await withdrawAlertLead({ lead_id: leadId, alert_id: id }).unwrap()
    refreshLead()
   },
  })
 }

 const title = useMemo(() => {
  switch (type) {
   case 'forsale':
    return 'New Listing Alert'
   case 'openhouse':
    return 'Open House Alert'
   case 'sold':
    return 'Neighbourhood Alert'
   case 'price-drop':
    return 'Price Drop Alert'
   default:
    return ''
  }
 }, [type])

 const [form] = Form.useForm<
  TPutAlertType & { priceRange?: { min?: any; max?: any } }
 >()

 return (
  <div>
   <ProInnerCard
    emptyText={`${title} is Empty`}
    isEmpty={!nla || nla?.length === 0}
    onCLickAdd={isTransferred ? () => setShowModal(true) : undefined}
    title={title}
   >
    <Row gutter={[15, 15]}>
     {nla &&
      nla.map((item, i) => (
       <Col span={24} key={i}>
        <SimpleBlockItem
         onClose={isTransferred ? () => handleRemoveAlert(item.id) : undefined}
         text={item.name}
         onView={
          isTransferred
           ? () => {
              setShowViewModal({ show: true, data: item })
              form.setFieldsValue({})
             }
           : undefined
         }
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   {showModal && (
    <AssignAlertModal
     type={type}
     leadId={leadId}
     onSuccess={refreshLead}
     onCancel={() => setShowModal(false)}
     visible={showModal}
    />
   )}

   {showViewModal?.show && (
    <Modal
     footer={null}
     width={1100}
     title={`View ${title}`}
     onCancel={() => {
      setShowViewModal({ show: false })
      form.resetFields()
     }}
     visible={showViewModal?.show}
    >
     <AlertFormSection
      show={showViewModal?.show}
      form={form}
      type={type}
      mode="EDIT"
      editData={showViewModal?.data as any}
      isReadOnly
     />
    </Modal>
   )}
  </div>
 )
}

export default AlertBox
