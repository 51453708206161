import { Modal } from 'antd'
import { LoadMoreSelect } from 'components/elements/Form'
import { TAlertFeedType, TGetAlertName } from 'features/alert'
import { useAssignAlertLeadMutation } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import { useMemo, FC, useState } from 'react'

type AssignAlertProp = {
 type?: TAlertFeedType
 leadId?: any
 onSuccess?: () => void
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
}

const AssignAlertModal: FC<AssignAlertProp> = ({
 leadId,
 onSuccess,
 visible,
 onCancel,
 type = 'forsale',
}) => {
 const [searchKey, setSearch] = useState('')
 const { flatData, isLoading, hasMore, fetchMore, isRefetching } =
  useFetchPage<TGetAlertName>('get-alert-name', {
   method: 'post',
   body: useMemo(
    () => ({
     search: searchKey,
     lead_id: leadId ?? '',
     fead: [type],
    }),
    [leadId, searchKey],
   ),
  })

 const [assignAlertLead, { isLoading: submitLoading }] =
  useAssignAlertLeadMutation()

 const [selectedAlert, setAlert] = useState<any>(null)

 const handleSubmit = async (): Promise<void> => {
  if (selectedAlert) {
   await assignAlertLead({
    lead_id: [leadId],
    alert_id: [selectedAlert],
   }).unwrap()
  }
  handleClose?.()
  onSuccess?.()
 }

 const handleClose = () => {
  onCancel?.()
  setAlert(null)
 }

 return (
  <Modal
   okButtonProps={{ disabled: !selectedAlert }}
   confirmLoading={submitLoading}
   onOk={handleSubmit}
   forceRender
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title="Assign Alert"
  >
   <LoadMoreSelect
    onChange={(val) => setAlert(val as any)}
    data={flatData?.map((v) => ({ value: v?.id, label: v?.name }))}
    onSearch={setSearch}
    placeholder="Please select an alert"
    hasMore={hasMore}
    fetchMore={fetchMore}
    loading={isLoading || isRefetching}
   />
  </Modal>
 )
}

export default AssignAlertModal
