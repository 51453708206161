/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Input, Modal, Space, Empty, Checkbox, Divider } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BaseButton } from 'components/elements/BaseButton'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import {
 TActionPlanLeads,
 useControlActionPlanMutation,
 useControlNewsLetterMutation,
 useWithdrawActionplanMutation,
} from 'features/automation'
import useFetchPage from 'hooks/useFetchPage'
import { queryClient } from 'lib/react-query'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { TControlAPModal } from 'features/automation/routes/action-plan/List'

const ControlActionPlanModal: FC<{
 onCancel?: () => void
 ctrlAP: TControlAPModal
 refetch?: () => void
 from?: 'action-plan' | 'real-estate-news' | 'market-updates'
}> = ({
 onCancel,
 ctrlAP: { id, show, type },
 refetch,
 from = 'action-plan',
}) => {
 const [Ids, setIds] = useState<any[]>([])
 const [apActionType, setApActionType] = useState<'stop' | null>(null)
 const [newsLetterActionType, setNewsLetterActionType] = useState<
  'pause' | 'delete' | null
 >(null)
 const [selectAll, setSelectAll] = useState(false)
 const [unselected, setUnSelected] = useState<number[]>([])

 const [searchKey, setSearchKey] = useState('')
 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  totalCount,
  isFetching,
  refetch: refetchList,
 } = useFetchPage<TActionPlanLeads>(
  from === 'action-plan' ? 'get-action-plan-leads' : 'assignlist',
  {
   method: 'post',
   body: useMemo(
    () =>
     from === 'action-plan'
      ? {
         id: [id],
         type: [type],
         search: searchKey,
        }
      : {
         search: searchKey,
         type:
          from === 'market-updates'
           ? 'market-update'
           : 'Real-Estate-News-Letter',
         status: type === 'Running' ? 'Active' : 'Pause',
         id,
        },
    [id, searchKey],
   ),
   customApiKey:
    from === 'action-plan'
     ? `get-action-plan-leads-${id}-${type}-${searchKey}`
     : `assignlist-${id}-${type}-${searchKey}`,
  },
 )

 useEffect(() => {
  refetchList?.()
 }, [])

 const [controlAction, { isLoading: controlActionLoading }] =
  useControlActionPlanMutation()
 const [controlNewsLetter, { isLoading: controlNewsLetterLoading }] =
  useControlNewsLetterMutation()

 const [withdrawAP, { isLoading: withdrawAPLdng }] =
  useWithdrawActionplanMutation()
 const HandleControlAP = async (
  actionType: 'Resume' | 'Restart' | 'Pause' | 'Stop',
  planId?: any,
  leadId?: any[],
 ) => {
  if (from === 'action-plan') {
   await controlAction({
    action: actionType,
    plan_id: planId,
    lead_id: selectAll && hasMore ? [] : leadId,
    ...(selectAll && hasMore
     ? {
        select_all: selectAll,
        unselected,
        filter_info: JSON.stringify({ search: searchKey }),
        from: type,
       }
     : {}),
   }).unwrap()
   queryClient.invalidateQueries('get-action-plan-leads')
   queryClient.invalidateQueries('get-action-plans')
  } else {
   await controlNewsLetter({
    type:
     from === 'market-updates' ? 'market-update' : 'Real-Estate-News-Letter',
    action:
     actionType === 'Pause'
      ? 'pause'
      : actionType === 'Resume'
      ? 'restart'
      : 'Delete',
    newsletter_id: [planId],
    lead_id: selectAll && hasMore ? [] : leadId,
    ...(selectAll && hasMore
     ? {
        select_all: selectAll,
        unselected,
        filter_info: JSON.stringify({ search: searchKey }),
        from: type === 'Running' ? 'Active' : 'Pause',
       }
     : {}),
   }).unwrap()
   queryClient.invalidateQueries('assignlist')
  }

  setApActionType(null)
  onCancel?.()
  refetch?.()
 }

 const handleDeleteAP = async (planId?: any, leadID?: any[]) => {
  await withdrawAP({
   actionplan_id: planId,
   lead_id: selectAll && hasMore ? [] : leadID,
   ...(selectAll && hasMore
    ? {
       select_all: selectAll,
       unselected,
       filter_info: JSON.stringify({ search: searchKey }),
       from: type,
      }
    : {}),
  }).unwrap()

  queryClient.invalidateQueries('get-action-plan-leads')
  queryClient.invalidateQueries('get-action-plans')
  onCancel?.()
  refetch?.()
 }

 const title = {
  Running: 'Active',
  Pause: 'Paused',
  Completed: 'Completed',
  Stop: 'Stopped',
 }

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unselected)
   const result = flatData
    ?.filter((obj) => !newunSelected.has(obj.id))
    .map((i) => i?.id)

   setIds(result as any)
  }
 }, [flatData, selectAll, unselected])

 useEffect(() => {
  if (!hasMore && selectAll && Ids?.length !== flatData?.length) {
   setSelectAll(false)
  }
 }, [flatData])

 return (
  <Modal
   visible={show}
   onCancel={onCancel}
   footer={false}
   title={type ? title[type] : ''}
  >
   <div style={{ marginBottom: '12px' }}>
    <Input
     value={searchKey}
     onChange={(e) => setSearchKey(e.target.value)}
     placeholder="Enter your search"
    />
   </div>

   {isLoading ? (
    <div
     style={{ height: '160px' }}
     className="d-flex align-items-center justify-content-center"
    >
     <BlockLoader />
    </div>
   ) : (flatData?.length ?? 0) === 0 && !isLoading && !isFetching ? (
    <div
     style={{ height: '160px' }}
     className="d-flex align-items-center justify-content-center"
    >
     <Empty description="Lead Empty" />
    </div>
   ) : (
    <LoeadMorePagination
     loading={isLoading || isFetching}
     hasMore={hasMore}
     fethMore={fetchMore}
    >
     {(flatData?.length ?? 0) > 0 && (
      <div
       className="d-flex justify-content-end"
       style={{ fontWeight: '500', marginBottom: '8px' }}
      >
       <span
        style={{ paddingRight: '6px', cursor: 'pointer' }}
        onClick={() => {
         setSelectAll(!selectAll)
         if (selectAll) {
          setIds([])
         }
         setUnSelected([])
        }}
       >
        Select All
       </span>
       <Checkbox
        onChange={() => {
         setSelectAll(!selectAll)
         if (selectAll) {
          setIds([])
         }
         setUnSelected([])
        }}
        checked={selectAll}
       />
      </div>
     )}
     {flatData &&
      flatData.length > 0 &&
      flatData.map((item, index) => (
       <AvatarStatus
        key={index}
        title={item.name}
        className="action-active-leads "
        action={
         <Checkbox
          onChange={() => {
           if (selectAll && hasMore) {
            setUnSelected((prv) => {
             if (prv.includes(item.id)) {
              return prv.filter((x) => x !== item.id)
             }
             return [...prv, item.id]
            })
           } else {
            setIds((prv) => {
             const find = prv.find((m) => m === item.id)
             if (find) {
              const filter = prv.filter((x) => x !== item.id)
              return [...filter]
             }
             return [...prv, item.id]
            })
           }

           //  if (Ids.find((x) => x === item.id)) {
           //   if (selectAll) {
           //    setSelectAll(false)
           //   }
           //  } else if (Ids.length + 1 === flatData?.length && !hasMore) {
           //   setSelectAll(true)
           //  }
          }}
          checked={
           selectAll && hasMore
            ? !unselected.includes(item.id)
            : !!Ids.find((x) => x === item.id)
          }
         />
        }
       />
      ))}
    </LoeadMorePagination>
   )}

   {Ids && Ids.length > 0 && (
    <>
     <Divider />
     <Space>
      <BaseButton
       //  buttonType="secondary"
       customClass="btn-secondary-custom"
       onClick={() => {
        setSelectAll(false)
        setIds([])
        setUnSelected([])
        setApActionType(null)
       }}
      >
       Clear
      </BaseButton>
      <BaseButton
       buttonType="primary"
       loading={
        (apActionType === null && controlActionLoading) ||
        (newsLetterActionType === null && controlNewsLetterLoading)
       }
       onClick={() =>
        HandleControlAP?.(
         type === 'Running' ? 'Pause' : type === 'Pause' ? 'Resume' : 'Restart',
         id,
         Ids,
        )
       }
      >
       {type === 'Running' ? 'Pause' : type === 'Pause' ? 'Resume' : 'Restart'}
       {selectAll && hasMore
        ? ` (${(totalCount ?? 0) - (unselected?.length ?? 0)})`
        : ` (${Ids && Ids.length})`}
      </BaseButton>
      {type === 'Running' && from === 'action-plan' && (
       <BaseButton
        buttonType="primary"
        loading={apActionType === 'stop' && controlActionLoading}
        onClick={() => {
         setApActionType('stop')
         HandleControlAP?.('Stop', id, Ids)
        }}
       >
        Stop{' '}
        {selectAll && hasMore
         ? `(${(totalCount ?? 0) - (unselected?.length ?? 0)})`
         : `(${Ids && Ids.length})`}
       </BaseButton>
      )}
      <BaseButton
       loading={
        withdrawAPLdng ||
        (newsLetterActionType === 'delete' && controlNewsLetterLoading)
       }
       buttonType="danger"
       onClick={() => {
        if (from === 'action-plan') {
         handleDeleteAP?.(id, Ids)
        } else {
         setNewsLetterActionType('delete')
         HandleControlAP('Delete' as any, id, Ids)
        }
       }}
      >
       Delete{' '}
       {selectAll && hasMore
        ? `(${(totalCount ?? 0) - (unselected?.length ?? 0)})`
        : `(${Ids && Ids.length})`}
      </BaseButton>
     </Space>
    </>
   )}
  </Modal>
 )
}

export default ControlActionPlanModal
