import TestPage from 'features/misc/routes/TestPage'
import useAuth from 'hooks/useAuth'
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom'
import { FC } from 'react'
import storage from 'utils/storage'
import { protectedRoutes } from './protected'
import { publicRoutes } from './public'

export const AppRoutes: FC = () => {
 const { isAuthenticated } = useAuth()
 const [searchParams] = useSearchParams()
 const { pathname } = useLocation()
 const token = searchParams.get('token')
 if (token) {
  if (pathname === '/admin') {
   storage.setToken(token)
  }
  window.location.href = '/admin/dashboard'
 }
 const routes = isAuthenticated || token ? protectedRoutes : publicRoutes

 const element = useRoutes([
  ...routes,
  {
   path: '/test/*',
   element: <TestPage />,
  },
 ])

 return <>{element}</>
}
