import { Modal, Space, Checkbox } from 'antd'
// import { ColumnsType } from 'antd/lib/table'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
// import { SvgDeleteIcon } from 'components/icons'
// import BaseInfinityTable from 'components/shared/DataTable/BaseDataTable'
import TextTemplateViewModal from 'components/shared/Modals/TextTemplateViewModal'
import { useDeleteTextTemplatesMutation } from 'features/settings/api'
import { TGetTextTemplates } from 'features/settings/types'
import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useState } from 'react'
// import { TModalState } from 'types'
import TextTemplateForm from 'features/settings/components/TextTemplateForm'
import useSelectAll from 'hooks/useSelectAll'
// import MTable, {
//  TMTableActionRowParams,
// } from 'components/shared/MDataTable/MTable'
// import { TSelectAllP } from 'features/leads'
// import { handleSelectAllBody } from 'utils/functions'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { MDataTable } from 'components/shared/MDataTable'
import Edit from './Edit'

const List: FC = () => {
 const [searchKey, setSearchkey] = useState('')
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetTextTemplates>('get-text-templates', {
  method: 'get',
  params: searchKey ? `search=${searchKey}` : '',
 })
 const {
  selectedKeys,
  selectAll,
  handleBody,
  rowSelectionState,
  onSelectRow,
  setSelectedRow,
  unSelected,
 } = useSelectAll(JSON.stringify({ search: searchKey }), flatData)

 const [textActionModal, setTextActionModal] = useState<{
  show: 'edit' | 'add' | 'view' | false
  data: null | number | TGetTextTemplates
 }>({ data: null, show: false })

 //  const [showViewPop, setShowViewPop] = useState<TModalState<TGetTextTemplates>>(
 //   {
 //    show: false,
 //   },
 //  )

 const [deleteTextTemplate] = useDeleteTextTemplatesMutation()

 const handleDelete = (): void => {
  globalConfirm({
   content: 'Are you sure you want to delete the selected text templates?',
   onOk: async () => {
    await deleteTextTemplate(handleBody({ id: selectedKeys })).unwrap()
    refetch?.()
   },
  })
 }

 const Columns: MDataTableColumns<TGetTextTemplates>[] = [
  {
   title: 'Name',
   dataIndex: 'name',
   //    render: (_, r) => `${r?.name} (${r?.owner})`,
   size: 200,
  },
  {
   title: 'Created By',
   dataIndex: 'owner',
   size: 150,
  },
  {
   title: 'Body',
   dataIndex: 'body',
   size: 300,
  },

  {
   title: 'Action',
   size: 130,
   fixed: 'right',
   render: (_, record) => (
    <Space>
     <BaseButton
      type="link"
      onClick={() => {
       setTextActionModal({ show: 'view', data: record })
      }}
      tooltipText="View Text Template"
      shape="circle"
      size="small"
      buttonType="primary"
      icon={<i className="fas fa-eye" />}
     />
     <BaseButton
      type="link"
      onClick={() => setTextActionModal({ show: 'edit', data: record.id })}
      tooltipText="Edit Text Template"
      shape="circle"
      size="small"
      buttonType="info"
      icon={<i className="fas fa-edit" />}
     />
    </Space>
   ),
  },
 ]

 const commonModalProps = {
  onCancel: () => setTextActionModal({ show: false, data: null }),
  visible: true,
  width: '100vw',
  centered: true,
  className: 'page-size-modal',
 }

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])
 //  const headerCheckbox = (
 //   <Checkbox
 //    checked={selectAll || flatData?.length === selectedKeys?.length}
 //    onChange={toggleSelectAll}
 //   />
 //  )

 return (
  <ListContentLayout
   title="SMS Templates"
   actions={
    <Space>
     <BaseButton
      shape="circle"
      buttonType="gamma"
      tooltipText="Add"
      onClick={() => setTextActionModal({ show: 'add', data: null })}
      icon={<i className="fas fa-plus" />}
     />
     <BaseButton
      shape="circle"
      disabled={!selectedKeys.length}
      buttonType="beta"
      tooltipText="Delete"
      icon={<i className="fas fa-trash" />}
      onClick={handleDelete}
     />
    </Space>
   }
  >
   {/* <BaseInfinityTable
    search={{
     placeholder: 'Enter SMS Template Name',
     onSearch: setSearchkey,
     show: true,
     searchKey,
    }}
    rowSelection={{ ...rowSelection, columnTitle: headerCheckbox }}
    loading={isLoading}
    hasMore={hasMore}
    columns={Columns}
    onFetchMore={fetchMore}
    data={flatData || []}
   /> */}
   {/* <MTable
    searchProps={{ placeholder: 'Enter SMS Template Name' }}
    columns={Columns}
    actionRow={(val) => [
     {
      icon: <i className="fas fa-trash" />,
      label: 'Delete',
      onClick: () => handleDeleteTextTemplate(val),
      danger: true,
     },
    ]}
    fetchProps={{ url: 'get-text-templates' }}
   /> */}

   <MDataTable
    totalData={totalCount || 1000}
    search={{
     placeholder: 'Enter SMS Template Name',
     onSearch: setSearchkey,
     show: true,
    }}
    onSelectRow={onSelectRow}
    rowSelectionState={{
     ...rowSelectionState,
    }}
    loading={isLoading}
    isFetching={isRefetching}
    hasMore={hasMore}
    fetchMore={() => {
     fetchMore()
    }}
    data={flatData}
    columns={Columns}
   />
   {textActionModal.show === 'view' && textActionModal.data && (
    <TextTemplateViewModal
     data={textActionModal.data as TGetTextTemplates}
     visible
     onCancel={() => setTextActionModal({ show: false, data: null })}
    />
   )}
   {textActionModal?.show === 'add' && (
    <Modal title="Add Text Template" {...commonModalProps}>
     <TextTemplateForm onSuccess={commonModalProps.onCancel} mode="ADD" />
    </Modal>
   )}

   {textActionModal?.show === 'edit' && (
    <Modal title="Edit Text Template" {...commonModalProps}>
     <Edit
      id={`${textActionModal.data}`}
      onCancel={commonModalProps.onCancel}
     />
    </Modal>
   )}
  </ListContentLayout>
 )
}

export default List
