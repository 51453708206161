/* eslint-disable quotes */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import { Card, Collapse, Empty } from 'antd'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { FC, ReactNode } from 'react'
import {
 LeadTimeLineEmptyMessageIcon,
 LeadTimeLineEmptyNotesIcon,
 LeadTimelineCallIcon,
} from 'components/icons'
import CommonItemCard from './ActivityCommonItem'

const ActivityLayout: FC<{
 listIsLoading?: boolean
 flatData?: any[]
 totalCount?: string | number
 totalTitle: 'ALL' | 'SMS' | 'Call Log' | 'Email' | 'WEB Activity' | 'Notes'
 listFetchMore?: () => void
 listHasMore?: boolean
 listisRefetching?: boolean
 fetchDetail?: (val: any) => void
 detailLoading?: boolean
 refetechList?: () => void
 detailData: any
 setItemCard?: (item: any) => {
  titleNode?: ReactNode
  title?: string
  description?: string
  updated?: string
  estatus?: any
 }
 from?: 'email'
}> = ({
 flatData,
 listIsLoading,
 totalCount,
 totalTitle,
 listFetchMore,
 listHasMore,
 listisRefetching,
 fetchDetail,
 detailLoading,
 refetechList,
 detailData,
 setItemCard,
 from,
}) => {
 if (listIsLoading) {
  return <BlockLoader />
 }

 const emptyMsgs = {
  SMS: {
   head: 'No SMS History',
   desc: 'No results found related to SMS history',
   icon: (
    <span className="no-data-image">
     <LeadTimeLineEmptyMessageIcon />
    </span>
   ),
  },
  ALL: {
   head: 'You have no Timeline list',
   desc: "Don't have any timeline info to show",
   icon: <i className="fas fa-history no-data-image" />,
  },
  Email: {
   head: 'You have no message',
   desc: 'Your inbox is empty. Send a message to a friend to get started',
   icon: <i className="fal fa-envelope-open no-data-image" />,
  },
  'WEB Activity': {
   head: 'No Latest Activities',
   desc: 'No results found related to leads latest activities',
   icon: <i className="fas fa-clipboard no-data-image" />,
  },
  Notes: {
   head: 'Notes Empty',
   desc: 'No results found related to notes',
   icon: (
    <span className="no-data-image">
     <LeadTimeLineEmptyNotesIcon />
    </span>
   ),
  },
  'Call Log': {
   head: 'No Call History',
   desc: 'No results found related to Call history',
   icon: (
    <span className="no-data-image">
     <LeadTimelineCallIcon />
    </span>
   ),
  },
 }

 if (!flatData || flatData.length === 0) {
  return (
   <div className="no-data-empty-compo">
    <Empty
     image={emptyMsgs?.[totalTitle]?.icon}
     description={
      <span className="no-data-heading">{emptyMsgs?.[totalTitle]?.head}</span>
     }
    >
     <p className="no-data-description">{emptyMsgs?.[totalTitle]?.desc}</p>
    </Empty>
   </div>
  )
 }
 return (
  <Card
   className="activity-layout-crd"
   title={`${totalTitle} (${totalCount})`}
   loading={listIsLoading}
   bodyStyle={{ flex: 1 }}
  >
   <div className="card-body-inner">
    <LoeadMorePagination
     fethMore={listFetchMore}
     hasMore={listHasMore}
     loading={listisRefetching}
    >
     <Collapse className="mts-collapse activity-collaps">
      {flatData?.map((item, index) => (
       <CommonItemCard
        refechList={refetechList}
        ItemcardDetail={setItemCard?.(item)}
        fetchDetail={() =>
         fetchDetail?.({
          activity_id: item?.activity_id,
          lead_id: item?.lead_id,
          type: item?.type,
          index,
         })
        }
        type={item?.type}
        loading={detailLoading}
        data={detailData}
        key={index}
        from={from}
       />
      ))}
     </Collapse>
    </LoeadMorePagination>
   </div>
  </Card>
 )
}

export default ActivityLayout
