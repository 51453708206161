/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
 RowSelectionState,
 SortingState,
 ColumnFiltersState,
 useReactTable,
 getCoreRowModel,
 getSortedRowModel,
 getFilteredRowModel,
 ColumnDef,
} from '@tanstack/react-table'
import { Row, Col, Spin } from 'antd'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useVirtual } from 'react-virtual'
import classNames from 'classnames'
import { BaseButton } from 'components/elements/BaseButton'
import useEvent from 'hooks/useEvent'

import {
 DndContext,
 KeyboardSensor,
 MouseSensor,
 TouchSensor,
 closestCenter,
 type DragEndEvent,
 useSensor,
 useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { arrayMove } from '@dnd-kit/sortable'
import { useGlobal } from 'raducer/global'
import { useUpdateTableStructureMutation } from 'features/leads'
import { useWebSettings } from 'raducer/webSettings'
import { areArraysEqual } from 'utils/functions'
import MTHeader from './Header/MTHeader'
import MTBody from './Body/MTBody'
import MTableSearchBar from './MTableSearchBar'
import {
 MDataTableColumnsNew,
 MDataTableProps,
 MFIlterObjectType,
} from './type'
import { MTContext } from './MTContext'
import MTRowSelection from './MTRowSelection'
import { forceScroll } from './utils/helper'
import MTRowAction from './Action/MTRowAction'
import {
 convertRowObject,
 filterArray2Object,
 filterObject2Array,
 formatCols,
} from './utils/functions'

const InternalMDataTable = <
 RecordType extends object = any,
 FilterType extends object = MFIlterObjectType,
>(
 props: MDataTableProps<RecordType, FilterType>,
 ref: React.ForwardedRef<HTMLDivElement>,
) => {
 const {
  loading,
  columns,
  data,
  isFetching,
  hasMore,
  fetchMore,
  rowSelectionState,
  onUnSelectRow,
  onSelectRow,
  onChangeFilter,
  refreshData,
  extraHeaderDom,
  search,
  actionRow,
  onRowClick,
  totalData = 1000,
  currentFilter,
  onReset,
  customFilterKeys,
  customReverseFilterKeys,
  excludeResetButtonValues,
  selectAllCustomNode,
  isDraggableColumn = false,
 } = props

 const [pingedLeft, setPingedLeft] = React.useState(false)
 const [pingedRight, setPingedRight] = React.useState(false)
 const scrollHeaderRef = React.useRef<HTMLDivElement>(null)
 const scrollBodyRef = React.useRef<HTMLDivElement>(null)
 //  const { updateTableStructure, global } = useGlobal()
 const { table_structure, updateTableStructure } = useWebSettings()
 const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
 const [updateTableStructureMutation] = useUpdateTableStructureMutation()

 // we must flatten the array of arrays from the useInfiniteQuery hook

 //  const totalDBRowCount = 1000
 const totalFetched = data?.length || 0

 // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
 const fetchMoreOnBottomReached = React.useCallback(
  (containerRefElement?: HTMLDivElement | null) => {
   if (containerRefElement) {
    const { scrollHeight, scrollTop, clientHeight } = containerRefElement

    // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
    if (
     scrollHeight - scrollTop - clientHeight < 900 &&
     !isFetching &&
     totalFetched < totalData
    ) {
     if (hasMore && !isFetching && !loading) {
      fetchMore?.()
     }
    }
   }
  },
  [isFetching, totalFetched, totalData, hasMore, loading, fetchMore],
 )

 const [sorting, setSorting] = React.useState<SortingState>([])
 const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
  [],
 )
 const [globalFilter, setGlobalFilter] = React.useState('')

 // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
 React.useEffect(() => {
  fetchMoreOnBottomReached(scrollBodyRef.current)
 }, [fetchMoreOnBottomReached])

 const mergedColumns: ColumnDef<any>[] = useMemo(
  () => [
   ...(rowSelectionState
    ? [
       MTRowSelection({
        onSelectRow,
        onUnSelect: (id) => onUnSelectRow?.(id),
        onSelectAll: (b) => rowSelectionState.onSelectedAll?.(b),
        selectAllCustomNode,
       }),
      ]
    : []),
   ...formatCols(columns),
  ],
  [columns, rowSelectionState],
 )

 const onRowSelectionChange = (v?: any) => {
  setRowSelection(v)
  setTimeout(() => {
   rowSelectionState?.onChangeSelection?.(
    table.getSelectedRowModel().flatRows.map((m) => m.original),
   )
  }, 200)
 }

 const onColumnFiltersChange = useCallback(
  (filterVal?: any) => {
   setColumnFilters((prv) => {
    const newFilter = filterVal(prv)
    onChangeFilter?.(filterArray2Object(newFilter, customFilterKeys) as any)
    return newFilter
   })
  },
  [onChangeFilter],
 )

 useEffect(() => {
  if (currentFilter) {
   setColumnFilters(filterObject2Array(currentFilter, customReverseFilterKeys))
  }
 }, [currentFilter])
 const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
  mergedColumns.map((c: any) =>
   c.accessorKey === 'id' ? 'selection' : c?.accessorKey?.replace(/\./g, '_'),
  ),
 )
 const colIds = useMemo(
  () =>
   mergedColumns.map((c: any) =>
    c.accessorKey === 'id' ? 'selection' : c?.accessorKey?.replace(/\./g, '_'),
   ),
  [mergedColumns],
 )

 useEffect(() => {
  if (
   isDraggableColumn &&
   colIds?.length &&
   !areArraysEqual(colIds, columnOrder)
  ) {
   setColumnOrder(colIds)
  }
 }, [colIds])

 const table = useReactTable({
  data: data || [],
  columns: mergedColumns,

  meta: { refreshData },
  state: {
   sorting,
   rowSelection,
   columnFilters,
   globalFilter,
   ...(isDraggableColumn ? { columnOrder } : {}),
  },
  onSortingChange: setSorting,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),

  onRowSelectionChange,
  getFilteredRowModel: getFilteredRowModel(),
  onColumnFiltersChange,
  onGlobalFilterChange: setGlobalFilter,
  manualFiltering: true,

  getRowId: (row) => row.id,
  columnResizeMode: 'onChange',
  // debugTable: true,
  ...(isDraggableColumn ? { onColumnOrderChange: setColumnOrder } : {}),
 })

 const { rows } = table.getRowModel()

 // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
 const rowVirtualizer = useVirtual({
  parentRef: scrollBodyRef,
  size: rows.length,
  overscan: 15,
 })
 const { virtualItems: virtualRows, totalSize } = rowVirtualizer

 useEffect(() => {
  if (rowSelectionState?.selection) {
   setRowSelection(convertRowObject(rowSelectionState.selection))
  }
 }, [rowSelectionState?.selection])

 const contextValue = useMemo(() => ({ table }), [table])

 const onScroll = useEvent(
  ({
   currentTarget,
   scrollLeft,
   target,
  }: {
   target: HTMLElement
   currentTarget: HTMLElement
   scrollLeft?: number
  }) => {
   fetchMoreOnBottomReached(target as HTMLDivElement)
   const mergedScrollLeft =
    typeof scrollLeft === 'number' ? scrollLeft : currentTarget.scrollLeft
   if (scrollHeaderRef && scrollHeaderRef.current) {
    forceScroll(currentTarget.scrollLeft, scrollHeaderRef.current)
   }
   if (scrollBodyRef && scrollBodyRef.current) {
    forceScroll(currentTarget.scrollLeft, scrollBodyRef.current)
   }
   if (currentTarget) {
    const { scrollWidth, clientWidth } = currentTarget
    // There is no space to scroll
    if (scrollWidth === clientWidth) {
     setPingedLeft(false)
     setPingedRight(false)
     return
    }
    setPingedLeft(mergedScrollLeft > 0)
    setPingedRight(mergedScrollLeft < scrollWidth - clientWidth)
   }
  },
 )

 const checkShowRestButton = useMemo(() => {
  if (
   columnFilters?.length === 0 ||
   columnFilters?.every((cv) =>
    excludeResetButtonValues?.some?.(
     (ev) => cv?.id === ev?.id && cv?.value === ev?.value,
    ),
   )
  ) {
   return false
  }
  return true
 }, [columnFilters, excludeResetButtonValues])

 const sensors = useSensors(
  useSensor(MouseSensor, {}),
  useSensor(TouchSensor, {}),
  useSensor(KeyboardSensor, {}),
 )

 const handleDragEnd = (event: DragEndEvent) => {
  const { active, over } = event

  if (
   active &&
   over &&
   active.id !== over.id &&
   over?.id !== 'selection' &&
   active?.id !== 'selection' &&
   over?.id !== 'name' &&
   active?.id !== 'name'
  ) {
   setColumnOrder((col) => {
    const oldIndex = col.findIndex(
     (item) =>
      item.replace(/\./g, '_') === (active?.id as string)?.replace(/\./g, '_'),
    )
    const newIndex = col.findIndex(
     (item) =>
      item.replace(/\./g, '_') === (over?.id as string)?.replace(/\./g, '_'),
    )
    const newStructure = arrayMove(
     table_structure ?? [],
     oldIndex - 1,
     newIndex - 1,
    )
    updateTableStructure?.(newStructure)
    updateTableStructureMutation({
     table_structure: JSON.stringify(newStructure),
    })
    return arrayMove(col, oldIndex, newIndex)
   })
  }
 }

 const isOverflowBody = useMemo(() => {
  if (!scrollBodyRef?.current) return false

  return (
   scrollBodyRef?.current?.scrollHeight > scrollBodyRef?.current?.clientHeight
  )
 }, [
  scrollBodyRef?.current?.scrollHeight,
  scrollBodyRef?.current?.clientHeight,
 ])

 const tableBody = (
  <div className="m-data-table-body">
   <div
    className={classNames('m-table m-table-scroll', {
     'm-table-ping-left': pingedLeft,
     'm-table-ping-right': pingedRight,
     'm-table-is-loading': loading,
    })}
   >
    <div className="m-table-container">
     <div
      ref={scrollHeaderRef}
      className="m-table-header"
      {...(isDraggableColumn &&
       isOverflowBody && { style: { width: 'calc(100% - 7px)' } })}
     >
      <MTHeader
       table={table}
       {...(isDraggableColumn ? { columnOrder } : {})}
       isDraggableColumn={isDraggableColumn}
      />
     </div>
     <Spin wrapperClassName="m-table-loader-wrapper" spinning={loading}>
      <div
       ref={scrollBodyRef}
       onScroll={onScroll as any}
       className="m-table-body"
      >
       <MTBody
        {...(isDraggableColumn ? { columnOrder } : {})}
        isLoading={isFetching}
        onRowClick={onRowClick}
        isEmpty={!data || (data && data.length === 0)}
        table={table}
        virtualRows={virtualRows}
        hasMore={hasMore}
        totalSize={totalSize}
        isDraggableColumn={isDraggableColumn}
       />
      </div>
     </Spin>
    </div>
   </div>
  </div>
 )

 return (
  <MTContext.Provider value={contextValue}>
   <div className="m-data-table-wrapper">
    <div className="m-data-table-header">
     <Row>
      {search?.show && (
       <Col flex={1}>
        <MTableSearchBar
         placeholder={search.placeholder}
         onChange={search.onSearch}
        />
       </Col>
      )}
      {extraHeaderDom && <Col className="ps-2">{extraHeaderDom}</Col>}
      {columnFilters.length > 0 &&
       (excludeResetButtonValues ? checkShowRestButton : true) && (
        <Col>
         <BaseButton
          onClick={() => {
           table.resetColumnFilters(true)
           onReset?.()
          }}
          tooltipText="Reset"
          danger
          type="primary"
          icon={<i className="far fa-undo" />}
          className="ms-2"
         />
        </Col>
       )}
     </Row>
    </div>
    {isDraggableColumn ? (
     <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
     >
      {tableBody}
     </DndContext>
    ) : (
     tableBody
    )}
   </div>
  </MTContext.Provider>
 )
}

const MDataTable = React.forwardRef(InternalMDataTable) as <
 RecordType extends object = any,
 FilterType extends object = MFIlterObjectType,
>(
 props: React.PropsWithChildren<MDataTableProps<RecordType, FilterType>> & {
  ref?: React.Ref<HTMLDivElement>
 },
) => React.ReactElement

export default MDataTable
