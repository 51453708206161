import { Col, Row } from 'antd'
import {
 SvgTownHouse,
 SvgCondoUnitStories,
 SvgCondoBed,
 SvgCondoCompleted,
 SvgCondoSqft,
 SvgCondoSqftPer,
} from 'components/icons'
import { TPreConCommon } from 'features/property'
import { FC } from 'react'

const PreCondoSpecs: FC<{ data?: TPreConCommon }> = ({ data }) => {
 return (
  <div>
   <ul
    className="list-inline condo-specs m-0"
    style={{ justifyContent: 'normal' }}
   >
    {data?.building_type && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgTownHouse />
       <span>{data.building_type}</span>
      </li>
     </Col>
    )}
    {(data?.units || data?.stories) && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgCondoUnitStories />
       {data.units && <span>{data.units} Units</span>}
       {data.stories && <span>{data.stories} Stories</span>}
      </li>
     </Col>
    )}{' '}
    {data?.bedrooms && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgCondoBed />
       <span>{data?.bedrooms}</span>
      </li>
     </Col>
    )}
    {data?.completion_year && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgCondoCompleted />
       <span>
        Occupancy Year
        {data.completion_year}
       </span>
      </li>
     </Col>
    )}
    {data?.sqft && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgCondoSqft />
       <span>{data?.sqft}</span>
      </li>
     </Col>
    )}
    {data?.price_per_sqft && (
     <Col span={4}>
      <li className="list-inline-item">
       <SvgCondoSqftPer />
       <span>{data.price_per_sqft}</span>
      </li>
     </Col>
    )}
   </ul>
  </div>
 )
}

export default PreCondoSpecs
