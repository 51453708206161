import { Empty, EmptyProps } from 'antd'
import React, { FC } from 'react'

const BaseEmptyBlock: FC<
 EmptyProps & {
  mode?: 'full' | 'block'
 }
> = ({ mode = 'full', ...rest }) => {
 const fullClass =
  'd-flex w-100 h-100 align-items-center justify-content-center'
 return (
  <div className={`${mode === 'full' ? fullClass : ''}`}>
   <Empty {...rest} />
  </div>
 )
}

export default BaseEmptyBlock
