import { Row, Col, Card } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import { FC } from 'react'
import { useGetPreconDetailQuery } from 'features/property/api/precon'
import PropertyImageBlock from '../PropertyImageBlock/PropertyImageBlock'
import PreAmenities from './_components/PreAmenities'
import PreCondoSpecs from './_components/PreCondoSpecs'
import PreDescription from './_components/PreDescription'
import PreMainInfoBlock from './_components/PreMainInfoBlock'
import PropertyDetailsBlock from '../NonPreCondoDetails/_components/PropertyDetailsBlock'
import PreFeatures from './_components/PreFeatures'
import PreSimilarPropBlock from './_components/PreSimilarProModal'

const PreCondoDetails: FC<{
 slug: string
}> = ({ slug }) => {
 const { data, isLoading } = useGetPreconDetailQuery({ slug })
 if (isLoading) {
  return <BlockLoader />
 }

 return (
  <div className="pre-pro-dtls-page">
   <Row gutter={[15, 15]}>
    <Col span={24}>
     <PropertyImageBlock images={data?.images} />
    </Col>
    <Col span={24}>
     <Card title="" className="m-0">
      <PreMainInfoBlock data={data?.common} />
     </Card>
    </Col>
    <Col span={24}>
     <Card title="" className="m-0">
      <PreCondoSpecs data={data?.common} />
     </Card>
    </Col>
    {data?.description && (
     <Col span={24}>
      <Card className="m-0">
       <PreDescription data={data?.description} />
      </Card>
     </Col>
    )}
    {data?.details && <PropertyDetailsBlock details={data?.details} />}
    {data?.amenities && (
     <Col span={24}>
      <Card className="m-0">
       <PreAmenities data={data?.amenities} />
      </Card>
     </Col>
    )}
    <PreFeatures data={data?.features} />
    {data?.similar_properties?.length && (
     <Col span={24}>
      <Card className="m-0">
       <PreSimilarPropBlock data={data?.similar_properties} />
      </Card>
     </Col>
    )}
   </Row>
  </div>
 )
}

export default PreCondoDetails
