/* eslint-disable no-nested-ternary */
import { Tag } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import {
 useControlActionPlanMutation,
 useControlNewsLetterMutation,
 useWithdrawActionPlanLeadMutation,
} from 'features/automation'
import { TPlanStatus, TPlanStatusPost } from 'features/leads/types'
import { FC, useCallback, useEffect, useState } from 'react'

const AutomationBlockItem: FC<{
 id?: any
 leadId?: string
 status?: TPlanStatus
 text?: string
 isTransferred?: boolean
 from?: 'action-plan' | 'real-estate-news' | 'market-updates'
}> = ({ text, status = 'Pause', leadId, id, isTransferred, from }) => {
 const [actionControl] = useControlActionPlanMutation()
 const [newStatus, setStatus] = useState(status)
 const [deleteBulkActionPlan] = useWithdrawActionPlanLeadMutation()
 const [controlNewsLetter] = useControlNewsLetterMutation()

 useEffect(() => {
  setStatus(status)
 }, [status])

 const handleCLickControl = useCallback(
  async (sts: TPlanStatusPost) => {
   globalConfirm({
    content: `Are you sure want to ${sts} this?`,
    onOk: async () => {
     if (from === 'action-plan') {
      await actionControl({
       lead_id: [leadId],
       action: sts,
       plan_id: id,
      }).unwrap()

      if (sts === 'Resume' || sts === 'Restart') {
       setStatus('Running')
      } else if (sts === 'Stop') {
       setStatus('Completed')
      } else if (sts === 'Pause') {
       setStatus('Pause')
      }
     } else {
      await controlNewsLetter({
       action:
        sts === 'Pause' ? 'pause' : sts === 'Resume' ? 'restart' : 'Delete',
       newsletter_id: [id],
       type:
        from === 'market-updates' ? 'market-update' : 'Real-Estate-News-Letter',
       lead_id: leadId ? [Number(leadId)] : [],
      }).unwrap()
      setStatus(sts === 'Resume' ? 'Running' : 'Pause')
     }
    },
   })
  },
  [actionControl, id, leadId],
 )
 const deleteAutomationItem = () => {
  globalConfirm({
   content: `Are you sure you want to delete this ${
    from === 'action-plan'
     ? 'actionplan'
     : from === 'market-updates'
     ? 'market update'
     : from === 'real-estate-news'
     ? 'real estate news'
     : ''
   }?`,
   onOk: async () => {
    if (from === 'action-plan') {
     await deleteBulkActionPlan({
      lead_id: leadId,
      actionplan_id: id,
     }).unwrap()
    } else {
     await controlNewsLetter({
      action: 'Delete',
      newsletter_id: [id],
      type:
       from === 'market-updates' ? 'market-update' : 'Real-Estate-News-Letter',
      lead_id: leadId ? [Number(leadId)] : [],
     }).unwrap()
    }
   },
  })
 }

 return (
  <div className="item-block-li">
   <span className="content">{text}</span>
   <span>{status === 'Completed' ? <Tag color="red">{status}</Tag> : ''}</span>

   <span className="action" style={{ width: '140px' }}>
    {isTransferred && (
     <>
      {(newStatus === 'Running' || newStatus === 'Pause') && (
       <BaseButton
        onClick={() =>
         handleCLickControl(newStatus === 'Running' ? 'Pause' : 'Resume')
        }
        buttonStyle="outline"
        size="small"
        buttonType="success"
        icon={
         <i
          className={`fas fa-${newStatus === 'Running' ? 'pause' : 'play'}`}
         />
        }
       />
      )}
      {(newStatus === 'Running' || newStatus === 'Pause') &&
       from === 'action-plan' && (
        <BaseButton
         onClick={() => handleCLickControl('Stop')}
         buttonStyle="outline"
         size="small"
         buttonType="warning"
         icon={<i className="fas fa-stop" />}
        />
       )}
      {(newStatus === 'Completed' || newStatus === 'Stop') &&
       from === 'action-plan' && (
        <BaseButton
         buttonStyle="outline"
         size="small"
         onClick={() => handleCLickControl('Restart')}
         buttonType="info"
         icon={<i className="fas fa-undo" />}
        />
       )}

      <BaseButton
       buttonStyle="outline"
       size="small"
       onClick={deleteAutomationItem}
       buttonType="danger"
       icon={<i className="fas fa-trash" />}
      />
     </>
    )}
   </span>
  </div>
 )
}

export default AutomationBlockItem
