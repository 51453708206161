import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { FCC } from 'types'
import { BlockLoader } from '../Loader'

const InfinityPagination: FCC<{
 useWindow?: boolean
 isReverse?: boolean
 hasMore?: boolean
 className?: any
 loadMore?: () => void
}> = ({
 children,
 loadMore,
 hasMore,
 useWindow = true,
 isReverse = false,
 className,
}) => (
 <InfiniteScroll
  className={className ?? ''}
  isReverse={isReverse}
  useWindow={useWindow}
  hasMore={hasMore}
  loader={<BlockLoader key="loader" className="w-100 py-4" />}
  loadMore={() => loadMore?.()}
 >
  {children}
 </InfiniteScroll>
)

export default InfinityPagination
