import { Layout } from 'antd'
import React, { FC } from 'react'
import moment from 'moment'

const Footer: FC = () => {
 const { Footer } = Layout

 return (
  <Footer className="crm-footer" style={{ textAlign: 'center' }}>
   Copyright © {moment().format('YYYY')}
   <div className="link">
    <a href="https://agentroof.com/" target="_blank" rel="noreferrer">
     &nbsp;Agentroof
    </a>
   </div>
   . All rights reserved.
  </Footer>
 )
}

export default Footer
