import { Col, Row } from 'antd'
import { useGetGroupsQuery } from 'features/settings/api'
import ListContentLayout from 'layout/ListContentLayout'
import React, { FC } from 'react'
import TagListBlock from './GroupListBlock'

const TagStageSource: FC = () => {
 const { data, isLoading } = useGetGroupsQuery({
  type: ['source', 'stage', 'tag'],
 })
 return (
  <ListContentLayout title="Tags | Stage | Source">
   <Row gutter={[15, 15]}>
    <Col span={8}>
     <TagListBlock
      loading={isLoading}
      title="Tag"
      type="tag"
      data={data?.tag}
     />
    </Col>
    <Col span={8}>
     <TagListBlock
      loading={isLoading}
      title="Stage"
      type="stage"
      data={data?.stage}
     />
    </Col>
    <Col span={8}>
     <TagListBlock
      loading={isLoading}
      title="Source"
      type="source"
      data={data?.source}
     />
    </Col>
   </Row>
  </ListContentLayout>
 )
}

export default TagStageSource
