import { Card, Space, Input } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import BaseEmptyBlock from 'components/elements/BaseEmpty/BaseEmptyBlock'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import { useSendSmsLeadMutation } from 'features/leads'
import useAuth from 'hooks/useAuth'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useAppControl from 'hooks/useAppControl'
import { formatDate } from 'utils/functions'
import { TInboxItemMsgs, TInboxSMS } from '../types'
import { groupMailByDate } from '../functions'

const TextMessageList: FC<{
 flatData?: TInboxItemMsgs[]
 refetch?: () => void
 hasMore?: boolean
 fetchMore?: () => void
}> = ({ flatData, refetch, fetchMore, hasMore }) => {
 const data = useMemo(() => groupMailByDate('sms', flatData), [flatData])

 const [input, setInput] = useState('')
 const params = useParams()
 const { user } = useAuth()
 const [sendSMS] = useSendSmsLeadMutation()
 const { updateSendSMSWidget } = useAppControl({
  onSuccessSMS: () => refetch?.(),
 })

 const handleClickSend = async (): Promise<void> => {
  if (input.trim()) {
   await sendSMS({
    body: input,
    // to: Array.isArray(phoneNumbers) ? phoneNumbers.join('') : phoneNumbers,
    to: data?.[0]?.sms?.[0]?.to_number,
    from: user?.twilio_phone,
   }).unwrap()
   refetch?.()
   setInput('')
  }
 }
 if (!data || (data && data.length === 0)) {
  return <BaseEmptyBlock description="Text Empty!" />
 }

 return (
  <Card
   className="messge-list-inner"
   type="inner"
   title={data?.[0]?.sms?.[0]?.to_name ?? ''}
   extra={
    <Space>
     <BaseButton
      onClick={() => {
       updateSendSMSWidget({
        widgetFrom: 'lead',
        show: true,
        to: data?.[0]?.sms?.[0]?.to_number,
       })
      }}
      buttonType="primary"
      icon={<i className="fal fa-comment-alt-lines me-1" />}
      size="small"
     >
      Send SMS
     </BaseButton>
     <Link
      to={{
       hash: `lead-details/${params.id}/profile`,
      }}
     >
      <BaseButton
       buttonType="primary"
       buttonStyle="outline"
       icon={<i className="fas fa-user me-1" />}
       size="small"
      >
       Profile
      </BaseButton>
     </Link>
    </Space>
   }
  >
   <div className="sms-msg-container">
    <div className="sms-msg-flex">
     <InfinityPagination
      className="chat-block"
      isReverse
      useWindow={false}
      hasMore={hasMore}
      loadMore={fetchMore}
     >
      {data?.map?.((item: TInboxSMS, i) => (
       <div key={i}>
        <div className="history-date">{item.date}</div>
        {item?.sms?.map?.((msg, index) => (
         <div
          key={index}
          className={`chat-content ${
           msg?.send_type === 'Send' ? 'is-you' : ''
          }`}
         >
          <div className="text-inner">
           <div className="chat-arrow" />
           {msg?.body}
           {msg?.updated_at && (
            <div className="date">{formatDate(msg?.updated_at)}</div>
           )}
          </div>
         </div>
        ))}
       </div>
      ))}
     </InfinityPagination>
    </div>
   </div>
   <div className="sms-msg-input">
    <Input.TextArea
     value={input}
     onChange={(e: any) => setInput(e.target.value)}
     placeholder="Enter Message"
     size="large"
     autoSize={{ minRows: 1, maxRows: 4 }}
     onKeyDown={(e: any) => {
      if (e.keyCode == 13 && e.shiftKey == false) {
       e.preventDefault()
       handleClickSend()
      }
     }}
    />
    <i
     onClick={() => handleClickSend()}
     className="fas fa-paper-plane send-icon"
    />
   </div>
  </Card>
 )
}

export default TextMessageList
