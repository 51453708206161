import { Select, SelectProps, Spin } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import React, { FC, useEffect, useState } from 'react'

const LoadMoreSelect: FC<
 SelectProps & {
  fetchMore?: () => void
  hasMore?: boolean
  data?: DefaultOptionType[]
 }
> = ({ fetchMore, data, children, hasMore, onSearch, ...rest }) => {
 const [searchKey, setSearchKey] = useState('')

 useEffect(() => {
  const timer = setTimeout(() => {
   onSearch?.(searchKey ?? '')
  }, 500)
  return () => {
   clearTimeout(timer)
  }
 }, [searchKey])

 return (
  <Select
   getPopupContainer={(trigger) => trigger.parentNode}
   className="w-100"
   {...rest}
   value={!rest.loading ? rest.value : ''}
   onSearch={(val) => {
    setSearchKey(val)
   }}
   showSearch
   filterOption={false}
   notFoundContent={
    <div className="text-center">{rest.loading ? <Spin /> : 'Not Found'}</div>
   }
   onPopupScroll={(e) => {
    e.persist()
    const { currentTarget } = e
    if (
     currentTarget.scrollTop + currentTarget.offsetHeight ===
     currentTarget.scrollHeight
    ) {
     fetchMore?.()
     // API call (to load more data..........)
    }
   }}
  >
   {children ||
    (data &&
     data.length > 0 &&
     data.map((item, i) => (
      <Select.Option key={i} value={item.value}>
       {item.label}
      </Select.Option>
     )))}
   {hasMore && (
    <Select.Option disabled>
     <div className="text-center">
      <Spin size="small" /> Loading
     </div>
    </Select.Option>
   )}
  </Select>
 )
}

export default LoadMoreSelect
