import { BaseButton } from 'components/elements/BaseButton'
import BaseEmptyBlock from 'components/elements/BaseEmpty/BaseEmptyBlock'
import { BlockLoader } from 'components/elements/Loader'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { FCC } from 'types'

const InnerContentLayout: FCC<{
 isEmpty?: boolean
 loading?: boolean
 title?: string
}> = ({ children, title, loading, isEmpty = false }) => {
 const naviagate = useNavigate()

 if (loading) {
  return <BlockLoader mode="full" />
 }

 return (
  <div className="innnercontentlayout">
   <div className="innnercontentlayout-header">
    <h4 className="innnercontentlayout-title">{title}</h4>
    <div className="innnercontentlayout-actions">
     <BaseButton
      onClick={() => naviagate(-1)}
      icon={<i className="fas fa-times" />}
     />
    </div>
   </div>
   <div className="innnercontentlayout-body">
    {isEmpty ? <BaseEmptyBlock /> : children}
   </div>
  </div>
 )
}

export default InnerContentLayout
