/* eslint-disable react/no-unescaped-entities */
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { FC, useEffect, useState } from 'react'
import { useLazyResendOtpQuery, useVerifyOtpMutation } from 'features/leads'
import useAuth from 'hooks/useAuth'
import { BlockLoader } from 'components/elements/Loader'
import OTPInput from './OtpInput/OTPInput'

export const OtpModal: FC<{
 handleClickVerify: () => Promise<void>
}> = ({ handleClickVerify }) => {
 const { user } = useAuth()

 const [sendOtp] = useLazyResendOtpQuery()
 const [verifyOtp] = useVerifyOtpMutation()
 const [otp, setOtp] = useState<string>('')
 const [loading, setLoading] = useState(false)

 useEffect(() => {
  sendOtp({ showToast: false }).unwrap()
 }, [])

 const handleResendOtp = async (): Promise<void> => {
  setOtp('')
  await sendOtp({ showToast: true }).unwrap()
 }

 const handleSubmitVerify = async (): Promise<void> => {
  // if (!user?.twilio_phone) return
  setLoading(true)
  verifyOtp({
   otp,
  })
   .unwrap()
   .then(async () => {
    handleClickVerify()
     .then(() => {
      setLoading(false)
     })
     .catch(() => {
      setLoading(false)
     })
   })
   .catch(() => {
    setLoading(false)
   })
 }

 useEffect(() => {
  if (otp && otp.length > 3) {
   handleSubmitVerify()
  }
 }, [otp])

 //  if (sendLoading) {
 //   return <BlockLoader />
 //  }

 return (
  <div className="otp-form-section text-center">
   <div>
    {/* <h6 className="header">
     OTP is sent to
     {`${user?.phone ? `+${user?.phone}` : ''} ${
      user?.email ? ' and' : ''
     }`}{' '}
     {user?.email}
    </h6> */}
    <h6 className="header">
     An OTP has been shared with your primary number {user?.phone} and the email{' '}
     {user?.email}. Please verify the OTP to continue exporting the leads.
    </h6>
   </div>
   <div className="otp-group-form">
    {/* <span>Enter OTP</span> */}
    <OTPInput
     autoFocus
     isNumberInput
     length={4}
     className="otoblock justify-content-center mt-2"
     inputClassName="form-control"
     onChangeOTP={(ot) => setOtp(ot)}
    />
   </div>
   <BaseButton
    onClick={() => handleSubmitVerify()}
    loading={loading}
    disabled={otp.length !== 4}
    type="primary"
    className="mt-3"
   >
    Verify OTP
   </BaseButton>
   <p className="mt-3">
    Didn't receive the OTP?
    <BtnAnch onClick={handleResendOtp}>
     <span className="ms-2 colored-link-1">Resend OTP</span>
    </BtnAnch>
   </p>
  </div>
 )
}
