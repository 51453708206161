import { LoadMoreSelect } from 'components/elements/Form'
import { TLeadsNames } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import React, { FC, useMemo, useState } from 'react'
import { TMode } from 'types'

const SelectLeadBlock: FC<{
 mode?: 'multiple' | 'tags'
 onChange?: (val?: any) => void
 value?: any
 leadId?: any
 formMode?: TMode
}> = ({ leadId, formMode, value, ...props }) => {
 const [searchLeadKey, setLeadKey] = useState('')

 const {
  flatData: leadList,
  isLoading: leadLoading,
  hasMore,
  fetchMore,
 } = useFetchPage<TLeadsNames>('get-leads-name', {
  method: 'post',
  body: useMemo(
   () => ({
    search: searchLeadKey,
    id: formMode === 'EDIT' && value ? [value] : null,
   }),
   [formMode, searchLeadKey, value],
  ),
 })
 return (
  <LoadMoreSelect
   {...props}
   value={value}
   onSearch={setLeadKey}
   fetchMore={fetchMore}
   hasMore={hasMore}
   loading={leadLoading}
   data={leadList?.map((v) => ({ label: v.name, value: v.id }))}
   placeholder="Enter Lead"
  />
 )
}

export default SelectLeadBlock
