import { Select } from 'antd'
import { ADMIN_PREFIX_PATH } from 'config'
import useAuth from 'hooks/useAuth'
import { FC } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

const options = [
 { value: 'inbox', label: 'Inbox' },
 { value: 'send', label: 'Sent' },
 { value: 'scheduled', label: 'Scheduled' },
]

const InboxNavLinks: FC = () => {
 const { user } = useAuth()
 const navigate = useNavigate()
 const { pathname } = useLocation()
 const mode = pathname.split('/')?.at(3)
 const type = pathname.split('/')?.at(4)

 return (
  <ul className="head-links">
   <li>
    <NavLink
     className={`nav-link ${mode === 'email' ? 'active' : ''}`}
     to={`${ADMIN_PREFIX_PATH}/inbox/email/${type ?? 'inbox'}`}
    >
     Email
    </NavLink>
   </li>
   {user?.twilio_phone && (
    <li>
     <NavLink
      className={`nav-link ${mode === 'sms' ? 'active' : ''}`}
      to={`${ADMIN_PREFIX_PATH}/inbox/sms/${type ?? 'inbox'}`}
     >
      SMS
     </NavLink>
    </li>
   )}
   <li>
    <Select
     onChange={(val) => {
      navigate(`${ADMIN_PREFIX_PATH}/inbox/${mode}/${val}`)
     }}
     value={type ?? 'inbox'}
     style={{ width: '200px' }}
     options={options}
    />
   </li>
  </ul>
 )
}

export default InboxNavLinks
