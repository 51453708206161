/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, Empty, Tag, Avatar } from 'antd'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { SvgEmailIcon } from 'components/icons'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TUserActivityEmailLog } from 'features/leads/types'
import { FC, useState } from 'react'
import { formatDate } from 'utils/functions'
import useFetchPageNew from 'hooks/useFetchpageNew'

import ActivityEmailActions from './components/ActivityEmailActions'
import { buildDetailModal } from './components/ActivityDetailModal'

const EmailActivity: FC = () => {
 const { leadId, isTransferred, realtorId } = useLeadDetails()
 const [showModal, setShowModal] = useState<{
  show: boolean
  index?: number | null
 }>({
  show: false,
 })

 const { Meta } = Card

 const {
  flatData,
  isLoading,
  fetchMore,
  hasMore,
  isRefetching,
  total,
  refetch,
 } = useFetchPageNew<TUserActivityEmailLog>(`lead/profile/${leadId}`, {
  body: { type: 'emaillog', ...(realtorId ? { realtor_id: realtorId } : {}) },
  method: 'post',
 })

 if (isLoading) {
  return <BlockLoader />
 }

 if (!flatData || flatData.length === 0) {
  return (
   <div className="no-data-empty-compo">
    <Empty
     image={<i className="fal fa-envelope-open no-data-image" />}
     description={<span className="no-data-heading">You have no message</span>}
    >
     <p className="no-data-description">
      {' '}
      Your inbox is empty. Send a message to a friend to get started
     </p>
    </Empty>
   </div>
  )
 }

 return (
  <Card
   className="activity-layout-crd"
   title={`Email (${total})`}
   loading={isLoading}
   bodyStyle={{ flex: 1 }}
  >
   <div className="card-body-inner">
    <LoeadMorePagination
     fethMore={fetchMore}
     hasMore={hasMore}
     loading={isRefetching}
    >
     {flatData?.map((item, index) => (
      <Card
       key={index}
       hoverable
       onClick={() => setShowModal({ show: true, index })}
       style={{ borderColor: '#ccc' }}
       className="mb-2"
      >
       <div className="d-flex justify-content-between">
        <div>
         <Meta
          avatar={<Avatar size={50} icon={<SvgEmailIcon />} />}
          description={
           <div>
            <h3 className="mb-0 mt-0">
             {item?.from_name} <i className="fal fa-angle-right" />{' '}
             {item?.to_name}
            </h3>
            {item?.subject && <h4 className="mb-1 mt-0">{item?.subject}</h4>}
            {item?.updated_at && (
             <p className="mb-0 mt-2" style={{ fontSize: '13px' }}>
              <span
               style={{
                border: ' 1px solid #ccc',
                padding: ' 0 7px',
               }}
              >
               {formatDate?.(item?.updated_at)}
              </span>
              {item?.status && (
               <Tag className="ms-2" color="blue">
                {item.status}
               </Tag>
              )}
             </p>
            )}
           </div>
          }
         />
        </div>
        {isTransferred && (
         <div
          onClick={(e) => {
           e.stopPropagation()
          }}
         >
          <ActivityEmailActions datas={flatData[index]} />
         </div>
        )}
       </div>
      </Card>
     ))}
    </LoeadMorePagination>
   </div>
   {showModal?.show &&
    showModal?.index !== null &&
    showModal?.index !== undefined &&
    buildDetailModal(
     () => setShowModal({ show: false, index: null }),
     showModal?.show,
     'email',
     refetch,
     isLoading,
     flatData[showModal?.index],
    )}
  </Card>
 )
}
export default EmailActivity
