import {
 baseAPI,
 baseTransformResponse,
 baseTransformResponse2,
 baseTransformResponse3,
} from 'api/baseApi'
import { queryClient } from 'lib/react-query'
import { ApiResponce, TGroupName } from 'types'
import {
 TBirthdayWishes,
 TGetAllSignatures,
 TGetEmailTemplateId,
 TGetGroups,
 TGetGroupsBody,
 TGetSmsTemplateId,
 TGroupForm,
 TPutTextTemplate,
 TTemplateName,
} from '../types'
import { TResetPass } from '../routes/reset-password'

export const refetchTextTemplateList = () =>
 queryClient.invalidateQueries('get-text-templates')

export const settingApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  getEmailTemplateNames: query<TTemplateName[], any>({
   query: () => 'user/get-email-templates-name',
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['EMAIL_TEMP'],
  }),
  getTextTemplateNames: query<TTemplateName[], any | void>({
   query: () => 'user/get-text-templates-name',
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['TEXT_TEMP'],
  }),
  addEmailTemplate: mutation<void, any>({
   query: (body) => ({
    url: 'user/put-email-template',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['EMAIL_TEMP'],
  }),
  getEmailTemplateById: query<TGetEmailTemplateId, { id?: string }>({
   query: ({ id }) => `user/get-email-template/${id}`,
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getSmsTemplateById: query<TGetSmsTemplateId, { id?: string }>({
   query: ({ id }) => `user/get-text-template/${id}`,
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getGroups: query<TGetGroups, TGetGroupsBody & { from?: 'my_realtor' }>({
   query: (body) => ({
    url: 'user/get-groups',
    method: 'post',
    body,
   }),
   providesTags: ['GROUP_LIST'],
   transformResponse: (res: ApiResponce) => res.data,
  }),
  addTextTemplate: mutation<void, { name: string; body?: string }>({
   query: (body) => ({
    url: 'user/put-text-template',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['TEXT_TEMP'],
  }),
  deleteTextTemplates: mutation<void, { id: any[] }>({
   query: (body) => ({
    url: 'user/delete-text-templates',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  deleteEmailTemplates: mutation<void, { id: any[] }>({
   query: (body) => ({
    url: 'user/delete-email-templates',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  getTextTemplateById: query<TPutTextTemplate, { id?: string }>({
   query: ({ id }) => `user/get-text-template/${id}`,
   transformResponse: (res: ApiResponce) => res.data,
  }),
  editTextTemplate: mutation<void, TPutTextTemplate>({
   query: ({ id, ...body }) => ({
    url: `user/update-text-template/${id}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['TEXT_TEMP'],
  }),
  editEmailTemplate: mutation<void, { body: FormData; id?: any }>({
   query: ({ id, body }) => ({
    url: `user/update-email-template/${id}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['EMAIL_TEMP'],
  }),
  addGroup: mutation<void, TGroupForm>({
   query: (body) => ({
    url: 'user/put-group',
    method: 'post',
    body,
   }),
   invalidatesTags: ['GROUP_LIST'],
   transformResponse: baseTransformResponse,
  }),
  deleteGroup: mutation<void, { id: any[]; type?: TGroupName }>({
   query: (body) => ({
    url: 'user/delete-groups',
    method: 'delete',
    body,
   }),
   invalidatesTags: ['GROUP_LIST'],
   transformResponse: baseTransformResponse,
  }),
  updateGroup: mutation<void, { body?: TGroupForm; id?: any }>({
   query: ({ body, id }) => ({
    url: `user/update-group/${id}`,
    method: 'post',
    body,
   }),
   invalidatesTags: ['GROUP_LIST'],
   transformResponse: baseTransformResponse,
  }),
  //   editSignature: mutation<void, { signature?: any }>({
  //    query: (body) => ({
  //     url: 'user/signature-update',
  //     method: 'post',
  //     body,
  //    }),
  //    invalidatesTags: ['SIGNATURE'],
  //    transformResponse: baseTransformResponse,
  //   }),
  uploadTinyFile: mutation<any, any>({
   query: (body) => ({
    url: 'user/tinynote-upload',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  resetPassword: mutation<void, TResetPass>({
   query: (body) => ({
    url: 'user/reset-password',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  getListingSetting: query<
   string[],
   { url: 'get-neighbourhood-setting' | 'get-brokerage-setting' }
  >({
   query: ({ url }) => ({
    url: `user/${url}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
  saveListingItems: mutation<
   void,
   {
    url: 'save-brokerage-to-listing' | 'save-neighbourhood-to-listing'
    body: any
   }
  >({
   query: ({ url, body }) => ({
    url: `user/${url}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  dltListingItem: mutation<
   void,
   {
    url: 'delete-brokerage-listing' | 'delete-neighbourhood-listing'
    body: any
   }
  >({
   query: ({ url, body }) => ({
    url: `user/${url}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  filterListingSettingSearch: query<
   string[],
   { type: 'brokerage' | 'city'; val: string }
  >({
   query: (body) => ({
    url: 'user/filter-search-two',
    method: 'post',
    body,
   }),
   transformResponse: (res: any) => res?.data ?? [],
  }),
  // birthday wishes
  getBirthdayTemplates: query<TBirthdayWishes, void>({
   query: (body) => ({
    url: 'user/birthday',
    method: 'get',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),
  updateAutoShare: mutation<void, 'Active' | 'Pause'>({
   query: (val) => ({
    url: 'user/birthday/autoShare',
    method: 'post',
    body: { status: val },
   }),
   transformResponse: baseTransformResponse,
  }),
  bDayTempCreate: mutation<
   void,
   {
    template_id?: number
    type?: 'sms' | 'email'
   }
  >({
   query: (body) => ({
    url: 'user/birthday/create',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  bDayTempDlt: mutation<void, { type?: string }>({
   query: (body) => ({
    url: 'user/birthday/delete',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),

  //   signature
  getAllSignatures: query<TGetAllSignatures[], void>({
   query: (body) => ({
    url: 'user/signature',
    method: 'get',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),
  updateSignature: mutation<
   TGetAllSignatures & { created_at?: string; updated_at?: string },
   TGetAllSignatures & {
    method: 'put' | 'post'
   }
  >({
   query: ({ id, method, ...body }) => ({
    url: `user/signature${id ? `/${id}` : ''}`,
    method,
    body: { ...body },
   }),
   transformResponse: baseTransformResponse2,
  }),
  deleteSignature: mutation<void, { id?: number[] }>({
   query: (body) => ({
    url: 'user/signature',
    method: 'delete',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  updateSignatureStatus: mutation<void, { id?: number; is_default: boolean }>({
   query: (body) => ({
    url: 'user/signature-update',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useUpdateGroupMutation,
 useAddGroupMutation,
 useDeleteGroupMutation,
 useGetGroupsQuery,
 useGetSmsTemplateByIdQuery,
 useGetEmailTemplateByIdQuery,
 useGetEmailTemplateNamesQuery,
 useGetTextTemplateNamesQuery,
 useAddEmailTemplateMutation,
 useAddTextTemplateMutation,
 useDeleteTextTemplatesMutation,
 useGetTextTemplateByIdQuery,
 useEditTextTemplateMutation,
 useEditEmailTemplateMutation,
 //  useGetSignatureQuery,
 //  useEditSignatureMutation,
 useDeleteEmailTemplatesMutation,
 useUploadTinyFileMutation,
 useResetPasswordMutation,
 useGetListingSettingQuery,
 useSaveListingItemsMutation,
 useDltListingItemMutation,
 useLazyFilterListingSettingSearchQuery,
 useGetBirthdayTemplatesQuery,
 useUpdateAutoShareMutation,
 useBDayTempCreateMutation,
 useBDayTempDltMutation,
 useGetAllSignaturesQuery,
 useUpdateSignatureMutation,
 useDeleteSignatureMutation,
 useUpdateSignatureStatusMutation,
} = settingApi
