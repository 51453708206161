import { BlockLoader } from 'components/elements/Loader'
import EmailMessageList from 'features/inbox/components/EmailMessageList'
import TextMessageList from 'features/inbox/components/TextMessageList'
import { TInboxEmail, TInboxItemMsgs, TInboxSMS } from 'features/inbox/types'
import useFetchPage2 from 'hooks/useFetchPage2'
import { FC } from 'react'
import { useParams } from 'react-router'

const InboxMessages: FC = () => {
 const params = useParams()

 const { flatData, hasMore, isLoading, fetchMore, refetch } =
  useFetchPage2<TInboxItemMsgs>(
   `inbox/lead/${params?.mode === 'sms' ? 'texts' : 'emails'}`,
   {
    params: `lead_id=${params?.id}&type=${params?.type}`,
   },
  )

 if (isLoading) {
  return <BlockLoader mode="full" />
 }

 if (params?.mode === 'email') {
  return (
   <EmailMessageList
    refetch={refetch}
    flatData={flatData}
    hasMore={hasMore}
    fetchMore={fetchMore}
   />
  )
 }
 if (params?.mode === 'sms') {
  return (
   <TextMessageList
    hasMore={hasMore}
    fetchMore={fetchMore}
    refetch={refetch}
    flatData={flatData}
   />
  )
 }

 return null
}

export default InboxMessages
