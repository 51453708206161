/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import { Col, Empty, Modal, Row } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { FC, useEffect, useMemo, useState } from 'react'
import { useGetGroupsQuery } from 'features/settings'
import useFetchPage from 'hooks/useFetchPage'
import BaseSelector, { BaseSelectorOption } from '../BaseSelector'

const SWToBarTag: FC<{
 showAdd?: boolean
 type: 'text' | 'email'
 onChange?: (v: BaseSelectorOption[]) => void
 value?: BaseSelectorOption[]
}> = ({ onChange, value, type, showAdd = true }) => {
 const [show, setShow] = useState(false)
 const [selected, setSelected] = useState<BaseSelectorOption[]>(value || [])

 useEffect(() => {
  if (value) {
   if (typeof value === 'string') {
    setSelected([value])
   } else {
    setSelected(value)
   }
  }
 }, [value])

 return (
  <>
   <div className="sw-to-bar ">
    <div className="input-to-area">
     <div className="sw-bar-container">
      {selected && selected?.length > 0 ? (
       <Row gutter={[6, 6]} className="m-0">
        {selected?.map?.((v, i) => (
         <Col key={i}>
          <div className="sw-to-bar-tag">
           <span>{v.title}</span>
           {showAdd ? (
            <BtnAnch
             onClick={() => {
              setSelected((prv) => {
               let newData = prv
               newData = prv.filter((m) => m.id !== v.id)
               onChange?.(newData)
               return [...newData]
              })
             }}
            >
             <i className="fal fa-times" />
            </BtnAnch>
           ) : (
            ''
           )}
          </div>
         </Col>
        ))}
       </Row>
      ) : (
       <BtnAnch onClick={() => setShow(true)} className="sw-bar-placeholder">
        Select Tag
       </BtnAnch>
      )}
     </div>
    </div>
    {showAdd && (
     <BtnAnch
      onClick={() => setShow(true)}
      className="sw-to-bar-action"
      icon={<i className="far fa-plus" />}
     />
    )}
   </div>
   {show && (
    <SWToBarTagModal
     type={type}
     selectedValue={selected}
     handleOkay={(v) => {
      onChange?.(v)
      setSelected(v)
     }}
     visible={show}
     onCancel={() => setShow(false)}
    />
   )}
  </>
 )
}

export default SWToBarTag

const SWToBarTagModal: FC<{
 type: 'text' | 'email'
 selectedValue?: BaseSelectorOption[]
 visible?: boolean
 onCancel?: () => void
 handleOkay?: (v: BaseSelectorOption[]) => void
}> = ({ visible, onCancel, handleOkay, selectedValue, type }) => {
 const [selected, setSelected] = useState<BaseSelectorOption[]>(
  selectedValue ?? [],
 )
 const [searchLeadKey, setSearchKey] = useState('')
 //  const { data, isFetching } = useGetGroupsQuery({
 //   type: ['tag'],
 //   feed: (type as any) ?? null,
 //   search: searchLeadKey,
 //  })
 const { flatData, fetchMore, hasMore, isLoading } = useFetchPage<any>(
  'search_lead_withtag',
  {
   method: 'post',
   body: { type: (type as any) ?? null, search: searchLeadKey },
  },
 )

 useEffect(() => {
  if (selectedValue) {
   setSelected(selectedValue)
  }
 }, [selectedValue])

 //  const options = useMemo(
 //   () =>
 //    data && data.tag && data.tag.length > 0
 //     ? data?.tag?.map((v) => ({ id: v.id, title: v.name }))
 //     : [],
 //   [data],
 //  )

 return (
  <Modal
   {...{ onCancel }}
   visible={visible}
   title="Select Tag"
   bodyStyle={{ padding: 0 }}
   onOk={(e) => {
    handleOkay?.(selected)
    onCancel?.()
   }}
  >
   {!isLoading && flatData?.length === 0 ? (
    <Empty />
   ) : (
    <BaseSelector
     isLoading={isLoading}
     onSearch={setSearchKey}
     options={flatData?.map((v) => ({
      id: v?.id ?? Math.random(),
      title: v?.name ?? '',
     }))}
     value={selected}
     onChange={(v) => setSelected(v ?? [])}
     fetchMore={fetchMore}
     hasMore={hasMore}
    />
   )}
  </Modal>
 )
}
