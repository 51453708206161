import { Col, Form, Input, Modal, Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { SMSInputArea } from 'components/shared/Common/SMSInputArea'
import React, { FC, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router'
import { TMode } from 'types'
import {
 refetchTextTemplateList,
 useAddTextTemplateMutation,
 useEditTextTemplateMutation,
} from '../api'
import { TPutTextTemplate } from '../types'

const FormINit: TPutTextTemplate = {
 name: '',
 body: '',
 id: '',
}
const TextTemplateForm: FC<{
 mode?: TMode
 editData?: TPutTextTemplate
 onSuccess?: () => void
}> = ({ mode = 'ADD', editData, onSuccess }) => {
 const [addTextTemplate, { isLoading: addLoading }] =
  useAddTextTemplateMutation()

 const [saveAsTextTemplate, { isLoading: saLoading }] =
  useAddTextTemplateMutation()

 const [editTextTemplate, { isLoading: editLoading }] =
  useEditTextTemplateMutation()

 const [form] = Form.useForm<TPutTextTemplate>()

 const [showSaveAsModal, setShowAsModal] = useState(false)

 //  const navigate = useNavigate()
 const HandleSubmit = async () => {
  const values = await form.validateFields()
  if (mode === 'EDIT' && editData) {
   await editTextTemplate({ ...values, id: editData.id }).unwrap()
   form.resetFields()
   refetchTextTemplateList()
   onSuccess?.()
  } else {
   await addTextTemplate({ ...values }).unwrap()
   form.resetFields()
   refetchTextTemplateList()
   onSuccess?.()
  }
 }
 const handleSubmitSaveAs = async (title: string) => {
  const values = await form.validateFields()
  await saveAsTextTemplate({ ...values, name: title }).unwrap()
  setShowAsModal(false)
  onSuccess?.()
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    name: editData.name,
    body: editData.body,
   })
  }
  return () => {
   form.resetFields()
  }
 }, [mode, editData])

 return (
  <>
   <Form layout="vertical" initialValues={FormINit} form={form}>
    <Row gutter={15}>
     <Col span={24}>
      <Form.Item label="Template Name" name="name">
       <Input placeholder="Template Name" />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Form.Item label="Content" name="body">
       <SMSInputArea />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Space>
       <BaseButton
        buttonStyle="outline"
        buttonType="danger"
        onClick={() => onSuccess?.()}
       >
        Cancel
       </BaseButton>
       <BaseButton
        buttonType="primary"
        onClick={HandleSubmit}
        loading={mode == 'EDIT' ? editLoading : addLoading}
       >
        {mode === 'EDIT' ? 'Update Text Template' : 'Add Text Template'}
       </BaseButton>
       {mode === 'EDIT' && (
        <BaseButton onClick={() => setShowAsModal(true)} buttonType="info">
         Save As
        </BaseButton>
       )}
      </Space>
     </Col>
    </Row>
   </Form>
   <SaveAsModal
    Loading={saLoading}
    handleSubmitSaveAs={handleSubmitSaveAs}
    visible={showSaveAsModal}
    onCancel={() => setShowAsModal(false)}
   />
  </>
 )
}

export default TextTemplateForm

const SaveAsModal: FC<{
 Loading?: boolean
 handleSubmitSaveAs?: (title: string) => void
 visible?: boolean
 onCancel?: () => void
}> = ({ visible, onCancel, handleSubmitSaveAs, Loading }) => {
 const [input, setInput] = useState('')
 return (
  <Modal
   confirmLoading={Loading}
   okButtonProps={{ disabled: !input }}
   onOk={() => handleSubmitSaveAs?.(input)}
   okText="Save As"
   title="Save As"
   onCancel={onCancel}
   visible={visible}
  >
   <Input
    value={input}
    onChange={(e) => setInput(e.target.value)}
    placeholder="Enter A New Template Name"
   />
  </Modal>
 )
}
