import React, { FC } from 'react'
import { FCC } from 'types'
import { BaseButton } from '../BaseButton'

const LoeadMorePagination: FCC<{
 loading?: boolean
 fethMore?: () => void
 hasMore?: boolean
}> = ({ children, hasMore, fethMore, loading }) => (
 <>
  {children}
  {hasMore && (
   <div className="py-3 text-center w-100">
    <BaseButton onClick={fethMore} loading={loading}>
     Load More
    </BaseButton>
   </div>
  )}
 </>
)

export default LoeadMorePagination
