import { Image } from 'antd'
import React, { FC, useMemo } from 'react'
import style from './image.module.scss'

type TImageRatio = '1x1' | '4:3' | '16:9'

const BaseImage: FC<{
 width?: number
 height?: number
 ratio?: TImageRatio | number
 src?: string
}> = ({ ratio, src = '', height, width }) => {
 const ratioClass = useMemo(
  () =>
   ratio
    ? ratio === '1x1'
      ? style._1x1
      : ratio === '16:9'
      ? style._16x9
      : ratio === '4:3'
      ? style._4x3
      : ''
    : '',
  [ratio],
 )

 //  const imageStyle: React.CSSProperties = useMemo(() => {
 //   if (height && width) {
 //    return {
 //     width: `${width}px`,
 //     height: `${height}px`,
 //    }
 //   } else {
 //    return {}
 //   }
 //  }, [height, width])

 //  commented because not using anywhere

 return (
  <Image
   width={width}
   height={height}
   onClick={() => window.open(src, '_blank')}
   style={{ cursor: 'pointer' }}
   preview={false}
   wrapperClassName={`${style.image} ${ratioClass} `}
   placeholder="/images/placeholder.png"
   fallback="/images/placeholder.png"
   src={src}
  />
 )
}

export default BaseImage
