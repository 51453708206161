import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { TSSBlockList } from 'components/shared/Common'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { TGetAlerts } from 'features/alert/types'

type TAlertProps = {
 handleClickEdit?: (data?: TGetAlerts) => void
}

const AlertTableColumns = ({
 handleClickEdit,
}: TAlertProps): MDataTableColumns<TGetAlerts>[] => [
 {
  title: 'Name',
  dataIndex: 'name',
  size: 200,
 },
 {
  title: 'Area',
  size: 200,
  render: (_e, r) => <div>{r?.area?.join?.(',')}</div>,
 },
 {
  title: 'Frequency',
  dataIndex: 'frequency',
  size: 200,
 },
 {
  title: 'Tags',
  dataIndex: 'tags',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList typeId={r.id} mode="tag" type="alert" data={r.tags} closable />
  ),
 },
 {
  title: 'Stages',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    mode="stage"
    type="alert"
    data={r.stages}
    closable
   />
  ),
 },
 {
  title: 'Source',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    mode="source"
    type="alert"
    data={r.source}
    closable
   />
  ),
 },
 {
  title: 'Action',
  size: 70,
  fixed: 'right',
  render: (_, record) => (
   <Space>
    <BaseButton
     onClick={() => handleClickEdit?.(record)}
     tooltipText="Edit"
     shape="circle"
     size="small"
     buttonType="info"
     icon={<i className="fas fa-edit" />}
    />
   </Space>
  ),
 },
]

export default AlertTableColumns
