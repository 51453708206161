import { Tag } from 'antd'
import React, { CSSProperties, FC, useMemo } from 'react'
import { findTag } from 'static-data'
import { TListingTag, TListingType } from 'types'
import style from './pro.module.scss'

type TSize = 'sm' | 'lg' | 'md'

const Large: CSSProperties = {
 fontSize: '16px',
 padding: '6px 16px',
}

const PropertyTag: FC<{
 constructionStatus?: string
 tag?: TListingTag
 size?: TSize
 className?: string
 isPreCon?: boolean
}> = ({ className, size, tag, constructionStatus, isPreCon = false }) => {
 const buidStyle = (key: TSize): CSSProperties => {
  switch (key) {
   case 'lg':
    return Large
   default:
    return {
     padding: '2px 10px',
     color: 'white',
     fontWeight: '400',
     fontSize: '12px',
    }
  }
 }

 const buildTag = useMemo(() => {
  if (isPreCon) {
   if (constructionStatus) {
    return (
     <Tag
      className={`${style.proTag} ${className ?? ''}`}
      style={buidStyle(size ?? 'md')}
      color="var(--bs-success)"
     >
      {constructionStatus}
     </Tag>
    )
   }
   return ''
  }
  return (
   <Tag
    style={buidStyle(size ?? 'md')}
    color={`var(--bs-${findTag(tag).color})`}
    className={`${style.proTag} ${className ?? ''}`}
   >
    {findTag(tag).label}
   </Tag>
  )
 }, [isPreCon, constructionStatus, tag])

 return (
  <>
   {/* {isPreCon ? (
    <>
     {constructionStatus ? (
      <Tag
       className={`${style.proTag} ${className ?? ''}`}
       style={buidStyle(size ?? 'md')}
       color="var(--bs-success)"
      >
       {constructionStatus}
      </Tag>
     ) : (
      ''
     )}
    </>
   ) : (
    <Tag
     style={buidStyle(size ?? 'md')}
     color={`var(--bs-${findTag(tag).color})`}
     className={`${style.proTag} ${className ?? ''}`}
    >
     {findTag(tag).label}
    </Tag>
   )} */}
   {buildTag}
  </>
 )
}

export default PropertyTag
