import { Col, Row } from 'antd'
import { TDetailsImages } from 'features/property'
import { FC } from 'react'
import { Navigation } from 'swiper'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'

const PropertyImageBlock: FC<{ images?: TDetailsImages }> = ({ images }) => {
 if (
  !images ||
  images === null ||
  images?.images === null ||
  images?.images.length === 0
 ) {
  return (
   <Swiper slidesPerView={1}>
    <SwiperSlide>
     <img
      alt=""
      src="/images/CondoApt.png"
      className="img-fluid w-100 swiper-imgs"
     />
    </SwiperSlide>
   </Swiper>
  )
 }

 if (images?.images.length < 3) {
  return (
   <div className="pro-img-block">
    <Row gutter={12}>
     {images?.images &&
      images?.images.map((item, index) => (
       <Col sm={8} key={index}>
        <img
         alt=""
         src={`${images.directory}Photo${images.ml_num}-${item}.jpeg`}
         className="img-fluid w-100 swiper-imgs h-100"
        />
       </Col>
      ))}
     <Col sm={images?.images.length === 1 ? 16 : 8}>
      <img
       alt=""
       src="/images/no-image.png"
       className="img-fluid w-100 swiper-imgs"
      />
     </Col>
    </Row>
   </div>
  )
 }
 return (
  <div>
   <Swiper
    className="swiper-imgs"
    spaceBetween={15}
    slidesPerView={2}
    navigation
    modules={[Navigation]}
    breakpoints={{
     678: {
      slidesPerView: 2,
     },
     1200: {
      slidesPerView: 3,
     },
     1600: {
      slidesPerView: 3,
     },
    }}
   >
    {images?.images &&
     images?.images.map((item, index) => (
      <SwiperSlide key={index}>
       <img
        alt=""
        src={`${images.directory}Photo${images.ml_num}-${item}.jpeg`}
        className="img-fluid w-100 swiper-imgs"
       />
      </SwiperSlide>
     ))}
   </Swiper>
  </div>
 )
}

export default PropertyImageBlock
