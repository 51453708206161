/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { leadApi } from './api'
import {
 TGetLead,
 TLeadFilterBody,
 TLeadTabType,
 TLeadCustomSelectAll,
} from './types'

export const toLeadFilterObj = (
 filters: TLeadFilterBody,
 searchKey: string,
): TLeadFilterBody & { search: string } => {
 return {
  search: searchKey ?? '',
  ...(filters?.phone && filters?.phone !== 'All'
   ? { phone: filters?.phone }
   : {}),
  ...(filters?.email && filters?.email !== 'All'
   ? { email: filters?.email }
   : {}),
  ...(filters?.created && filters?.created !== 0 && filters?.created !== 'All'
   ? { created: filters?.created }
   : {}),
  ...(filters?.source ? { source: filters?.source } : {}),
  ...(filters?.stage ? { stage: filters?.stage } : {}),
  ...(filters?.tag ? { tag: filters?.tag } : {}),
  ...(filters?.expert ? { expert: filters?.expert } : {}),
  ...(filters?.recentactivity
   ? { recentactivity: filters?.recentactivity }
   : {}),
  ...(filters?.from_date ? { from_date: filters?.from_date } : {}),
  ...(filters?.to_date ? { to_date: filters?.to_date } : {}),
 }
}

export const handleLeadFilterTab = (val?: TLeadTabType) => {
 switch (val) {
  case 'weeklead':
   return { created: 7 }
  case 'activeLead':
   return { recentactivity: true }
  case 'buyer_lead':
   return { stage: ['3'] }
  case 'seller_lead':
   return { stage: ['4'] }
  case 'closed_lead':
   return { stage: ['5'] }
  case 'hot_lead':
   return { stage: ['2'] }
  default:
   return {}
 }
}

type TLeadTableFilters = {
 phone_number?: any
 email_address?: any
 tags?: any
 sources?: any
 stages?: any
}

export const convertLeadFilters = (
 values?: TLeadFilterBody & TLeadTableFilters,
) => {
 const { email, phone, tag, source, stage, created, ...other } = values || {}
 return {
  ...other,
  ...(email ? { email_address: email } : {}),
  ...(phone ? { phone_number: phone } : {}),
  ...(tag ? { tags: tag } : {}),
  ...(source ? { sources: source } : {}),
  ...(stage ? { stages: stage } : {}),
  ...(created ? { created_at: created } : {}),
 }
 //  return Object.fromEntries(
 //   Object.entries(values ?? {}).map(([key, value]) => {
 //    switch (key) {
 //     case 'phone':
 //      return ['phone_number', value]
 //     case 'email':
 //      return ['email_address', value]
 //     case 'tag':
 //      return ['tags', value]
 //     case 'source':
 //      return ['sources', value]
 //     case 'stage':
 //      return ['stages', value]
 //     default:
 //      return [key, value]
 //    }
 //   }),
 //  )
}

export const handleChangeLeadFilter = (v: TLeadTableFilters) => {
 //  const upValues: TLeadFilterBody = {}
 //  const values = {
 //   phone_number: 'phone',
 //   email_address: 'email',
 //   tags: 'tag',
 //   sources: 'source',
 //   stages: 'stage',
 //  }
 //  Object.keys(v).forEach((el) => {
 //   const key = el as keyof TLeadTableFilters
 //   if (['phone_number', 'email_address'].includes(el)) {
 //    if (v[key] && v[key] !== 'All') {
 //     upValues[values[key] as keyof TLeadFilterBody] = v[key]
 //    }
 //   } else if (['tags', 'sources', 'stages'].includes(el)) {
 //    upValues[values[key] as keyof TLeadFilterBody] = v[key]
 //   } else {
 //    upValues[key as keyof TLeadFilterBody] = v[key]
 //   }
 //  })
 //  return upValues
 const { email_address: EA, phone_number, sources, stages, tags, ...other } = v
 const newVal = {
  ...other,
  source: sources,
  stage: stages,
  tag: tags,
  email: EA,
  phone: phone_number,
  // ...(EA ? { email: EA === 'All' ? {} : EA } : {}),
  // ...(phone_number
  //  ? { phone: phone_number === 'All' ? {} : phone_number }
  //  : {}),
 }

 return newVal
}

export const getLeadListSelectAllDropDown = (val: {
 selectedRows: any[]
 totalCount: number
 clearSelectAll: () => void
 onSelectDropDown: (v: TLeadCustomSelectAll) => void
}): {
 key: string
 label: string
 onClick: () => any
}[] => {
 const {
  selectedRows = [],
  totalCount = 0,
  clearSelectAll,
  onSelectDropDown,
 } = val

 const items = []

 if (selectedRows.length > 0) {
  items.push({
   key: 'clear',
   label: 'Clear',
   onClick: () => clearSelectAll(),
  })
 }

 items.push({
  key: 'all',
  label: 'Select All Leads',
  onClick: () => onSelectDropDown('all'),
 })

 const LEAD_SELECTION_OPTIONS = [50, 100, 200, 400, 500, 1000]
 LEAD_SELECTION_OPTIONS.forEach((v) => {
  if (totalCount >= v) {
   items.push({
    key: `${v}`,
    label: `Select First ${v} Leads`,
    onClick: () => onSelectDropDown(`${v}` as TLeadCustomSelectAll),
   })
  }
 })

 return items
}

type TransformDirection = 'toFilter' | 'fromFilter'

export const transformLeadFilterKeys = (
 data: any[],
 input: Record<string, string>,
 direction: TransformDirection,
): Record<string, string> | null => {
 const variableMap = new Map<string, string>()
 const customMap = new Map<string, string>()

 data?.forEach?.((item) => {
  const customKey = item.variableCustom.replace('.', '_')
  variableMap.set(customKey, item.variable)
  customMap.set(item.variable, customKey)
 })

 const map = direction === 'toFilter' ? customMap : variableMap

 return Object.fromEntries(
  Object.entries(input)
   .map(([key, value]): [string, string] | null => {
    const mappedKey = map.get(key)
    return mappedKey ? [mappedKey, value] : null
   })
   .filter((entry): entry is [string, string] => entry !== null),
 )
}
