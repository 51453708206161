import { globalToaster } from 'components/elements/global'
import { SendEmailForm } from 'components/shared/SendEmailForm'
import { ADMIN_PREFIX_PATH } from 'config'
import InnerContentLayout from 'layout/InnerContentLayout'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const LeadSendEmail = () => {
 const navigate = useNavigate()
 const location = useLocation()

 const { state }: any = location
 let toData = state
 if (state?.includes?.(null) || state?.includes?.(undefined)) {
  globalToaster({
   type: 'error',
   content: 'Lead(s) without valid Email ID skipped!',
  })
  toData = state?.filter((i: any) => i !== null && i !== undefined)
 }

 return (
  <InnerContentLayout title="Send Email">
   {toData ? (
    <SendEmailForm
     onSendSuccess={() => navigate(`${ADMIN_PREFIX_PATH}/leads`)}
     to={toData ?? []}
    />
   ) : null}
  </InnerContentLayout>
 )
}

export default LeadSendEmail
