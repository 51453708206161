import { Space, Button, Row } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import BaseTag from 'components/elements/BaseTag'
import { BtnAnch } from 'components/elements/global'
import { FC, useState } from 'react'
import { useUpdateLeadlogdataStatusMutation } from 'features/leads'
import { formatDate } from 'utils/functions'
import { TInboxItemMsgs } from '../types'

const MessageBlockPanel: FC<{
 item: TInboxItemMsgs
 onClickReply?: () => void
 onClickForward?: () => void
 refetch?: () => void
}> = ({ item, onClickReply, onClickForward, refetch }) => {
 const [show, setShow] = useState(false)
 const [updateLeadlogdataStatus] = useUpdateLeadlogdataStatusMutation()

 const updateLeadLog = async (eid: any): Promise<void> => {
  await updateLeadlogdataStatus?.({ id: eid, type: 'email' }).unwrap()
  refetch?.()
 }
 return (
  <div className="msg-blk-panel">
   <div className="msg-blk-panel-header">
    <AvatarStatus
     title={item?.from_name ?? ''}
     titleNode={
      <BtnAnch
       onClick={() => {
        if (
         ['received', 'Received']?.includes(item?.send_type ?? '') &&
         item?.status !== 'Opened'
        ) {
         updateLeadLog?.(item?.id)
        }
        setShow((prv) => !prv)
       }}
       className="avt-hdrnoder"
      >
       <h5>{item?.from_name ? `${item?.from_name} > ${item?.to_name}` : ''}</h5>
       {item?.status && <BaseTag label={item?.status} />}
      </BtnAnch>
     }
     subTitle={item?.updated_at ? formatDate?.(item?.updated_at) : ''}
     action={
      <Space>
       <Button onClick={onClickReply} size="small" title="Reply">
        <i className="far fa-reply" />
       </Button>
       <Button onClick={onClickForward} size="small" title="Forward">
        <i className="far fa-share" />
       </Button>
       <Button onClick={() => setShow((prv) => !prv)} size="small">
        <i className={`far fa-angle-${show ? 'up' : 'down'}`} />
       </Button>
      </Space>
     }
    />
   </div>
   {show && (
    <div className="msg-blk-panel-body">
     <h4 className="subject-blk">Subject: {item?.subject}</h4>
     <div dangerouslySetInnerHTML={{ __html: `${item?.body}` }} />
     <Row gutter={[10, 10]} className="attachments-wrapper">
      {item?.attachment?.map?.((v: any, index) => (
       <a
        target={
         ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
          v?.split('.').at(-1),
         )
          ? '_blank'
          : '_self'
        }
        href={v}
        key={index}
        rel="noreferrer"
       >
        <div className="attachment">
         <i className="fas fa-file" />
        </div>
       </a>
      ))}
     </Row>
    </div>
   )}
  </div>
 )
}

export default MessageBlockPanel

MessageBlockPanel.defaultProps = {
 onClickForward: undefined,
 onClickReply: undefined,
}
