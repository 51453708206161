import { Editor } from '@tinymce/tinymce-react'
import { Spin } from 'antd'
import { useUploadTinyFileMutation } from 'features/settings'
import { useGlobal } from 'raducer/global'
import { FC, useState } from 'react'

const toolbars =
 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | fullscreen  preview save print code image fullpage link | casechange permanentpen formatpainter | charmap insertfile  pageembed   anchor codesample | a11ycheck ltr rtl | showcomments addcomment | inserttable'
// const toolbars =
//  'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap | fullscreen code preview save print | insertfile image pageembed  link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | inserttable'
const plugin = [
 'searchreplace',
 'visualblocks',
 'visualchars',
 'insertdatetime',
 'nonbreaking',
 'table',

 'template',
 'paste',
 'help',

 'fullpage',
 'preview',
 'image',
 'code',
 'fullscreen',
 'link',
 'lists advlist',
 'charmap',
 'hr',
]

const BaseEditor: FC<{
 value?: string
 emailTemplate?: boolean
 mergfield?: boolean
 height?: number
 plugins?: string | string[]
 toolbar?: string
 ref?: any
 onChange?: (val: any) => void
 setup?: any
 onSelectTemplate?: (id?: string) => void
 loading?: boolean
 setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
 height,
 onChange,
 setup,
 value,
 ref,
 plugins,
 loading,
 setLoading,
}) => {
 const [uploadImg] = useUploadTinyFileMutation()
 const { global } = useGlobal()

 return (
  <>
   <Editor
    value={value}
    onEditorChange={onChange}
    ref={ref}
    apiKey={global?.tiny_mce_key ?? ''}
    init={{
     body_class: 'w-100',
     height,
     plugins: plugin,
     toolbar1: `${toolbars}`,
     setup,
     menubar: false,

     images_upload_handler: async (
      blobInfo: any,
      success: any,
      failure: any,
     ) => {
      const formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      try {
       const data = await uploadImg(formData).unwrap()
       success(data) as any
      } catch (error) {
       failure('Upload Image Error') as any
      }
     },
    }}
    onInit={() => setLoading?.(false)}
   />
   {loading && (
    <div className="tiny-loading-sec">
     <Spin />
    </div>
   )}
  </>
 )
}
export default BaseEditor
