/* eslint-disable react/require-default-props */
import { flexRender, Header } from '@tanstack/react-table'
import classNames from 'classnames'
import { FC } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import MTCell from '../Cell/MTCell'
import { MTFilterProps } from '../type'
import MTFilterPanel from './MTFilterPanel'

const MTHeaderCell: FC<{
 isScroll?: boolean
 header: Header<any, any>
 filter?: MTFilterProps
 isDraggableColumn: boolean
 columnKey?: string
}> = ({ header, filter, isScroll, isDraggableColumn, columnKey }) => {
 const { attributes, isDragging, listeners, setNodeRef, transform } =
  useSortable({
   id: header.column.id,
  })
 //  return (
 //   <MTCell
 //    {...(isDraggableColumn && key !== 'selection'
 //     ? { isDragging, setNodeRef, transform }
 //     : {})}
 //    scrollBarWidth={isScroll ? 12 : 0}
 //    type="th"
 //    className={classNames({
 //     'm-table-cell-has-filter': filter && (filter.options || filter?.customNode),
 //    })}
 //    as="th"
 //    column={header.column}
 //   >
 //    <div className="d-flex justify-content-between w-100">
 //     {header.isPlaceholder ? null : (
 //      <>
 //       <span
 //        className="line-clamp-1 mt-header-cell-span"
 //        {...(isDraggableColumn
 //         ? {
 //            ...attributes,
 //            ...listeners,
 //            style: {
 //             cursor: 'move',
 //             width: '100%',
 //             //    padding: '8px 0',
 //             // border: '1px solid red',
 //            },
 //           }
 //         : {})}
 //       >
 //        {flexRender(header.column.columnDef.header, header.getContext())}
 //        {/* {filter && (filter.options || filter?.customNode) && (
 //       <MTFilterPanel filter={filter} header={header} />
 //      )} */}
 //       </span>
 //       {filter && (filter.options || filter?.customNode) && (
 //        <MTFilterPanel filter={filter} header={header} />
 //       )}
 //      </>
 //     )}
 //    </div>
 //    {header.column.getCanResize() && (
 //     <div
 //      {...{
 //       onMouseDown: header.getResizeHandler(),
 //       onTouchStart: header.getResizeHandler(),
 //       className: `m-table-resize-col ${
 //        header.column.getIsResizing() ? 'is-resizing' : ''
 //       }`,
 //      }}
 //     />
 //    )}
 //   </MTCell>
 //  )

 return (
  <MTCell
   {...(isDraggableColumn && columnKey !== 'selection' && columnKey !== 'name'
    ? { isDragging, setNodeRef, transform }
    : {})}
   scrollBarWidth={isScroll ? 12 : 0}
   type="th"
   className={classNames({
    'm-table-cell-has-filter ':
     filter && (filter.options || filter?.customNode),
   })}
   as="th"
   column={header.column}
  >
   {header.isPlaceholder ? null : (
    <div
     className={
      // 'd-flex align-items-center justify-content-between'
      `d-flex align-items-center ${
       header?.id === 'selection'
        ? 'justify-content-center'
        : 'justify-content-between'
      }`
     }
     style={{
      padding:
       header?.id === 'selection' ? '2px 0px 2px 0px' : '2px 6px 2px 2px',
     }}
    >
     <span
      {...(isDraggableColumn &&
      columnKey !== 'selection' &&
      columnKey !== 'name'
       ? {
          ...attributes,
          ...listeners,
         }
       : {})}
      className="line-clamp-1"
      style={{
       margin: '0',
       lineHeight: '30px',
       paddingLeft: '7px',
       ...(filter && (filter.options || filter?.customNode)
        ? {
           width: 'calc(100% - 25px)',
          }
        : {}),
       ...(isDraggableColumn &&
       columnKey !== 'selection' &&
       columnKey !== 'name'
        ? {
           cursor: 'move',
          }
        : {}),
       ...(!filter && isDraggableColumn ? { width: '100%' } : {}),
      }}
     >
      {flexRender(header.column.columnDef.header, header.getContext())}
     </span>
     <div>
      {' '}
      {filter && (filter.options || filter?.customNode) && (
       <MTFilterPanel filter={filter} header={header} />
      )}
     </div>
    </div>
   )}
   {header.column.getCanResize() && (
    <div
     {...{
      onMouseDown: header.getResizeHandler(),
      onTouchStart: header.getResizeHandler(),
      className: `m-table-resize-col ${
       header.column.getIsResizing() ? 'is-resizing' : ''
      }`,
     }}
    />
   )}
  </MTCell>
 )
}

export default MTHeaderCell
