import { Card } from 'antd'
import { TPropertyListingDetailOpenHouse } from 'features/property'
import React, { FC } from 'react'

const OpenHousesBlock: FC<{
 openHouse?: TPropertyListingDetailOpenHouse[]
}> = ({ openHouse }) => {
 if (openHouse?.length === 0) {
  return null
 }
 return (
  <Card className="m-0" title="Open House">
   <ul className="d-flex justify-content-start flex-nowrap list-inline category-scroll">
    {openHouse?.map?.((i, n) => (
     <li className="flex" key={n}>
      <div className="cat-wrap">
       <div
        // className="image d-flex"
        style={
         {
          //  border: '1px solid gray',
          //  height: '100%',
          //  height: '200px',
          //  height: '50px',
          //  backgroundColor: 'gray',
         }
        }
       >
        <i
         className="fa fa-calendar"
         aria-hidden="true"
         style={{ fontSize: '35px' }}
        />
       </div>
       <div
        style={{
         display: 'flex',
         flexDirection: 'column',
         paddingLeft: '1rem',
        }}
       >
        {i?.date && <p style={{ marginBottom: '0' }}>{i.date}</p>}
        {i?.time && <p style={{ marginBottom: '0' }}> {i.time}</p>}
        {i?.link && <a href={i.link}>Open House Link</a>}
       </div>
      </div>
     </li>
    ))}
   </ul>
  </Card>
 )
}

export default OpenHousesBlock
