/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
import {
 Badge,
 Button,
 Checkbox,
 Empty,
 Input,
 Menu,
 MenuProps,
 Radio,
 Space,
 Tooltip,
} from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useDebounce } from 'react-use'
// import { areArraysEqual, mergeArrays } from 'utils/functions'
import { areArraysEqual, mergeArrays } from 'utils/functions'
// import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { MTFilterMode, MTFilterOption } from '../type'

const rmArrayFromArray = (A: any[], B: any[]) => {
 return B.filter((element) => !A.includes(element))
}

type CustomNodeProps = {
 onHide?: () => void
}

const MTFilterBlock: FC<{
 customNode?: (val?: {
  onHide?: () => void
  onClickSubmit?: (val?: any[]) => void
 }) => ReactNode
 showSelectAll?: boolean
 searchPlaceholder?: string
 showSearch?: boolean
 mode?: MTFilterMode
 options?: MTFilterOption[]
 onHide?: () => void
 onClickSubmit?: (val?: any[]) => void
 selectedValue?: any
}> = ({
 options,
 onHide,
 mode,
 onClickSubmit,
 selectedValue,
 showSearch = false,
 searchPlaceholder,
 showSelectAll = false,
 customNode,
}) => {
 const [searchKey, setSearchKey] = useState('')
 const [data, setData] = useState(options || [])
 const [checkedList, setCheckedList] = useState<any[]>([])
 //  const [slected, setSelected] = useState<any[]>([])
 const [indeterminate, setIndeterminate] = useState(false)
 const [checkAll, setCheckAll] = useState(false)

 useEffect(() => {
  if (selectedValue) {
   setCheckedList(mode === 'single' ? [selectedValue] : selectedValue)
  } else {
   setCheckedList([])
  }
 }, [mode, selectedValue])

 const onCheckAllChange = (e: CheckboxChangeEvent) => {
  setCheckedList(
   e.target.checked
    ? mergeArrays(
       checkedList,
       data.map((v) => v.value),
      ) || []
    : rmArrayFromArray(
       data.map((v) => v.value),
       checkedList,
      ),
  )
  //   setIndeterminate(false)
  setCheckAll(e.target.checked)
 }

 const onChange: MenuProps['onClick'] = (info) => {
  if (mode === 'multiple') {
   setCheckedList((prv) => {
    if (prv.find((m) => m === info.key)) {
     const newVal = prv.filter((m) => m !== info.key)
     return [...newVal]
    }
    return [...prv, info.key]
   })
  } else {
   setCheckedList([info.key])
   setIndeterminate(!!checkedList.length && checkedList.length < data.length)
  }
 }

 useDebounce(
  () => {
   if (searchKey && options && options.length > 0) {
    setData(
     options?.filter((v) =>
      `${v.label}`
       .toLocaleLowerCase()
       .includes(searchKey.toLocaleLowerCase(), 0),
     ) || [],
    )
    setCheckAll(
     checkedList.length > 0
      ? data.every((xx) => checkedList.includes(xx.value))
      : false,
    )
    setIndeterminate(!!checkedList.length && checkedList.length < data.length)
   } else {
    setData(options || [])
    setCheckAll(
     areArraysEqual(
      data.map((v) => v.value),
      checkedList,
     ),
    )
    setIndeterminate(!!checkedList.length && checkedList.length < data.length)
   }
  },
  100,
  [options, searchKey, data, checkedList],
 )

 useEffect(() => {
  if (options) {
   setData(options)
  }
  return () => {
   setSearchKey('')
   setCheckedList([])
   setCheckAll(false)
   setIndeterminate(false)
  }
 }, [options])

 if (customNode) {
  return <>{customNode({ onHide, onClickSubmit })}</>
 }
 const onReset = () => {
  setCheckedList([])
  if (checkAll) {
   setCheckAll(false)
  }
  if (indeterminate) {
   setIndeterminate(false)
  }
 }

 return (
  <div className="mt-filter-block">
   <div className="mt-filter-block-header">
    {showSearch && (
     <div className="mt-filter-block-search">
      <Input.Search
       value={searchKey}
       onChange={({ target }) => {
        setSearchKey(target.value || '')
       }}
       placeholder={searchPlaceholder || 'Search'}
       size="small"
      />
     </div>
    )}
    {mode === 'multiple' && showSelectAll && (
     <div className="mt-filter-block-selectAll d-flex justify-content-between">
      <div>
       <Checkbox
        disabled={data.length === 0}
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
       >
        Select All
       </Checkbox>
       <Badge
        style={{ fontSize: '11px' }}
        size="small"
        count={checkedList.length || 0}
       />
      </div>
      <BtnAnch
       className={`ms-1 ${
        checkedList?.length || checkAll ? 'text-primary' : 'text-dark'
       }`}
       style={{
        cursor:
         checkedList?.length || checkedList?.length || checkAll
          ? 'pointer'
          : 'default',
       }}
       tooltipText="Reset"
       onClick={onReset}
      >
       <i className="fas fa-undo" />
      </BtnAnch>
     </div>
    )}
   </div>
   <div className="mt-filter-block-body">
    {data && data.length > 0 ? (
     <Menu
      multiple={mode === 'multiple'}
      onClick={onChange}
      selectable={false}
      selectedKeys={checkedList}
     >
      {data?.map((item, i) => (
       <Menu.Item
        key={item.value}
        icon={
         mode === 'single' ? (
          <Radio checked={checkedList.find((v) => v === item.value)} type="" />
         ) : (
          <Checkbox
           checked={checkedList.find((v) => v === item.value)}
           type=""
          />
         )
        }
       >
        <Tooltip overlay={item.label}>{item.label}</Tooltip>
       </Menu.Item>
      ))}
     </Menu>
    ) : (
     <Empty description="Data Not Found!" />
    )}
   </div>
   <div className="mt-filter-block-footer">
    <Space>
     <Button size="small" onClick={onHide}>
      Cancel
     </Button>
     <Button
      size="small"
      onClick={() => {
       onClickSubmit?.(checkedList)
       onHide?.()
      }}
      type="primary"
     >
      Submit
     </Button>
    </Space>
   </div>
  </div>
 )
}
MTFilterBlock.defaultProps = {
 mode: 'multiple',
}

export default MTFilterBlock
