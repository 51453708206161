import { NewListingSlug, SelectType, TListingTag } from 'types'

export const MergeFieldData: SelectType[] = [
 {
  value: '[[first_name]]',
  label: 'First Name',
 },
 {
  value: '[[name]]',
  label: 'Contact Name',
 },
 {
  value: '[[address]]',
  label: 'Contact Address',
 },
 {
  value: '[[phone]]',
  label: 'Contact Phone',
 },
 {
  value: '[[realtor_name]]',
  label: 'Realtor Name',
 },
 {
  value: '[[realtor_email]]',
  label: 'Realtor Name',
 },
 {
  value: '[[realtor_phone]]',
  label: 'Realtor Phone',
 },
 {
  value: '[[city]]',
  label: 'City',
 },
 {
  value: '[[price_range]]',
  label: 'Price Range',
 },
]

export const LabelList: SelectType<any>[] = [
 {
  label: 'Good',
  value: 'Good',
 },
 {
  label: 'Bad',
  value: 'Bad',
 },
]

export const BedList: SelectType[] = [
 {
  label: '0+',
  value: '0p',
 },
 {
  label: '1+',
  value: '1p',
 },
 {
  label: '2+',
  value: '2p',
 },
 {
  label: '3+',
  value: '3p',
 },
 {
  label: '4+',
  value: '4p',
 },
 {
  label: 'All',
  value: 'all',
 },
]

export const FilterBedLists: SelectType[] = [
 {
  label: '1+',
  value: '1',
 },
 {
  label: '2+',
  value: '2',
 },
 {
  label: '3+',
  value: '3',
 },
 {
  label: '4+',
  value: '4',
 },
 {
  label: '5+',
  value: '5',
 },
 {
  label: '6+',
  value: '6',
 },
]

export const FilterDaysOnMarket: SelectType[] = [
 {
  label: '1 Day',
  value: 1,
 },
 {
  label: '7 Day',
  value: 7,
 },
 {
  label: '14 Days',
  value: 14,
 },
 {
  label: '30 Days',
  value: 30,
 },
]

export const FilterSoldLeasedDOM: SelectType[] = [
 {
  label: '3 months',
  value: 3,
 },
 {
  label: '6 months',
  value: 6,
 },
 {
  label: '1 Year',
  value: 12,
 },
]

export const BathList: SelectType[] = [
 {
  label: '0+',
  value: '0p',
 },
 {
  label: '1+',
  value: '1p',
 },
 {
  label: '2+',
  value: '2p',
 },
 {
  label: '3+',
  value: '3p',
 },
 {
  label: '4+',
  value: '4p',
 },
 {
  label: 'All',
  value: 'all',
 },
]

type TTagList = SelectType<TListingTag> & {
 color?: string
}

export const TagList: TTagList[] = [
 {
  label: 'For Sale',
  value: 'sale',
  color: 'sale',
 },
 {
  label: 'Sold',
  value: 'sold',
  color: 'sold',
 },
 {
  label: 'Rent',
  value: 'rent',
  color: 'rent',
 },
 {
  label: 'Leased',
  value: 'leased',
  color: 'leased',
 },
 {
  label: 'In-active',
  value: 'in-active',
  color: 'black',
 },
 {
  label: 'Sold Conditional',
  value: 'sold-conditional',
  color: 'sold',
 },
 {
  label: 'Sold Conditional (e)',
  value: 'sce',
  color: 'sold',
 },
 {
  label: 'Leased Conditional',
  value: 'leased-conditional',
  color: 'leased',
 },
 {
  label: 'Expired',
  value: 'expired',
  color: 'danger',
 },
 {
  label: 'Extended',
  value: 'extended',
  color: 'primary',
 },
 {
  label: 'Suspended',
  value: 'suspended',
  color: 'danger',
 },
 {
  label: 'Terminated',
  value: 'terminated',
  color: 'danger',
 },
 {
  label: 'Deal Fell Through',
  value: 'deal-fell-through',
  color: 'primary',
 },
 {
  label: 'Others',
  value: 'others',
  color: 'primary',
 },
]

export const taskTypes: SelectType[] = [
 {
  value: 'Follow Up',
  label: 'Follow Up',
 },
 {
  value: 'Call',
  label: 'Call',
 },
 {
  value: 'Email',
  label: 'Email',
 },
 {
  value: 'Text',
  label: 'Text',
 },
 {
  value: 'Closing',
  label: 'Closing',
 },
 {
  value: 'Showing',
  label: 'Showing',
 },
 {
  value: 'Open House',
  label: 'Open House',
 },
 {
  value: 'Thank You',
  label: 'Thank You',
 },
]
export const taskReminder: SelectType[] = [
 {
  value: 10,
  label: '10 min before',
 },
 {
  value: 30,
  label: '30 min before',
 },
 {
  value: 60,
  label: '1 hour before',
 },
 {
  value: 1440,
  label: '1 day before',
 },
 {
  value: 10080,
  label: '1 week before',
 },
]
export type TListingPageTitle = { label: string; slug: NewListingSlug }
export const ListingTypes: TListingPageTitle[] = [
 {
  label: 'For Sale',
  slug: 'sale',
 },
 {
  label: 'Recently Sold',
  slug: 'sold',
 },
 {
  label: 'For Rent',
  slug: 'rent',
 },
 {
  label: 'Open House',
  slug: 'open-house',
 },
 {
  label: 'Leased',
  slug: 'leased',
 },
 //  {
 //   label: 'My Brokerage Listing',
 //   slug: 'brokerage',
 //  },
]

export const VowListingTypes: TListingPageTitle[] = [
 {
  label: 'For Sale',
  slug: 'sale',
 },
 {
  label: 'For Rent',
  slug: 'rent',
 },
 {
  label: 'Open House',
  slug: 'open-house',
 },
 //  {
 //   label: 'My Brokerage Listing',
 //   slug: 'brokerage',
 //  },
]

export const sortData: SelectType[] = [
 {
  label: 'Latest (Default)',
  value: '0',
 },
 {
  label: 'Price: Low to High',
  value: '2',
 },
 {
  label: 'Price: High to Low',
  value: '1',
 },
]

export const Frequencys: SelectType[] = [
 { label: 'Daily', value: 'Daily' },
 { label: 'Once Weekly', value: 'Once Weekly' },
 { label: 'Twice Weekly', value: 'Twice Weekly' },
 { label: 'Once in a Month', value: 'Monthly' },
 { label: 'Twice in a Month', value: 'Twice a Month' },
]

export const ListingType: SelectType[] = [
 { label: 'Residential', value: 'Residential' },
 { label: 'Condo', value: 'Condo' },
 { label: 'Commercial', value: 'Commercial' },
]

export const findTag = (val?: TListingTag): TTagList => {
 const findVal = TagList.find((v) => v.value === val)
 if (findVal) {
  return findVal
 }
 return {
  label: 'Others',
  value: 'others',
  color: 'primary',
 }
}
