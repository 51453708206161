import { Col, Row } from 'antd'
import { SvgBath, SvgBed, SvgCondoSqft, SvgGarage } from 'components/icons'
import React, { FC } from 'react'
import { TListingType } from 'types'
import { rangeText } from 'utils/functions'
import style from './pro.module.scss'

const PropertyBBC: FC<{
 maxBed?: number
 type?: TListingType
 bed?: any
 bath?: any
 garage?: any
 isPrecon?: boolean
 bedMax?: number
 bedMin?: number
 sqftMin?: string
 sqftMax?: string
}> = ({
 bath,
 bed,
 garage,
 maxBed,
 type,
 sqftMin,
 sqftMax,
 bedMax,
 bedMin,
 isPrecon = false,
}) => (
 <div className="pro-bbc">
  {isPrecon ? (
   <>
    <div className="bbc">
     <SvgBed />
     <span className="span">
      {rangeText(bedMin || 0, bedMax || 0) ? rangeText(bedMin, bedMax) : 0}
     </span>
    </div>
    {!!bath && (
     <div className="bbc">
      <SvgBath />
      <span className="span"> {bath}</span>
     </div>
    )}

    {!!rangeText(sqftMin, sqftMax) && (
     <div className="sqft bbc">
      <SvgCondoSqft />
      <span className="span">{rangeText(sqftMin, sqftMax)} Sqft</span>
     </div>
    )}
   </>
  ) : (
   <>
    {Number(bed ?? 0) !== 0 && (
     <div className="bbc">
      <SvgBed />{' '}
      <span className="span">{maxBed ? `${bed} + ${maxBed}` : bed}</span>
     </div>
    )}
    {Number(bath ?? 0) !== 0 && (
     <div className="bbc">
      <SvgBath /> <span className="span">{bath}</span>
     </div>
    )}
    {Number(garage ?? 0) !== 0 && (
     <div className="bbc">
      <SvgGarage /> <span className="span">{garage}</span>
     </div>
    )}
   </>
  )}
 </div>
 //  <Row className={`${style.bbc} bbc-card`} align="middle" gutter={[8, 8]}>
 //   {isPrecon ? (
 //    <>
 //     <Col>
 //      <span style={{ display: 'flex', alignItems: 'center' }}>
 //       <SvgBed />
 //       <span className={`${style.bbcSpan}`}>
 //        {rangeText(bedMin || 0, bedMax || 0) ? rangeText(bedMin, bedMax) : 0}
 //       </span>
 //      </span>
 //     </Col>
 //     {!!bath && (
 //      <Col className={`${style.bbcCol}`}>
 //       <SvgBath />
 //       <span className={`${style.bbcSpan} me-1`}> {bath}</span>
 //      </Col>
 //     )}

 //     {!!rangeText(sqftMin, sqftMax) && (
 //      <Col className={`${style.bbcCol}`}>
 //       <SvgCondoSqft />
 //       <span className={`${style.bbcSpan} ms-1`}>
 //        {rangeText(sqftMin, sqftMax)} Sqft
 //       </span>
 //      </Col>
 //     )}
 //    </>
 //   ) : (
 //    <>
 //     {Number(bed ?? 0) !== 0 && (
 //      <Col className={`${style.bbcCol}`}>
 //       <SvgBed />{' '}
 //       <span className={`${style.bbcSpan}`}>
 //        {maxBed ? `${bed} + ${maxBed}` : bed}
 //       </span>
 //      </Col>
 //     )}
 //     {Number(bath ?? 0) !== 0 && (
 //      <Col className={`${style.bbcCol}`}>
 //       <SvgBath /> {bath}
 //      </Col>
 //     )}
 //     {Number(garage ?? 0) !== 0 && (
 //      <Col className={`${style.bbcCol}`}>
 //       <SvgGarage /> {garage}
 //      </Col>
 //     )}
 //    </>
 //   )}
 //  </Row>
)

export default PropertyBBC
