import React from 'react'
import { FCC } from 'types'

const OverlaySection: FCC = ({ children }) => {
 return (
  <div className="overlay-wrapper">
   <div className="overlay-section">
    <div className="overlay-section-body">{children}</div>
   </div>
  </div>
 )
}

export default OverlaySection
