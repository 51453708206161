import { Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import useAuth from 'hooks/useAuth'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import useAppControl from 'hooks/useAppControl'
import InboxNavLinks from '../components/InboxNavLinks'
import InboxType from './type'

const InboxRoute: FC = () => {
 const { user } = useAuth()
 const { updateSendEmailWidget, updateSendSMSWidget } = useAppControl()

 return (
  <div className="inbox-page">
   <div className="inbox-page-header">
    <Row className="w-100" align="middle" justify="space-between">
     <InboxNavLinks />
     <Space>
      <BaseButton
       icon={<i className="fas fa-plus me-2" />}
       buttonType="primary"
       onClick={() => {
        updateSendEmailWidget({
         show: true,
         widgetFrom: 'lead',
        })
       }}
      >
       Send New Email
      </BaseButton>
      {user?.twilio_phone && (
       <BaseButton
        onClick={() => {
         updateSendSMSWidget({
          show: true,
          widgetFrom: 'lead',
         })
        }}
        icon={<i className="fas fa-plus me-2" />}
        buttonType="info"
       >
        Send New SMS
       </BaseButton>
      )}
     </Space>
    </Row>
   </div>
   <div className="inbox-page-body">
    <Routes>
     <Route path=":mode/:type/*" element={<InboxType />} />
    </Routes>
   </div>
  </div>
 )
}

export default InboxRoute
