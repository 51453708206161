import { Spin } from 'antd'
import React, { FC } from 'react'

export const BlockLoader: FC<{
 mode?: 'block' | 'full'
 className?: string
}> = ({ className, mode }) => {
 const fullClass =
  'w-100 h-100 d-flex align-items-center justify-content-center'

 return (
  <div
   className={`text-center py-2 ${
    mode === 'full' ? fullClass : ''
   } ${className}`}
  >
   <Spin />
  </div>
 )
}
