import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import { SvgDeleteIcon, SvgStageIcon, SvgTagsIcon } from 'components/icons'
import { AssignToLeadModal } from 'components/shared/Modals'
import AssignToSourceModal from 'components/shared/Modals/AssignToSourceModal'
import { FC, useMemo, useState } from 'react'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import { TSelectAllP } from 'features/leads'
import useAuth from 'hooks/useAuth'
import { useDeleteBulkActionPlanMutation } from '../../api'
import { TActionPlanList, TAutomationTypes } from '../../types'
import ShareAP from './ShareAP'

const BuilkActions: FC<
 TSelectAllP & {
  actionRefresh?: () => void
  selectedRows?: TActionPlanList[]
  onClickAdd?: () => void
  mode: 'action-plan' | TAutomationTypes
 }
> = ({
 selectedRows,
 actionRefresh,
 onClickAdd,
 filterInfo,
 selectAll,
 unSelected,
 mode = 'action-plan',
}) => {
 const [deleteActionplans] = useDeleteBulkActionPlanMutation()
 const [showModal, setShowModal] = useState<
  'lead' | 'tag' | 'stage' | 'source' | 'share' | false
 >(false)
 const { user } = useAuth()
 const selectedKeys: any[] = useMemo(
  () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
  [selectedRows],
 )

 const handleDeleteActionplan = async () => {
  globalConfirm({
   content: 'Are you sure want to delete selected items?',
   onOk: async () => {
    await deleteActionplans({ id: selectedKeys }).unwrap()
    actionRefresh?.()
   },
  })
 }

 const buttons = [
  ...(mode === 'action-plan'
   ? [
      {
       tooltipText: 'Add New',
       onClick: onClickAdd,
       buttonType: 'gamma',
       icon: <i className="far fa-plus" />,
       disabled: false,
      },
     ]
   : []),
  {
   tooltipText: 'Assign to Lead',
   onClick: () => setShowModal('lead'),
   buttonType: 'alpha',
   icon: <i className="far fa-user" />,
  },
  {
   tooltipText: 'Assign to Tag',
   onClick: () => setShowModal('tag'),
   buttonType: 'alpha',
   icon: <i className="far fa-tags" style={{ fontSize: '17px' }} />,
  },
  {
   tooltipText: 'Assign to Stage',
   onClick: () => setShowModal('stage'),
   buttonType: 'alpha',
   icon: <i className="far fa-th" style={{ fontSize: '17px' }} />,
  },
  {
   tooltipText: 'Assign to Source',
   onClick: () => setShowModal('source'),
   buttonType: 'alpha',
   icon: <i className="far fa-globe" style={{ fontSize: '17px' }} />,
   tooltipProps: {
    placement: 'topRight',
    getTooltipContainer: (e: HTMLElement) =>
     e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
      ?.parentNode as any,
   },
  },
  ...(user?.account_type === 'Brokerage' && mode === 'action-plan'
   ? [
      {
       tooltipText: 'Share',
       onClick: () => setShowModal('share'),
       buttonType: 'alpha',
       icon: <i className="far fa-share" />,
      },
     ]
   : []),
  ...(mode === 'action-plan'
   ? [
      {
       tooltipText: 'Delete',
       onClick: handleDeleteActionplan,
       buttonType: 'beta',
       icon: <SvgDeleteIcon />,
      },
     ]
   : []),
 ]

 const commonModalProps = {
  onCancel: () => setShowModal(false),
  type: mode === 'action-plan' ? 'actionPlan' : mode,
  visible: true,
  selectedKeys,
  selectAll,
  unSelected,
  filterInfo,
 }

 return (
  <>
   <Space>
    {buttons?.map(({ disabled = !selectedKeys?.length, ...props }, i) => (
     <BaseButton key={i} disabled={disabled} {...(props as any)} />
    ))}
   </Space>

   {showModal === 'lead' && (
    <AssignToLeadModal
     {...(mode !== 'action-plan' ? { extrabody: { type: 'newsletter' } } : {})}
     {...(commonModalProps as any)}
     refetch={actionRefresh}
    />
   )}

   {showModal === 'share' && <ShareAP {...(commonModalProps as any)} />}

   {(showModal === 'tag' || showModal === 'stage') && (
    <AssignTagsStagesModal
     refetch={actionRefresh}
     assignType={showModal}
     {...(commonModalProps as any)}
     extraBody={
      mode !== 'action-plan'
       ? {
          type: 'newsletter',
          ...(!selectAll ? { newsletter_id: selectedKeys } : {}),
         }
       : {}
     }
    />
   )}
   {showModal === 'source' && (
    <AssignToSourceModal
     refetch={actionRefresh}
     {...(commonModalProps as any)}
     extraBody={
      mode !== 'action-plan'
       ? {
          type: 'newsletter',
          ...(!selectAll ? { newsletter_id: selectedKeys } : {}),
         }
       : {}
     }
    />
   )}
  </>
 )
}

export default BuilkActions
