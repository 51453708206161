/* eslint-disable no-nested-ternary */
import { Spin, Select, Space, Modal, Form } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { useLazyGetBroOrCitySuggestionsQuery } from 'features/property'
import { useSaveListingItemsMutation } from 'features/settings/api'
import { useGlobal } from 'raducer/global'
import { FC, useEffect, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/functions'

const ActionBlockListingSettings: FC<{
 type: 'brokerage' | 'city'
 refetch: () => void
}> = ({ type, refetch }) => {
 const [form] = Form.useForm<{ selected: string[] }>()
 const { updateBrokerageData } = useGlobal()

 const [addNewModal, setAddNewModal] = useState(false)
 const [searchKey, setSearchKey] = useState('')
 const [saveListing, { isLoading: saveLoading }] = useSaveListingItemsMutation()

 const [getBroOrCitySuggestions, { data, isLoading, isFetching }] =
  useLazyGetBroOrCitySuggestionsQuery()

 const [suggestion, setSugg] = useState<any[]>([])

 useEffect(() => {
  if (data) {
   setSugg(data)
  }
 }, [data])

 useEffect(() => {
  if (type) {
   setSugg([])
  }
 }, [type])

 useEffect(() => {
  if (searchKey?.length > 2) {
   getBroOrCitySuggestions({
    search: searchKey,
    url: type === 'brokerage' ? 'get-brokerage-search' : 'city-search',
   })
  }
 }, [searchKey])

 //  useEffect(() => {
 //   getBroOrCitySuggestions({
 //    search: '',
 //    url: type === 'brokerage' ? 'get-brokerage-search' : 'city-search',
 //   })
 //  }, [])

 const onHandleAddNew = async () => {
  const isCity = type !== 'brokerage'
  const values = await form.validateFields()
  await saveListing({
   url: !isCity ? 'save-brokerage-to-listing' : 'save-neighbourhood-to-listing',
   body: {
    [!isCity ? 'selected_brokerages' : 'selected_neighbourhoods']:
     JSON.stringify(values?.selected),
   },
  }).unwrap()
  refetch?.()
  setAddNewModal(false)
  form.resetFields()
  setSearchKey('')
  updateBrokerageData?.({ type: 'add', data: values?.selected })
 }

 return (
  <Space>
   <BaseButton
    tooltipText="Email"
    shape="default"
    onClick={() => setAddNewModal(true)}
    buttonType="primary"
    icon={<i className="fas fa-plus me-1" />}
   >
    Add New
   </BaseButton>

   <Modal
    visible={addNewModal}
    onCancel={() => {
     setAddNewModal(false)
     setSearchKey('')
     form.resetFields()
    }}
    onOk={onHandleAddNew}
    okText="Submit"
    confirmLoading={saveLoading}
    title={`Find ${type === 'brokerage' ? 'Brokerage' : 'City'}`}
   >
    <Form layout="vertical" form={form}>
     <Form.Item
      name="selected"
      label={type === 'brokerage' ? 'Brokerage' : 'City'}
      rules={[
       {
        required: true,
        message: `${capitalizeFirstLetter(type)} is required`,
       },
      ]}
     >
      <Select
       notFoundContent={
        isLoading || isFetching ? (
         <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '70px' }}
         >
          <Spin />
         </div>
        ) : searchKey?.length < 3 ? null : undefined
       }
       onSearch={(v) => setSearchKey(v)}
       searchValue={searchKey}
       mode="multiple"
       allowClear
       placeholder={`Select ${type === 'brokerage' ? 'Brokerage' : 'City'}`}
       options={
        searchKey?.length > 2
         ? suggestion?.map?.((i, n) => ({ label: i, value: i }))
         : []
       }
      />
     </Form.Item>
     {type === 'brokerage' && (
      <p>
       The selected brokerage listings will be shown in the My Brokerage section
       under Listings.
      </p>
     )}
    </Form>
   </Modal>
  </Space>
 )
}

export default ActionBlockListingSettings
