import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import {
 useGetAnalyticsOrderQuery,
 useGetLeadAnalyticsQuery,
} from 'features/leads/api'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { Col, Row, Empty, Menu } from 'antd'
import Chart from 'react-apexcharts'
import { BlockLoader } from 'components/elements/Loader'
import { useWindowSize } from 'react-use'
import { FCC } from 'types'
import { useInView } from 'react-intersection-observer'

const colors = [
 '#97D9FF',
 '#5FB7D4',
 '#7CDDDD',
 '#26D7AE',
 '#2DCB75',
 '#1BAA2F',
 '#52D726',
 '#D5F30B',
 '#FFEC00',
 '#FFAF00',
 '#FF7300',
 '#D66508',
 '#994500',
 '#BF245E',
 '#E13790',
 '#C758D0',
 '#9C46D0',
 '#8E6CEF',
 '#8399EB',
 '#007ED6',
]

const AnalyticsSection: FC = () => {
 const { leadId } = useLeadDetails()
 const [type, setType] = useState<null | string>(null)
 const { data, isLoading, isFetching } = useGetLeadAnalyticsQuery(
  {
   leadId,
   type: type ?? '',
  },
  { skip: !type },
 )
 const {
  data: orderData,
  isLoading: analyticsOrderLoading,
  isFetching: analyticsOrderFetching,
 } = useGetAnalyticsOrderQuery({ leadId })

 useEffect(() => {
  if (orderData?.length) {
   setType(orderData?.[0]?.key)
  }
 }, [orderData])

 const [visibleCharts, setVisibleCharts] = useState<Set<unknown | string>>(
  new Set(),
 )

 const { width, height } = useWindowSize()
 const chartWidth = useMemo(() => {
  if (width > 1600) {
   return 550
  }
  if (width < 1300) {
   return 340
  }
  if (width < 1400) {
   return 410
  }
  if (width < 1600) {
   return 490
  }
  return 490
 }, [width])

 const handleChartData = (v: {
  type: 'table' | 'pie-chart'
  val?: { key?: string; value?: string }[]
  visibleCharts: Set<unknown>
  setVisibleCharts: React.Dispatch<React.SetStateAction<Set<unknown>>>
  nKey: string
 }): ReactNode => {
  const { nKey } = v
  if (v.type === 'table') {
   return (
    <div className="analytics-table">
     {v?.val?.map((i, n) => (
      <div key={n} className="wrapper">
       <div>{i?.key}</div>
       <div>{i?.value}</div>
      </div>
     ))}
    </div>
   )
  }
  if (v.type === 'pie-chart') {
   return (
    <div className="analytics-pie">
     <Chart
      key={
       v?.visibleCharts.has(nKey)
        ? `chart-${nKey}`
        : `chart-placeholder-${nKey}`
      }
      options={{
       plotOptions: {
        pie: {
         dataLabels: {
          minAngleToShowLabel: 5,
          offset: -45,
         },
        },
       },

       chart: {
        type: 'pie',
        events: {
         mouseMove: undefined,
         mouseLeave: undefined,
        },
       },
       states: {
        hover: {
         filter: {
          type: 'none',
         },
        },
        active: {
         filter: {
          type: 'none',
         },
        },
       },
       stroke: { show: (v?.val?.length ?? 0) > 1 },

       dataLabels: {
        formatter: (_, pt) => {
         return pt?.w?.config?.series?.[pt?.seriesIndex ?? 0]
        },
        style: { colors: ['#000'], fontWeight: 400 },
       },
       tooltip: {
        custom({ series, seriesIndex, dataPointIndex, w }) {
         return (
          `<div class="custom-tooltip" style="background: ${
           colors[seriesIndex % colors.length]
          }; color: #000; padding: 8px;">` +
          `<span>${w.config.labels[seriesIndex]}: ${series[seriesIndex]}</span>` +
          '</div>'
         )
        },
       },
       legend: {
        show: false,
       },
       labels: v?.val?.map((i) => i?.key ?? '') ?? [],
       colors,
      }}
      //   series={v?.val?.map((i) => Number(i?.value ?? '0')) ?? []}
      series={
       v?.visibleCharts.has(nKey)
        ? v?.val?.map((i) => Number(i?.value ?? '0')) ?? []
        : []
      }
      type="pie"
      width={chartWidth}
     />

     <div className="custom-legends" style={{ maxHeight: `${chartWidth}px` }}>
      {v?.val?.map((i, n) => (
       <div className="legend-item" key={n}>
        <div
         className="custom-marker"
         style={{ backgroundColor: colors[n % colors.length] }}
        />{' '}
        <div className="content">{i?.key}</div>
       </div>
      ))}
     </div>
    </div>
   )
  }
 }

 const handleData = (
  visibleCharts: Set<unknown>,
  setVisibleCharts: React.Dispatch<React.SetStateAction<Set<unknown>>>,
 ): ReactNode => {
  if (isLoading || isFetching || !type) {
   return <BlockLoader mode="block" className="mt-5" />
  }
  if (!data) {
   return (
    <Empty
     className="mt-5"
     description="Analytics data will be available once the lead signs up and starts engaging with listings"
    />
   )
  }
  return (
   <Row gutter={[20, 20]}>
    <Col span={24}>{data?.summary && <p>{data?.summary}</p>}</Col>
    {data?.data?.map((i, n) => (
     <Col key={n} span={24} className="my-3">
      <h3 className="mb-3">{i?.title}</h3>
      <ChartInView
       onInView={() =>
        setVisibleCharts((prev) => new Set(prev).add(`${type}-${n}`))
       }
      >
       {handleChartData({
        type: i?.type,
        val: i?.value,
        setVisibleCharts,
        visibleCharts,
        nKey: `${type}-${n}`,
       })}
      </ChartInView>
     </Col>
    ))}
   </Row>
  )
 }

 return (
  <div className="pt-3 analytics">
   {analyticsOrderLoading || analyticsOrderFetching ? (
    <BlockLoader mode="block" className="mt-5" />
   ) : (
    <Row gutter={[16, 16]}>
     <Col span={6}>
      <Menu
       className="analytics-menu"
       defaultSelectedKeys={[orderData?.[0]?.key ?? '']}
       mode="inline"
       //   items={items}
       items={orderData?.map((i) => ({ ...i, onClick: () => setType(i?.key) }))}
      />
     </Col>
     <Col className="analytics-content" span={16}>
      {handleData(visibleCharts, setVisibleCharts)}
     </Col>
    </Row>
   )}
  </div>
 )
}

export default AnalyticsSection

const ChartInView: FCC = ({ onInView, children }) => {
 const { ref, inView } = useInView({
  triggerOnce: true,
  threshold: 0.3,
 })

 useEffect(() => {
  if (inView) {
   onInView()
  }
 }, [inView])

 return <div ref={ref}>{children}</div>
}
