/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function forceScroll(
 scrollLeft: number,
 target: HTMLDivElement | ((left: number) => void),
) {
 if (!target) {
  return
 }
 if (typeof target === 'function') {
  target(scrollLeft)
 } else if (target.scrollLeft !== scrollLeft) {
  target.scrollLeft = scrollLeft

  // Delay to force scroll position if not sync
  // ref: https://github.com/ant-design/ant-design/issues/37179
  if (target.scrollLeft !== scrollLeft) {
   setTimeout(() => {
    target.scrollLeft = scrollLeft
   }, 0)
  }
 }
}
