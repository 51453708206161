import ListContentLayout from 'layout/ListContentLayout'
import React, { FC, useEffect, useMemo } from 'react'
import { PropertyList } from 'components/shared/Property'
import useFetchListing from 'hooks/useFetchListing'
import { BtnAnch } from 'components/elements/global'
import { useNavigate } from 'react-router'
import { ADMIN_PREFIX_PATH } from 'config'
import ProListingActionBlock from '../components/ProListingActionBlock'
import ProListingHeader from '../components/ProListingHeader'
import { TPropertyListing } from '../types'
import { useProListing } from '../provider/ProListingProvider'

const ProListing: FC = () => {
 const { filterParams, filterState } = useProListing()
 const navigate = useNavigate()

 const apiURL = useMemo(() => {
  switch (filterState?.listing_type) {
   case 'sold':
   case 'leased':
    return 'get-sold-filter-properties'
   case 'open-house':
    return 'get-openhouse-properties'
   case 'brokerage':
    return 'get-brockerage-prop-list'
   default:
    return 'get-filter-properties'
  }
 }, [filterState?.listing_type])

 const { flatData, isLoading, fetchMore, hasMore } =
  useFetchListing<TPropertyListing>(apiURL, {
   params: filterParams,
   enable: filterParams !== '' && filterParams !== undefined,
  })
 return (
  <ListContentLayout
   actions={<ProListingActionBlock />}
   title={
    <>
     Property Listings{' '}
     <BtnAnch
      onClick={() => navigate(`${ADMIN_PREFIX_PATH}/settings/listing-settings`)}
      tooltipText="Listing Settings"
     >
      <i className="fas fa-cog" />
     </BtnAnch>
    </>
   }
  >
   <div className="prolisting-page">
    <ProListingHeader type="listing" />
    <PropertyList
     data={flatData}
     fetchMore={fetchMore}
     hasMore={hasMore}
     isLoading={isLoading}
     selection
    />
   </div>
  </ListContentLayout>
 )
}

export default ProListing
