import { DatePicker, Form, Input, Modal, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import BaseTDPicker, {
 BaseTDRangePicker,
} from 'components/elements/BaseComponents/BaseTDPicker'
import {
 TAddLeadEventBody,
 useAddLeadEventMutation,
 useUpdateLeadEventMutation,
} from 'features/leads'
import { baseTime } from 'lib'
import moment from 'moment'
import { FC, useEffect, useMemo } from 'react'
import { taskReminder } from 'static-data'
import { TMode } from 'types'
import { eventReminder } from 'utils/functions'
import SelectLeadBlock from '../LoadMoreBlocks/SelectLeadBlock'

const formInit: TAddLeadEventBody = {
 title: '',
 description: '',
 location: '',
 from_date: '',
 to_date: '',
 reminder: null,
}

const AddAppointmentModal: FC<{
 //  onSuccess?: (val?: TAddLeadEventBody) => void
 onSuccess?: () => void
 mode?: TMode
 visible?: boolean
 onCancel?: () => void
 editData?: TAddLeadEventBody
 leadId?: any
}> = ({ mode = 'ADD', visible, onCancel, editData, leadId, onSuccess }) => {
 const [addTask, { isLoading: addLoading }] = useAddLeadEventMutation()
 const [updateLeadEvent, { isLoading: updateLoading }] =
  useUpdateLeadEventMutation()
 const [form] = Form.useForm<TAddLeadEventBody>()
 const dateValue = Form.useWatch('dateRange', form) as baseTime.Moment[]
 const handleSubmit = async () => {
  const values = await form.validateFields()

  const postData: TAddLeadEventBody = {
   description: values.description,
   location: values.location,
   reminder: values.reminder,
   title: values.title,
   ...(values.dateRange && values.dateRange.length > 0
    ? {
       from_date: baseTime(values.dateRange?.[0]).format('YYYY-MM-DD'),
       from_time: baseTime(values.dateRange?.[0]).format('HH:mm:ss'),
       to_date: baseTime(values.dateRange?.[1]).format('YYYY-MM-DD'),
       to_time: baseTime(values.dateRange?.[1]).format('HH:mm:ss'),
      }
    : {}),
   lead_id: values.lead_id ?? leadId,
   feed: 'appointment',
  }
  if (mode === 'EDIT') {
   await updateLeadEvent({
    ...postData,
    id: editData?.id,
   }).unwrap()
   form.resetFields()
   //  onSuccess?.({
   //   ...postData,
   //   id: editData?.id,
   //  })
   onSuccess?.()
   onCancel?.()
  } else {
   await addTask(postData).unwrap()
   form.resetFields()
   //  onSuccess?.({
   //   ...postData,
   //   id,
   //  })
   onSuccess?.()
   onCancel?.()
  }
 }
 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    lead_id: editData.lead_id ?? '',
    title: editData.title,
    reminder: editData.reminder,
    description: editData.description,
    dateRange: [
     baseTime(`${editData.from_date} ${editData.from_time}`),
     baseTime(`${editData.to_date} ${editData.to_time}`),
    ],
    location: editData.location,
   })
  } else {
   form.resetFields()
  }
  return () => {
   form.resetFields()
  }
 }, [editData, form, mode])

 const eventRemiderList = useMemo(
  () => (dateValue && dateValue.length > 0 ? eventReminder(dateValue[0]) : []),
  [dateValue],
 )

 return (
  <Modal
   forceRender
   confirmLoading={mode === 'EDIT' ? updateLoading : addLoading}
   onOk={handleSubmit}
   //  okText={`${mode === 'EDIT' ? 'Update' : 'Submit'}`}
   cancelButtonProps={{
    className: 'ant-btn ant-btn-default ant-btn-secondary secondary-btn',
   }}
   okText="Save"
   onCancel={onCancel}
   visible={visible}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} Appointment`}
  >
   <Form form={form} layout="vertical" initialValues={formInit}>
    {!leadId && (
     <Form.Item
      name="lead_id"
      rules={[{ required: true, message: 'Lead name is required' }]}
      label="Lead name"
     >
      <SelectLeadBlock formMode={mode} />
     </Form.Item>
    )}
    <Form.Item
     name="title"
     rules={[{ required: true, message: 'Title is required' }]}
     label="Title"
    >
     <Input placeholder="Enter Title" />
    </Form.Item>
    <Form.Item label="Description" name="description">
     <TextArea placeholder="Description" />
    </Form.Item>
    <Form.Item label="Location" name="location">
     <Input placeholder="Location" />
    </Form.Item>
    <Form.Item
     name="dateRange"
     label="Date"
     rules={[{ required: true, message: 'Date is required' }]}
    >
     <BaseTDRangePicker disablePastTime />
    </Form.Item>

    {eventRemiderList && eventRemiderList.length > 0 && (
     <Form.Item
      name="reminder"
      label="Set Reminder"
      // rules={[{ required: true }]}
     >
      <Select
       placeholder="Set Reminder"
       //  options={[
       //   { value: null, label: 'Select Reminder' },
       //   ...eventRemiderList,
       //  ]}
       options={eventRemiderList}
      />
     </Form.Item>
    )}
   </Form>
  </Modal>
 )
}

export default AddAppointmentModal
