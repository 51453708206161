import { Badge, Popover, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { FC, useState, useMemo } from 'react'
import { TGetLeads, TLeadFilterBody, TSelectAllP } from 'features/leads'
import SelectedLeadModal from 'features/leads/components/SelectedLeadModal'
import LeadTransferBtn from './LeadTransferBtn'

const ActionBlockRealtorDetail: FC<
 TSelectAllP & {
  onRemoveSelectedKey?: (id?: any) => void
  selectedRows?: TGetLeads[]
  clearAllKeys?: () => void
  // clearFilter: () => void
  // activeFilter: TLeadFilterBody
  realtorId?: string
  onSuccess?: () => void
  totalCount?: number
  hasMore?: boolean
 }
> = ({
 selectedRows,
 onRemoveSelectedKey,
 clearAllKeys,
 //  clearFilter,
 //  activeFilter,
 selectAll,
 unSelected,
 filterInfo,
 realtorId,
 onSuccess,
 totalCount,
 hasMore,
}) => {
 const selectedKeys: any[] = useMemo(
  () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
  [selectedRows],
 )
 const [showModal, setShowModal] = useState<false | 'lead-modal'>(false)

 return (
  <Space>
   {(selectedRows?.length ?? 0) > 0 && (
    <BaseButton
     type="link"
     disabled={selectAll && !(selectedRows?.length === totalCount) && hasMore}
     onClick={() => setShowModal('lead-modal')}
    >
     Selected Leads
     <Badge
      className="ms-1"
      count={
       selectAll && hasMore
        ? `${(totalCount ?? 0) - (unSelected?.length || 0)}`
        : selectedKeys.length ?? 0
      }
      overflowCount={999999}
     />
    </BaseButton>
   )}
   {/* {selectedRows && selectedRows.length > 0 && (
    <Popover
     title="Selected Leads"
     content={
      <>
       {selectedRows.map((v, i) => (
        <BaseTag
         onClose={() => onRemoveSelectedKey?.(v.id)}
         closable
         className="py-1 px-2"
         label={v.name}
         key={i}
        />
       ))}
       <div className="d-block mt-2">
        <BaseButton onClick={clearAllKeys} danger size="small">
         Clear All
        </BaseButton>
       </div>
      </>
     }
    >
     <BaseButton type="link">
      Selected Leads
      <Badge
       className="ms-1"
       count={selectedKeys.length ?? 0}
       overflowCount={99999}
      />{' '}
     </BaseButton>
    </Popover>
   )} */}
   {/* <BaseButton
    buttonType="danger"
    onClick={() => {
     clearFilter?.()
    }}
    disabled={
     Object.keys(activeFilter as any).length === 0 ||
     Object.values(activeFilter as any).every((val) => val === null)
    }
   >
    Clear Filter
   </BaseButton> */}

   <LeadTransferBtn
    id={JSON.stringify(selectedKeys)}
    btnDisabled={!selectedRows?.length}
    type="text"
    selectAll={selectAll}
    filterInfo={filterInfo}
    unSelected={unSelected}
    from="realtor"
    // realtorId={realtorId}
    onSuccess={() => {
     onSuccess?.()
     clearAllKeys?.()
    }}
    iconClassName="me-2"
    extraBody={{
     crm_list_type: 'myrealtor',
     current_owner: realtorId,
    }}
    searchExtraBody={{ type: 'my_realtor' }}
   />
   {showModal === 'lead-modal' && selectedRows?.length && (
    <SelectedLeadModal
     visible
     onCancel={() => setShowModal(false)}
     handleRemove={(v) => {
      onRemoveSelectedKey?.(v?.id)
     }}
     data={selectedRows}
     onClearAll={clearAllKeys}
     isRealtor={false}
    />
   )}
  </Space>
 )
}

export default ActionBlockRealtorDetail
