import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { baseAPI } from 'api/baseApi'
import { preConAPI, secondAPI } from 'api/secondApi'
import { authSlice } from 'features/auth'
import { LeadSlice } from 'features/leads/slice/leadSlice'
import { controlSlice } from 'raducer/control'
import { globalSlice } from 'raducer/global'
import { webSettingsSlice } from 'raducer/webSettings'

export const store = configureStore({
 reducer: {
  auth: authSlice.reducer,
  LeadSlice: LeadSlice.reducer,
  global: globalSlice.reducer,
  webSettings: webSettingsSlice.reducer,
  control: controlSlice.reducer,
  [baseAPI.reducerPath]: baseAPI.reducer,
  [secondAPI.reducerPath]: secondAPI.reducer,
  [preConAPI.reducerPath]: preConAPI.reducer,
 },
 devTools: true,
 middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
   baseAPI.middleware,
   secondAPI.middleware,
   preConAPI.middleware,
  ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
 ReturnType,
 RootState,
 unknown,
 Action<string>
>
