import { Card, Col, Empty, Popover, Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import { TSSBlock } from 'components/shared/Common'
import { useDeleteGroupMutation } from 'features/settings/api'
import TSSFormModal from 'features/settings/components/TSSFormModal'
import { TGroupForm } from 'features/settings/types'
import React, { FC, ReactNode, useState } from 'react'
import { TGroupName, TGroupType, TModalState } from 'types'

const GroupListBlock: FC<{
 loading?: boolean
 type: TGroupName
 title?: ReactNode
 data?: TGroupType[]
}> = ({ type, data, title, loading }) => {
 const [deleteGroup] = useDeleteGroupMutation()
 const [showModal, setShowModal] = useState<TModalState<TGroupForm>>({
  show: false,
  data: {},
  mode: 'ADD',
 })

 const handleDelete = async (id?: any) => {
  globalConfirm({
   content: `Are you sure want to delete this ${type}?`,
   onOk: async () => {
    await deleteGroup({ type, id: [id] }).unwrap()
   },
  })
 }

 return (
  <>
   <Card
    loading={loading}
    title={title}
    extra={
     <BaseButton
      onClick={() => setShowModal({ show: true, mode: 'ADD' })}
      size="small"
      type="link"
     >
      <i className="fas fa-plus" />{' '}
     </BaseButton>
    }
   >
    {data && data.length > 0 ? (
     <Row gutter={[10, 10]}>
      {data.map((item) => (
       <Col key={item.id}>
        <Popover
         //  title="Action"

         trigger={undefined}
         content={
          item.is_admin === 1 && (
           <Space className="w-100" align="center">
            <BaseButton
             onClick={() => {
              setShowModal({
               show: true,
               mode: 'EDIT',
               data: { id: item.id, name: item.name },
              })
             }}
             tooltipText="Edit"
             size="small"
             type="link"
             icon={<i className="fas fa-edit" />}
            />
            <BaseButton
             onClick={() => handleDelete(item.id)}
             danger
             tooltipText="Delete"
             size="small"
             type="link"
             icon={<i className="fas fa-trash" />}
            />
           </Space>
          )
         }
        >
         <a>
          <TSSBlock
           removeEllips
           className="m-0"
           type={type}
           label={item.name}
          />
         </a>
        </Popover>
       </Col>
      ))}
     </Row>
    ) : (
     <Empty />
    )}
   </Card>
   {showModal.show && (
    <TSSFormModal
     editData={showModal.data}
     mode={showModal.mode}
     type={type}
     onCancel={() => setShowModal({ show: false })}
     visible={showModal.show}
    />
   )}
  </>
 )
}

export default GroupListBlock
