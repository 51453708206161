import { Input, Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useUpdateNameMutation } from 'features/leads/api'
import { queryClient } from 'lib/react-query'
import React, { FC, useEffect } from 'react'

const EditLeadNameModal: FC<{
 name: string
 id?: number
 visible: boolean
 onCancel: () => void
 updateLeadCache: (upVal: any) => void
}> = ({ name, id, visible, onCancel, updateLeadCache }) => {
 const [updateName, { isLoading: updateNameLoading }] = useUpdateNameMutation()

 const [editNameForm] = useForm<{ name: string }>()
 useEffect(() => {
  editNameForm.setFieldsValue({ name })
 }, [name])

 const onSubmit = async (): Promise<void> => {
  const val = await editNameForm.validateFields()
  await updateName({
   lead_id: id,
   name: val?.name,
  }).unwrap()
  onCancel()
  editNameForm.resetFields()
  queryClient.invalidateQueries('get-leads')
  updateLeadCache({ name: val?.name })
 }

 return (
  <Modal
   centered
   confirmLoading={updateNameLoading}
   onOk={onSubmit}
   okText="Update"
   title="Edit lead name"
   visible={visible}
   onCancel={() => {
    onCancel?.()
    editNameForm.resetFields()
   }}
  >
   <Form form={editNameForm} layout="vertical">
    <Form.Item
     label="Name"
     required
     name="name"
     rules={[
      { required: true, message: 'Name is required' },
      { max: 50, message: 'Name should not exceed 50 characters' },
     ]}
    >
     <Input placeholder="Enter Name" />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default EditLeadNameModal
