import { preConAPI } from 'api/secondApi'
import { baseTransformResponse3 } from 'api/baseApi'
import { ApiResponce } from 'types'
import { TGetCrmFilters, TPreConDetail, TSearchSuggType } from '../types'

export const preconAPIs = preConAPI.injectEndpoints({
 endpoints: ({ query }) => ({
  getPreconDetail: query<TPreConDetail, { slug: string }>({
   query: ({ slug }) => ({
    method: 'post',
    url: `api/get-condo-detail?api_version=v3&view_type=crm&slug=${slug}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
  getPreconFilters: query<TGetCrmFilters[], string>({
   query: (body) => ({
    method: 'get',
    url: `api/get-crm-filters${body ?? ''}`,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getSearchPreCon: query<TSearchSuggType[], { search?: string }>({
   query: ({ search }) => ({
    method: 'get',
    url: `api/filter-search-pre-condo?api_version=v3&val=${search}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
 }),
})

export const {
 useGetPreconDetailQuery,
 useGetPreconFiltersQuery,
 useGetSearchPreConQuery,
} = preconAPIs
