import Upload, { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { BaseButton } from 'components/elements/BaseButton'
import { globalToaster } from 'components/elements/global'
import React, { FC, useEffect, useState } from 'react'

const supporttedFiles = [
 'pdf',
 'csv',
 'txt',
 'xls',
 'jpg',
 'jpeg',
 'png',
 'docx',
 'doc',
]

const EmailATUpload: FC<{
 value?: UploadFile[]
 onChange?: (val?: any) => void
}> = ({ onChange, value }) => {
 const [nValue, setValue] = useState(value)

 useEffect(() => {
  let mouted = true
  if (mouted && value) {
   setValue(value)
  }
  return () => {
   mouted = false
  }
 }, [value])

 const beforeUpload = (file: RcFile) => {
  const ext = file.name.split('.').pop()
  if (!supporttedFiles.some((v) => v === ext)) {
   globalToaster({ content: `${file.name} is Not Supported`, type: 'error' })
   return false
  }
  return false
 }
 return (
  <Upload
   listType="picture"
   className="upload-list-inline"
   fileList={nValue}
   onChange={(e) => {
    onChange?.(Array.isArray(e) ? e : e.fileList)
   }}
   //    accept=".pdf,.csv,.txt,.xls,.jpg,.jpeg,.png,.docx,.doc"
   multiple
   beforeUpload={beforeUpload}
  >
   <BaseButton
    // size={type === 'page' ? 'middle' : 'small'}
    buttonType="secondary"
   >
    <i className="fas fa-upload me-1" /> Attachment
   </BaseButton>
  </Upload>
 )
}

export default EmailATUpload
