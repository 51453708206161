import { Col, Drawer, Form, Radio, Row, Select, Checkbox } from 'antd'
import PropertyPriceRanger from 'components/shared/Property/PropertyPriceRanger'
import { FC, useEffect, useMemo, useState } from 'react'
import { BaseButton } from 'components/elements/BaseButton'
import {
 FilterBedLists,
 FilterDaysOnMarket,
 FilterSoldLeasedDOM,
} from 'static-data'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import BaseNumberInput from 'components/elements/BaseNumberInput'
import { BtnAnch } from 'components/elements/global'
import {
 proFilterIntialState,
 useProListing,
} from '../provider/ProListingProvider'
import { useGetCrmFiltersQuery } from '../api'
import { TProFilterState } from '../types'
import { useGetPreconFiltersQuery } from '../api/precon'
// import { useGetPreconFiltersQuery } from '../api/precon'

const ProFilterBlock: FC<{ onHide: () => void }> = ({ onHide }) => {
 const {
  changeListingType,
  filterState,
  type,
  setFilterState,
  searchType,
  setSearch,
  setSearchType,
  selectedCity,
 } = useProListing()

 const selectedType = filterState.listing_type
 const [dynamicSelectAll, setDynamicSelectAll] = useState<any>({})

 const [form] = useForm<
  TProFilterState & {
   price_range: { min: any; max: any }
  }
 >()

 const propertyType = Form.useWatch('property_type', form)

 const filterQuery = useMemo(() => {
  return `${
   propertyType && propertyType?.length !== 0
    ? `?property_type=${JSON.stringify(propertyType)}`
    : ''
  }${
   (propertyType && propertyType?.length === 0) ||
   propertyType === undefined ||
   propertyType === null
    ? '?'
    : '&'
  }from=listing&listing=${selectedType}
  `
 }, [propertyType])

 const { data: propertyFilterData, refetch } = useGetCrmFiltersQuery(
  filterQuery && type !== 'preconstruction' ? filterQuery : '',
  {
   skip: type === 'preconstruction',
  },
 )

 const { data: preConFiltersData, refetch: preconRefetch } =
  useGetPreconFiltersQuery('', {
   skip: type === 'listing',
  })

 useEffect(() => {
  if (type === 'preconstruction') {
   preconRefetch()
  } else {
   refetch()
  }
 }, [filterQuery, type])

 const filtersData = useMemo(
  () => (type === 'listing' ? propertyFilterData : preConFiltersData),
  [propertyFilterData, preConFiltersData, type],
 )

 const handleSubmit = async () => {
  const values: any = await form.validateFields()
  const finalValues: any = values
  finalValues.listing_type = selectedType
  finalValues.min_price = values?.price_range.min
  finalValues.max_price = values?.price_range.max

  if (
   (selectedType === 'rent' || selectedType === 'leased') &&
   finalValues?.max_price === 10000
  ) {
   finalValues.max_price = ''
  } else if (finalValues?.max_price === 5000000) {
   finalValues.max_price = ''
  }
  delete finalValues.price_range

  setFilterState(finalValues)
  onHide?.()
 }

 const onChangePropertyType = (clearFields: string[]): void => {
  // const oldValues: any = form.getFieldsValue()
  // Object.entries(oldValues).forEach(([key, value]) => {
  //  if (key === 'baths' || key === 'beds') {
  //   oldValues[key] = ''
  //  } else if (key === 'building_type') {
  //   oldValues[key] = []
  //  } else if (key === 'ownership_type' || key === 'building_style') {
  //   oldValues[key] = null
  //  } else if (
  //   key === 'price_range' ||
  //   key === 'property_type' ||
  //   key === 'd_o_m' ||
  //   key === 'listing_type'
  //  ) {
  //   oldValues[key] = value
  //  } else {
  //   oldValues[key] = null
  //  }
  // })

  const result = Object.fromEntries(
   clearFields?.map((field) => [
    field,
    filtersData?.find((i) => i?.name === field)?.type === 'multi-select'
     ? []
     : null,
   ]),
  )
  form.setFieldsValue(result)
 }

 useEffect(() => {
  if (filtersData?.length) {
   filtersData
    ?.filter((i) => i?.type === 'multi-select')
    ?.forEach((i) => {
     if ((filterState as any)[i?.name]?.length === i?.options?.length) {
      setDynamicSelectAll((prv: any) => ({
       ...prv,
       [i.name]: true,
      }))
     }
    })
  }

  const { price_range } = (filterState as any) || {}
  if (filterState) {
   const values: any = filterState
   values.price_range = {
    min: (filterState.min_price || price_range?.min) ?? 0,
    max: (filterState.max_price || price_range?.max) ?? '',
   }
   //  values.property_type = filterState.property_type
   //  values.building_type = filterState.building_type
   delete values?.min_price
   delete values?.max_price
   form.setFieldsValue(values)
  }
 }, [filterState])

 // eslint-disable-next-line no-nested-ternary
 //  const domLabel = ['sale', 'rent', 'open-house'].includes(selectedType)
 //   ? 'Days on Market'
 //   : selectedType === 'sold'
 //   ? 'Sold On'
 //   : 'Leased On'

 return (
  <Drawer
   visible
   onClose={onHide}
   width={500}
   title="Filter"
   className="pro-filter-block"
  >
   <Form
    initialValues={{
     ...proFilterIntialState,
     price_range:
      selectedType === 'leased' || selectedType === 'rent'
       ? { min: 0, max: 10000 }
       : { min: 0, max: 5000000 },
    }}
    form={form}
    layout="vertical"
   >
    <Row gutter={[15, 15]} className="form-wrapper">
     {(searchType === 'city' || searchType === 'community') && (
      <Col span={24}>
       <div
        className="d-flex align-items-center justify-content-between"
        style={{
         border: '1px solid #ccc',
         padding: '10px',
         borderRadius: '8px',
         fontSize: '13px',
        }}
       >
        <div className="d-flex align-items-center">
         <div>
          <i className="fas fa-search" style={{ fontSize: '18px' }} />
         </div>
         <div style={{ marginLeft: '20px' }}>
          <span className="d-block" style={{ fontWeight: '500' }}>
           {selectedCity}
          </span>
          <span className="d-block" style={{ color: 'gray' }}>
           {searchType}
          </span>
         </div>
        </div>{' '}
        <div>
         <BtnAnch
          onClick={() => {
           setSearch('')
           setSearchType(null)
          }}
         >
          <i
           className="fas fa-times"
           style={{ fontSize: '15px', marginRight: '5px' }}
          />
         </BtnAnch>
        </div>
       </div>
      </Col>
     )}
     <Col span={24}>
      <Form.Item name="price_range" label="Price Range">
       <PropertyPriceRanger
        // min={0}
        // max={5000000}
        rangeMode={
         selectedType === 'rent' || selectedType === 'leased' ? 'Lease' : 'Sale'
        }
       />
      </Form.Item>
     </Col>

     {filtersData?.map((i, n) =>
      i?.options?.length ? (
       <Col span={24} key={n}>
        <Form.Item
         name={i?.name}
         // label={i?.label}
         className={`mb-0 ${
          i?.type === 'multi-select' ? 'label-with-select-all' : ''
         }`}
         label={
          i?.type === 'multi-select' ? (
           <div className="d-flex align-items-center justify-content-between w-100">
            <span>{i?.label}</span>
            <span>
             <Checkbox
              onChange={({ target }) => {
               form.setFieldsValue({
                [i?.name]: target?.checked
                 ? i?.options?.map((opt) => opt?.value)
                 : [],
               })
               if (i?.name === 'property_type') {
                onChangePropertyType(i?.clear_fields ?? [])
                setDynamicSelectAll({ [i.name]: target?.checked })
               } else {
                setDynamicSelectAll((prv: any) => ({
                 ...prv,
                 [i.name]: target?.checked,
                }))
               }
              }}
              checked={dynamicSelectAll?.[i?.name] ?? false}
             >
              All
             </Checkbox>
            </span>
           </div>
          ) : (
           i?.label
          )
         }
         getValueFromEvent={(e) => {
          if (i?.name === 'property_type') {
           onChangePropertyType(i?.clear_fields ?? [])
           setDynamicSelectAll({ [i?.name]: e?.length === i?.options?.length })
          } else if (i?.type === 'multi-select') {
           setDynamicSelectAll((prv: any) => ({
            ...prv,
            [i?.name]: e?.length === i?.options?.length,
           }))
          }
          return e
         }}
        >
         <Select
          allowClear
          placeholder={`Select ${i?.label}`}
          options={i?.options?.map((j, k) => ({
           label: j?.label,
           value: j?.value,
          }))}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...(i?.type === 'multi-select' && {
           mode: 'multiple',
          })}
         />
        </Form.Item>
       </Col>
      ) : (
       ''
      ),
     )}

     {/* {preConFiltersData?.map?.((i, n) => (
       <Col span={24} key={n}>
        <Form.Item
         name={
          i.key === 'Property Category'
           ? 'property_type'
           : i?.key?.toLowerCase()?.split(' ').join('_')
         }
         label={i.key}
         getValueFromEvent={(e) => {
          if (i.key === 'Property Category') {
           onChangePropertyType?.()
          }
          return e
         }}
        >
         <Select
          allowClear
          placeholder={`Select ${i?.key}`}
          options={i.value?.map((j, k) => ({ label: j, value: j }))}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...((i?.key === 'Property Category' || i?.key === 'Building Type') &&
          type !== 'preconstruction'
           ? {
              mode: 'multiple',
              maxTagCount: 3,
             }
           : {})}
         />
        </Form.Item>
       </Col>
      ))} */}

     <Col span={24}>
      <Form.Item name="bed" label="Bedrooms">
       <RadioFormItem />
      </Form.Item>
     </Col>

     {type !== 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="bath" label="Bathrooms">
        <RadioFormItem />
       </Form.Item>
      </Col>
     )}
     {/* {type !== 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="d_o_m" label={domLabel}>
        <Select
         placeholder={domLabel}
         allowClear
         options={
          selectedType === 'sale' ||
          selectedType === 'rent' ||
          selectedType === 'open-house'
           ? FilterDaysOnMarket
           : FilterSoldLeasedDOM
         }
         getPopupContainer={(trigger) => trigger.parentNode}
        />
       </Form.Item>
      </Col>
     )} */}

     {type === 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="completion_year" label="Completion Year">
        <Select
         placeholder="Select Completion Year"
         allowClear
         options={Array.from({ length: 4 }, (_, index) => {
          const year = moment().year() + index
          return {
           label: index < 3 ? `${year}` : `${year}+`,
           value: index < 3 ? `${year}` : 'Later',
          }
         })}
         getPopupContainer={(trigger) => trigger.parentNode}
        />
       </Form.Item>
      </Col>
     )}

     {type === 'preconstruction' && (
      <Col span={24}>
       <label>Sqft</label>
       <div className="d-flex pt-2">
        <Form.Item name="min_sqft" className="pe-3">
         <BaseNumberInput className="w-100" placeholder="Min Sqft" />
        </Form.Item>
        <Form.Item name="max_sqft" className="pl-3">
         <BaseNumberInput className="w-100" placeholder="Max Sqft" />
        </Form.Item>
       </div>
      </Col>
     )}
    </Row>
   </Form>
   <div className="footer">
    <BaseButton
     customClass="secondary-btn"
     buttonType="secondary"
     onClick={() => {
      onHide()
      changeListingType(selectedType)
     }}
     block
    >
     Reset
    </BaseButton>
    <BaseButton buttonType="primary" onClick={handleSubmit} block>
     Apply
    </BaseButton>
   </div>
  </Drawer>
 )
}

export default ProFilterBlock

const RadioFormItem: FC<{
 value?: string
 onChange?: (val?: string) => void
}> = ({ value, onChange }) => {
 return (
  <Radio.Group
   buttonStyle="solid"
   size="large"
   className="radio-filter radio-filter-block"
   value={value}
  >
   {FilterBedLists?.map?.((i, k) => (
    <Radio.Button
     onClick={(e: any) => {
      if (value === e?.target?.value) {
       onChange?.(undefined)
      } else {
       onChange?.(e?.target?.value)
      }
     }}
     className="radio-filter"
     key={k}
     value={i.value}
    >
     {i.label}
    </Radio.Button>
   ))}
  </Radio.Group>
 )
}
