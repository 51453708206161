/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
// import { ColumnsType } from 'antd/lib/table'
import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { SvgDeleteIcon } from 'components/icons'
import { BtnAnch } from 'components/elements/global'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { TSSBlockList } from 'components/shared/Common'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { TRealtors } from '../types'

type TRealtorColumns = {
 onClickRealtor: (id: number, name: string) => void
 onDelete: (id: number) => void
 refetch: () => void
 selectedKeys?: any[]
 selectAll?: boolean
 unSelected?: any[]
 onCancelReq?: (id: number) => void
}

const TableColumns = ({
 onClickRealtor,
 onDelete,
 refetch,
 selectAll,
 selectedKeys,
 unSelected,
 onCancelReq,
}: TRealtorColumns): MDataTableColumns<TRealtors>[] => [
 {
  title: 'Name',
  dataIndex: 'name',
  size: 250,
  fixed: 'left',
  render: (val, rec) => (
   <BtnAnch onClick={() => onClickRealtor(rec.id, rec?.name)}>
    <AvatarStatus title={val} showTooltip />
   </BtnAnch>
  ),
 },
 {
  title: 'Email',
  dataIndex: 'email',
  size: 250,
 },
 {
  title: 'Website',
  dataIndex: 'website',
  size: 250,
  render: (val) => (
   <a
    className="colored-link"
    style={{ textDecoration: 'underline' }}
    href={val}
    target="_blank"
    rel="noreferrer"
   >
    {val}
   </a>
  ),
 },
 {
  title: 'Lead Assigned',
  dataIndex: 'lead_assigned',
  size: 250,
 },
 {
  title: 'Source',
  size: 250,
  dataIndex: 'sources',
  render: (_e, rec) => (
   <TSSBlockList
    // closable={false}
    closable
    mode="source"
    type="realtors"
    typeId={rec.id ?? ''}
    data={rec?.sources}
    onRemoveSuccess={refetch}
   />
  ),
 },
 {
  title: 'Action',
  size: 250,
  fixed: 'right',
  dataIndex: 'status',
  render: (val, rec) => (
   <Space>
    {val === 'Active' ? (
     <>
      <BaseButton
       disabled={
        ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(rec?.id)) ||
        (selectAll && !unSelected?.includes(rec?.id))
       }
       tooltipText="View"
       shape="circle"
       size="small"
       buttonType="danger"
       icon={<i className="fas fa-eye" />}
       onClick={() => onClickRealtor(rec.id, rec?.name)}
      />

      <BaseButton
       disabled={
        ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(rec?.id)) ||
        (selectAll && !unSelected?.includes(rec?.id))
       }
       onClick={() => onDelete(rec?.id)}
       shape="circle"
       size="small"
       buttonType="info"
       tooltipText="Delete"
       icon={<SvgDeleteIcon />}
      />
     </>
    ) : val === 'Request Sent' ? (
     <div>
      <span className="pe-2">{val}</span>{' '}
      {/* <BaseButton
       //    disabled={
       //     ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(rec?.id)) ||
       //     (selectAll && !unSelected?.includes(rec?.id))
       //    }
       tooltipText="Cancel Request"
       shape="circle"
       size="small"
       buttonType="danger"
       icon={<i className="fas fa-undo" />}
       onClick={() => onCancelReq?.(rec?.id)}
      /> */}
      <BaseButton
       onClick={() => onCancelReq?.(rec?.id)}
       shape="circle"
       size="small"
       buttonType="info"
       tooltipText="Delete Request"
       icon={<SvgDeleteIcon />}
      />
     </div>
    ) : (
     val
    )}
   </Space>
  ),
 },
]

export default TableColumns
