import { Space, Switch } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { FC, useEffect, useMemo, useState } from 'react'
import { TBirthdayWishes } from 'features/settings/types'
import { useUpdateAutoShareMutation } from 'features/settings/api'
import BirthDayWishesModal from './BirthDayWishesModal'

const BirthDayWishesActions: FC<{
 data?: TBirthdayWishes
 loading?: boolean
 refetch?: () => void
}> = ({ data, loading, refetch }) => {
 const [autoShare, setAutoShare] = useState(data?.status === 'Active')
 const [showModal, setShowModal] = useState(false)
 const [updateAutoShare, { isLoading }] = useUpdateAutoShareMutation()

 useEffect(() => setAutoShare(data?.status === 'Active'), [data])

 const btnLabel = useMemo(() => {
  if (!data?.email && !data?.sms) {
   return 'Add Template'
  }
  if (data?.email && data?.sms) {
   return ''
  }
  return `Add ${data?.sms ? 'Email' : 'SMS'} Template`
 }, [data])

 return (
  <Space>
   {(data?.email && data?.sms) || loading ? (
    ''
   ) : (
    <BaseButton
     tooltipText=""
     type="primary"
     onClick={() => setShowModal(true)}
     size="middle"
    >
     {btnLabel}
    </BaseButton>
   )}
   <div style={{ minWidth: '140px' }}>
    <span>Auto Share</span>{' '}
    <Switch
     checkedChildren="ON"
     unCheckedChildren="OFF"
     checked={autoShare}
     loading={loading || isLoading}
     onChange={async (val) => {
      await updateAutoShare(val ? 'Active' : 'Pause').unwrap()
      setAutoShare(val)
     }}
    />
   </div>
   {showModal && (
    <BirthDayWishesModal
     visible={showModal}
     onCancel={() => setShowModal(false)}
     data={data}
     templateMode={
      !data?.email && !data?.sms ? 'Email' : data?.sms ? 'Email' : 'SMS'
     }
     showItems={
      !data?.email && !data?.sms ? 'both' : data?.sms ? 'email' : 'sms'
     }
     onSuccess={refetch}
    />
   )}
  </Space>
 )
}

export default BirthDayWishesActions
