import { queryClient } from 'lib/react-query'
import moment from 'moment'
import { InfiniteData } from 'react-query'
import { InfinityNewType, TGetNotification } from 'types'

const PER_PAGE = 50

export const removeNotificationFromCache = (id: any) => {
 const cache =
  queryClient.getQueryData<InfiniteData<InfinityNewType<TGetNotification>>>(
   'all-notifications',
  )
 if (cache?.pages && cache?.pages.length > 0) {
  const messages =
   (cache?.pages.map((v) => v.data).flat() as TGetNotification[]) || []
  updateCacheData(messages.filter((v) => v.id !== id))
 }
}

const updateCacheData = (messages: TGetNotification[]) => {
 const newData: InfinityNewType<TGetNotification>[] = []

 for (let i = 0; i < messages.length; i += PER_PAGE) {
  const currentPage: InfinityNewType<TGetNotification> = {
   data: messages.slice(i, i + PER_PAGE),
   next: i + 2,
  }
  newData.push(currentPage)
 }

 queryClient.setQueryData<InfiniteData<InfinityNewType<TGetNotification>>>(
  'all-notifications',
  (data) => ({
   pageParams: data?.pageParams || [],
   pages: newData,
  }),
 )
}

export const ReadNotifyFromCache = (id: any) => {
 const cache =
  queryClient.getQueryData<InfiniteData<InfinityNewType<TGetNotification>>>(
   'all-notifications',
  )
 if (cache?.pages && cache?.pages.length > 0) {
  const messages =
   (cache?.pages.map((v) => v.data).flat() as TGetNotification[]) || []
  const index = messages.findIndex((v) => v.id === id)
  messages[index] = {
   ...messages[index],
   read_at: moment().format(),
  }
  updateCacheData(messages)
 }
}
