import { Modal, Spin, Divider } from 'antd'
import {
 TPreviewTemplateBody,
 //  TSendTextBody,
 usePreviewSmsLeadQuery,
} from 'features/leads'
// import useAuth from 'hooks/useAuth'
import React, { FC } from 'react'

type SmsPreviewProp = {
 //  data: TSendTextBody
 visible: boolean
 onCancel: () => void
 body: TPreviewTemplateBody
}

const SmsPreview: FC<SmsPreviewProp> = ({ visible, onCancel, body }) => {
 //  const { user } = useAuth()
 const { data: preview, isLoading } = usePreviewSmsLeadQuery(body)
 //     {
 //   to: Array.isArray(data.to) ? data.to.join(',') : data.to,
 //   body: data.body,
 //   type: data.type,
 //   from: user?.twilio_phone,
 //   property: data.property,
 //   prop_full_info: JSON.stringify(data?.prop_full_info),
 //  }
 return (
  <Modal
   footer={null}
   onCancel={onCancel}
   visible={visible}
   title="SMS Preview"
  >
   <Spin spinning={isLoading} className="w-100">
    {preview && (
     <>
      <p className="mb-1">
       <strong>To</strong>:{preview.to}
      </p>
      <Divider />
      {preview.body && (
       <div
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
         __html: `${preview.body}`,
        }}
       />
      )}
     </>
    )}
   </Spin>
  </Modal>
 )
}

export default SmsPreview
