/* eslint-disable no-nested-ternary */
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { RootState, store } from 'store'
import { BtnAnch } from 'components/elements/global'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { baseTime } from 'lib'
import { Popover, Tooltip } from 'antd'
import { TSSBlockList } from 'components/shared/Common'
import { TGlobalTableStructure } from 'types'
import { ReactNode } from 'react'
import {
 TGetLeadFilter,
 TGetLeads,
 TLeadFilterBody,
 TLeadTabType,
} from '../types'
import CustomDateFilter from './CustomDateFilter'

type TRangeDate = {
 from_date?: string
 to_date?: string
}

interface TLeadListColumnProps {
 onClickLead?: (id?: any) => void
 selectedLeadsType?: 'all-leads' | 'my-leads'
 activeFilter?: TLeadFilterBody
 filter?: TGetLeadFilter
 tileKey: TLeadTabType
 onSubmitCustomDate?: (val: { value: any; range?: TRangeDate }) => void
 table_structure?: TGlobalTableStructure[]
}

interface THanldeRender extends TLeadListColumnProps {
 key: string
 account_type?: 'Brokerage' | 'Realtor'
 accessKey: 'name'
 item: TGlobalTableStructure
}

const getColumn = (v: THanldeRender): MDataTableColumns<TGetLeads> => {
 const convertFilterOptions = (
  filters?: {
   id?: number
   name?: string
  }[],
  key?: 'id' | 'name',
  format?: (label?: any) => string,
 ): { label?: string; value?: any }[] => {
  if (!filters) return []
  return filters?.map((v) => ({
   label: format ? format(v.name) : v.name,
   value: `${v[key ?? 'id'] || v.name}`,
  }))
 }

 const getValue = (obj: TGetLeads, key: any): string =>
  key.split('.').reduce((o: any, k: any) => (o || {})[k], obj)
 const getNestedValue = (obj: TGetLeads, path: any): string => {
  const result = path.split('.').reduce((acc: any, part: any) => {
   if (Array.isArray(acc)) {
    return acc.map((item) => item[part])
   }
   return acc && acc[part]
  }, obj)

  return Array.isArray(result) ? result.flat().join(', ') : result
 }

 const showArrayValues = (
  obj: TGetLeads,
  path: any,
  columnTitle?: string,
 ): ReactNode => {
  const result = path.split('.').reduce((acc: any, part: any) => {
   if (Array.isArray(acc)) {
    return acc.map((item) => item[part])
   }
   return acc && acc[part]
  }, obj)
  if (Array.isArray(result)) {
   if (result.length > 1) {
    return (
     <>
      <div className="d-flex">
       <span style={{ width: 'max-content' }} className="line-clamp-1">
        <Tooltip overlay={result?.[0]}>{result?.[0]}</Tooltip>
       </span>
       <span>
        <Popover
         overlayStyle={{ width: '300px' }}
         title={columnTitle ?? ''}
         trigger="click"
         content={
          <div>
           {/* {result?.map((i, n) => ( */}

           {result?.slice(1)?.map((i, n) => (
            <p key={n} className="mb-1">
             {n + 2}. {i} <br />
            </p>
           ))}
          </div>
         }
        >
         <span
          style={{
           border: '1px solid #ddd',
           marginLeft: '5px',
           padding: '0 4px',
           borderRadius: '3px',
           cursor: 'pointer',
           //  color: '#ED1C24',
          }}
         >
          +{result.length - 1}
         </span>
        </Popover>
       </span>
      </div>{' '}
     </>
    )
   }
   return (
    <Tooltip overlay={result?.[0]}>
     <span className="line-clamp-1">{result?.[0]}</span>
    </Tooltip>
   )
  }
  return result
  // return Array.isArray(result) ? result.flat().join(', ') : result
 }

 const {
  accessKey,
  key,
  account_type,
  onClickLead,
  activeFilter,
  filter,
  onSubmitCustomDate,
  tileKey,
  item: { isArray, column: columnName },
 } = v || {}

 switch (key) {
  case 'name':
   return {
    render: (_, rec, i) => (
     <BtnAnch onClick={() => onClickLead?.(i)}>
      <AvatarStatus
       title={`${rec?.[accessKey]} ${
        rec?.transferowner
         ? `(${account_type === 'Brokerage' ? 'Assigned To' : 'Assigned By'} ${
            rec?.transferowner
           })`
         : ''
       }`}
       showTooltip
      />
     </BtnAnch>
    ),
    size: 250,
    fixed: 'left',
   }
  case 'email_log':
  case 'lead_note':
  case 'text_log':
  case 'latest_new_listing_alert':
  case 'latest_neighbourhood_alert':
  case 'latest_open_house_alert':
  case 'latest_price_drop_alert':
  case 'task':
  case 'appointment':
  case 'plan':
  case 'background':
  case 'city':
  case 'activity':
   return {
    size: 200,
    ...(isArray ? {} : { ellipsis: true }),
    render: (_, rec: any) => {
     return isArray
      ? showArrayValues(rec, accessKey, columnName)
      : getValue(rec, accessKey)
    },
   }

  case 'last_activity_date':
   return {
    size: 200,
    render: (_, val) =>
     getNestedValue(val, accessKey)
      ? baseTime(getNestedValue(val, accessKey)).format('DD MMM YYYY HH:mm A')
      : '',
   }
  case 'phone_number':
  case 'email_address':
   return {
    render: (_, rec) => (
     <div
      className={
       key === 'phone_number'
        ? rec?.phone_number?.label === 'Bad'
          ? 'bad-label'
          : ''
        : rec?.email_address?.label === 'Bad'
        ? 'bad-label'
        : ''
      }
     >
      {/* {rec?.[accessKey]} */}
      {key === 'phone_number'
       ? getValue(rec, accessKey)
         ? `+1 ${getValue(rec, accessKey)}`
         : ''
       : getValue(rec, accessKey)}
     </div>
    ),
    size: 200,
    ellipsis: true,
    filter: {
     mode: 'single',
     options: convertFilterOptions(
      key === 'phone_number' ? filter?.phone_filter : filter?.email_filter,
      'name',
     ),
     showFilteCustomDot: true,
     filterDotExcludeValues: ['All'],
    },
   }
  case 'created_at':
   return {
    size: 200,
    render: (val) => baseTime(val).format('DD MMM YYYY'),
    ...(tileKey !== 'weeklead'
     ? {
        filter: {
         mode: 'single',
         customNode: (cProps) => (
          <CustomDateFilter
           value={activeFilter?.created}
           rangeValue={{
            from_date: activeFilter?.from_date,
            to_date: activeFilter?.to_date,
           }}
           onHide={cProps?.onHide}
           onClickSubmit={onSubmitCustomDate}
           data={convertFilterOptions(filter?.day_filter)}
          />
         ),
         showFilteCustomDot: true,
         filterDotExcludeValues: ['All'],
        },
       }
     : {}),
   }
  case 'tags':
  case 'stages':
   return {
    size: 250,
    render: (_, r) => (
     <TSSBlockList
      closable={key === 'tags'}
      type="lead"
      mode={key === 'tags' ? 'tag' : 'stage'}
      typeId={r.id ?? ''}
      data={key === 'tags' ? r.tags : r?.stages ? [r?.stages] : []}
      tooltiOptions={{
       getPopupContainer: (e) =>
        e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
         ?.parentNode as any,
      }}
     />
    ),
    filter: {
     searchPlaceholder: `Search ${key === 'tags' ? 'Tags' : 'Stages'}`,
     showSearch: true,
     showSelectAll: true,
     mode: 'multiple' as const,
     options: convertFilterOptions(
      key === 'tags' ? filter?.tag_filter : filter?.stage_filter,
     ),
    },
   }

  case 'sources':
   return {
    size: 200,
    ellipsis: false,

    render: (_, r) => {
     if (r.sources) {
      return (
       <TSSBlockList
        closable={false}
        type="lead"
        mode="source"
        typeId={r.id ?? ''}
        data={[{ name: r?.sources?.name }]}
        tooltiOptions={{
         getPopupContainer: (e) =>
          e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
           ?.parentNode as any,
        }}
       />
       //  <span className="line-clamp-1">
       //   <Tooltip
       //    title={r.sources?.name}
       //    getPopupContainer={(e) =>
       //     e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
       //      ?.parentNode as any
       //    }
       //   >
       //    {r.sources?.name}
       //   </Tooltip>
       //  </span>
      )
     }
    },
    filter: {
     searchPlaceholder: 'Search Sources',
     showSearch: true,
     showSelectAll: true,
     mode: 'multiple',
     options: convertFilterOptions(filter?.source_filter),
    },
   }
  case 'transferred_on':
   return {
    size: 130,
    ellipsis: true,
    render: (_, r) => (
     <div>
      {r?.transferowner
       ? baseTime(r?.transaction?.created_at).format('DD MMM YYYY')
       : ''}
     </div>
    ),
   }

  default:
   return {
    size: 200,
    render: (_, rec: any) => {
     return isArray
      ? showArrayValues(rec, accessKey, columnName)
      : getValue(rec, accessKey)
    },
   }
 }
}
const LeadListDynamicColumns = ({
 onClickLead,
 activeFilter,
 filter,
 selectedLeadsType,
 tileKey,
 onSubmitCustomDate,
 table_structure,
}: TLeadListColumnProps): MDataTableColumns<TGetLeads>[] => {
 const accountType = (store.getState() as RootState).auth.user?.account_type

 return (
  table_structure?.map((item) => ({
   title: item?.column,
   dataIndex: item?.variableCustom,
   dragId: item?.order,
   ...((['tags', 'stages'].includes(item?.variable) &&
    (selectedLeadsType === 'my-leads' || accountType === 'Realtor')) ||
   !['tags', 'stages'].includes(item?.variable) ||
   (item?.variable === 'transferred_on' &&
    selectedLeadsType === 'all-leads' &&
    accountType === 'Brokerage')
    ? getColumn({
       key: item?.variable,
       accessKey: item?.variableCustom as any,
       onClickLead,
       account_type: accountType,
       filter,
       selectedLeadsType,
       activeFilter,
       tileKey,
       onSubmitCustomDate,
       item,
      })
    : {}),
  })) ?? []
 )
}

export default LeadListDynamicColumns
