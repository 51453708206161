import { Card, Col, Row } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { TPropertyListingDetailWashRoom } from 'features/property'
import { FC, useState } from 'react'

const WashRoomsBlock: FC<{
 washrooms?: TPropertyListingDetailWashRoom[]
}> = ({ washrooms }) => {
 const [limit, setLimit] = useState(2)

 if (!washrooms) {
  return null
 }
 return (
  <div>
   <Card className="m-0" title="Wash Rooms">
    <div className="room-blocks" style={{ width: '100%' }}>
     <div className="room-block" style={{ width: '100%' }}>
      <Row>
       <Col xs={12} md={8}>
        <h5>Level</h5>
       </Col>
       <Col xs={12} md={8}>
        <h5>Ensuite</h5>
       </Col>
       <Col xs={12} md={8}>
        <h5>Pcs</h5>
       </Col>
      </Row>
     </div>
     {washrooms?.length > 0 &&
      washrooms?.slice?.(0, limit)?.map((i, n) => (
       <div className="room-block" key={n} style={{ width: '100%' }}>
        <Row>
         <Col xs={12} md={8}>
          <h5>{i?.level}</h5>
         </Col>
         <Col xs={12} md={8}>
          {i?.ensuite}
         </Col>
         <Col xs={12} md={8}>
          {i?.pcs}
         </Col>
        </Row>
       </div>
      ))}
    </div>
    {washrooms?.length > 2 && (
     <div>
      {washrooms?.length > limit ? (
       <BtnAnch
        className="btnstyle"
        onClick={() => setLimit(washrooms?.length || 0)}
       >
        View more
       </BtnAnch>
      ) : (
       <BtnAnch className="btnstyle" onClick={() => setLimit(2)}>
        View Less
       </BtnAnch>
      )}
     </div>
    )}
   </Card>
  </div>
 )
}

export default WashRoomsBlock
