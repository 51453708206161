/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { Row, Table } from '@tanstack/react-table'
import classNames from 'classnames'
import React, { DOMAttributes, FC } from 'react'
import { VirtualItem } from 'react-virtual'
import MTColGroup from '../MTColGroup'
import MTBodyRow from './MTBodyRow'
import MTEmptyBlock from './MTEmptyBlock'
import MTRowLoading from './MTRowLoading'

const MTBody: FC<{
 isLoading?: boolean
 isEmpty?: boolean
 table: Table<any>
 virtualRows: VirtualItem[]
 totalSize?: number
 hasMore?: boolean
 onScroll?: DOMAttributes<HTMLDivElement>['onScroll']
 scrollBodyRef?: React.LegacyRef<HTMLDivElement>
 onRowClick?: (v: any) => void
 columnOrder?: string[]
 isDraggableColumn: boolean
}> = ({
 table,
 virtualRows,
 totalSize = 0,
 hasMore,
 isEmpty,
 onRowClick,
 isLoading,
 columnOrder,
 isDraggableColumn,
}) => {
 const { rows } = table.getRowModel()
 const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
 const paddingBottom =
  virtualRows.length > 0
   ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
   : 0

 return (
  <table
   className={classNames({
    'h-100': isEmpty,
   })}
  >
   <MTColGroup isDraggableColumn={isDraggableColumn} />
   {isEmpty ? (
    <MTEmptyBlock columCount={table.getAllColumns().length || 3} />
   ) : (
    <tbody className="m-table-tbody">
     {paddingTop > 0 && (
      <tr>
       <td style={{ height: `${paddingTop}px` }} />
      </tr>
     )}
     {virtualRows.map((virtualRow, index) => {
      const row = rows[virtualRow.index] as Row<any>
      return (
       <MTBodyRow
        onRowClick={() => onRowClick?.(row.original)}
        row={row}
        key={`${row.id}-${index}`}
        columnOrder={columnOrder ?? []}
        isDraggableColumn={isDraggableColumn}
       />
      )
     })}

     {hasMore && isLoading && (
      <MTRowLoading
       rows={3}
       totalCols={table.getAllColumns().length - 1 || 3}
      />
     )}
     {paddingBottom > 0 && (
      <tr>
       <td style={{ height: `${paddingBottom}px` }} />
      </tr>
     )}
    </tbody>
   )}
  </table>
 )
}

export default MTBody
