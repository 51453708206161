import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { SVGBlockCall, SVGBlockMail } from 'components/icons'
import React, { FC, ReactNode } from 'react'

const SimpleBlockItem: FC<{
 actions?: ReactNode[]
 onClose?: () => void
 text?: any
 onDelete?: () => void
 onEdit?: () => void
 contentClassName?: string
 call?: boolean
 showSMS?: boolean
 onSendSMS?: () => void
 showEmail?: boolean
 onSendEmail?: () => void
 onView?: () => void
 onBlock?: () => void
 onUnblock?: () => void
 blockType?: 'sms' | 'email'
 tooltip?: {
  block?: string
  unblock?: string
  sendMail?: string
  sendSms?: string
  delete?: string
  edit?: string
  call?: string
 }
 contentNode?: ReactNode
}> = ({
 text,
 onDelete,
 onEdit,
 onClose,
 actions,
 contentClassName = '',
 call,
 showSMS,
 onSendSMS,
 onSendEmail,
 showEmail,
 onView,
 onBlock,
 onUnblock,
 blockType,
 tooltip,
 contentNode,
}) => (
 <div className="item-block-li">
  {contentNode ?? <span className={`content ${contentClassName}`}>{text}</span>}
  <span className="action">
   {actions}

   {call && (
    <BaseButton buttonStyle="outline" size="small" buttonType="primary">
     <a href={`tel:${text}`}>
      <i className="fas fa-phone" />
     </a>
    </BaseButton>
   )}
   {showSMS && (
    <BaseButton
     buttonStyle="outline"
     onClick={onSendSMS}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-comment-alt" />}
     tooltipText={tooltip?.sendSms ?? ''}
    />
   )}
   {showEmail && (
    <BaseButton
     buttonStyle="outline"
     onClick={onSendEmail}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-envelope" />}
     tooltipText={tooltip?.sendMail ?? ''}
    />
   )}
   {onView && (
    <BaseButton
     buttonStyle="outline"
     buttonType="danger"
     onClick={onView}
     size="small"
     danger
     icon={<i className="fas fa-eye" />}
    />
   )}
   {onEdit && (
    <BaseButton
     buttonStyle="outline"
     onClick={onEdit}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-edit" />}
     tooltipText={tooltip?.edit ?? ''}
    />
   )}
   {onUnblock && (
    <BaseButton
     buttonStyle="outline"
     onClick={onUnblock}
     size="large"
     buttonType="primary"
     icon={blockType === 'email' ? <SVGBlockMail /> : <SVGBlockCall />}
     tooltipText={tooltip?.unblock ?? ''}
    />
   )}
   {onBlock && (
    <BaseButton
     buttonStyle="outline"
     onClick={onBlock}
     size="large"
     buttonType="primary"
     icon={blockType === 'email' ? <SVGBlockMail /> : <SVGBlockCall />}
     tooltipText={tooltip?.block ?? ''}
    />
   )}
   {onDelete && (
    <BaseButton
     buttonStyle="outline"
     buttonType="danger"
     onClick={onDelete}
     size="small"
     danger
     icon={<i className="fas fa-trash" />}
     tooltipText={tooltip?.delete ?? ''}
    />
   )}
   {onClose && (
    <BaseButton
     buttonStyle="outline"
     buttonType="danger"
     onClick={onClose}
     size="small"
     danger
     icon={<i className="fas fa-times" />}
    />
   )}
  </span>
 </div>
)
export default SimpleBlockItem
