import { baseAPI } from 'api/baseApi'
import { globalToaster } from 'components/elements/global'
import { ApiResponce } from 'types'
import { AuthResponse, LoginType, TUser } from '../types'

export const authAPI = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  Login: mutation<any, LoginType>({
   query: (body) => ({
    url: 'user/login',
    method: 'post',
    body,
   }),
   transformResponse: (res: AuthResponse) => {
    if (res.status === 'success') {
     globalToaster({ type: 'success', content: res.message })
     return res.access_token
    }
   },
  }),
  profile: query<TUser, void>({
   query: () => ({
    url: 'user/getmyprofile',
    method: 'post',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  //   getProfile: mutation<TUser, void>({
  //    query: () => ({
  //     url: 'user/getmyprofile',
  //     method: 'post',
  //    }),
  //    transformResponse: (res: ApiResponce) => res.data,
  //   }),
  logout: mutation<void, void>({
   query: () => ({
    url: 'user/logout',
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => {
    if (res.status === 'success') {
     globalToaster({ type: 'success', content: res.message })
    }
   },
  }),
  passwordReset: mutation<void, { email?: string }>({
   query: (body) => ({
    url: 'user/forgot-password',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => {
    if (res.status === 'success') {
     globalToaster({ type: 'success', content: res.message })
    }
   },
  }),
 }),
})

export const {
 useLoginMutation,
 useProfileQuery,
 useLazyProfileQuery,
 //  useGetProfileMutation,
 useLogoutMutation,
 usePasswordResetMutation,
} = authAPI
