import { Modal } from 'antd'
import { globalToaster } from 'components/elements/global'
import React, { FC } from 'react'
import {
 EmailShareButton,
 FacebookShareButton,
 LinkedinShareButton,
 TwitterShareButton,
 WhatsappShareButton,
} from 'react-share'

const ShareBlock: FC<{
 show: boolean
 onHide: () => void
 link?: any
 title?: string
}> = ({ show, onHide, link, title }) => {
 const copy = async () => {
  await navigator.clipboard.writeText(link)
  globalToaster({ type: 'success', content: 'Link copied' })
 }

 return (
  <Modal
   title={title}
   visible={show}
   footer={false}
   onCancel={onHide}
   className="share-block-modal"
   centered
  >
   <ul className="share-ui-list">
    <li>
     <FacebookShareButton url={link}>
      <i className="fab fa-facebook-f" />
      Facebook
     </FacebookShareButton>
    </li>
    <li>
     <EmailShareButton url={link}>
      <i className="far fa-envelope" />
      Email
     </EmailShareButton>
    </li>
    <li>
     <TwitterShareButton url={link}>
      <i className="fab fa-twitter" />
      Twitter
     </TwitterShareButton>
    </li>
    <li>
     <WhatsappShareButton url={link}>
      <i className="fab fa-whatsapp" />
      Whatsapp
     </WhatsappShareButton>
    </li>
    <li>
     <LinkedinShareButton url={link}>
      <i className="fab fa-linkedin" />
      LinkedIn
     </LinkedinShareButton>
    </li>
    <li>
     <button type="button" onClick={copy}>
      {' '}
      <i className="fas fa-copy" /> Copy LInk
     </button>
    </li>
   </ul>
  </Modal>
 )
}

export default ShareBlock
