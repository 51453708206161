/* eslint-disable no-nested-ternary */
import { Row, Radio, Select, Modal } from 'antd'
import {
 useBDayTempCreateMutation,
 useGetEmailTemplateNamesQuery,
 useGetTextTemplateNamesQuery,
} from 'features/settings/api'
import { TBirthdayWishes } from 'features/settings/types'
import { FC, useEffect, useMemo, useState } from 'react'

const BirthDayWishesModal: FC<{
 onCancel: () => void
 visible: boolean
 templateMode: 'Email' | 'SMS'
 type?: string
 data?: TBirthdayWishes
 onSuccess?: () => void
 showItems?: 'both' | 'email' | 'sms'
}> = ({
 onCancel,
 visible,
 templateMode,
 type,
 data,
 onSuccess,
 showItems,
}) => {
 const [selectId, setSelectId] = useState<null | string | number | undefined>(
  null,
 )

 const [mode, setMode] = useState<'Email' | 'SMS'>(templateMode ?? 'Email')

 useEffect(() => {
  if (mode === 'Email') {
   setSelectId(data?.email?.id)
  } else if (mode === 'SMS') {
   setSelectId(data?.sms?.id)
  } else {
   setSelectId(null)
  }
 }, [data, mode])

 const { data: smsData, isLoading: textLoading } = useGetTextTemplateNamesQuery(
  {},
  { skip: mode === 'Email' },
 )
 const { data: emailData, isLoading: emailLoading } =
  useGetEmailTemplateNamesQuery({}, { skip: mode === 'SMS' })

 const [updateBdayTemp, { isLoading: bDayTempLoading }] =
  useBDayTempCreateMutation()

 const flatData = useMemo(() => {
  if (mode === 'Email') {
   return emailData
  }
  return smsData
 }, [smsData, emailData, mode])

 useEffect(() => {
  if (templateMode) {
   setMode(templateMode)
  }
 }, [templateMode])

 const handleOkay = async () => {
  await updateBdayTemp({
   template_id: selectId as any,
   type: mode.toLowerCase() as any,
  }).unwrap()
  setSelectId(null)
  onCancel?.()
  onSuccess?.()
 }

 const titlePrefix = useMemo(() => {
  if (type) {
   return type
  }
  if ((mode === 'Email' && data?.email) || (mode === 'SMS' && data?.sms)) {
   return 'Change'
  }
  return 'Add'
 }, [data, mode, type])

 const filterOption = (
  input: string,
  option?: { label: string; value: string },
 ): boolean => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

 return (
  <Modal
   {...{ onCancel }}
   visible={visible}
   title={`${titlePrefix} ${mode} Template`}
   bodyStyle={{ padding: 0 }}
   onOk={handleOkay}
   className="b-day-wish-form"
   okButtonProps={{ loading: bDayTempLoading }}
  >
   <div className="p-3 ">
    <Row className="menu">
     <Radio.Group
      onChange={({ target }) => setMode(target.value)}
      value={mode}
      style={{ marginBottom: 8 }}
     >
      {showItems === 'both' ? (
       <>
        <Radio.Button value="Email">Email</Radio.Button>
        <Radio.Button value="SMS">SMS</Radio.Button>
       </>
      ) : showItems === 'email' ? (
       <Radio.Button value="Email">Email</Radio.Button>
      ) : (
       <Radio.Button value="SMS">SMS</Radio.Button>
      )}
     </Radio.Group>
    </Row>

    <span>{mode} templates</span>
    <Select
     loading={textLoading || emailLoading}
     value={!(textLoading || emailLoading) ? (selectId as any) : undefined}
     style={{ width: '100%' }}
     onChange={(e) => setSelectId(e)}
     filterOption={filterOption}
     options={flatData?.map((i, n) => ({
      value: i?.id ?? '',
      label: i?.name ?? '',
     }))}
     placeholder={`Select ${mode} templates`}
     showSearch
    />
   </div>
  </Modal>
 )
}

export default BirthDayWishesModal
