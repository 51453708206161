import { Card } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { TPropertyListingDetailHistories } from 'features/property'
import { baseTime } from 'lib'
import { FC, useState } from 'react'
import { utils } from 'utils'

const ListingHistoryBlock: FC<{
 histories?: TPropertyListingDetailHistories[]
}> = ({ histories }) => {
 const [limit, setLmit] = useState(2)
 if (!histories) {
  return null
 }
 return (
  <div>
   <Card title="Listing History" className="m-0">
    <div className="history-blocks">
     {histories?.slice?.(0, limit)?.map?.((i, n) => (
      <div className="history-single-blocks" key={n}>
       {i?.ml_num && (
        <div>
         <span>MLS® # : </span>
         <span className="label-values">{i?.ml_num}</span>
        </div>
       )}

       {!i?.price && !i?.status ? null : (
        <div className="price-and-status">
         <span className="price">{utils.pricefy(i?.price)}</span>
         {i?.status && <span className="status">{i.status}</span>}
        </div>
       )}
       {i?.date && (
        <div>
         <span>List : </span>
         <span className="label-values">
          {baseTime(i?.date).format('D MMM YYYY')}
         </span>
        </div>
       )}
       {i?.brokerage && (
        <div>
         <span>Listed Brokerage : </span>
         <span className="label-values" title={i?.brokerage}>
          {i?.brokerage}
         </span>
        </div>
       )}
      </div>
     ))}
    </div>
    {histories?.length > 2 && (
     <div>
      {histories?.length > limit ? (
       <BtnAnch className="btnstyle" onClick={() => setLmit(histories?.length)}>
        View More
       </BtnAnch>
      ) : (
       <BtnAnch className="btnstyle" onClick={() => setLmit(2)}>
        View less
       </BtnAnch>
      )}
     </div>
    )}
   </Card>
  </div>
 )
}

export default ListingHistoryBlock
