import { createApi } from '@reduxjs/toolkit/query/react'
import { globalToaster } from 'components/elements/global'
import { ApiResponce } from 'types'
import { API_PRECON_LISTNG } from 'config'
import dynamicAxiosBaseQuery from './dynamicAxiosBaseQuery'

export const baseTransformResponse = (resp: ApiResponce) => {
 if (resp.status === 'error' || resp.status === false) {
  globalToaster({ type: 'error', content: resp.message })
 } else {
  globalToaster({ type: 'success', content: resp.message })
 }
}

export const secondAPI = createApi({
 reducerPath: 'secondApi',
 baseQuery: dynamicAxiosBaseQuery(),
 refetchOnFocus: false,
 endpoints: ({ query, mutation }) => ({}),
})

export const preConAPI = createApi({
 reducerPath: 'preConAPI',
 baseQuery: dynamicAxiosBaseQuery('precon'),
 refetchOnFocus: false,
 endpoints: ({ query, mutation }) => ({}),
})
