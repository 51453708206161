import React, { FC } from 'react'
import ActionPlanForm from '../../components/action-plan/ActionPlanForm'
import ActionFormProvider from '../../providers/ActionFormProvider'

const Add: FC<{ onCancel: () => void }> = ({ onCancel }) => (
 <ActionFormProvider>
  <ActionPlanForm onCancel={onCancel} />
 </ActionFormProvider>
)

export default Add
