import { Card, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import BaseEmptyBlock from 'components/elements/BaseEmpty/BaseEmptyBlock'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useAppControl from 'hooks/useAppControl'
import { TInboxEmail, TInboxItemMsgs } from '../types'
import MessageBlockPanel from './MessageBlockPanel'
import { groupMailByDate } from '../functions'

const EmailMessageList: FC<{
 refetch?: () => void
 flatData?: TInboxItemMsgs[]
 hasMore?: boolean
 fetchMore?: () => void
}> = ({ flatData, refetch, fetchMore, hasMore }) => {
 const { updateSendEmailWidget } = useAppControl()
 const params = useParams()

 const data = useMemo(() => groupMailByDate('emails', flatData), [flatData])

 if (!data || (data && data.length === 0)) {
  return <BaseEmptyBlock description="Email Empty!" />
 }

 return (
  <Card
   className="messge-list-inner"
   type="inner"
   title={data?.[0]?.emails?.[0]?.to_name ?? ''}
   extra={
    <Space>
     <BaseButton
      onClick={() => {
       updateSendEmailWidget({
        show: true,
        widgetFrom: 'lead',
        to: data?.[0]?.emails?.[0]?.to_mail,
       })
      }}
      buttonType="primary"
      icon={<i className="fas fa-envelope me-1" />}
      size="small"
     >
      Compose
     </BaseButton>
     <Link
      to={{
       hash: `lead-details/${params.id}/profile`,
      }}
     >
      <BaseButton
       buttonType="primary"
       buttonStyle="outline"
       icon={<i className="fas fa-user me-1" />}
       size="small"
      >
       Profile
      </BaseButton>
     </Link>
    </Space>
   }
  >
   <InfinityPagination
    // isReverse
    useWindow={false}
    hasMore={hasMore}
    loadMore={fetchMore}
   >
    {data?.map((item, i) => (
     <div key={i}>
      <div className="history-date">{item?.date}</div>
      {item.emails && item.emails.length > 0 && (
       <div className="msg-blk-panel-list">
        {item.emails.map((v, i) => (
         <MessageBlockPanel
          refetch={refetch}
          onClickReply={() => {
           updateSendEmailWidget({
            widgetFrom: 'lead',
            show: true,
            to: ['Send', 'send'].includes(v?.send_type ?? '') ? v?.to : v?.from,
            replyData: {
             subject: `${!v?.subject?.startsWith('Re:') ? 'Re: ' : ''}${
              v.subject
             }`,
             to: ['Send', 'send'].includes(v?.send_type ?? '')
              ? v?.to_mail
              : v?.from_mail,
             body: v?.body,
            },
            mode: 'REPLY',
           })
          }}
          onClickForward={() => {
           updateSendEmailWidget({
            widgetFrom: 'lead',
            show: true,
            forwardData: {
             body: v?.body,
             from: v?.from_mail,
             subject: `${!v?.subject?.startsWith('Fwd:') ? 'Fwd: ' : ''} ${
              v?.subject
             }`,
             to: v?.to_mail,
             attachment: v.attachment,
            },
            mode: 'FORWARD',
           })
          }}
          key={i}
          item={v}
         />
        ))}
       </div>
      )}
     </div>
    ))}
   </InfinityPagination>
  </Card>
 )
}

export default EmailMessageList
