import { Modal, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import EmailTemplateViewModal from 'components/shared/Modals/EmailTemplateViewModal'
import { useDeleteEmailTemplatesMutation } from 'features/settings/api'
import EmailTemplateForm from 'features/settings/components/EmailTemplateForm'
import { TGetEmailTemplate } from 'features/settings/types'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useState } from 'react'
import useSelectAll from 'hooks/useSelectAll'
import useFetchPage from 'hooks/useFetchPage'
import { MDataTable } from 'components/shared/MDataTable'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import Edit from './Edit'

const List: FC = () => {
 const [searchKey, setSearchkey] = useState('')
 const [editFetching, setEditFetching] = useState(false)

 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetEmailTemplate>('get-email-templates', {
  method: 'get',
  params: searchKey ? `search=${searchKey}` : '',
  customApiKey: `get-email-templates-${searchKey}`,
  onSettled: () => {
   if (editFetching) {
    setEditFetching(false)
   }
  },
 })
 const {
  selectedKeys,
  handleBody,
  onSelectRow,
  rowSelectionState,
  selectAll,
  setSelectedRow,
  unSelected,
 } = useSelectAll(JSON.stringify({ search: searchKey }), flatData)

 const [mailActionModal, setMailActionModal] = useState<{
  show: 'edit' | 'add' | 'view' | false
  data: null | number | TGetEmailTemplate
 }>({ data: null, show: false })
 const [deleteEmailTemplates] = useDeleteEmailTemplatesMutation()

 const handleDelete = (): void => {
  globalConfirm({
   content: 'Are you sure you want to delete the selected Email templates?',
   onOk: async () => {
    await deleteEmailTemplates(handleBody({ id: selectedKeys })).unwrap()
    refetch?.()
   },
  })
 }

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 const Columns: MDataTableColumns<TGetEmailTemplate>[] = [
  {
   title: 'Name',
   dataIndex: 'name',
   size: 200,
   //    render: (_, r) => `${r?.name} (${r?.owner})`,
  },
  {
   title: 'Created By',
   dataIndex: 'owner',
   size: 150,
  },
  {
   title: 'Subject',
   dataIndex: 'subject',
   size: 300,
  },
  {
   title: 'Action',
   size: 130,
   fixed: 'right',
   render: (_, record) => (
    <Space>
     <BaseButton
      type="link"
      onClick={() => setMailActionModal({ show: 'view', data: record })}
      tooltipText="View Email Template"
      shape="circle"
      size="small"
      buttonType="primary"
      icon={<i className="fas fa-eye" />}
     />
     <BaseButton
      type="link"
      onClick={() => setMailActionModal({ show: 'edit', data: record.id })}
      tooltipText="Edit Email Template"
      shape="circle"
      size="small"
      buttonType="info"
      icon={<i className="fas fa-edit" />}
     />
    </Space>
   ),
  },
 ]

 const commonModalProps = {
  onCancel: () => setMailActionModal({ show: false, data: null }),
  visible: true,
  width: '100vw',
  centered: true,
  className: 'page-size-modal',
 }

 return (
  <ListContentLayout
   title="Email Templates"
   actions={
    <Space>
     <BaseButton
      shape="circle"
      buttonType="gamma"
      tooltipText="Add"
      onClick={() => setMailActionModal({ show: 'add', data: null })}
      icon={<i className="fas fa-plus" />}
     />
     <BaseButton
      disabled={!selectedKeys.length}
      shape="circle"
      buttonType="beta"
      tooltipText="Delete"
      onClick={handleDelete}
      icon={<i className="fas fa-trash" />}
     />
    </Space>
   }
  >
   <MDataTable
    totalData={totalCount || 1000}
    search={{
     placeholder: 'Enter Email Template Name',
     onSearch: setSearchkey,
     show: true,
    }}
    onSelectRow={onSelectRow}
    rowSelectionState={{
     ...rowSelectionState,
    }}
    loading={isLoading || (editFetching && isRefetching)}
    isFetching={isRefetching}
    hasMore={hasMore}
    fetchMore={() => {
     fetchMore()
    }}
    data={flatData}
    columns={Columns}
   />

   {mailActionModal?.show === 'view' && mailActionModal?.data && (
    <EmailTemplateViewModal
     data={mailActionModal.data as TGetEmailTemplate}
     visible
     onCancel={() => setMailActionModal({ show: false, data: null })}
    />
   )}
   {mailActionModal?.show === 'add' && (
    <Modal title="Add Email Template" {...commonModalProps}>
     <EmailTemplateForm
      onSuccess={() => {
       setMailActionModal({ show: false, data: null })
       refetch?.()
      }}
      mode="ADD"
     />
    </Modal>
   )}

   {mailActionModal?.show === 'edit' && (
    <Modal title="Edit Email Template" {...commonModalProps}>
     <Edit
      id={`${mailActionModal.data}`}
      onCancel={() => {
       setMailActionModal({ show: false, data: null })
       refetch?.()
       setEditFetching(true)
      }}
     />
    </Modal>
   )}
  </ListContentLayout>
 )
}

export default List
