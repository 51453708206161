import React, { FC } from 'react'

const PreDescription: FC<{ data?: { key?: string; value?: string } }> = ({
 data,
}) => {
 return (
  <div>
   <div className="description">
    <h3 className="common-head">{data?.key}</h3>
    {/* <p dangerouslySetInnerHTML={{ __html: `${data?.value}` }} /> */}
    <p>{data?.value}</p>
   </div>
  </div>
 )
}

export default PreDescription
