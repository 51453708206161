import { Avatar, Col, Modal, Row, Card, Tooltip } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import useAuth from 'hooks/useAuth'
import React, { FC } from 'react'
import { BtnAnch } from 'components/elements/global'
import Utils from 'utils/helper'
import { baseTime } from 'lib'
import { TGetLeads } from '../types'

const SelectedLeadModal: FC<{
 visible?: boolean
 onCancel?: () => void
 data: TGetLeads[]
 handleRemove?: (v: TGetLeads) => void
 onClearAll?: () => void
 isRealtor?: boolean
}> = ({ data, handleRemove, isRealtor, onCancel, onClearAll, ...rest }) => {
 const { user } = useAuth()

 return (
  <Modal
   title={
    <div className="d-flex justify-content-between align-items-center">
     <span>Selected Leads</span>
     <BaseButton
      className="me-5"
      onClick={() => {
       onClearAll?.()
       onCancel?.()
      }}
     >
      Clear All
     </BaseButton>
    </div>
   }
   onCancel={onCancel}
   {...rest}
   footer={null}
   width={1058}
  >
   <div
    style={{
     maxHeight: '400px',
     overflow: 'auto',
     padding: '3px',
    }}
   >
    <Row gutter={[6, 6]}>
     {data &&
      data?.map((item, index) => (
       <Col span={12} key={index}>
        <div className="pro--card">
         <BtnAnch
          className="close-icon"
          onClick={() => {
           if (data?.length === 1) {
            onClearAll?.()
            onCancel?.()
           }
           handleRemove?.(item)
          }}
         >
          <i className="fal fa-times" />
         </BtnAnch>
         <Card style={{ cursor: 'default' }}>
          <div className="list-modal">
           <div>
            <Avatar
             icon={Utils.shortName(item?.name || '')}
             size={70}
             className="bg-primary"
             style={{ fontSize: '1.3rem' }}
            />
           </div>

           <ul style={{ overflow: 'hidden' }}>
            <li className="list-modal-title">{`${item?.name} ${
             !isRealtor && item?.transferowner
              ? `(${
                 user?.account_type === 'Brokerage'
                  ? 'Assigned To'
                  : 'Assigned By'
                } ${item?.transferowner})`
              : ''
            }`}</li>
            <li className="list-modal-content">
             <span className="d-flex">
              <label htmlFor="" style={{ color: '#777' }}>
               Stage:
              </label>
              <span className="ps-1 text-primary line-clamp-1">
               <Tooltip placement="topLeft" title={item?.stages?.name}>
                {item?.stages?.name}
               </Tooltip>
              </span>
             </span>
            </li>
            <li className="list-modal-content">
             <span className="d-flex">
              <label htmlFor="" style={{ color: '#777' }}>
               Source:
              </label>
              <span className="ps-1 text-primary line-clamp-1">
               <Tooltip placement="topLeft" title={item?.sources?.name}>
                {item?.sources?.name}
               </Tooltip>
              </span>
             </span>
            </li>
           </ul>
          </div>
          <div className="list-modal-date">
           Created At: {baseTime(item?.created_at).format('DD MMM YYYY')}
          </div>
         </Card>
        </div>
       </Col>
      ))}
    </Row>
   </div>
  </Modal>
 )
}

export default SelectedLeadModal
