import LeadDetailPage from 'features/leads/routes/details'
import useHashRouter from 'hooks/useHashRouter'
import OverlaySection from 'layout/OverlaySection'
import { queryClient } from 'lib/react-query'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'

const OverlayContextWrapper: FC = () => {
 const { state } = useLocation()
 const { isHash, params } = useHashRouter()
 const naviagate = useNavigate()
 if (!isHash) return null
 return (
  <OverlaySection>
   <LeadDetailPage
    onClose={() => {
     naviagate({ hash: '' }, { state })
     queryClient.invalidateQueries('get-leads')
    }}
    leadId={params?.[0]}
   />
  </OverlaySection>
 )
}

export default OverlayContextWrapper
