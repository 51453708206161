import { Result } from 'antd'
import ActivitySectionLayout from 'features/leads/components/ActivitySectionLayout'
import React, { FC, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import useHashRouter from 'hooks/useHashRouter'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TLeadDetailType } from 'features/leads/types'
import AllActivity from './AllActivity'
import CallActivity from './Call'
import EmailActivity from './email'
import Enquiry from './Enquiry'
import NoteActivity from './note'
import TextActivity from './text'

const ActivitySection: FC = () => {
 const { params } = useHashRouter()
 const { from, leadId } = useLeadDetails()
 const navigate = useNavigate()
 const [activeMenu, setActiveMenu] = useState<TLeadDetailType>('all')

 const buildScreen = (type?: TLeadDetailType) => {
  switch (type) {
   case 'all':
    return <AllActivity />
   case 'textlog':
    return <TextActivity />
   case 'emaillog':
    return <EmailActivity />
   case 'calllog':
    return <CallActivity />
   case 'note':
    return <NoteActivity />
   case 'web':
    return <Enquiry />
   default:
    return <AllActivity />
  }
 }

 useEffect(() => {
  if (params?.length === 2 && params[1] === 'timeline') {
   navigate({ hash: `lead-details/${leadId}/timeline/all` })
   setActiveMenu('all')
  } else if (params?.length === 3) {
   setActiveMenu(params?.[2] as TLeadDetailType)
  }
 }, [leadId, params, params?.length])

 return (
  <ActivitySectionLayout
   onSelectMenu={(v) => {
    if (from === 'lead') {
     setActiveMenu(v)
    } else {
     navigate({ hash: `lead-details/${leadId}/timeline/${v}` })
    }
   }}
   activeId={activeMenu}
  >
   {buildScreen(activeMenu)}
  </ActivitySectionLayout>
 )
}

export default ActivitySection
