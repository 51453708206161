import { Row, Modal } from 'antd'
import { TBirthdayWishes } from 'features/settings/types'
import React, { FC } from 'react'

const BDayTempViewModal: FC<{
 onCancel?: () => void
 mode: 'SMS' | 'Email'
 data?: TBirthdayWishes
}> = ({ onCancel, mode, data }) => {
 const type = mode === 'Email' ? 'email' : 'sms'
 return (
  <Modal onCancel={onCancel} visible title={`${mode} Template`} footer={null}>
   <h3>{data?.[type]?.name}</h3>
   <p>{mode === 'SMS' ? data?.sms?.body : data?.email?.subject}</p>
   {(data?.email?.attachment?.length ?? 0) > 0 && type === 'email' && (
    <>
     <p>Attachments</p>
     <Row gutter={[10, 10]} className="attachments-wrapper">
      {data?.email?.attachment?.map((i, n) => {
       return (
        <a
         target={
          ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
           `${i.split('.').at(-1)}`,
          )
           ? '_blank'
           : '_self'
         }
         href={i}
         key={n}
         rel="noreferrer"
        >
         <div className="attachment">
          <i className="fas fa-file" />
         </div>
        </a>
       )
      })}
     </Row>
    </>
   )}
  </Modal>
 )
}

export default BDayTempViewModal
