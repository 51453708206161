/* eslint-disable react/jsx-no-useless-fragment */
import { Card, Col } from 'antd'
import React, { FC } from 'react'

const PreFeatures: FC<{ data?: { key?: string; value?: string }[] }> = ({
 data,
}) => {
 return (
  <>
   {data?.map?.((item, n) => (
    <Col span={24} key={n}>
     <Card className="m-0">
      <div className="description">
       <h3 className="common-head">{item?.key}</h3>
       <p dangerouslySetInnerHTML={{ __html: `${item?.value}` }} />
      </div>
     </Card>
    </Col>
   ))}
  </>
 )
}

export default PreFeatures
