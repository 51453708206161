import { Form, Modal } from 'antd'
import { FC, useEffect } from 'react'
import { TRealtorCommonForm } from '../types'
import { useRealtorTransferRequestMutation } from '../api'
import SWToBarRealtor from './elements/SWToBarRealtor'

const AddRealtorForm: FC<TRealtorCommonForm & { refetch: () => void }> = ({
 onCancel,
 visible,
 refetch,
}) => {
 const [realtorTransferRequest, { isLoading }] =
  useRealtorTransferRequestMutation()
 const [form] = Form.useForm<{ realtor_id: number[] }>()

 const onSubmit = async () => {
  const values = await form.validateFields()
  await realtorTransferRequest({
   realtor_id: JSON.stringify(values?.realtor_id?.map((i: any, j) => i?.id)),
  }).unwrap()
  onCancel?.()
  refetch?.()
  form.resetFields()
 }

 return (
  <Modal
   confirmLoading={isLoading}
   onOk={onSubmit}
   okText="Submit"
   title="Add Realtor"
   onCancel={onCancel}
   visible={visible}
  >
   <Form initialValues={{ realtor_id: [] }} form={form} layout="vertical">
    <Form.Item
     rules={[{ required: true, message: 'Realtor field is required' }]}
     name="realtor_id"
     label="Realtors"
     className="sw-bar-realtor-form-item"
    >
     <SWToBarRealtor />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddRealtorForm
