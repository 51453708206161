/* eslint-disable no-nested-ternary */
import { Button, DatePicker, Empty, Menu, Radio, Space } from 'antd'
import { MTFilterOption } from 'components/shared/MDataTable/type'
import { baseTime } from 'lib'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

type TRangeDate = {
 from_date?: string
 to_date?: string
}
export type CustomDateSubmitData = {
 value: any
 range?: TRangeDate
}

const CustomDateFilter: FC<{
 value?: string
 rangeValue?: TRangeDate
 onClickSubmit?: (values: CustomDateSubmitData) => void
 onHide?: () => void
 selectedValue?: any
 data?: MTFilterOption[]
 onChangeRange?: (val?: TRangeDate) => void
}> = ({ data, onHide, onClickSubmit, rangeValue, value }) => {
 const [selected, setSelected] = useState(value || '')
 const [selectedRange, setRange] = useState<[any, any] | null>()
 const handleSubmit = () => {
  const submitData: CustomDateSubmitData = {
   value: selected,
  }
  if (selected === 'Custom' && selectedRange && selectedRange.length > 0) {
   const fromDate = baseTime(selectedRange?.[0]).startOf('day').format()
   const toDate = baseTime(selectedRange?.[1]).endOf('day').format()
   submitData.range = {
    from_date: baseTime.utc(fromDate).format(),
    to_date: baseTime.utc(toDate).format(),
   }
  }
  onClickSubmit?.(submitData)
  onHide?.()
 }

 const disabledBtn = useMemo(() => {
  if (selected) {
   if (selected === 'Custom') {
    return !(selectedRange && selectedRange.length === 2)
   }
   return false
  }
  return true
 }, [selected, selectedRange])

 useEffect(() => {
  if (rangeValue?.from_date && rangeValue?.to_date) {
   setRange([baseTime(rangeValue.from_date), baseTime(rangeValue.to_date)])
  }
 }, [rangeValue?.from_date, rangeValue?.to_date])

 useEffect(() => {
  if (value) {
   setSelected(value)
  }
 }, [value])

 const filterBodyRef: any = useRef(null)

 const menuRef: any = useRef(null)
 const childList: NodeList = menuRef?.current?.menu?.list?.childNodes

 //  var objDiv = document.getElementById("your_div");
 // objDiv.scrollTop = objDiv.scrollHeight;

 useEffect(() => {
  if (
   selected === 'Custom' &&
   (childList?.length ?? 0) === (data?.length ?? 0) + 1
  ) {
   filterBodyRef.current.scrollTop = filterBodyRef?.current?.scrollHeight
  }
 }, [selected])

 return (
  <div className="mt-filter-block custom-date" style={{ width: '260px' }}>
   <div className="mt-filter-block-body" ref={filterBodyRef}>
    {data && data.length > 0 ? (
     <Menu
      onClick={(e) => {
       setSelected(e.key)
      }}
      selectable={false}
      selectedKeys={[selected]}
      ref={menuRef}
     >
      {data?.map((item, i) => (
       <Menu.Item
        key={item.label}
        icon={<Radio checked={selected === item.label} type="" />}
       >
        {/* {item.label === '1'
         ? '1 Day'
         : item.label === 'Custom'
         ? 'Custom Date'
         : item.label !== 'All'
         ? `${item.label} Days`
         : item.label} */}
        {item?.label === 'All'
         ? 'All Days'
         : item?.label === '1'
         ? `Within ${item?.label} Day`
         : item?.label === 'Custom'
         ? 'Custom Days'
         : `Within ${item?.label} Days`}
       </Menu.Item>
      ))}

      {selected === 'Custom' && (
       <li className="ant-menu-item">
        <DatePicker.RangePicker
         disabledDate={(c) => c && c > baseTime()}
         clearIcon={false}
         format="DD-MM-YYYY"
         value={selectedRange}
         onChange={(date) => {
          if (date && date.length > 0) {
           setRange(date)
          }
         }}
        />
       </li>
      )}
     </Menu>
    ) : (
     <Empty description="Data Not Found!" />
    )}
   </div>
   <div className="mt-filter-block-footer">
    <Space>
     <Button size="small" onClick={onHide}>
      Cancel
     </Button>
     <Button
      disabled={disabledBtn}
      size="small"
      onClick={handleSubmit}
      type="primary"
     >
      Submit
     </Button>
    </Space>
   </div>
  </div>
 )
}

export default CustomDateFilter
