import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import Integration from 'features/integration/routes/Integration'
import EmailTemplate from './email-templates'

import Signature from './signature'
import TagStageSource from './tags-stages-sources'
import TextTemplate from './text-templates'
import ResetPassword from './reset-password'
import ListingSettings from './listing-settings'
import BirthDayWishes from './birthday-wishes/BirthDayWishes'

export const SettingsRoute: FC = () => {
 return (
  <Routes>
   <Route
    path=""
    element={<Navigate to="/admin/settings/tags-stages-sources" replace />}
   />
   <Route path="tags-stages-sources" element={<TagStageSource />} />
   <Route path="signature/*" element={<Signature />} />
   <Route path="reset-password" element={<ResetPassword />} />
   <Route path="text-templates/*" element={<TextTemplate />} />
   <Route path="email-templates/*" element={<EmailTemplate />} />
   <Route path="birthday-wishes" element={<BirthDayWishes />} />
   <Route path="integration" element={<Integration />} />
   <Route path="listing-settings" element={<ListingSettings />} />
  </Routes>
 )
}
