import { Col, Row } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import { AddAppointmentModal } from 'components/shared/Modals'
import { leadApi, useDeleteLeadEventMutation } from 'features/leads/api'
import { TAddLeadEventBody, TAppointmentType } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import { TModalState } from 'types'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

const AppointmentsBlock: FC<{
 appointments?: TAppointmentType[]
 leadId?: any
 isTransferred?: boolean
}> = ({ appointments, leadId, isTransferred }) => {
 const [showAppointment, setShowAppointment] = useState<
  TModalState<TAddLeadEventBody>
 >({ show: false, mode: 'ADD' })

 const d = useAppDispatch()

 const [deleteLeadEvent] = useDeleteLeadEventMutation()

 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))

 const HandleDeleteLeadAppointment = (id?: any) => {
  globalConfirm({
   content: 'Are you sure you want to delete the appointment?',
   onOk: async () => {
    await deleteLeadEvent({ id, feed: 'appointment' }).unwrap()
    refreshLead()
   },
  })
 }
 return (
  <div>
   <ProInnerCard
    emptyText="Appointments is Empty"
    isEmpty={!appointments || appointments?.length === 0}
    onCLickAdd={
     isTransferred
      ? () =>
         setShowAppointment({
          show: true,
          mode: 'ADD',
         })
      : undefined
    }
    title="Appointments"
   >
    {/* <Row gutter={[15, 15]}>
            {alerts &&
              alerts.map((item, i) => (
                <Col span={12} key={i}>
                  <SimpleBlockItem
                    onClose={() => handleRemoveAlert(item.id)}
                    text={item.name}
                  />
                </Col>
              ))}
          </Row> */}
    <Row gutter={[15, 15]}>
     {appointments &&
      appointments.map((item, i) => (
       <Col span={24} key={i}>
        <SimpleBlockItem
         actions={
          isTransferred
           ? [
              <BaseButton
               key={1}
               buttonStyle="outline"
               onClick={() => {
                setShowAppointment({
                 show: true,
                 data: {
                  reminder: item.reminder,
                  feed: 'appointment',
                  from_date: item?.from_date,
                  from_time: item?.from_time,
                  to_date: item?.to_date,
                  to_time: item?.to_time,
                  title: item.title,
                  description: item.description,
                  location: item.location,
                  lead_id: item.lead_id,
                  id: item.id,
                 },
                 mode: 'EDIT',
                })
               }}
               size="small"
               buttonType="primary"
               icon={<i className="fas fa-edit" />}
              />,
              // <BaseButton
              //   key={2}
              //   buttonStyle="outline"
              //   // onClick={onEdit}
              //   size="small"
              //   buttonType="success"
              //   icon={<i className="fas fa-check" />}
              // />,
              <BaseButton
               key={3}
               buttonStyle="outline"
               onClick={() => HandleDeleteLeadAppointment?.(item.id)}
               size="small"
               buttonType="danger"
               icon={<i className="fas fa-trash" />}
              />,
             ]
           : undefined
         }
         text={item.title}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   <AddAppointmentModal
    editData={showAppointment.data}
    leadId={leadId}
    mode={showAppointment.mode}
    visible={showAppointment.show}
    onCancel={() => setShowAppointment({ show: false, mode: 'ADD' })}
   />
  </div>
 )
}

export default AppointmentsBlock
