import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAppControlState, TSendWidgetState } from 'types'

const initialState: TAppControlState = {
 sendWidget: {
  forwardData: null,
  widgetFrom: 'lead',
  widget: 'email',
  large: false,
  mode: 'SEND',
  show: false,
  propertyData: [],
  to: [],
  type: 'lead',
  replyData: null,
  selectAll: false,
  unSelected: [],
  filterInfo: '',
 },
}

export const controlSlice = createSlice({
 name: 'controlSlice',
 initialState,
 reducers: {
  updateAppControl: (state, { payload }: PayloadAction<TAppControlState>) => {
   return {
    ...state,
    ...payload,
   }
  },
  updateSendWidget: (state, { payload }: PayloadAction<TSendWidgetState>) => {
   state.sendWidget = {
    ...state.sendWidget,
    ...payload,
   }
  },
  resetSendWidget: (state) => {
   state.sendWidget = initialState.sendWidget
  },
 },
})

export const { updateAppControl, updateSendWidget, resetSendWidget } =
 controlSlice.actions
