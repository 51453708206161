import { Col, Row } from 'antd'
import PropertyTag from 'components/shared/Property/PropertyTag'
import { TPreConCommon } from 'features/property'
import { FC } from 'react'

const PreMainInfoBlock: FC<{ data?: TPreConCommon }> = ({ data }) => (
 <div>
  <div className="main-info">
   <Row>
    <Col span={24}>
     <ul className="status list-inline">
      <li className="list-inline-item">
       <PropertyTag
        constructionStatus={data?.construction_status}
        isPreCon
        size="lg"
       />
      </li>
     </ul>
     <div className="address">{data?.property_name}</div>
     {data?.address?.full_address && (
      <div className="location">
       <i className="fas fa-map-marker-alt me-1" />
       {data?.address?.full_address}
      </div>
     )}
     <div className="address" style={{ fontSize: '17px' }}>
      {data?.listed_price}
     </div>
    </Col>
   </Row>
  </div>
 </div>
)

export default PreMainInfoBlock
