import { FC } from 'react'

const PreAmenities: FC<{ data?: { key?: string; value?: string[] } }> = ({
 data,
}) => {
 return (
  <div className="key-facts-block border-block sp-card">
   <h3 className="common-head">{data?.key}</h3>
   <ul className="key-facts ">
    {data?.value?.map?.((item, index) => (
     <li className="two-span" key={index}>
      <i className="far fa-check-circle me-1 text-primary" /> {item}
     </li>
    ))}
   </ul>
  </div>
 )
}

export default PreAmenities
