import { Spin } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import React, { FC, ReactNode } from 'react'
import { FCC } from 'types'

const ListContentLayout: FCC<{
 bodyClass?: string
 title: ReactNode
 actions?: any
 loading?: boolean
 classNames?: { header?: string }
}> = ({ children, title, actions, bodyClass, loading, classNames }) => {
 const { header } = classNames || {}
 if (loading) {
  return <BlockLoader mode="full" />
 }
 return (
  <div className="listcontentlayout">
   <div className={`listcontentlayout-header ${header ?? ''}`}>
    <h4 className="listcontentlayout-title">{title}</h4>
    <div className="listcontentlayout-actions">{actions}</div>
   </div>
   <div className={`listcontentlayout-body ${bodyClass ?? ''}`}>{children}</div>
  </div>
 )
}

export default ListContentLayout
