import { useAppSelector, useAppDispatch } from 'hooks'
import { createSlice } from '@reduxjs/toolkit'
import { TGlobalTableStructure, TWebSettings } from 'types'
import { baseAPI } from 'api/baseApi'
import { RootState } from 'store'

export type TWebSettingsState = TWebSettings & {
 //
}

const webSettingsState: TWebSettingsState = {}

export const webSettingsSlice = createSlice({
 name: 'webSettingsSlice',
 initialState: webSettingsState,
 reducers: {
  updateTableStructure: (state, { payload }) => {
   state.table_structure = payload
  },
 },
 extraReducers(builder) {
  builder.addMatcher(
   baseAPI.endpoints.webSettings.matchFulfilled,
   (state, { payload }) => ({
    ...state,
    ...payload,
   }),
  )
 },
})

export const { updateTableStructure } = webSettingsSlice.actions

export const useWebSettings = (): TWebSettingsState & {
 updateTableStructure?: (v: TGlobalTableStructure[]) => void
} => {
 const d = useAppDispatch()
 const webSettings = useAppSelector((state: RootState) => state.webSettings)
 return {
  ...webSettings,

  updateTableStructure: (v) => d(updateTableStructure(v)),
 }
}
