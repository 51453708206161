/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UploadFile } from 'antd'
import { TFileExt } from 'types'
import Utils from 'utils/helper'

export const checkIsSupportedFile = (
 file: UploadFile,
 exts?: TFileExt[],
): boolean => {
 const ext = Utils.getExtNameFromUrl(file.name).toLowerCase()

 if (exts && exts.length > 0) {
  if (exts.find((v) => v === ext)) {
   return true
  }
  return false
 }
 return true
}

export const bytesToMegaBytes = (bytes: number) => {
 const n = bytes / (1024 * 1024)
 return Number(n.toFixed(2))
}

export const checkIsSizeExceed = (file: UploadFile, size = 10): boolean => {
 const fileSize = bytesToMegaBytes(file.size ?? 0)
 if (fileSize > size) {
  return true
 }
 return false
}
