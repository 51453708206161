import { Spin } from 'antd'
import React, { FC } from 'react'

export const FullLoader: FC<{
 className?: string
}> = ({ className }) => (
 <div className={`full-loader ${className ?? ''}`}>
  <Spin />
 </div>
)
