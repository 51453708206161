import './App.css'
import { AppProvider } from 'providers/app'
import { AppRoutes } from 'routes'
import { ConfigProvider } from 'antd'
import styles from 'styles/var.module.scss'
import { FacebookProvider } from 'react-facebook'
import { FACEBOOK_ID, RECAPTCHA_SITE_KEY } from 'config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ConfigProvider.config({
 theme: {
  primaryColor: styles.primaryColor,
 },
})

const App = () => {
 return (
  <ConfigProvider>
   <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
    <AppProvider>
     <FacebookProvider version="v18.0" appId={FACEBOOK_ID}>
      <AppRoutes />
     </FacebookProvider>
    </AppProvider>
   </GoogleReCaptchaProvider>
  </ConfigProvider>
 )
}

export default App
