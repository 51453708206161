import { SpeedDialBlock } from 'components/elements'
import { BtnAnch, globalConfirm } from 'components/elements/global'
import { BlockLoader } from 'components/elements/Loader'
import {
 SendEmailModal,
 SendTextModal,
 AddNoteModal,
 AddTaskModal,
} from 'components/shared/Modals'
import LeadTransferModal from 'components/shared/Modals/LeadTransferModal'
import LogCallModal from 'components/shared/Modals/LogCallModal'
import { useDeleteLeadsMutation, useGetLeadQuery } from 'features/leads/api'
import { LeadDetailsProvider } from 'features/leads/providers'
import useAuth from 'hooks/useAuth'
import useFetchPage from 'hooks/useFetchPage'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotFound } from 'features/misc'
import { TGetLeads } from 'features/leads/types'
import { ADMIN_PREFIX_PATH } from 'config'
import useHashRouter from 'hooks/useHashRouter'
import useAppControl from 'hooks/useAppControl'
import {
 SVGAcitonPlanFilled,
 SVGTaskIcon,
 SvgActionPlanIcon,
 SvgAutomationIcon,
} from 'components/icons'
import AssignActionPlan from 'components/shared/Modals/AssignActionPlan'
import ActivitySection from './activity'
import LeadDetailHeader from './LeadDetailHeader'
import ProfileSection from './profile'
import AnalyticsSection from './analytics'

type LeadDetailPageProps = {
 leadId?: any
 from?: 'lead' | 'other'
 onClose?: () => void
 leadData?: TGetLeads
 onNextLead?: () => void
 onPrevLead?: () => void
 isRealtor?: boolean
 refetchLeadList?: () => void
 realtorId?: number
 leadListIndex?: number
}

const LeadDetailPage: FC<LeadDetailPageProps> = ({
 leadData,
 leadId,
 from = 'other',
 onClose,
 onNextLead,
 onPrevLead,
 isRealtor,
 refetchLeadList,
 realtorId,
 leadListIndex,
}) => {
 const [deleteLead] = useDeleteLeadsMutation()
 const { params } = useHashRouter()
 const [showModal, setShowModal] = useState<
  false | 'mail' | 'text' | 'note' | 'leadTransfer' | 'call' | 'task' | 'ap'
 >(false)
 const naviagate = useNavigate()
 const { user } = useAuth()
 const location = useLocation()
 const currentUrl = location?.pathname?.split('/')
 const urlItems = ['all', 'textlog', 'emaillog', 'note', 'calllog', 'web']
 const { state } = useLocation()
 const [leadTab, setLeadTab] = useState<'timeline' | 'profile' | 'analytics'>(
  (params?.[1] as any) || 'timeline',
 )
 const { refetch } = useFetchPage<any>(`lead/profile/${leadId}`, {
  method: 'post',
  body: {
   type: [currentUrl[currentUrl.length - 1]],
   ...(realtorId ? { realtor_id: realtorId } : {}),
  },
  enable: false,
 })

 const getUpdatedList = () => {
  if (urlItems.includes(currentUrl[currentUrl.length - 1])) {
   refetch?.()
  }
 }
 const { updateSendSMSWidget, updateSendEmailWidget } = useAppControl()
 const { data, isLoading, isError, error, isFetching } = useGetLeadQuery(
  { id: leadId, ...(realtorId ? { realtor_id: realtorId } : {}) },
  { skip: !leadId, refetchOnMountOrArgChange: true },
 )

 const currentLink = useMemo(
  () => `${ADMIN_PREFIX_PATH}/leads#lead-details/${leadId}`,
  [leadId],
 )
 useEffect(() => {
  if (params?.length === 1 && from === 'other') {
   naviagate(`${currentLink}/timeline`)
  }
 }, [currentLink, from, params?.length])

 const handleSetTab = useCallback(
  (tab: 'timeline' | 'profile' | 'analytics') => {
   if (from === 'other') {
    naviagate({ hash: `lead-details/${leadId}/${tab}` }, { state })
   }
   setLeadTab(tab)
  },
  [from, leadId],
 )

 const buildSection = useCallback(
  (type?: string) => {
   switch (type) {
    case 'profile':
     return (
      <ProfileSection
       onClose={onClose}
       leadId={leadId}
       data1={data}
       leadListIndex={leadListIndex}
      />
     )
    case 'timeline':
     return <ActivitySection />
    case 'analytics':
     return <AnalyticsSection />
    default:
     return <ActivitySection />
   }
  },
  [data, from, isFetching, isLoading, leadId],
 )

 if ((isLoading || isFetching) && from === 'other') {
  return <BlockLoader mode="full" />
 }

 if (isError) {
  return (
   <NotFound
    title={(error as any)?.data?.message ?? 'Something went wrong'}
    message=""
   />
  )
 }

 const handleDeleteLead = () => {
  globalConfirm({
   content: 'Are you sure want to delete this lead?',
   onOk: async () => {
    await deleteLead({ id: [leadId as any] }).unwrap()
    // naviagate?.('/admin/leads')
    refetchLeadList?.()
    onClose?.()
   },
  })
 }

 const dialBlockArray: any = [
  ...((data?.phone_numbers?.length ?? 0) > 0
   ? [
      {
       icon: <i className="fas fa-phone" />,
       title: 'Call Log ',
       onClick: () => setShowModal('call'),
       hide: data?.lead_transfer === false,
      },
     ]
   : []),
  {
   icon: <i className="fas fa-comment-alt" />,
   title: 'SMS from Smart Number',
   onClick: () => {
    updateSendSMSWidget({
     to: data?.phone_numbers?.[0].number,
     widgetFrom: 'lead',
     show: true,
    })
   },
   hide: !user?.twilio_phone || data?.lead_transfer === false,
  },
  {
   icon: <i className="fas fa-envelope" />,
   title: 'Send Email',
   onClick: () => {
    // setShowModal('mail')
    updateSendEmailWidget({
     to: data?.email_addresses?.[0]?.email,
     widgetFrom: 'lead',
     show: true,
    })
   },
   hide: data?.lead_transfer === false,
  },
  {
   icon: <i className="fas fa-clipboard" />,
   title: 'Add Note',
   onClick: () => setShowModal('note'),
   hide: data?.lead_transfer === false,
  },
  // {
  //  icon: <i className="fas fa-trash" />,
  //  title: 'Delete Lead',
  //  color: 'danger',
  //  onClick: handleDeleteLead,
  //  hide: data?.lead_transfer === false,
  // },
  {
   icon: <SVGAcitonPlanFilled />,
   title: 'Add Action Plan',
   onClick: () => setShowModal('ap'),
   hide: data?.lead_transfer === false,
  },
  {
   icon: <SVGTaskIcon />,
   title: 'Add Task',
   onClick: () => setShowModal('task'),
   hide: data?.lead_transfer === false,
  },
  ...(user?.account_type === 'Brokerage'
   ? [
      {
       icon: <i className="fas fa-exchange" />,
       title: 'Lead Transfer',
       onClick: () => setShowModal('leadTransfer'),
      },
     ]
   : []),
 ]

 return (
  <LeadDetailsProvider
   from={from}
   leadId={leadId || params?.[0]}
   isTransferred={data?.lead_transfer}
   realtorId={realtorId}
  >
   <div className="lead-dtl-page">
    <LeadDetailHeader
     onPrevLead={onPrevLead}
     onNextLead={onNextLead}
     onClose={onClose}
     //  data={(leadData as any) || data}
     //  from={from}
     //  isRealtor={isRealtor}
     headerData={{
      name: leadData?.name ?? data?.name,
      transferowner: leadData?.transferowner ?? data?.transferowner,
      tags: leadData?.tags ?? data?.tags,
      sources: leadData?.sources ?? data?.sources,
      stages: leadData?.stages ?? data?.stages,
     }}
    />
    <ul className="lead-dtl-nav">
     <li>
      <BtnAnch
       className={leadTab === 'timeline' ? 'active' : ''}
       onClick={() => handleSetTab('timeline')}
      >
       Timeline
      </BtnAnch>
     </li>
     <li>
      <BtnAnch
       className={leadTab === 'profile' ? 'active' : ''}
       onClick={() => handleSetTab('profile')}
      >
       Profile
      </BtnAnch>
     </li>
     <li>
      <BtnAnch
       className={leadTab === 'analytics' ? 'active' : ''}
       onClick={() => handleSetTab('analytics')}
      >
       Analytics
      </BtnAnch>
     </li>
    </ul>
    <div className="lead-dtl-page-body">{buildSection(leadTab)}</div>
   </div>
   <SpeedDialBlock menus={dialBlockArray} />

   {/* {showModal === 'mail' && (
    <SendEmailModal
     to={data?.email_addresses?.[0]?.email}
     onCancel={() => setShowModal(false)}
     visible={showModal === 'mail'}
     onSendSuccess={() => {
      setShowModal(false)
      getUpdatedList?.()
     }}
     isAttachment={false}
    />
   )}
   {showModal === 'text' &&
    data?.phone_numbers &&
    data?.phone_numbers.length > 0 && (
     <SendTextModal
      to={data?.phone_numbers?.[0].number}
      onCancel={() => setShowModal(false)}
      visible={showModal === 'text'}
      onSendSuccess={() => setShowModal(false)}
     />
    )} */}
   {showModal === 'note' && (
    <AddNoteModal
     visible={showModal === 'note'}
     onCancel={() => setShowModal(false)}
     leadId={data?.id}
    />
   )}
   {showModal === 'leadTransfer' && (
    <LeadTransferModal
     lead_id={[leadId as any]}
     onCancel={() => setShowModal(false)}
     visible={showModal === 'leadTransfer'}
     // prettier-ignore
     onSuccess={() => { refetchLeadList?.();  onClose?.() }}
     isTransferred={data?.lead_transfer}
     extraBody={{
      ...(realtorId
       ? { type: 'my_realtor', realtor_id: realtorId }
       : { type: 'crm_leads' }),
     }}
     submitExtraBody={{
      ...(realtorId
       ? { current_owner: realtorId, crm_list_type: 'myrealtor' }
       : {}),
     }}
    />
   )}
   {showModal === 'call' && (
    <LogCallModal
     visible={showModal === 'call'}
     onCancel={() => setShowModal(false)}
     numbers={data?.phone_numbers}
    />
   )}
   {showModal === 'task' && (
    <AddTaskModal
     mode="ADD"
     leadId={leadId}
     onCancel={() => setShowModal(false)}
     visible
     autoFocusTitle
    />
   )}

   {showModal === 'ap' && (
    <AssignActionPlan
     lead_id={[leadId]}
     visible
     onCancel={() => setShowModal(false)}
    />
   )}
  </LeadDetailsProvider>
 )
}

export default LeadDetailPage
