import { Col, Row } from 'antd'
import BaseTag from 'components/elements/BaseTag'
import { FC } from 'react'
import { TGroupType } from 'types'
import ProInnerCard from '../../ProInnerCard'

const SourceBlock: FC<{ sources?: TGroupType[] }> = ({ sources }) => {
 return (
  <div>
   <ProInnerCard emptyText="Source is Empty" isEmpty={!sources} title="Source">
    <Row gutter={[7, 7]}>
     {sources?.map((i, n) => (
      <Col key={n}>
       <BaseTag className="m-0 p-2" label={i?.name} />
      </Col>
     ))}
    </Row>
   </ProInnerCard>
  </div>
 )
}

export default SourceBlock
