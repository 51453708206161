import useFetchPage from 'hooks/useFetchPage'
import { FC, useState } from 'react'
import { BaseButton } from 'components/elements/BaseButton'
import { Modal } from 'antd'
import { LoadMoreSelect } from 'components/elements/Form'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useLeadTransferMutation,
} from 'features/leads'
import { globalConfirm } from 'components/elements/global'
import { TNewRealtor } from '../types'

const LeadTransferBtn: FC<
 TSelectAllP & {
  type: 'icon' | 'text'
  btnDisabled?: boolean
  id: string
  from?: 'realtor' | 'lead'
  //   realtorId?: string
  onSuccess?: () => void
  iconClassName?: string
  extraBody?: any
  searchExtraBody?: { [key: string]: any }
  customtSelectAll?: TLeadCustomSelectAll
 }
> = ({
 type,
 btnDisabled,
 id,
 selectAll,
 unSelected,
 filterInfo,
 from,
 //  realtorId,
 onSuccess,
 iconClassName = '',
 extraBody,
 searchExtraBody,
 customtSelectAll,
}) => {
 const [showLeadTransferModal, setShowLeadTransferModal] = useState(false)

 return (
  <>
   <BaseButton
    tooltipText="Lead Transfer"
    onClick={() => setShowLeadTransferModal(true)}
    buttonType={type === 'icon' ? 'alpha' : 'primary'}
    icon={<i className={`far fa-exchange ${iconClassName}`} />}
    disabled={btnDisabled}
    shape={type === 'icon' ? 'circle' : 'default'}
   >
    {type === 'text' ? ' Lead Transfer' : null}
   </BaseButton>
   {showLeadTransferModal && (
    <LeadTransferModal
     onCancel={() => {
      setShowLeadTransferModal(false)
     }}
     filterInfo={filterInfo}
     selectAll={selectAll}
     unSelected={unSelected}
     id={id}
     show={showLeadTransferModal}
     from={from}
     //  realtorId={realtorId}
     onSuccess={onSuccess}
     extraBody={extraBody}
     searchExtraBody={searchExtraBody}
     customtSelectAll={customtSelectAll}
    />
   )}
  </>
 )
}

export default LeadTransferBtn

const LeadTransferModal: FC<
 TSelectAllP & {
  onCancel: () => void
  id: string
  show: boolean
  from?: 'realtor' | 'lead'
  //   realtorId?: string
  onSuccess?: () => void
  extraBody?: any
  searchExtraBody?: { [key: string]: any }
  customtSelectAll?: TLeadCustomSelectAll
 }
> = ({
 onCancel,
 filterInfo,
 selectAll,
 unSelected,
 id,
 show,
 from,
 //  realtorId,
 onSuccess,
 extraBody = {},
 searchExtraBody = {},
 customtSelectAll,
}) => {
 const [searchKey, setSearchKey] = useState('')
 const [selectedRealtor, setRealtor] = useState([])

 const [leadTransfer, { isLoading: leadTransferLoading }] =
  useLeadTransferMutation()
 const { isLoading, hasMore, fetchMore, flatData, isRefetching } =
  useFetchPage<TNewRealtor>('search-realtor', {
   method: 'post',
   body: {
    search: searchKey,
    ...searchExtraBody,
    ...(from === 'realtor'
     ? {
        realtor_id: extraBody?.current_owner,
       }
     : {}),
   },
  })

 const callLeadTransfer = async () => {
  const body: TSelectAllB & { customtSelectAll?: TLeadCustomSelectAll } = {}
  if (selectAll || customtSelectAll) {
   if (selectAll) {
    body.select_all = selectAll
   } else if (customtSelectAll) {
    body.customtSelectAll = customtSelectAll
   }
   body.unselected = unSelected
   body.filter_info = filterInfo
  }

  await leadTransfer?.({
   ...body,
   id: selectAll || customtSelectAll ? '' : (id as string),
   realtor: selectedRealtor,
   ...extraBody,
   //  ...(from === 'realtor'
   //   ? { crm_list_type: 'myrealtor', current_owner: realtorId }
   //   : {}),
  }).unwrap()
  onCancel()
  onSuccess?.()
  setRealtor([])
 }
 const onSubmitLeadTransfer = async (val: any) => {
  if (from === 'realtor') {
   globalConfirm({
    content:
     // eslint-disable-next-line quotes
     "The Tag/Stage/Source/ Action Plans assigned by the realtor won't be copied to the new realtor",
    onOk: callLeadTransfer,
   })
  } else {
   callLeadTransfer()
  }
 }
 return (
  <Modal
   okButtonProps={{ disabled: selectedRealtor?.length === 0 }}
   confirmLoading={leadTransferLoading}
   onOk={onSubmitLeadTransfer}
   okText="Submit"
   title="Lead Transfer"
   onCancel={() => {
    onCancel()
    setRealtor([])
   }}
   visible={show}
  >
   <LoadMoreSelect
    value={selectedRealtor}
    onChange={(val) => setRealtor(val)}
    data={flatData?.map((v) => ({ value: v?.id, label: v?.text }))}
    onSearch={setSearchKey}
    placeholder="Select Realtor"
    hasMore={hasMore}
    fetchMore={fetchMore}
    loading={isLoading || isRefetching}
   />
  </Modal>
 )
}
