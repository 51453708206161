import { Button } from 'antd'
import { TUserActivityEmailLog } from 'features/leads/types'
import useAppControl from 'hooks/useAppControl'
import { FC } from 'react'

const ActivityEmailActions: FC<{
 datas?: TUserActivityEmailLog
 onClickReOrFor?: () => void
}> = ({ datas, onClickReOrFor }) => {
 const { updateSendEmailWidget } = useAppControl()

 const onClickMail = (
  val: 'REPLY' | 'FORWARD',
  data?: TUserActivityEmailLog,
 ): void => {
  updateSendEmailWidget({
   replyData: {
    date: data?.updated_at,
    subject: `${!data?.subject?.startsWith('Re:') ? 'Re: ' : ''}${
     data?.subject
    }`,
    to:
     data?.send_type === 'Send' || data?.send_type === 'send'
      ? data?.to_mail
      : data?.from_mail,
    toName: data?.from_name,
    body: data?.body,
   },
   forwardData: {
    body: data?.body,
    from: data?.from_mail,
    subject: `${!data?.subject?.startsWith('Fwd:') ? 'Fwd: ' : ''} ${
     data?.subject
    }`,
    to: data?.to_mail,
    date: data?.updated_at,
    fromName: data?.from_name,
    toName: data?.to_name,
    attachment: data?.attachment,
   },
   show: true,
   mode: val,
   widgetFrom: 'lead',
  })
  onClickReOrFor?.()
 }
 return (
  <div className="d-flex justify-content-end">
   <Button
    size="small"
    onClick={() => {
     onClickMail('REPLY', datas)
    }}
    className="reply-i me-1"
    title="Reply"
   >
    <i className="far fa-reply" />
   </Button>
   <Button
    size="small"
    style={{ marginRight: '5px' }}
    onClick={() => {
     onClickMail('FORWARD', datas)
    }}
    title="Forward"
    className="forward-i"
   >
    <i className="far fa-share" />
   </Button>
  </div>
 )
}

export default ActivityEmailActions
