import { Card } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { BtnAnch } from 'components/elements/global'
import PropertyBBC from 'components/shared/Property/PropertyBBC'
import PropertyTag from 'components/shared/Property/PropertyTag'
import { TDetailSimilarLists } from 'features/property'
import React, { FC, useState } from 'react'
import { Navigation } from 'swiper'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
import PropertyDetailsModal from '../PropertyDetailsModal'

const SimilarPropBlock: FC<{
 smProps?: TDetailSimilarLists[]
}> = ({ smProps }) => {
 const [showModal, setShowModal] = useState(false)
 const [similarSlug, setSlug] = useState<string | undefined>('')
 if (!smProps || smProps?.length === 0) {
  return null
 }
 return (
  <Card title=" Similar Properties" className="m-0">
   <div>
    {/* <h4 style={{ fontSize: '15px', fontWeight: '500', marginLeft: '10px' }}>
     Similar Properties
    </h4> */}
    <Swiper
     spaceBetween={15}
     slidesPerView={3}
     navigation
     modules={[Navigation]}
     breakpoints={{
      678: {
       slidesPerView: 2,
      },
      1200: {
       slidesPerView: 3,
      },
      1600: {
       slidesPerView: 3,
      },
     }}
    >
     {smProps?.length > 0 &&
      smProps?.map((item, index) => (
       <SwiperSlide key={index}>
        <div>
         <Card
          hoverable
          cover={
           <BtnAnch
            onClick={() => {
             setShowModal(true)
             setSlug(item?.slug)
            }}
           >
            <img
             alt="example"
             src={
              item?.images === null ||
              item?.images?.images === null ||
              item?.images?.images === ''
               ? '/images/CondoApt.png'
               : `${item?.images?.directory}Photo${item?.images?.ml_num}-${item?.images?.images}.jpeg`
             }
             className="img-fluid w-100 swiper-imgs"
            />
           </BtnAnch>
          }
         >
          <Meta
           title={
            <div>
             <h4 style={{ marginBottom: '3px' }}>{item?.addr} </h4>
             <p style={{ marginBottom: '3px' }}>
              {' '}
              <div style={{ color: 'gray' }}>
               <i className="fas fa-map-marker-alt me-1" /> {item?.city},{' '}
               {item?.province}
              </div>
             </p>
            </div>
           }
           description={
            <>
             <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div
               style={{
                marginRight:
                 item?.bed !== null ||
                 item?.bath !== null ||
                 item?.bed_plus !== null ||
                 item?.garage_space !== null
                  ? '6px'
                  : '0px',
               }}
              >
               <PropertyBBC
                bed={item?.bed}
                bath={item?.bath}
                maxBed={item?.bed_plus}
                garage={item?.garage_space}
               />
              </div>
              <div style={{ verticalAlign: 'text-top', lineHeight: '14px' }}>
               <PropertyTag tag={item?.listing_type} />
              </div>
             </div>
             <div style={{ fontSize: '16px', marginTop: '8px' }}>
              <h5 style={{ color: 'gray' }}>{item?.building_type}</h5>
             </div>
            </>
           }
          />
         </Card>
        </div>
       </SwiperSlide>
      ))}
    </Swiper>
    {showModal && (
     <PropertyDetailsModal
      visible={showModal}
      onHide={() => setShowModal(false)}
      value={similarSlug}
     />
    )}
   </div>
  </Card>
 )
}

export default SimilarPropBlock
