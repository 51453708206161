import { FC, useState } from 'react'
import { Badge, Col, Dropdown, Menu, Row, Select } from 'antd'
import { useGlobal } from 'raducer/global'
import { ListingTypes, VowListingTypes, sortData } from 'static-data'
import { NewListingSlug } from 'types'
import { BaseButton } from 'components/elements/BaseButton'
import { isEmptyFilter } from 'utils/functions'
import { useProListing } from '../provider/ProListingProvider'
import { ProListingSearch } from './ProListingSearch'
import ProFilterBlock from './ProFilterBlock'

const ProListingHeader: FC<{
 type: 'brokerage' | 'listing' | 'precon'
}> = ({ type }) => {
 const {
  filterState,
  changeListingType,
  selectedBroker,
  setSelectedBroker,
  sort,
  setSort,
  // type,
  selectedCity,
  searchType,
 } = useProListing()
 const { global } = useGlobal()
 const listingTypeItems =
  global.user_type_sold === true ? ListingTypes : VowListingTypes
 const selectedType = filterState?.listing_type

 const commonInputProps: { size: 'large'; className: string } = {
  size: 'large',
  className: 'w-100',
 }

 const [showFilter, setShowFilter] = useState(false)

 return (
  <>
   <div className="listing-filter-blk">
    <Row gutter={[10, 10]}>
     {type === 'listing' && (
      <Col md={6} flex={1}>
       <Select
        onSelect={(e: NewListingSlug) => {
         changeListingType(e)
        }}
        value={selectedType}
        placeholder="Select Type"
        {...commonInputProps}
        options={listingTypeItems
         ?.filter((i) => (!global?.open_house ? i.slug !== 'open-house' : i))
         .map((v) => ({
          label: v.label,
          value: v.slug,
         }))}
       />
      </Col>
     )}

     {type === 'brokerage' && (
      <Col md={20}>
       <Select
        {...commonInputProps}
        placeholder="Select Brokerage Listings"
        value={selectedBroker}
        options={
         global?.brokerage_data?.map((x) => ({
          label: x,
          value: x,
         })) ?? []
        }
        onSelect={(e: string) => {
         setSelectedBroker([e])
        }}
       />
      </Col>
     )}

     {type !== 'brokerage' && (
      <>
       <ProListingSearch />
       <Badge
        className="filter-dot-icon"
        dot={isEmptyFilter({
         ...filterState,
         ...((searchType === 'city' || searchType === 'community') &&
         selectedCity !== ''
          ? { city: selectedCity }
          : {}),
        })}
       >
        <BaseButton
         tooltipText="More Filter"
         shape="default"
         onClick={() => setShowFilter(true)}
         style={{ border: 'none', height: '100%', padding: '0 3px' }}
         icon={<img src="/filter.svg" alt="ar" className="img-fluid w-100" />}
        />
       </Badge>
      </>
     )}
     <Col md={4}>
      <Dropdown
       trigger={['click']}
       placement="bottomRight"
       overlay={
        <Menu
         selectable
         selectedKeys={[sort ?? '0']}
         onSelect={({ key }) => setSort(key as any)}
        >
         {sortData.map((item, index) => (
          <Menu.Item key={index} eventKey={item.value}>
           {item.label}
          </Menu.Item>
         ))}
        </Menu>
       }
      >
       <BaseButton
        className="w-100"
        tooltipText="Sort"
        buttonType="primary"
        size="large"
        icon={<i className="fas fa-sort-amount-down" />}
       >
        {sort === '0' ? 'Sort' : sortData.find((v) => v.value === sort)?.label}
       </BaseButton>
      </Dropdown>
     </Col>
    </Row>
   </div>
   {showFilter && <ProFilterBlock onHide={() => setShowFilter(false)} />}
  </>
 )
}

export default ProListingHeader
