import { Avatar, Card, Tag } from 'antd'
import { SvgEmailIcon, SvgNoteIcon, SvgTextIcon } from 'components/icons'
import { TMainActivityTypes, TUserAllActivity } from 'features/leads/types'
import { FC, ReactNode, useState } from 'react'
import { formatDate } from 'utils/functions'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { buildDetailModal } from './ActivityDetailModal'
import ActivityEmailActions from './ActivityEmailActions'

const CommonItemCard: FC<{
 refechList?: () => void
 fetchDetail?: () => void
 data?: TUserAllActivity | any
 type?: TMainActivityTypes
 loading?: boolean
 ItemcardDetail?: {
  titleNode?: ReactNode
  title?: string
  description?: string
  updated?: string
  estatus?: any
 }
 from?: 'email'
}> = ({
 data,
 ItemcardDetail,
 fetchDetail,
 refechList,
 loading,
 type,
 from,
}) => {
 const { Meta } = Card
 const [showModal, setShowModal] = useState(false)
 const { isTransferred } = useLeadDetails()

 const setAvtIcon = () => {
  switch (type) {
   case 'email':
    return <SvgEmailIcon />
   case 'call':
    return <i className="fas fa-phone-alt" style={{ color: 'black' }} />
   case 'note':
    return <SvgNoteIcon />
   case 'text':
    return <SvgTextIcon />
   case 'web':
    return (
     <i
      className="fas fa-solid fa-globe"
      style={{ color: 'black', fontSize: '35px' }}
     />
    )
   default:
    return null
  }
 }

 return (
  <>
   <Card
    hoverable
    onClick={() => {
     setShowModal(true)
     fetchDetail?.()
    }}
    style={{ borderColor: '#ccc' }}
   >
    <div className="d-flex justify-content-between">
     <div>
      <Meta
       avatar={
        <Avatar
         //  style={{ backgroundColor: '#eff6fc' }}
         size={50}
         icon={setAvtIcon()}
        />
       }
       description={
        <div>
         {ItemcardDetail?.titleNode ? (
          ItemcardDetail?.titleNode
         ) : (
          <h3 className="mb-0 mt-0">{ItemcardDetail?.title}</h3>
         )}
         {ItemcardDetail?.description && (
          <h4 className="mb-1 mt-0 line-clamp-1">
           {ItemcardDetail?.description}
          </h4>
         )}
         {ItemcardDetail?.updated && (
          <p className="mb-0 mt-2" style={{ fontSize: '13px' }}>
           <span
            style={{
             border: ' 1px solid #ccc',
             padding: ' 0 7px',
            }}
           >
            {formatDate?.(ItemcardDetail?.updated)}
           </span>
           {ItemcardDetail?.estatus && (
            <Tag className="ms-2" color="blue">
             {ItemcardDetail.estatus}
            </Tag>
           )}
          </p>
         )}
        </div>
       }
      />
     </div>
     {from === 'email' && isTransferred && (
      <div
       onClick={(e) => {
        e.stopPropagation()
        // fetchDetail?.()
       }}
      >
       <ActivityEmailActions onClickReOrFor={fetchDetail} datas={data} />
      </div>
     )}
    </div>
   </Card>
   {showModal &&
    buildDetailModal(
     () => setShowModal(false),
     showModal,
     type,
     refechList,
     loading,
     data,
    )}
  </>
 )
}

export default CommonItemCard
