/* eslint-disable no-nested-ternary */
import { Spin, Badge, UploadFile } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch, globalToaster } from 'components/elements/global'
import ScheduleModal from 'components/shared/Modals/ScheduleModal'
import {
 TMailEditData,
 TPreviewTemplateBody,
 TSendMailBody,
 TSendMailPro,
 TSendType,
} from 'features/leads'
import useAppControl from 'hooks/useAppControl'
import { useState } from 'react'
import { FCC, TMsgMode } from 'types'
import { TPropertyListing } from 'features/property'
import SWAttachmentModal from './_components/SWAttachmentModal'
import SWPreviewModal from './_components/SWPreviewModal'
import SWProductModal from './_components/SWProductModal'

type SendWidgetLayoutProps = {
 previewData?: TPreviewTemplateBody
 type?: TSendType
 loading?: boolean
 submitLoading?: boolean
 onSend?: () => void
 onPreview?: () => void
 attachmentCount?: number
 productCount?: number
 scheduleLoading?: boolean
 onSchedule?: (time: string, date: string) => void
 proData?: TPropertyListing[]
 onUpdateProData: (v: TPropertyListing[]) => void
 attachment?: {
  onChange: (file: UploadFile[]) => void
  value: UploadFile[]
  count: number
 }
 from: 'Email' | 'SMS'
 widgetFrom?: 'lead' | 'listing'
 handleValidation?: () => boolean
 hideAttachment?: boolean
 mode?: TMsgMode
 editData?: TMailEditData | null
}

const SendWidgetLayout: FCC<SendWidgetLayoutProps> = ({
 type,
 children,
 submitLoading,
 loading,
 onSend,
 productCount,
 scheduleLoading,
 onSchedule,
 attachment,
 previewData,
 proData,
 onUpdateProData,
 widgetFrom,
 from,
 handleValidation,
 hideAttachment = false,
 mode,
 editData,
}) => {
 const [showAttachementModal, setShowAttachmentModal] = useState(false)
 const { sendWidget, resetSendWidget, updateSendWidget } = useAppControl()
 const [showPreview, setShowPreview] = useState(false)
 const [showProductModal, setShowProductModal] = useState(false)
 const [showScheduleEmail, setShowScheduleEmail] = useState<boolean>(false)

 //  const handleClickPreview = () => {
 //   if (!previewData?.to || (previewData?.to && previewData?.to.length === 0)) {
 //    globalToaster({ type: 'error', content: 'Enter To Input' })
 //    return false
 //   }
 //   if (!previewData.subject && sendWidget?.widget === 'email') {
 //    globalToaster({ type: 'error', content: 'Enter Subject' })
 //    return false
 //   }
 //   setShowPreview(true)
 //  }

 return (
  <>
   <div
    className={`send-widget-wrapper ${
     sendWidget?.large ? 'send-widget-wrapper-large' : ''
    }`}
   >
    <div className="send-widget">
     <div className="sw-header">
      <h6 className="sw-title">
       {mode === 'EDIT-SHEDULE-MAIL'
        ? 'Edit Email'
        : mode === 'EDIT-SHEDULE-SMS'
        ? 'Edit SMS'
        : sendWidget?.widget === 'sms'
        ? 'Smart SMS'
        : 'Compose'}
      </h6>
      <div className="sw-action">
       <BtnAnch onClick={() => updateSendWidget({ large: !sendWidget?.large })}>
        {sendWidget?.large ? (
         <i className="far fa-compress-alt" />
        ) : (
         <i className="fas fa-expand-alt" />
        )}
       </BtnAnch>
       <BtnAnch onClick={() => resetSendWidget?.()}>
        <i className="fas fa-times" />
       </BtnAnch>
      </div>
     </div>
     <div className="sw-body">
      <div className="sw-body-inner">
       {loading && (
        <div className="sw-loading">
         <Spin />
        </div>
       )}
       {children}
      </div>
     </div>
     <div className="sw-footer">
      <BaseButton
       loading={submitLoading}
       onClick={async () => {
        if (await handleValidation?.()) {
         if (mode === 'EDIT-SHEDULE-MAIL' || mode === 'EDIT-SHEDULE-SMS') {
          onSchedule?.(editData?.time ?? '', editData?.date ?? '')
         } else {
          onSend?.()
         }
        }
       }}
       className="sw-btn"
       size="small"
       shape="round"
       type="primary"
      >
       <i className="fad fa-paper-plane me-1" />{' '}
       {mode === 'EDIT-SHEDULE-MAIL' || mode === 'EDIT-SHEDULE-SMS'
        ? 'Update'
        : 'Send'}
      </BaseButton>
      <ul className="sw-extra-btns">
       <li>
        <BtnAnch
         icon={<i className="fal fa-clock" />}
         //  onClick={() => setShowScheduleEmail(true)}
         onClick={async () => {
          if (await handleValidation?.()) {
           setShowScheduleEmail(true)
          }
         }}
         tooltipText="Schedule"
        />
       </li>
       {widgetFrom !== 'listing' &&
        Number(productCount || 0) === 0 &&
        attachment &&
        !hideAttachment && (
         <li>
          <Badge offset={[2, -4]} count={attachment?.count} key="at">
           <BtnAnch
            icon={
             <i
              style={{ transform: 'rotate(-43deg)' }}
              className="far fa-paperclip"
             />
            }
            onClick={() => setShowAttachmentModal(true)}
            tooltipText="Attachments"
           />
          </Badge>
         </li>
        )}
       {Number(productCount ?? 0) > 0 && (
        <li>
         <Badge offset={[2, -4]} count={productCount} key="pro">
          <BtnAnch
           //    icon={<i className="far fa-cube" />}
           icon={<i className="fas fa-home" />}
           onClick={() => setShowProductModal(true)}
           tooltipText="Properties"
          />
         </Badge>
        </li>
       )}
       <li>
        <BtnAnch
         // onClick={handleClickPreview}
         onClick={async () => {
          if (await handleValidation?.()) {
           setShowPreview(true)
          }
         }}
         tooltipText="Preview"
        >
         <i className="fas fa-eye" />
        </BtnAnch>
       </li>
      </ul>
     </div>
    </div>
   </div>
   {showAttachementModal && (
    <SWAttachmentModal
     value={attachment?.value}
     onChange={(file) => file && attachment?.onChange(file)}
     onCancel={() => setShowAttachmentModal(false)}
     visible={showAttachementModal}
    />
   )}
   <ScheduleModal
    isLoading={scheduleLoading}
    onSubmitSchedule={(time, date) => {
     onSchedule?.(time, date)
    }}
    type={sendWidget?.widget === 'sms' ? 'SMS' : 'EMAIL'}
    onCancel={() => setShowScheduleEmail(false)}
    visible={showScheduleEmail}
    defaultValue={
     editData?.date &&
     editData?.time &&
     (mode === 'EDIT-SHEDULE-MAIL' || mode === 'EDIT-SHEDULE-SMS')
      ? `${editData?.date}  ${editData?.time}`
      : undefined
    }
   />
   {showPreview && previewData && (
    <SWPreviewModal
     type={from}
     onToggle={() => setShowPreview(false)}
     visible={showPreview}
     body={previewData}
    />
   )}

   {showProductModal && (
    <SWProductModal
     onUpdateData={onUpdateProData}
     onCancel={() => setShowProductModal(false)}
     visible={showProductModal}
     data={proData}
     count={productCount ?? 0}
    />
   )}
  </>
 )
}

export default SendWidgetLayout
