import { Col, Row, Badge, Menu } from 'antd'
import { CSSProperties, FC, useState } from 'react'
import { useParams } from 'react-router'

import { BaseButton } from 'components/elements/BaseButton'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import { useTileForTransferredLeadsQuery } from '../api'

const spanStyle: CSSProperties = {
 padding: '8px 12px',
 border: '1px solid #ccc',
 display: 'inline-flex',
 alignItems: 'center',
 borderRadius: '4px',
}
const RealtorDetailTail: FC<{ id: string; name?: string }> = ({ id, name }) => {
 //  const params = useParams()
 const { setLeadFilter, clearLeadFilter, updateSearchKey, filter } =
  useLeadFilter()
 const { data } = useTileForTransferredLeadsQuery({
  realtor_id: id,
 })

 const { activeLeads, lead_count, totalemail, totalsms, recentActive, user } =
  data || {}

 const valid = (v: any) => v !== undefined && v !== null && v !== 0

 //  const [selectedKey, setSelectedKey] = useState<'total'|'active'>('total')
 const [selectedKey, setSelectedKey] = useState<'total' | 'active'>('total')

 return (
  //   <Row className="relator-detail-tail" style={{ minHeight: '75px' }}>
  //    <Col span={4} className="item">
  //     <div>
  //      <i className="fa fa-user me-2" /> {name ?? user}
  //     </div>
  //    </Col>
  //    {valid(lead_count) && (
  //     <Col span={4} className="item">
  //      {' '}
  //      <div>
  //       Lead Count
  //       <span className="count">{` - ${lead_count}`}</span>
  //      </div>
  //     </Col>
  //    )}
  //    {valid(totalemail) && (
  //     <Col span={4} className="item">
  //      <div>
  //       Total Email
  //       <span className="count">{` - ${totalemail}`}</span>
  //      </div>
  //     </Col>
  //    )}
  //    {valid(totalsms) && (
  //     <Col span={4} className="item">
  //      {' '}
  //      <div>
  //       Total SMS
  //       <span className="count">{` - ${totalsms}`}</span>
  //      </div>
  //     </Col>
  //    )}
  //    {valid(activeLeads) && (
  //     <Col span={4} className="item">
  //      <div>
  //       Recent Active
  //       <span className="count">{` - ${activeLeads}`}</span>
  //      </div>
  //     </Col>
  //    )}
  //   </Row>
  //   <div className=" ">
  <div className="lead-list-body px-0 py-2">
   {/* {valid(lead_count) && (
     <BaseButton
      className="me-2"
      {...(selectedKey === 'total' ? { type: 'primary' } : { type: 'ghost' })}
      onClick={() => {
       setSelectedKey('total')
      }}
     >
      Total Leads
      <Badge className="ms-2" count={lead_count} overflowCount={99999} />
     </BaseButton>
    )}
    {valid(activeLeads) && (
     <BaseButton
      {...(selectedKey === 'active' ? { type: 'primary' } : { type: 'ghost' })}
      onClick={() => {
       setSelectedKey('active')
      }}
     >
      Recent Active
      <Badge className="ms-2" count={activeLeads} overflowCount={99999} />
     </BaseButton>
    )} */}

   <div className="lead-tile-blk d-block h-auto ">
    <Menu
     items={[
      {
       label: (
        <>
         Total Leads
         <Badge className="ms-2" count={lead_count} overflowCount={99999} />
        </>
       ),
       key: 'total',
      },
      {
       label: (
        <>
         Recent Active
         <Badge className="ms-2" count={recentActive} overflowCount={99999} />
        </>
       ),
       key: 'active',
      },
     ]}
     mode="horizontal"
     defaultSelectedKeys={['total']}
     selectedKeys={[filter?.recentactivity ? 'active' : 'total']}
     onSelect={({ key }) => {
      setLeadFilter({ ...(key === 'active' ? { recentactivity: true } : {}) })
      //    setSelectedKey(key as 'total' | 'active')
     }}
    />
   </div>
   {/* </div> */}

   {/* <div>
    {valid(totalsms) && (
     <span style={{ ...spanStyle, marginRight: '8px' }}>
      Total SMS
      <Badge
       className="ms-2"
       count={totalsms}
       overflowCount={99999}
       //    color="secondary"
      />
     </span>
    )}
    {valid(totalemail) && (
     //  <BaseButton type="ghost">
     <span style={{ ...spanStyle }}>
      Total Email
      <Badge
       className="ms-2"
       count={totalemail}
       overflowCount={99999}
       //    color="secondary"
       //    status="warning"
      />
     </span>
     //  </BaseButton>
    )}
   </div> */}
  </div>
 )
}

export default RealtorDetailTail
