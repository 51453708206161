import { Modal, Space, Form, Select } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { FC, useState } from 'react'
import AssignToSourceModal from 'components/shared/Modals/AssignToSourceModal'
import { useGlobal } from 'raducer/global'
import { TSelectAllP } from 'features/leads'
import AddRealtorForm from './AddRealtorForm'
import { useLeadTransferTypeMutation } from '../api'

type TActionBlockRealtor = TSelectAllP & {
 refetchRealtor: () => void
 selectedKeys?: any[]
 handleDelete?: () => void
}

const ActionBlockRealtor: FC<TActionBlockRealtor> = ({
 refetchRealtor,
 selectedKeys,
 selectAll,
 unSelected,
 filterInfo,
 handleDelete,
}) => {
 const [showModal, setShowModal] = useState<
  false | 'add-realtor' | 'lead-transfer' | 'source'
 >(false)
 const [leadTransferType, { isLoading: leadTransferTypeLdg }] =
  useLeadTransferTypeMutation()

 const { updateLeadAssignType, global } = useGlobal()
 const { lead_assign_type } = global || {}

 const [form] = Form.useForm<{ lead_transfer_type: string }>()

 const onSubmitForm = async () => {
  const body = {
   unselected: unSelected,
   select_all: selectAll,
   filter_info: filterInfo,
  }
  const values = await form.validateFields()
  await leadTransferType?.({
   ...values,
   ...(selectAll ? { ...body } : {}),
  })?.unwrap()
  setShowModal(false)
  updateLeadAssignType?.(values?.lead_transfer_type ?? lead_assign_type)
 }

 const buttons = [
  {
   tooltipText: 'Add Realtor',
   onClick: () => setShowModal('add-realtor'),
   icon: <i className="fas fa-plus me-2" />,
  },
  {
   tooltipText: 'Lead Transfer',
   onClick: () => setShowModal('lead-transfer'),
  },
  {
   tooltipText: 'Assign Source',
   onClick: () => setShowModal('source'),
   icon: <i className="me-2 far fa-book-spells" />,
   disabled: !selectedKeys?.length,
  },
 ]

 const commonModalProps = {
  selectAll,
  unSelected,
  filterInfo,
  onCancel: () => setShowModal(false),
  visible: true,
  refetch: refetchRealtor,
 }

 return (
  <>
   <Space>
    {buttons?.map(
     (
      { tooltipText, disabled = (selectedKeys?.length ?? 0) > 0, ...props },
      n,
     ) => (
      <BaseButton
       tooltipText={tooltipText}
       type="primary"
       disabled={disabled}
       key={n}
       {...(props as any)}
      >
       {tooltipText}
      </BaseButton>
     ),
    )}
   </Space>
   {showModal === 'add-realtor' && (
    <AddRealtorForm {...(commonModalProps as any)} />
   )}

   {showModal === 'lead-transfer' && (
    <Modal
     confirmLoading={leadTransferTypeLdg}
     onOk={onSubmitForm}
     okText="Submit"
     title="Lead Transfer"
     onCancel={() => {
      setShowModal(false)
      form.resetFields()
     }}
     visible={showModal === 'lead-transfer'}
    >
     <Form
      initialValues={{ lead_transfer_type: lead_assign_type }}
      form={form}
      layout="vertical"
     >
      <Form.Item
       rules={[{ required: true, message: 'Transfer type is required' }]}
       name="lead_transfer_type"
       label="Type"
      >
       <Select
        placeholder="Select Mode"
        options={[
         { value: 'Manual', label: 'Manual' },
         { value: 'Automatic', label: 'Automatic' },
        ]}
       />
      </Form.Item>
     </Form>
    </Modal>
   )}
   {showModal === 'source' && (
    <AssignToSourceModal
     selectedKeys={selectedKeys}
     type="realtors"
     {...(commonModalProps as any)}
    />
   )}
  </>
 )
}

export default ActionBlockRealtor
