import { Col, Row } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import UploadLeadFileModal from 'components/shared/Modals/UploadLeadFileModal'
import { useDeleteLeadFileMutation } from 'features/leads/api'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TLeadFileType } from 'features/leads/types'
import { FC, useState } from 'react'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

const FIleListBlock: FC<{
 data?: TLeadFileType[]
 isTransferred?: boolean
}> = ({ data, isTransferred }) => {
 const [deleteLeadFile] = useDeleteLeadFileMutation()
 const [showModal, setShowModal] = useState(false)
 const { leadId } = useLeadDetails()

 const handleDeleteFile = (id?: any) => {
  globalConfirm({
   content: 'Are you sure want to delete this file?',
   onOk: async () => {
    await deleteLeadFile({ id: [id] }).unwrap()
   },
  })
 }

 return (
  <>
   <ProInnerCard
    emptyText="Files is Empty"
    isEmpty={!data || data?.length === 0}
    onCLickAdd={isTransferred ? () => setShowModal(true) : undefined}
    title="Files"
   >
    <Row gutter={[15, 15]}>
     {data &&
      data.map((item, i) => (
       <Col span={24} key={i}>
        <SimpleBlockItem
         actions={
          isTransferred
           ? [
              <BaseButton
               style={{ zIndex: '2', position: 'relative' }}
               key={3}
               buttonStyle="outline"
               onClick={() => handleDeleteFile?.(item.id)}
               size="small"
               buttonType="danger"
               icon={<i className="fas fa-trash" />}
              />,
             ]
           : undefined
         }
         text={
          <a href={item.url} rel="noreferrer" target="_blank">
           {item.filename}
          </a>
         }
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   <UploadLeadFileModal
    leadId={leadId}
    visible={showModal}
    onCancel={() => setShowModal(false)}
   />
  </>
 )
}

export default FIleListBlock
