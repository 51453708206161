import { Drawer, List } from 'antd'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import useFetchPage2 from 'hooks/useFetchPage2'
import React, { FC } from 'react'
import { TGetNotification } from 'types'
import NotificationItem from './NotificationItem'

const NoticationModule: FC<{
 visible?: boolean
 onCancel?: () => void
}> = ({ visible, onCancel }) => {
 const { flatData, hasMore, fetchMore, isLoading } =
  useFetchPage2<TGetNotification>('all-notifications', {
   method: 'get',
  })

 return (
  <Drawer
   visible={visible}
   onClose={onCancel}
   width={480}
   title="Notification"
   extra={<i className="fab fa-faceboo" />}
  >
   <InfinityPagination useWindow={false} hasMore={hasMore} loadMore={fetchMore}>
    <List
     loading={isLoading}
     className="notification-list"
     itemLayout="horizontal"
     dataSource={flatData}
     renderItem={(item) => {
      return item.type === 'notification' ? (
       <NotificationItem data={item} onCancel={onCancel} />
      ) : item.type === 'title' ? (
       <p className="title">{item.title}</p>
      ) : null
     }}
    />
   </InfinityPagination>
  </Drawer>
 )
}

export default NoticationModule
