import { Col, Row, Select, SelectProps } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { TTemplateName } from 'features/settings'
import React, { FC } from 'react'

const TemplateEditorSelect: FC<
 {
  onAdd?: () => void
  placeholder?: string
  loading?: boolean
  TemplateNames?: TTemplateName[]
  onEdit?: (val?: any) => void
 } & SelectProps
> = ({
 TemplateNames,
 loading,
 placeholder,
 onEdit,
 onAdd,
 value,
 ...rest
}) => {
 return (
  <Row>
   <Col flex={1}>
    <Select
     {...rest}
     value={loading ? null : value}
     loading={loading}
     placeholder={placeholder}
     className="w-100"
    >
     {TemplateNames &&
      TemplateNames.map((v, i) => (
       <Select.Option value={`${v.id}`} key={i}>
        {`${v.name} (${v?.owner})`}
       </Select.Option>
      ))}
    </Select>
   </Col>
   {value && (
    <Col>
     <BaseButton
      tooltipText="Edit Template"
      type="link"
      onClick={() => onEdit?.(value)}
      icon={<i className="fas fa-edit" />}
     />
    </Col>
   )}
   {onAdd && (
    <Col>
     <BaseButton
      tooltipText="Add Template"
      type="link"
      onClick={onAdd}
      icon={<i className="fas fa-plus" />}
     />
    </Col>
   )}
  </Row>
 )
}
export default TemplateEditorSelect
