import { Divider, Modal, Spin } from 'antd'
import {
 TPreviewTemplateBody,
 //  TPreviewType,
 //  TSendMailBody,
 //  TSendType,
 usePreviewEmailLeadQuery,
} from 'features/leads'
// import useAuth from 'hooks/useAuth'
import { FC } from 'react'
// import { TenplateAttachment } from './SendEmail'

type EmailPreviewProp = {
 //  type?: TSendType
 //  data?: TSendMailBody
 visible: boolean
 onCancel: () => void
 body: TPreviewTemplateBody
}

const EmailPreview: FC<EmailPreviewProp> = ({
 visible,
 onCancel,
 body,
 //  type = 'lead',
}) => {
 //  const { user } = useAuth()
 const {
  data: preview,
  isLoading,
  isError,
 } = usePreviewEmailLeadQuery(
  //   {
  //    body: data?.body ?? null,
  //    from: user?.twilio_email,
  //    subject: data?.subject,
  //    to: Array.isArray(data?.to) ? data?.to.join(',') : data?.to,
  //    type,
  //    property: data?.property,
  //    prop_full_info: JSON.stringify(data?.prop_full_info),
  //   },
  body,
  { skip: !body?.to && !visible },
 )

 if (isError && !preview) {
  onCancel?.()
 }

 return (
  <Modal
   forceRender
   width={800}
   onCancel={onCancel}
   visible={visible}
   title="Email Preview"
   footer={null}
  >
   <Spin className="w-100" spinning={isLoading}>
    {preview && (
     <>
      <p className="mb-1">
       <strong>To</strong>:{preview?.to}{' '}
      </p>
      {preview.subject && (
       <p className="mb-1">
        <strong>Subject</strong>:{preview?.subject ?? ''}{' '}
       </p>
      )}
      <Divider />
      <div className="mail-preview-body">
       {preview?.body && (
        <div dangerouslySetInnerHTML={{ __html: `${preview?.body}` }} />
       )}
      </div>
     </>
    )}
    {/* <TenplateAttachment tempAtach={data?.temp_attachment} /> */}
   </Spin>
  </Modal>
 )
}

export default EmailPreview
