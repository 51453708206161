import {
 baseAPI,
 baseTransformResponse,
 baseTransformResponse2,
 baseTransformResponse3,
} from 'api/baseApi'
import { TPlanStatusPost, TSelectAllB, TSelectAllP } from 'features/leads'
import { queryClient } from 'lib/react-query'
import { ApiResponce, TGroupName } from 'types'
import {
 TActionPlanView,
 TAutomationTypes,
 TGetActionPlan,
 TNewsLetter,
} from '../types'

export const refetchAPList = () =>
 queryClient.invalidateQueries('get-action-plans')

export const actionPlanApi = baseAPI.injectEndpoints({
 overrideExisting: true,
 endpoints: ({ query, mutation }) => ({
  addActionPlan: mutation<void, FormData>({
   query: (body) => ({
    url: 'user/put-action-plan',
    method: 'post',
    body,
   }),
   async onQueryStarted(_, { queryFulfilled }) {
    await queryFulfilled
    refetchAPList()
   },
   transformResponse: baseTransformResponse,
  }),
  viewActionPlan: query<TActionPlanView, { id?: any }>({
   query: ({ id }) => `user/show-action-plan/${id}`,
   transformResponse: (res: ApiResponce) => res.data,
  }),
  updateActionPlan: mutation<void, { id?: any; body: FormData }>({
   query: ({ body, id }) => ({
    url: `user/update-action-plan/${id}`,
    method: 'post',
    body,
   }),
   async onQueryStarted(_, { queryFulfilled }) {
    await queryFulfilled
    refetchAPList()
   },
   transformResponse: baseTransformResponse,
  }),
  getActionPlanById: query<TGetActionPlan, { id?: any }>({
   query: ({ id }) => `user/get-action-plan/${id}`,
   transformResponse: (res: ApiResponce) => res.data,
  }),
  assignToActionPlanBulk: mutation<
   void,
   {
    type?: TGroupName
    type_id: any[]
    actionplan_id?: any[]
   }
  >({
   query: (body) => ({
    url: 'user/assign-action-plan-bulk',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  deleteBulkActionPlan: mutation<void, { id?: any[] }>({
   query: (body) => ({
    method: 'post',
    url: 'user/delete-action-plans',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  controlActionPlan: mutation<
   void,
   { plan_id: any; lead_id?: any; action: TPlanStatusPost }
  >({
   query: (body) => ({
    url: 'user/control-action-plan',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  controlNewsLetter: mutation<
   void,
   TSelectAllB & {
    type: 'Real-Estate-News-Letter' | 'market-update'
    newsletter_id: number[]
    lead_id?: number[]
    action: 'restart' | 'pause' | 'Delete'
   }
  >({
   query: (body) => ({
    url: 'user/newsletters/controle',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  withdrawActionPlanLead: mutation<
   void,
   { lead_id?: string; actionplan_id?: string }
  >({
   query: (body) => ({
    method: 'post',
    url: 'user/withdraw-action-plan-lead',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  withdrawActionplanBulk: mutation<
   void,
   { type?: TGroupName; type_id?: any; plan_id?: any }
  >({
   query: (body) => ({
    method: 'post',
    url: 'user/withdraw-action-plan-bulk',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  withdrawMarketUpdatesTags: mutation<
   void,
   { type?: TGroupName; type_id?: any; newsletter_id?: any }
  >({
   query: (body) => ({
    method: 'post',
    url: 'user/newsletter/remove',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  withdrawActionplan: mutation<void, { lead_id?: any; actionplan_id?: any[] }>({
   query: (body) => ({
    method: 'post',
    url: 'user/withdraw-action-plan',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  shareActionPlan: mutation<
   void,
   TSelectAllB & { realtor?: string; actionplan_id?: string }
  >({
   query: (body) => ({
    method: 'post',
    url: 'user/share-action-plan',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  getNewsLetter: query<TNewsLetter, { id: number; type: TAutomationTypes }>({
   query: ({ id, type }) => ({
    method: 'post',
    url: `user/newsletters/${id}`,
    body: { type },
   }),
   transformResponse: baseTransformResponse3,
  }),
  assignBulkNewsLetter: mutation<
   void,
   TSelectAllB & {
    newsletter_type: TAutomationTypes
    type: 'lead' | 'newsletter'
    type_id?: number[]
    newsletter_id?: number[]
    lead_id?: number[]
    selectall_type?: 'tag' | 'source' | 'stage' | 'lead'
   }
  >({
   query: (body) => ({
    method: 'post',
    url: 'user/newsletters/assignBulk',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useViewActionPlanQuery,
 useLazyViewActionPlanQuery,
 useGetActionPlanByIdQuery,
 useLazyGetActionPlanByIdQuery,
 useAddActionPlanMutation,
 useUpdateActionPlanMutation,
 useAssignToActionPlanBulkMutation,
 useDeleteBulkActionPlanMutation,
 useControlActionPlanMutation,
 useWithdrawActionPlanLeadMutation,
 useWithdrawActionplanBulkMutation,
 useWithdrawActionplanMutation,
 useShareActionPlanMutation,
 useGetNewsLetterQuery,
 useAssignBulkNewsLetterMutation,
 useWithdrawMarketUpdatesTagsMutation,
 useControlNewsLetterMutation,
} = actionPlanApi
