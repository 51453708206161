import BaseSelector, {
 BaseSelectorOption,
} from 'components/elements/BaseSelector'
import { TNewRealtor } from 'features/realtors/types'
import useFetchPage from 'hooks/useFetchPage'
import { FC, useEffect, useMemo, useState } from 'react'

const RealtorSelector: FC<{
 onChange?: (v?: BaseSelectorOption[]) => void
 value?: BaseSelectorOption[]
}> = ({ onChange, value }) => {
 const [searchLeadKey, setLeadKey] = useState('')
 const [selected, setSelected] = useState<BaseSelectorOption[]>(value || [])

 const { flatData, fetchMore, hasMore, isLoading } = useFetchPage<TNewRealtor>(
  'search-new-realtor',
  {
   method: 'post',
   body: useMemo(() => ({ search: searchLeadKey }), [searchLeadKey]),
  },
 )
 useEffect(() => {
  if (value) {
   setSelected?.(value)
  }
 }, [value])

 return (
  <BaseSelector
   isLoading={isLoading}
   hasMore={hasMore}
   fetchMore={fetchMore}
   value={selected}
   showSearch
   onChange={(val) => {
    setSelected?.(val)
    onChange?.(val)
   }}
   options={flatData
    ?.map((v) => ({
     id: v?.id,
     title: v?.text,
    }))
    ?.filter?.((v) => v?.id)}
   onSearch={setLeadKey}
   valueBy="id"
  />
 )
}

export default RealtorSelector
