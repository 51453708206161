import { ColumnsType } from 'antd/lib/table'
import { Space } from 'antd'
import { TSSBlockList } from 'components/shared/Common'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { ReactNode } from 'react'
import { TActionPlanList } from '../../types'

type APTableProps = {
 handleClickEdit?: (id?: any) => void
 handleClickView?: (id?: any) => void
 //  clickActiveModal: (id?: any) => void
 handleControlAP?: (
  type: 'Pause' | 'Stop' | 'Completed' | 'Running',
  id?: any,
 ) => void
 selectedKeys?: any[]
 selectAll?: boolean
 unSelected?: any[]
 mode: 'action-plan' | 'real-estate-news' | 'market-updates'
 refetch?: () => void
}

const getCount = (val: number, onClick: () => void): ReactNode => {
 if ((val ?? 0) > 0) {
  return (
   <BtnAnch className="colored-link" onClick={onClick}>
    {val}
   </BtnAnch>
  )
 }
 return <a className="text-dark">{val}</a>
}

const TableColumns = ({
 handleClickEdit,
 handleClickView,
 //  clickActiveModal,
 handleControlAP,
 selectAll,
 selectedKeys,
 unSelected,
 mode,
 refetch,
}: APTableProps): MDataTableColumns<TActionPlanList>[] => [
 {
  title: 'Name',
  dataIndex: 'name',
  size: 310,
  fixed: 'left',
  // render: (_e, r) => `${r?.name} (${r?.owner})`,
 },
 ...(mode === 'action-plan'
  ? [
     {
      title: 'Steps',
      dataIndex: 'steps_count',
      size: 90,
     },
    ]
  : []),
 {
  title: 'Active',
  // dataIndex: mode === 'action-plan' ? 'active_leads_count' : 'active',
  size: 90,
  // render: (_e, r) =>
  //  (mode === 'action-plan' ? r.active_leads_count ?? 0 : r?.active ?? 0) > 0 ? (
  //   <BtnAnch
  //    className="colored-link"
  //    onClick={() => handleControlAP?.('Running', r?.id)}
  //   >
  //    {r.active_leads_count}
  //   </BtnAnch>
  //  ) : (
  //   <a className="text-dark">{r.active_leads_count}</a>
  //  ),
  render: (_e, r) =>
   getCount(
    mode === 'action-plan' ? r.active_leads_count ?? 0 : r?.active ?? 0,
    () => handleControlAP?.('Running', r?.id),
   ),
 },
 {
  title: 'Paused',
  // dataIndex: mode === 'action-plan' ? 'paused_leads_count' : 'paused',
  size: 90,
  // render: (_e, r) =>
  //  r.paused_leads_count > 0 ? (
  //   <BtnAnch
  //    className="colored-link"
  //    onClick={() => handleControlAP?.('Pause', r.id)}
  //   >
  //    {r.paused_leads_count}
  //   </BtnAnch>
  //  ) : (
  //   <a className="text-dark">{r.paused_leads_count}</a>
  //  ),
  render: (_e, r) =>
   getCount(
    mode === 'action-plan' ? r.paused_leads_count ?? 0 : (r as any)?.paused,
    () => handleControlAP?.('Pause', r.id),
   ),
 },
 ...(mode !== 'action-plan'
  ? [
     {
      title: 'Frequency',
      dataIndex: 'frequency',
      size: 120,
     },
     {
      title: 'Run on',
      dataIndex: 'run_on',
      size: 150,
     },
     {
      title: 'Next Email On',
      dataIndex: 'next_email',
      size: 180,
     },
    ]
  : []),
 ...(mode === 'action-plan'
  ? [
     {
      title: 'Stopped',
      dataIndex: 'stoped_leads_count',
      size: 90,
      // render: (_e: any, r: any) =>
      //  r.stoped_leads_count > 0 ? (
      //   <BtnAnch
      //    className="colored-link"
      //    onClick={() => handleControlAP?.('Stop', r.id)}
      //   >
      //    {r.stoped_leads_count}
      //   </BtnAnch>
      //  ) : (
      //   <a className="text-dark">{r.stoped_leads_count}</a>
      //  ),
      render: (_e: any, r: any) =>
       getCount(r.stoped_leads_count ?? 0, () =>
        handleControlAP?.('Stop', r.id),
       ),
     },

     {
      title: 'Completed',
      dataIndex: 'completed_leads_count',
      size: 110,
      render: (_e: any, r: any) =>
       getCount(r.completed_leads_count ?? 0, () =>
        handleControlAP?.('Completed', r.id),
       ),
     },
     {
      title: 'Created By',
      dataIndex: 'owner',
      size: 150,
     },
    ]
  : []),
 {
  title: 'Tags',
  // ellipsis: true,
  size: 200,
  render: (_e, r) => {
   return (
    <TSSBlockList
     typeId={r.id}
     data={mode === 'action-plan' ? r.tags : (r as any)?.tag}
     mode="tag"
     type={mode === 'action-plan' ? 'actionPlan' : 'market-updates'}
     closable
     onRemoveSuccess={mode !== 'action-plan' ? () => refetch?.() : undefined}
    />
   )
  },
 },
 {
  title: 'Stage',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    data={mode === 'action-plan' ? r.stages : (r as any)?.stage}
    mode="stage"
    type={mode === 'action-plan' ? 'actionPlan' : 'market-updates'}
    closable
    onRemoveSuccess={mode !== 'action-plan' ? () => refetch?.() : undefined}
   />
  ),
 },
 {
  title: 'Sources',
  // dataIndex: 'completed_leads_count',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    data={mode === 'action-plan' ? r.sources : (r as any)?.source}
    mode="source"
    type={mode === 'action-plan' ? 'actionPlan' : 'market-updates'}
    closable
    onRemoveSuccess={mode !== 'action-plan' ? () => refetch?.() : undefined}
   />
  ),
 },
 {
  title: 'Action',
  size: 80,
  fixed: 'right',
  render: (_, record, index) => (
   <Space>
    <BaseButton
     disabled={
      ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(record?.id)) ||
      (selectAll && !unSelected?.includes(record?.id))
     }
     //  onClick={() =>
     //   handleClickView?.(mode === 'action-plan' ? record.id : index)
     //  }
     onClick={() => handleClickView?.(record.id)}
     tooltipText="View"
     shape="circle"
     size="small"
     buttonType="danger"
     icon={<i className="fas fa-eye" />}
    />
    {mode === 'action-plan' && (
     <BaseButton
      disabled={
       ((selectedKeys?.length ?? 0) > 1 &&
        selectedKeys?.includes(record?.id)) ||
       (selectAll && !unSelected?.includes(record?.id))
      }
      onClick={() => handleClickEdit?.(record.id)}
      tooltipText="Edit"
      shape="circle"
      size="small"
      buttonType="info"
      icon={<i className="fas fa-edit" />}
     />
    )}
   </Space>
  ),
 },
]

export default TableColumns
