import { Card } from 'antd'
import React, { FC, ReactNode } from 'react'
import { SelectType, TColor } from 'types'

const DashboardCard: FC<{
 title?: string
 extra?: SelectType[]
 color?: TColor
 icon?: ReactNode
 loading?: boolean
}> = ({ title, extra, color = 'primary', icon, loading }) => (
 <Card loading={loading} className={`dash-card dash-card-${color}`}>
  <h4 className="dash-title">
   {icon} {title}
  </h4>
  <ul className="extra-dash">
   {extra?.map((v, i) => (
    <li key={`card-${i}`}>
     <h6>{v.label}</h6>
     <p>{v.value}</p>
    </li>
   ))}
  </ul>
  {/* <div className="dash-icon">{icon}</div> */}
 </Card>
)

export default DashboardCard
