/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import storage from 'utils/storage'
import { authAPI } from '../api'
import { AuthState } from '../types'

const initialState: AuthState = {
 isAuthenticated: !!storage.getToken(),
 loading: false,
 token: storage.getToken(),
 brokerage_data: [],
}

export const authSlice = createSlice({
 name: 'name',
 initialState,
 reducers: {
  changeSignature: (state, payload) => {
   if (state?.user) {
    state.user.signature = payload?.payload
   }
  },
  changeUserData: (state, payload) => {
   if (state?.user && payload?.payload) {
    const updateVal = { ...state?.user, ...payload?.payload }
    state.user = updateVal
   }
  },
 },
 extraReducers: ({ addMatcher }) => {
  addMatcher(authAPI.endpoints.profile.matchFulfilled, (state, { payload }) => {
   if (payload) {
    state.user = payload
   }
  })
  addMatcher(authAPI.endpoints.Login.matchFulfilled, (state, { payload }) => {
   if (payload) {
    ;(state.isAuthenticated = true),
     (state.token = payload),
     storage.setToken(payload)
   }
  })
  addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
   ;(state.isAuthenticated = false), (state.token = null), storage.clearToken()
  })
 },
})

export const selectAuth = (state: RootState): AuthState => state.auth

export default authSlice.reducer

export const { changeSignature, changeUserData } = authSlice.actions
