import { Row, Col, Form, Input, Switch } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { BaseEditor } from 'components/elements/BaseEditor'
import { settingApi, useUpdateSignatureMutation } from 'features/settings/api'
import { TGetAllSignatures } from 'features/settings/types'
import { useAppDispatch } from 'hooks'
import useAuth from 'hooks/useAuth'
import React, { FC, useEffect, useState } from 'react'

const SignatureEditor: FC<{
 mode: 'add' | 'edit'
 values?: TGetAllSignatures
 onSuccess?: () => void
 setEditItem: React.Dispatch<
  React.SetStateAction<TGetAllSignatures | undefined>
 >
 onClickCancelOnAdd: () => void
 setSelected: React.Dispatch<
  React.SetStateAction<TGetAllSignatures | undefined>
 >
}> = ({
 mode,
 values,
 onSuccess,
 setEditItem,
 onClickCancelOnAdd,
 setSelected,
}) => {
 const [form] = Form.useForm<{
  signature: any
  name: string
  is_default: boolean
 }>()
 const [loadingEditor, setLoadingEditor] = useState(true)
 const [updateSignature, { isLoading }] = useUpdateSignatureMutation()
 const d = useAppDispatch()
 const { changeSignature, user } = useAuth()

 const setFieldIntialValues = (): void => {
  if (mode === 'add' && !values) {
   form.resetFields()
  } else if (values && mode === 'edit') {
   form.setFieldsValue({
    signature: values?.signature,
    name: values?.name,
    is_default: (values?.is_default as any) === 1,
   })
  }
 }

 useEffect(() => {
  setFieldIntialValues()
 }, [values, mode, loadingEditor])

 const handleSubmit = async (): Promise<void> => {
  const formValues = await form.validateFields()
  if (!(formValues?.is_default || mode === 'add')) {
   setEditItem({ id: values?.id, ...formValues })
  }

  await updateSignature({
   ...formValues,
   method: mode === 'add' ? 'post' : 'put',
   ...(mode === 'edit' ? { id: values?.id } : {}),
  })
   .unwrap()
   .then((res) => {
    const { created_at, updated_at, ...upSign } = res || {}

    if (mode === 'add' && !formValues?.is_default) {
     setEditItem({ ...upSign })
    }
    if (!formValues?.is_default && values?.id === user?.signature?.id) {
     changeSignature(null)
    }
    if (formValues?.is_default) {
     setEditItem(undefined)
     changeSignature?.({ ...upSign })
     onSuccess?.()
    } else {
     d(
      settingApi.util.updateQueryData(
       'getAllSignatures',
       undefined,
       (draft) => {
        if (mode === 'add') {
         return [...draft, { ...upSign }]
        }
        return draft?.map((i) => {
         if (i?.id === upSign?.id) {
          return { ...upSign }
         }
         return i
        })
       },
      ),
     )
    }
   })
   .catch((err) => {
    //
   })
 }

 return (
  <div className="signature-section">
   <h2>{mode === 'add' ? 'Add New' : 'Update'} Signature</h2>
   <Form
    layout="vertical"
    form={form}
    {...(mode === 'add' ? { initialValues: { is_default: true } } : {})}
   >
    <Row gutter={15}>
     <Col span={12}>
      <Form.Item
       rules={[
        { required: true, message: 'Name is required' },
        { max: 80, message: 'Name must be less than 80 characters' },
       ]}
       name="name"
       label="Signature Name"
      >
       <Input />
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item
       name="is_default"
       style={{ marginTop: '30px' }}
       label="Set as default"
       wrapperCol={{ span: 6 }}
       labelCol={{ span: 6 }}
       valuePropName="checked"
       className="make-as-default-form-item"
      >
       <Switch style={{ backgroundColor: 'green', marginLeft: '10px' }} />
      </Form.Item>
     </Col>

     <Col span={24}>
      <Form.Item name="signature">
       <BaseEditor
        height={450}
        loading={loadingEditor}
        setLoading={setLoadingEditor}
       />
      </Form.Item>
     </Col>
    </Row>

    {!loadingEditor && (
     <Row>
      <Col span={24} className="d-flex justify-content-end">
       {/* <Space> */}
       <BaseButton
        onClick={() => {
         if (mode === 'add') {
          onClickCancelOnAdd()
         } else {
          setFieldIntialValues()
         }
        }}
        // buttonType="gray"
        customClass="me-2"
       >
        Cancel
       </BaseButton>
       <BaseButton
        buttonType="primary"
        onClick={handleSubmit}
        loading={isLoading}
       >
        {mode === 'add' ? 'Add' : 'Update'} Signature
       </BaseButton>

       {/* </Space> */}
      </Col>
     </Row>
    )}
   </Form>
  </div>
 )
}

export default SignatureEditor
