import { Modal } from 'antd'
import {
 TAlertFeedType,
 useAssignToActionPlanLeadMutation,
 useAssignToLeadAlertMutation,
} from 'features/alert'
import { TSelectAllP } from 'features/leads'
import { FC, useState } from 'react'
import {
 TAutomationTypes,
 useAssignBulkNewsLetterMutation,
} from 'features/automation'
import SelectLeadBlock from '../LoadMoreBlocks/SelectLeadBlock'
import MessageModal from './MessageModal'

const AssignToLeadModal: FC<
 TSelectAllP & {
  type?: 'lead' | 'action-plan' | 'alert' | TAutomationTypes
  selectedKeys?: any[]
  visible: boolean
  onCancel: () => void
  alertType?: TAlertFeedType
  refetch?: () => void
  extrabody?: { [key: string]: any }
 }
> = ({
 onCancel,
 visible,
 selectedKeys,
 type = 'lead',
 filterInfo,
 selectAll,
 unSelected,
 alertType,
 refetch,
 extrabody = {},
}) => {
 const [selecteLead, setLead] = useState([])
 const [assignAlertLead, { isLoading: leadLoading }] =
  useAssignToLeadAlertMutation()
 const [assignToLead, { isLoading: actionplanLoading }] =
  useAssignToActionPlanLeadMutation()
 const [assignBulkNewsLetter, { isLoading: newsLetterLoading }] =
  useAssignBulkNewsLetterMutation()
 const [successModal, setModal] = useState<{
  show: boolean
  message: null | string
 }>({ show: false, message: null })

 const handleClose = () => {
  onCancel?.()
  setLead([])
 }

 const handleSubmit = async () => {
  const body: any = { lead_id: selecteLead }
  if (selectAll) {
   body.select_all = selectAll
   body.unselected = unSelected
   body.filter_info = filterInfo
  }
  if (type === 'action-plan' || (type as any) === 'actionPlan') {
   await assignToLead({
    ...body,
    actionplan_id: selectAll ? [] : selectedKeys,
    type: 'action-plan',
   })
    .unwrap()
    .then((res: any) => {
     if (res?.status === 'success') {
      setModal({ show: true, message: res?.message })
     }
    })
  } else if (type === 'alert') {
   await assignAlertLead({
    ...body,
    alert_id: selectAll ? [] : selectedKeys,
    type: 'alert',
    feed: alertType,
   }).unwrap()
   handleClose?.()
  } else if (type === 'Real-Estate-News-Letter' || type === 'market-update') {
   await assignBulkNewsLetter({
    ...body,
    selectall_type: 'lead',
    newsletter_id: selectAll ? [] : selectedKeys,
    newsletter_type: type,
    ...(extrabody || { type: 'lead' }),
   }).unwrap()
   refetch?.()
   handleClose?.()
  }
 }

 return (
  <>
   <Modal
    confirmLoading={actionplanLoading || leadLoading || newsLetterLoading}
    okButtonProps={{ disabled: !selecteLead.length }}
    onOk={handleSubmit}
    okText="Submit"
    onCancel={handleClose}
    visible={visible}
    title="Assign to Lead"
   >
    <SelectLeadBlock mode="multiple" onChange={(val) => setLead(val)} />
   </Modal>

   {successModal?.show && (
    <MessageModal
     visible={successModal?.show}
     onHide={() => {
      setModal({ message: null, show: false })
      handleClose()
     }}
     message={successModal?.message}
    />
   )}
  </>
 )
}

export default AssignToLeadModal
