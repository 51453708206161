import { Col, Row } from 'antd'
import AddCityModal from 'components/shared/Modals/AddCityModal'
import React, { FC, useState } from 'react'
import { useDeleteCityMutation } from 'features/leads/api'
import { globalConfirm } from 'components/elements/global'
import { TCity } from 'features/leads/types'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

export type TAddCity = {
 city?: string
 lead_id?: any
 city_id?: any
}

const CityBlock: FC<{ city?: TCity[]; isTransferred?: boolean }> = ({
 city,
 isTransferred,
}) => {
 //  const [showModal, setShowModal] = useState(false)
 const [showModal, setShowModal] = useState<{
  show?: boolean
  data?: any
  city_id?: any
 }>({
  show: false,
  data: null,
  city_id: null,
 })
 const [deleteCity] = useDeleteCityMutation()
 const handleRemoveCity = (id?: any) => {
  globalConfirm({
   content: 'Are you sure want remove City from this Lead?',
   onOk: async () => {
    await deleteCity({ city_id: id }).unwrap()
   },
  })
 }
 return (
  <div className="mb-3">
   <ProInnerCard
    title="City"
    // onCLickAdd={city || !isTransferred ? undefined : () => setShowModal(true)}
    onCLickAdd={isTransferred ? () => setShowModal({ show: true }) : undefined}
    //  isEmpty={!city}
    isEmpty={city?.length === 0}
    emptyText="No City Added"
   >
    {/* {city && ( */}
    {city?.length !== 0 && (
     <Row gutter={[15, 15]}>
      {city?.map((item, key) => (
       <Col span={24} key={key}>
        <SimpleBlockItem
         text={item.city}
         onEdit={
          isTransferred
           ? () =>
              setShowModal({ show: true, data: item.city, city_id: item.id })
           : undefined
         }
         onDelete={isTransferred ? () => handleRemoveCity(item.id) : undefined}
        />

        {/* <SimpleBlockItem
        text={city}
        onEdit={isTransferred ? () => setShowModal(true) : undefined}
       /> */}
       </Col>
      ))}
     </Row>
    )}
   </ProInnerCard>
   {/* {showModal && (
    <AddCityModal
     visible={showModal}
     onCancel={() => setShowModal(false)}
     city={city}
    />
   )} */}
   {showModal.show && (
    <AddCityModal
     visible={showModal.show}
     onCancel={() => setShowModal({ show: false })}
     city={showModal.data}
     id={showModal.city_id}
    />
   )}
  </div>
 )
}

export default CityBlock
