import { Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useAddBackGroundMutation } from 'features/leads'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import React, { FC, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

export type TAddBackGroundType = {
 background?: any
 lead_id?: any
}

const BackgroundModal: FC<{
 visible?: boolean
 onCancel?: () => void
 background?: any
}> = ({ visible, onCancel, background }) => {
 const [form] = useForm<TAddBackGroundType>()

 const { leadId } = useLeadDetails()

 const [addBackGround, { isLoading }] = useAddBackGroundMutation()

 const HandleUpdateBcakground = async () => {
  const values = await form.validateFields()
  await addBackGround({ ...values, lead_id: leadId }).unwrap()
  form.resetFields()
  onCancel?.()
 }

 useEffect(() => {
  if (background) {
   form.setFieldsValue({
    background,
   })
  }
 }, [background])

 return (
  <Modal
   visible={visible}
   onCancel={onCancel}
   title={`${background ? 'Update' : 'Add'} Background`}
   onOk={HandleUpdateBcakground}
   confirmLoading={isLoading}
   okText={background ? 'Update' : 'Add'}
  >
   <Form form={form}>
    <Form.Item name="background">
     {/* <TextArea
      className="scroll-text-area"
      rows={8}
      placeholder="Enter background"
     /> */}
     <TextareaAutosize
      className="w-100 auto-size-text-area"
      minRows={3}
      maxRows={3}
      //   maxRows={10}
      placeholder="Enter background"
     />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default BackgroundModal
