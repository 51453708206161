import { Input, Modal } from 'antd'
import { LoadMoreSelect, MultiSelector } from 'components/elements/Form'
import { TGetActionPlansName } from 'features/automation'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useAssignActionPlanLeadMutation,
} from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import { FC, useMemo, useState } from 'react'

type AssignAlertProp = TSelectAllP & {
 activeLeadId?: string
 lead_id?: number[]
 visible: boolean
 onCancel: () => void
 onSuccess?: () => void
 extraBody?: any
 customtSelectAll?: TLeadCustomSelectAll
}

const AssignActionPlan: FC<AssignAlertProp> = ({
 visible,
 onCancel,
 lead_id,
 activeLeadId,
 onSuccess,
 selectAll,
 unSelected,
 filterInfo,
 extraBody = {},
 customtSelectAll,
}) => {
 const [selectedAP, setAP] = useState([])
 const [searchKey, setSearch] = useState('')
 const { flatData, isLoading, hasMore, fetchMore, isRefetching } =
  useFetchPage<TGetActionPlansName>('get-action-plans-name', {
   method: 'post',
   body: useMemo(
    () => ({ search: searchKey, lead_id: activeLeadId ?? '' }),
    [activeLeadId, searchKey],
   ),
  })

 const [assignActionPlan, { isLoading: apLoading }] =
  useAssignActionPlanLeadMutation()

 const handleSubmit = async (): Promise<void> => {
  const body: TSelectAllB & { customtSelectAll?: TLeadCustomSelectAll } = {}
  if (selectAll || customtSelectAll) {
   if (selectAll) {
    body.select_all = selectAll
   } else if (customtSelectAll) {
    body.customtSelectAll = customtSelectAll
   }
   body.unselected = unSelected
   body.filter_info = filterInfo
  }
  await assignActionPlan({
   ...body,
   actionplan_id: [selectedAP],
   lead_id: selectAll || customtSelectAll ? [] : lead_id,
   type: 'lead',
   ...extraBody,
  }).unwrap()
  onSuccess?.()
  handleClose()
 }

 const handleClose = () => {
  onCancel?.()
  setAP([])
 }

 return (
  <Modal
   okButtonProps={{ disabled: selectedAP.length === 0 }}
   //    width={400}
   confirmLoading={apLoading}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title="Assign to Action Plan"
  >
   <LoadMoreSelect
    onChange={(val) => setAP(val as any)}
    // mode="multiple"
    data={flatData?.map((v) => ({
     value: v?.id,
     label: `${v?.name} (${v?.owner})`,
    }))}
    onSearch={setSearch}
    placeholder="Select Action Plan"
    hasMore={hasMore}
    fetchMore={fetchMore}
    loading={isLoading || isRefetching}
   />
  </Modal>
 )
}

export default AssignActionPlan
