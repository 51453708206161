import { Col, Row } from 'antd'
import { useGetDashBoardDataQuery } from 'api/baseApi'
import {
 SvgActionPlanIcon,
 SvgAutomationIcon,
 SvgInbox,
 SvgTaskIcon,
} from 'components/icons'
import ListContentLayout from 'layout/ListContentLayout'
import DashboardCard from '../components/DashboardCard'
import RecentLead from '../components/RecentLead'

export const Dashboard = () => {
 const { data, isLoading } = useGetDashBoardDataQuery()
 return (
  <ListContentLayout bodyClass="dashboard-page" title="Dashboard">
   <div>
    <Row gutter={[10, 10]}>
     <Col span={24}>
      <Row gutter={[10, 10]} className="dashboardcard-icons">
       <Col flex={1}>
        <DashboardCard
         loading={isLoading}
         title="Leads"
         icon={<i className="fas fa-users" />}
         extra={[
          { label: 'Today', value: data?.todaylead },
          { label: 'Active', value: data?.activeLeads },
         ]}
        />
       </Col>
       <Col flex={1}>
        <DashboardCard
         loading={isLoading}
         title="Action Plan"
         icon={<SvgActionPlanIcon />}
         extra={[{ label: 'Active', value: data?.activeplans }]}
        />
       </Col>
       <Col flex={1}>
        <DashboardCard
         loading={isLoading}
         title="Inbox"
         icon={<SvgInbox />}
         extra={[
          { label: 'E-mail', value: data?.inbox.totemail },
          { label: 'SMS', value: data?.inbox.totsms },
         ]}
        />
       </Col>
       <Col flex={1}>
        <DashboardCard
         loading={isLoading}
         title="Todays Events"
         icon={<SvgTaskIcon />}
         extra={[
          { label: 'Tasks', value: data?.events.task },
          { label: 'Appointments', value: data?.events.app },
         ]}
        />
       </Col>
      </Row>
     </Col>
    </Row>
   </div>
   {data?.lead_activity && data.lead_activity.length > 0 && (
    <RecentLead data={data?.lead_activity} loading={isLoading} />
   )}
  </ListContentLayout>
 )
}
