/* eslint-disable react/require-default-props */
import { Tag, Tooltip, TooltipProps } from 'antd'
import React, { FC, ReactNode } from 'react'
import { BtnAnch } from '../global'

const BaseTag: FC<
 {
  label: ReactNode
  onClose?: () => void
  closable?: boolean
  isEdit?: boolean
  OnClickEdit?: () => void
  className?: string
  icon?: ReactNode
  showTooltip?: boolean
  tooltiOptions?: TooltipProps
 } & React.HTMLAttributes<HTMLSpanElement>
> = ({
 closable,
 isEdit = false,
 onClose,
 label,
 className,
 OnClickEdit,
 icon,
 showTooltip = false,
 tooltiOptions = {},
 ...rest
}) => (
 <Tag
  {...rest}
  onClose={(e) => {
   e.preventDefault()
   onClose?.()
  }}
  icon={icon}
  closable={closable}
  style={{ width: 'max-content', maxWidth: '100%', alignItems: 'center' }}
  className={`${className ?? ''} d-inline-flex justify-content-between`}

  //   className={`${className ?? ''}`}

  // color="success"
  // prefixCls="hio-tag"
  // className={`px-2 hio-tag-${type} ${className ?? ''}`}
 >
  {showTooltip ? (
   <Tooltip placement="top" title={label} {...tooltiOptions}>
    <div>{label}</div>
   </Tooltip>
  ) : (
   <span>{label}</span>
  )}

  {isEdit && (
   <BtnAnch style={{ marginLeft: '5px' }} onClick={() => OnClickEdit?.()}>
    <i className="fas fa-edit" />
   </BtnAnch>
  )}
 </Tag>
)

export default BaseTag
