/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TGetLead } from 'features/leads/types'
import React, { FC, useEffect, useState } from 'react'
import { Col, Row, Modal, Input, DatePicker, Form } from 'antd'
import { useUpdateLeadDataMutation } from 'features/leads/api'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { baseTime } from 'lib'
import moment, { Moment } from 'moment'
import { InputPhoneNumber } from 'components/elements/Form'
import Utils from 'utils/helper'
import { utils } from 'utils'
import ProInnerCard from '../../ProInnerCard'

type TBaseInputType =
 | 'whatsapp_no'
 | 'facebook'
 | 'linkedin'
 | 'twitter'
 | 'instagram'
 | 'birthday'
 | 'website'

const LeadBasicDetails: FC<{ data?: TGetLead; isTransferred?: boolean }> = ({
 data,
 isTransferred,
}) => {
 const [show, setShow] = useState<{
  show: boolean
  label?:
   | null
   | 'Facebook'
   | 'Instagram'
   | 'Linkedin'
   | 'Twitter'
   | 'Whatsapp'
   | 'Web'
   | 'Birthday'
  value?: any
  type?: TBaseInputType | null
 }>({
  show: false,
  type: null,
  value: null,
  label: null,
 })

 const [updateData, { isLoading }] = useUpdateLeadDataMutation()
 const { leadId } = useLeadDetails()

 const onSubmit = async () => {
  const val: any = await form.validateFields()
  await updateData({
   type: show?.type,
   value:
    show?.type === 'birthday'
     ? val?.birthday?.format('YYYY-MM-DD')
     : val?.[show?.type as any],
   id: leadId,
  }).unwrap()
  setShow({ show: false, label: null, type: null, value: null })
 }

 const [form] = Form.useForm<{
  birthday: string | Moment
  whatsapp_no: any
  facebook: any
  linkedin: any
  twitter: any
  instagram: any
  website: any
 }>()

 const formInit = {
  facebook: '',
  instagram: '',
  linkedin: '',
  website: '',
  twitter: '',
  whatsapp_no: '',
  birthday: '',
 }

 const redir = (val?: string) => {
  if (utils?.isValidURL(val ?? '')) {
   window.open(val, '_blank')
  }
 }

 return (
  <>
   <ProInnerCard title="Lead Basic Details">
    <Row gutter={[15, 15]} className="basic-details">
     <Col span={12}>
      <p style={{ color: 'gray' }}>Facebook</p>

      <div className="d-flex justify-content-between">
       <div
        className={`url ${utils?.isValidURL(data?.facebook) ? 'link' : ''}`}
        onClick={() => redir(data?.facebook)}
       >
        {data?.facebook}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'facebook',
           label: 'Facebook',
          })
          form.setFieldsValue({ facebook: data?.facebook })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.facebook ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Instagram</p>

      <div className="d-flex justify-content-between">
       <div
        className={`url ${utils?.isValidURL(data?.instagram) ? 'link' : ''}`}
        onClick={() => redir(data?.instagram)}
       >
        {data?.instagram}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'instagram',
           label: 'Instagram',
          })
          form.setFieldsValue({ instagram: data?.instagram })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.instagram ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Linkedin</p>
      <div className="d-flex justify-content-between">
       <div
        className={`url ${utils?.isValidURL(data?.linkedin) ? 'link' : ''}`}
        onClick={() => redir(data?.linkedin)}
       >
        {data?.linkedin}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'linkedin',
           label: 'Linkedin',
          })
          form.setFieldsValue({ linkedin: data?.linkedin })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.linkedin ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Twitter</p>

      <div className="d-flex justify-content-between">
       <div
        className={`url ${utils?.isValidURL(data?.twitter) ? 'link' : ''}`}
        onClick={() => redir(data?.twitter)}
       >
        {data?.twitter}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'twitter',
           label: 'Twitter',
          })

          form.setFieldsValue({ twitter: data?.twitter })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.twitter ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Whatsapp</p>

      <div className="d-flex justify-content-between">
       <div
        className="url link"
        onClick={() =>
         window.open(`https://wa.me/${data?.whatsapp_no}`, '_blank')
        }
       >
        {data?.whatsapp_no}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'whatsapp_no',
           label: 'Whatsapp',
          })
          form.setFieldsValue({ whatsapp_no: data?.whatsapp_no })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.whatsapp_no ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Web</p>
      <div className="d-flex justify-content-between">
       <div
        className={`url ${utils?.isValidURL(data?.website) ? 'link' : ''}`}
        onClick={() => redir(data?.website)}
       >
        {data?.website}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'website',
           label: 'Web',
          })
          form.setFieldsValue({ website: data?.website })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.website ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
     <Col span={12}>
      <p style={{ color: 'gray' }}>Birthday</p>
      <div className="d-flex justify-content-between">
       <div>
        {data?.birthday ? baseTime(data?.birthday).format('DD-MM-YYYY') : ''}
       </div>
       {isTransferred && (
        <span
         onClick={() => {
          setShow({
           show: true,
           type: 'birthday',
           label: 'Birthday',
          })
          form.setFieldsValue({
           birthday: data?.birthday ? baseTime(data?.birthday) : '',
          })
         }}
        >
         <i
          style={{ cursor: 'pointer' }}
          className={`fas ${data?.birthday ? 'fa-edit' : 'fa-plus'}`}
         />
        </span>
       )}
      </div>
     </Col>
    </Row>
   </ProInnerCard>
   {show?.show && (
    <Modal
     title={`${(data as any)?.[show?.type as any] ? 'Edit' : 'Add'} ${
      show?.label
     }`}
     visible={show?.show}
     onOk={onSubmit}
     confirmLoading={isLoading}
     onCancel={() => {
      setShow({ show: false, type: null, value: null })
      form.resetFields()
     }}
    >
     <Form form={form} initialValues={formInit} layout="vertical">
      <Form.Item
       name={show?.type ?? ''}
       label={show?.label ?? ''}
       rules={[{ required: true, message: `${show?.label} is Required` }]}
      >
       {show?.type === 'birthday' ? (
        <DatePicker
         className="mt-2 w-100"
         format="DD-MM-YYYY"
         disabledDate={(c) => c && c > baseTime()}
         inputReadOnly
        />
       ) : show?.type === 'whatsapp_no' ? (
        <InputPhoneNumber />
       ) : (
        <Input className="mt-2" placeholder={`https://www.${show?.type}.com`} />
       )}
      </Form.Item>
     </Form>
    </Modal>
   )}
  </>
 )
}

export default LeadBasicDetails
