/* eslint-disable react/prop-types */
import * as React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'

// import { Button, Spinner } from '@/components/Elements'
import { queryClient } from 'lib/react-query'
import { BaseButton } from 'components/elements/BaseButton'
import { FullLoader } from 'components/elements/Loader'
import { Result } from 'antd'

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
 return (
  <div className="d-flex align-items-center vh-100 w-100 justify-content-center">
   <Result
    extra={
     <BaseButton
      buttonType="primary"
      className="mt-4"
      onClick={resetErrorBoundary}
     >
      Refresh
     </BaseButton>
    }
    title="Ooops, something went wrong "
    status="error"
   />
  </div>
 )
}

type AppProviderProps = {
 children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
 return (
  <React.Suspense fallback={<FullLoader />}>
   <ErrorBoundary FallbackComponent={ErrorFallback}>
    <HelmetProvider>
     <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}

      <Router>{children}</Router>
     </QueryClientProvider>
    </HelmetProvider>
   </ErrorBoundary>
  </React.Suspense>
 )
}
