/* eslint-disable react/require-default-props */
import { Skeleton } from 'antd'
import { FC } from 'react'

const MTRowLoading: FC<{
 totalCols?: number
 rows?: number
}> = ({ rows, totalCols }) => {
 return (
  <>
   {[...Array(rows)].map((k, i) => (
    <tr key={i}>
     <td>
      <div className="w-100">
       <Skeleton
        active
        title={{ className: 'm-0', style: { height: '30px' } }}
        paragraph={false}
       />
      </div>
     </td>
     {[...Array(totalCols)].map((v, i) => (
      <td key={i}>
       <div className="w-100">
        <Skeleton
         active
         title={{ className: 'm-0', style: { height: '30px' } }}
         paragraph={false}
        />
       </div>
      </td>
     ))}
    </tr>
   ))}
  </>
 )
}

export default MTRowLoading
