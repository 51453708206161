/* eslint-disable no-nested-ternary */
import { Card, Empty, Modal, Tooltip } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import { useGetAllSignaturesQuery } from 'features/settings'
import useAuth from 'hooks/useAuth'
import { FC, useState } from 'react'

const SignaturesModal: FC<{
 visible: boolean
 onCancel: () => void
 onChangeSignature: (val: string) => void
 selectedSign?: number
 setSelectedSign?: React.Dispatch<React.SetStateAction<number | undefined>>
}> = ({
 visible,
 onCancel,
 onChangeSignature,
 selectedSign,
 setSelectedSign,
}) => {
 const { user } = useAuth()
 const { data, isLoading } = useGetAllSignaturesQuery()
 const [selectedItem, setSelectedItem] = useState<number | undefined>(
  selectedSign,
 )

 const onSuccess = (): void => {
  onChangeSignature?.(
   data?.find((i) => i?.id === selectedItem)?.signature ?? '',
  )
  setSelectedSign?.(selectedItem)
  onCancel()
 }

 return (
  <Modal
   onOk={onSuccess}
   width={1024}
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title="Insert Signature"
   className="signatures-modal"
  >
   <div className="signatures d-flex flex-wrap">
    {isLoading ? (
     <BlockLoader className="d-flex align-items-center justify-content-center w-100" />
    ) : (!data || data?.length === 0) && !isLoading ? (
     <Empty description="No signatures found" className="w-100" />
    ) : (
     data?.map((i) => (
      <div key={i?.id} className="m-2">
       <Card
        className={selectedItem === i?.id ? 'active' : ''}
        // title={i?.name ?? ''}
        title={<Tooltip overlay={i?.name ?? ''}>{i?.name ?? ''}</Tooltip>}
        style={{ width: 300 }}
        onClick={() => setSelectedItem(i?.id)}
       >
        <div dangerouslySetInnerHTML={{ __html: i?.signature ?? '' }} />
       </Card>
      </div>
     ))
    )}
   </div>
  </Modal>
 )
}

export default SignaturesModal
