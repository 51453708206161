import { Button, Modal } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import React, { FC } from 'react'

const MessageModal: FC<{
 visible?: boolean
 onHide?: () => void
 message?: any
}> = ({ visible, onHide, message }) => {
 return (
  <Modal visible={visible} onCancel={onHide} footer={false}>
   <div style={{ padding: '12px 0px', textAlign: 'center' }}>{message}</div>
   <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
    <BaseButton type="primary" onClick={onHide}>
     OK
    </BaseButton>
   </div>
  </Modal>
 )
}

export default MessageModal
