import useFetchPage from 'hooks/useFetchPage'
import { FC, useMemo, useState } from 'react'
import { Menu } from 'antd'
import useAuth from 'hooks/useAuth'
import { TGetLeads } from '../types'
import { useLeadFilter } from '../slice/leadSlice'
import LeadList from '../components/LeadList'
import LeadListTitleTabsBlock from '../components/LeadListTitleTabsBlock'

const AllLeads: FC = () => {
 const { filterParams } = useLeadFilter()
 const { user } = useAuth()
 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  totalCount,
  isRefetching,
  isFetching,
  isFetchingNextPage,
 } = useFetchPage<TGetLeads>('get-leads-web', {
  method: 'post',
  body: useMemo(
   () => ({
    ...filterParams,
   }),
   [filterParams],
  ),
 })

 return (
  <LeadList
   title={
    user?.account_type === 'Brokerage' ? (
     <LeadListTitleTabsBlock selectedKey="all-leads" />
    ) : (
     'Leads'
    )
   }
   fetchMore={fetchMore}
   refetch={() => {
    refetch?.()
   }}
   from="lead"
   isLoading={isLoading}
   hasMore={hasMore}
   totalCount={totalCount}
   leads={flatData}
   selectedLeadsType="all-leads"
   isRefetching={isRefetching}
  />
 )
}

export default AllLeads
