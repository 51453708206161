/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { Form, Spin, Row, Col, Card, Badge, Input } from 'antd'
import {
 useSendListingMessageMutation,
 //  useSendListingSmsMutation,
} from 'api/baseApi'
import { BaseButton } from 'components/elements/BaseButton'
// import { globalToaster } from 'components/elements/global'
import {
 TSendTextBody,
 useSendSmsLeadMutation,
 TSendMailPro,
 TSendType,
 TSendScheduleSmsBody,
 useSendScheduleSmsProMutation,
 useSendScheduleSmsLeadMutation,
} from 'features/leads'
import { TPropertyListing } from 'features/property'
import { useGetSmsTemplateByIdQuery } from 'features/settings'
import useAuth from 'hooks/useAuth'
import { FC, useState, useEffect, useMemo } from 'react'
import { NewListingSlug } from 'types'
import SWToBar from 'components/elements/SWToBar/SWToBar'
import SWToBarTag from 'components/elements/SWToBarTag/SWToBarTag'
// import InputToAddress from '../Common/InputToAddress'
import { SMSInputArea } from '../Common/SMSInputArea'
import ScheduleModal from '../Modals/ScheduleModal'
import { ModalTypes } from '../Modals/SendEmailModal'
import PropertyCard from '../PropertyCard'
import SmsPreview from './SmsPreview'

type SendTextComponentProp = {
 type?: 'page' | 'inner'
 to?: any
 body?: string
 onSendSuccess?: () => void
 propertyData?: TSendMailPro[]
 sendType?: TSendType
 refetch?: () => void
 allData?: TPropertyListing[]
 listingType?: NewListingSlug
 modalType?: ModalTypes
 propFullInfo?: TPropertyListing[]
 updateSelectedProp?: (val?: any) => void
 removeTemplateDropdown?: boolean
 selectAll?: boolean
 unSelected?: number[]
 filterInfo?: string
 totalCount?: number
 showMoreLabel?: boolean
 extraBody?: any
}

const formInit: TSendTextBody = {
 body: '',
 from: '',
 to: [],
}

const SendTextMsgForm: FC<SendTextComponentProp> = ({
 type = 'page',
 to,
 body,
 onSendSuccess,
 propertyData,
 sendType = 'lead',
 allData,
 listingType,
 modalType,
 propFullInfo,
 updateSelectedProp,
 removeTemplateDropdown = false,
 selectAll,
 unSelected,
 filterInfo,
 totalCount,
 showMoreLabel,
 extraBody,
}) => {
 const { user } = useAuth()
 const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false)
 const [scheduleSms, { isLoading: scheduleSmsLoading }] =
  useSendScheduleSmsLeadMutation()
 const [sendSMS, { isLoading: submitSendLading }] = useSendSmsLeadMutation()
 const [SendScheduleSmsPro, { isLoading: SendScheduleSmsProLoading }] =
  useSendScheduleSmsProMutation()
 const [sendListingMessage, { isLoading: listingSmsLoading }] =
  useSendListingMessageMutation()
 const [smsDataState, setSmsDataState] = useState<
  TPropertyListing[] | undefined
 >(allData)
 const [templateId, setTemplateId] = useState<any>()
 const {
  data: tempData,
  isLoading,
  isFetching,
 } = useGetSmsTemplateByIdQuery({ id: templateId }, { skip: !templateId })
 const [showPreview, setShowPreview] = useState<boolean>(false)
 const [form] = Form.useForm<TSendTextBody>()
 const toWatch = Form.useWatch('to', form)
 const inpuSize = type === 'page' ? 'middle' : 'small'
 const [previewBtnDisableOnTag, setPreviewBtnDisableOntag] = useState(false)

 const updatedSmsDataState = useMemo(
  () =>
   smsDataState && smsDataState.length > 0
    ? smsDataState.map((v) => ({
       id: v.slug,
       type: v.listing_type,
      }))
    : [],
  [smsDataState],
 )

 const handleSubmitSend = async (time?: any, date?: any) => {
  const values = await form.validateFields()
  const postData: TSendScheduleSmsBody = {
   ...values,
   //  to: Array.isArray(values.to) ? values.to.join(',') : to,
   ...(!selectAll
    ? {
       to:
        sendType === 'lead'
         ? Array.isArray(values.to)
           ? values.to.join(',')
           : to
         : (values?.to as any)?.map((i: any) => i?.id)?.join(','),
      }
    : {}),
   type: sendType,
   body: values.body,
   from: user?.twilio_phone,
   ...(propertyData && propertyData.length > 0
    ? { property: updatedSmsDataState, listing_type: listingType }
    : {}),
   ...(date || time ? { date, time } : {}),
   ...(propFullInfo ? { prop_full_info: JSON.stringify(propFullInfo) } : {}),
   ...(selectAll && showMoreLabel ? { select_all: selectAll } : {}),
   ...(unSelected && selectAll && showMoreLabel
    ? { unselected: unSelected }
    : {}),
   ...(filterInfo && selectAll && showMoreLabel
    ? { filter_info: filterInfo }
    : {}),
   ...extraBody,
  }
  // if (propFullInfo) {
  //  postData.prop_full_info = JSON.stringify(propFullInfo)
  // }

  if (propertyData && propertyData.length > 0) {
   time || date
    ? await SendScheduleSmsPro(postData).unwrap()
    : // : await sendListingSms(postData).unwrap()
      await sendListingMessage(postData).unwrap()
   onSendSuccess?.()
  } else {
   time || date
    ? await scheduleSms(postData).unwrap()
    : await sendSMS(postData).unwrap()
   onSendSuccess?.()
  }
 }

 useEffect(() => {
  if (tempData?.body) {
   form.setFieldsValue({
    body: tempData.body ?? '',
   })
  } else {
   //    form.resetFields()
  }
 }, [tempData?.body])

 useEffect(() => {
  if (body) {
   form.setFieldsValue({
    body,
   })
  }
 }, [body])

 useEffect(() => {
  let mouted = true
  if (to && mouted) {
   form.setFieldsValue({
    to,
   })
  }
  return () => {
   mouted = false
  }
 }, [to])

 return (
  <Spin spinning={isLoading || isFetching}>
   <Form form={form} initialValues={formInit} layout="vertical">
    {modalType === 'listing' ? (
     <Row gutter={16}>
      <Col span={12}>
       <Row>
        <Col span={24} className="sw-to-bar-custom-input">
         <Form.Item
          name="to"
          label="To"
          rules={[
           {
            required: !selectAll,
            message: `${
             sendType === 'lead' ? 'Phone number' : 'Tag'
            } is required`,
           },
          ]}
          getValueFromEvent={(v) => {
           if (sendType !== 'lead') {
            setPreviewBtnDisableOntag(v?.length > 0)
           }
           return v
          }}
         >
          {sendType === 'lead' ? (
           <SWToBar
            showAdd
            type="phoneNumber"
            // selectAll={selectAll}
            // selectedToCount={`${(totalCount ?? 0) - (unSelected?.length || 0)}`}
           />
          ) : (
           <SWToBarTag type="text" />
          )}
         </Form.Item>
        </Col>
        <Col span={24}>
         <Form.Item
          rules={[
           {
            required: !removeTemplateDropdown,
            message: 'Content is required',
           },
          ]}
          name="body"
          label="Content"
         >
          <SMSInputArea
           template={!removeTemplateDropdown}
           onSelectTemp={setTemplateId}
          />
         </Form.Item>
        </Col>
       </Row>
      </Col>
      <Col span={12}>
       <Row>
        {smsDataState && smsDataState.length > 0 && (
         <Col span={24}>
          <Card
           className="mb-2"
           type="inner"
           title={
            <>
             Properties <Badge count={smsDataState.length} />
            </>
           }
          >
           <div className="listing-modal-rightside-sms">
            <Row gutter={[10, 10]}>
             {smsDataState.map((item, i) => (
              <Col key={i} span={24}>
               <PropertyCard
                item={item as any}
                cardType="inside-sms-modal"
                dataState={smsDataState}
                handleDataState={(val) => {
                 updateSelectedProp?.(val)
                 setSmsDataState?.(val)
                }}
               />
              </Col>
             ))}
            </Row>
           </div>
          </Card>
         </Col>
        )}
       </Row>
      </Col>
      <Col className="d-flex justify-content-end" span={24}>
       <BaseButton
        size={inpuSize}
        onClick={() => setShowPreview(true)}
        buttonType="primary"
        disabled={
         showMoreLabel
          ? false
          : sendType === 'lead'
          ? !toWatch?.length
          : !previewBtnDisableOnTag
         //  showMoreLabel ? false : !toWatch?.length
        }
        tooltipText={
         (sendType === 'lead' ? !toWatch?.length : !previewBtnDisableOnTag)
          ? 'Fill in the mandatory fields above to enable the Preview Option'
          : undefined
        }
       >
        <i className="fas fa-eye me-1" /> Preview
       </BaseButton>
       <BaseButton
        onClick={() => handleSubmitSend()}
        loading={submitSendLading || listingSmsLoading}
        size={inpuSize}
        customClass="mx-2"
        buttonType="success"
       >
        <i className="fas fa-paper-plane me-1" /> Send
       </BaseButton>
       <BaseButton
        size={inpuSize}
        onClick={() => setShowScheduleModal(true)}
        buttonType="info"
       >
        <i className="fas fa-clock me-1" /> Schedule
       </BaseButton>
      </Col>
     </Row>
    ) : (
     <Row gutter={14}>
      <Col span={24}>
       <Form.Item label="From">
        <Input readOnly value={user?.twilio_phone} />
       </Form.Item>
      </Col>
      <Col span={24} className="sw-to-bar-custom-input">
       <Form.Item
        name="to"
        label="To"
        rules={[
         {
          required: !selectAll,
          message: `${
           sendType === 'lead' ? 'Phone number' : 'Tag'
          } is required`,
         },
        ]}
       >
        {sendType === 'lead' ? (
         <SWToBar
          showAdd
          type="phoneNumber"
          //  showMoreLabel={showMoreLabel}
         />
        ) : (
         <SWToBarTag type="text" />
        )}
       </Form.Item>
      </Col>
      <Col span={24}>
       <Form.Item
        rules={[{ required: true, message: 'Content is required' }]}
        name="body"
        label="Content"
       >
        <SMSInputArea
         template={!removeTemplateDropdown}
         onSelectTemp={setTemplateId}
        />
       </Form.Item>
      </Col>
      <Col className="d-flex justify-content-end" span={24}>
       <BaseButton
        size={inpuSize}
        onClick={() => setShowPreview(true)}
        buttonType="primary"
        // disabled={selectAll ? false : !toWatch?.length}
        // disabled={showMoreLabel ? false : !toWatch?.length}
        disabled={
         showMoreLabel
          ? false
          : sendType === 'lead'
          ? !toWatch?.length
          : !previewBtnDisableOnTag
        }
        tooltipText={
         (sendType === 'lead' ? !toWatch?.length : !previewBtnDisableOnTag)
          ? 'Fill in the mandatory fields above to enable the Preview Option'
          : undefined
        }
       >
        <i className="fas fa-eye me-1" /> Preview
       </BaseButton>
       <BaseButton
        onClick={() => handleSubmitSend()}
        loading={submitSendLading || listingSmsLoading}
        size={inpuSize}
        customClass="mx-2"
        buttonType="success"
       >
        <i className="fas fa-paper-plane me-1" /> Send
       </BaseButton>
       <BaseButton
        size={inpuSize}
        onClick={() => setShowScheduleModal(true)}
        buttonType="info"
       >
        <i className="fas fa-clock me-1" /> Schedule
       </BaseButton>
      </Col>
     </Row>
    )}
   </Form>

   {showPreview && (
    <SmsPreview
     body={{
      ...// !selectAll
      //  ?
      (sendType === 'lead'
       ? {
          to: Array.isArray(form.getFieldValue('to'))
           ? form.getFieldValue('to')?.join(',')
           : form.getFieldValue('to'),
         }
       : {
          to: form
           .getFieldValue('to')
           ?.map?.((i: any) => i.id)
           ?.join(','),
         }),
      //  : {}
      from: user?.twilio_phone,
      body: form.getFieldValue('body'),
      type: sendType,
      property:
       propertyData && propertyData.length > 0
        ? JSON.stringify(propertyData)
        : '',
      ...(propFullInfo ? { prop_full_info: propFullInfo } : {}),

      // ...(selectAll ? { select_all: selectAll } : {}),
      // ...(unSelected && selectAll ? { unselected: unSelected } : {}),
      // ...(filterInfo && selectAll ? { filter_info: filterInfo } : {}),
      ...(listingType === 'preconstruction'
       ? { listing_type: 'preconstruction' }
       : {}),
     }}
     onCancel={() => setShowPreview(false)}
     visible={showPreview}
    />
   )}
   {showScheduleModal && (
    <ScheduleModal
     isLoading={SendScheduleSmsProLoading || scheduleSmsLoading}
     type="SMS"
     onCancel={() => {
      setShowScheduleModal(false)
     }}
     onSubmitSchedule={handleSubmitSend}
     visible={showScheduleModal}
    />
   )}
  </Spin>
 )
}

export default SendTextMsgForm
