import {
 AuthState,
 changeSignature,
 selectAuth,
 changeUserData,
} from 'features/auth'
import { useAppDispatch } from 'hooks'
import { useSelector } from 'react-redux'

const useAuth = (): AuthState & {
 changeSignature: (val: any) => void
 changeUserData: (val: any) => void
} => {
 const d = useAppDispatch()
 const authState = useSelector(selectAuth)
 return {
  ...authState,
  changeSignature: (v) => d(changeSignature(v)),
  changeUserData: (v) => d(changeUserData(v)),
 }
}
export default useAuth
