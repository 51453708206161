import { Col, Row } from 'antd'
import { FC, ReactNode } from 'react'
import { formatDate } from 'utils/functions'
import { useParams } from 'react-router-dom'
import { TInboxItem } from '../types'
import SchduleMsgActions from './SchduleMsgActions'

const InboxItem: FC<{
 item: TInboxItem
 active?: boolean
 refetchList?: () => void
 refetchListKey?: string
}> = ({ item, active, refetchList, refetchListKey }) => {
 const params = useParams()
 const type = params?.type
 const mode = params?.mode

 return (
  <div className={`inbox-item-link ${active ? 'inbox-item-link-active' : ''}`}>
   {/* {link && <NavLink className="inb-link" to={link} />} */}
   <div className="inbox-icon">
    <span>
     {mode === 'email' ? (
      <i className="far fa-envelope" />
     ) : (
      <i className="fal fa-comment-alt-dots" />
     )}
    </span>
   </div>
   <div className="inbox-content">
    <h4>
     {type !== 'scheduled'
      ? `${item?.from_name} > ${item?.to_name}`
      : `From: ${item?.from_name}`}
    </h4>
    {type === 'scheduled' && (item?.count ?? 0) > 0 && (
     <h4>To: {item?.count} Recipients</h4>
    )}
    {item?.subject && <h5>{item?.subject}</h5>}
    <div className="msg-status">
     <div className="msg-status">
      <Row justify="space-between">
       {type !== 'scheduled' && (
        <Col>
         {item?.status && <span className="status">{item?.status}</span>}
        </Col>
       )}

       {(item?.updated_at || item?.schedule_datetime) && (
        <Col>
         {type !== 'scheduled' ? (
          <div className="date">{formatDate?.(item?.updated_at)}</div>
         ) : (
          <div className="date text-dark">{item?.schedule_datetime}</div>
         )}
        </Col>
       )}
      </Row>
     </div>
    </div>
    {/* {actions && <div className="inbox-action-blk">{actions}</div>} */}
    {type === 'scheduled' && (
     <div className="inbox-action-blk">
      <SchduleMsgActions
       data={item}
       refetchList={refetchList}
       refetchListKey={refetchListKey}
      />
     </div>
    )}
   </div>
  </div>
 )
}

export default InboxItem
