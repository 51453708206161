import { Row } from 'antd'
import { useUpdateLeadlogdataStatusMutation } from 'features/leads/api'
import { TUserActivityEmailLog } from 'features/leads/types'
import { FC, useEffect } from 'react'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import ActivityEmailActions from './ActivityEmailActions'

const ActivityEmail: FC<{
 refetch?: () => void
 data?: TUserActivityEmailLog
 onCancel?: () => void
}> = ({ data, refetch, onCancel }) => {
 const [updateLeadlogdataStatus] = useUpdateLeadlogdataStatusMutation()
 const updateLeadLog = async (id: any) => {
  await updateLeadlogdataStatus?.({ id, type: 'email' }).unwrap()
  refetch?.()
 }
 const { isTransferred } = useLeadDetails()

 useEffect(() => {
  if (data) {
   if (data?.send_type === 'received' || data?.send_type === 'Received') {
    updateLeadLog?.(data.id)
   }
  }
 }, [data])

 return (
  <>
   {isTransferred && (
    <ActivityEmailActions onClickReOrFor={onCancel} datas={data} />
   )}
   <div className="mail-preview-activity">
    <p>From:</p>
    <div className="ms-3">
     <p className="bold">{data?.from_name}</p>
     <p className="body">{data?.from_mail}</p>
    </div>
    <p>To:</p>
    <div className="ms-3">
     <p className="bold">{data?.to_name}</p>
     <p className="body">{data?.to_mail}</p>
    </div>
    {data?.subject && (
     <>
      <p>Subject:</p>
      <div className="ms-3">
       <p className="body">{data?.subject}</p>
      </div>
     </>
    )}
    {data?.body !== null && data?.body !== '' && (
     <>
      <p>Message:</p>
      <div
       className="message ms-3 mb-3"
       dangerouslySetInnerHTML={{ __html: `${data?.body}` }}
      />
     </>
    )}
   </div>
   {(data?.attachment?.length ?? 0) > 0 && (
    <>
     <p>Attachments:</p>
     <Row gutter={[10, 10]} className="attachments-wrapper">
      {data?.attachment?.map?.((v, index) => (
       <a
        target={
         ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
          v?.split('.').at(-1),
         )
          ? '_blank'
          : '_self'
        }
        href={v}
        key={index}
        rel="noreferrer"
       >
        <div className="attachment">
         <i className="fas fa-file" />
        </div>
       </a>
      ))}
     </Row>
    </>
   )}
  </>
 )
}
export default ActivityEmail
