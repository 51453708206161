import { Form, FormInstance } from 'antd'
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { FCC, TMode } from 'types'
import { TActionPlanForm } from '../types'

type ActionFormContextValue = {
 form?: FormInstance<TActionPlanForm>
 editData?: TActionPlanForm | null
 mode?: TMode
 editId?: any
}

const ActionFormContext = React.createContext<ActionFormContextValue>({})

const ActionFormProvider: FCC<{
 editData?: TActionPlanForm | null
 mode?: TMode
 editId?: any
}> = ({ children, mode, editData, editId }) => {
 const [form] = Form.useForm<TActionPlanForm>()

 const fillEditData = useCallback(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue(editData)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [editData, mode])

 useEffect(() => {
  if (form) {
   fillEditData()
  }
  return () => {
   form && form.resetFields()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [fillEditData])

 const value: ActionFormContextValue = useMemo(
  () => ({
   form,
   editData,
   mode,
   editId,
  }),
  [editData, editId, form, mode],
 )

 return (
  <ActionFormContext.Provider value={value}>
   {form && children}
  </ActionFormContext.Provider>
 )
}

export default ActionFormProvider

export const useActionForm = () => useContext(ActionFormContext)
