import { Modal, Input, Form, Select } from 'antd'
import { useAddCallLogMutation } from 'features/leads/api'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { queryClient } from 'lib/react-query'
import React, { FC } from 'react'

export type TAddCallLog = {
 lead_id?: any
 content?: any
 number?: any
}

const LogCallModal: FC<{
 visible?: boolean
 onCancel?: () => void
 numbers?: any
}> = ({ visible, onCancel, numbers }) => {
 const { TextArea } = Input
 const { leadId } = useLeadDetails()

 const formInit: TAddCallLog = {
  content: '',
  number: numbers?.[0]?.number ?? null,
 }

 const [form] = Form.useForm<TAddCallLog>()

 const [putCallLog, { isLoading }] = useAddCallLogMutation()

 const HandleSubmit = async () => {
  const values = await form.validateFields()
  await putCallLog({
   content: values.content,
   number: values.number,
   lead_id: leadId,
  }).unwrap()
  queryClient.invalidateQueries(`lead/profile/${leadId}`)
  form.resetFields()
  onCancel?.()
 }
 return (
  <Modal
   visible={visible}
   onCancel={onCancel}
   title="Call Logs"
   onOk={HandleSubmit}
   confirmLoading={isLoading}
   okText="Submit"
  >
   <Form form={form} initialValues={formInit} layout="vertical">
    <Form.Item
     name="content"
     label="Content"
     rules={[{ required: true, message: 'Content is required' }]}
    >
     <TextArea rows={4} placeholder="Add call logs" />
    </Form.Item>
    <Form.Item
     name="number"
     label="Phone"
     rules={[{ required: true, message: 'Phone is required' }]}
    >
     <Select
      placeholder="phone numbers"
      options={numbers.map((item: any) => ({
       value: item.number,
       label: item.number,
      }))}
     />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default LogCallModal
