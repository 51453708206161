import { Route, Routes } from 'react-router-dom'
import { FC } from 'react'
import List from './action-plan/List'
import RealEstateNews from './real-estate-news/RealEstateNewsList'
import MarketUpdatesList from './market-updates/MarketUpdatesList'

export const AutomationRoutes: FC = () => {
 return (
  <Routes>
   <Route path="action-plan" element={<List />} />
   <Route path="market-updates" element={<MarketUpdatesList />} />
   <Route path="real-estate-news" element={<RealEstateNews />} />
  </Routes>
 )
}
