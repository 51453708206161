import { Col, Row } from 'antd'
import BaseTag from 'components/elements/BaseTag'
import { globalConfirm } from 'components/elements/global'
import { leadApi, useWithdrawTagleadMutation } from 'features/leads/api'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import { TGroupType } from 'types'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import ProInnerCard from '../../ProInnerCard'

const TagsBlock: FC<{
 tags?: TGroupType[]
 leadId?: any
 isTransferred?: boolean
}> = ({ tags, leadId, isTransferred }) => {
 const d = useAppDispatch()
 const [tagModal, setShowTagModal] = useState(false)
 const [deleteTag] = useWithdrawTagleadMutation()
 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))

 const handleRemoveTag = (id: any) => {
  globalConfirm({
   content: 'Are you sure want remove Tag?',
   onOk: async () => {
    await deleteTag({ lead_id: leadId, tag_id: id }).unwrap()
    refreshLead()
   },
  })
 }

 return (
  <div>
   <ProInnerCard
    emptyText="Tags is Empty"
    isEmpty={!tags || tags?.length === 0}
    onCLickAdd={isTransferred ? () => setShowTagModal(true) : undefined}
    title="Tags"
   >
    <Row gutter={[7, 7]}>
     {tags &&
      tags.map((item, i) => (
       <Col key={i}>
        <BaseTag
         className="m-0 p-2"
         closable={isTransferred && item?.is_editable}
         onClose={() => handleRemoveTag(item.id)}
         //  type={item.color}
         label={item.name}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   <AssignTagsStagesModal
    // leadRefresh={refreshLead}
    refetch={refreshLead}
    selectedKeys={[leadId]}
    visible={tagModal}
    onCancel={() => setShowTagModal(false)}
    assignType="tag"
   />
  </div>
 )
}

export default TagsBlock
