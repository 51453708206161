import { baseAPI, baseTransformResponse } from 'api/baseApi'
import { ApiResponce } from 'types'
import { TSheculeEmailPreview } from '../types'

export const inboxApi = baseAPI.injectEndpoints({
 overrideExisting: true,
 endpoints: ({ query, mutation }) => ({
  getScheduleEmailById: query<TSheculeEmailPreview, { id?: any }>({
   query: ({ id }) => ({
    url: `user/inbox/lead/emails?id=${id}&type=scheduled`,
    method: 'get',
    // body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['SCHEDULE-EMAIL-PREVIEW'],
  }),
  previewScheduleSms: query<TSheculeEmailPreview, { id?: any }>({
   query: ({ id }) => ({
    url: `user/inbox/lead/texts?id=${id}&type=scheduled`,
    method: 'get',
    // body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['SCHEDULE-SMS-PREVIEW'],
  }),
  deleteScheduleEmail: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-schedule-email',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  scheduleEmailNow: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/schedule-email-send',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  rescheduleEmail: mutation<void, { id?: any; date?: any; time?: any }>({
   query: (body) => ({
    url: 'user/re-schedule-email',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  rescheduleSms: mutation<void, { id?: any; date?: any; time?: any }>({
   query: (body) => ({
    url: 'user/re-schedule-sms',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  deleteScheduledSms: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-schedule-sms',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  schedulesmsNow: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/schedule-sms-send',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  updateEmailSchedule: mutation<void, { id?: number; data: FormData }>({
   query: ({ id, data }) => ({
    url: `user/update-email-schedule/${id}`,
    method: 'post',
    body: data,
   }),
   transformResponse: baseTransformResponse,
  }),
  updateTextSchedule: mutation<void, { id?: number; data: FormData }>({
   query: ({ id, data }) => ({
    url: `user/update-text-schedule/${id}`,
    method: 'post',
    body: data,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useGetScheduleEmailByIdQuery,
 useLazyGetScheduleEmailByIdQuery,
 usePreviewScheduleSmsQuery,
 useLazyPreviewScheduleSmsQuery,
 useDeleteScheduleEmailMutation,
 useScheduleEmailNowMutation,
 useRescheduleEmailMutation,
 useDeleteScheduledSmsMutation,
 useSchedulesmsNowMutation,
 useRescheduleSmsMutation,
 useUpdateEmailScheduleMutation,
 useUpdateTextScheduleMutation,
} = inboxApi
