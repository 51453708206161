import { Col, Form, Input, Row } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import useAuth from 'hooks/useAuth'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { usePasswordResetMutation } from '../api'

const ForgotPassword: FC = () => {
 const [form] = Form.useForm<{ email?: string }>()

 const [resetPassword, { isLoading }] = usePasswordResetMutation()
 const { executeRecaptcha } = useGoogleReCaptcha()

 const navigate = useNavigate()

 const HandleSubmit = async () => {
  const values = await form.validateFields()
  const token = await executeRecaptcha?.('resetPassword')
  await resetPassword({
   ...values,
   ...(token ? { _recaptcha: token } : {}),
  }).unwrap()
  form.resetFields()
  navigate(-1)
 }
 return (
  <div className="login-block">
   <div className="login-header">
    <h3>Forgot Password?</h3>
   </div>
   <Form form={form}>
    <Form.Item
     name="email"
     rules={[
      { required: true },
      { type: 'email', message: 'Enter the valid email' },
     ]}
    >
     <Input
      size="large"
      inputMode="email"
      placeholder="Enter Your Email"
      prefix={<i className="fas fa-envelope" />}
      type="email"
     />
    </Form.Item>
   </Form>
   <Row gutter={[15, 15]}>
    <Col flex={1}>
     <BaseButton
      size="large"
      htmlType="button"
      block
      buttonType="info"
      onClick={() => navigate(-1)}
     >
      Cancel
     </BaseButton>
    </Col>
    <Col flex={1}>
     {' '}
     <BaseButton
      size="large"
      htmlType="button"
      block
      buttonType="primary"
      onClick={HandleSubmit}
      loading={isLoading}
     >
      Submit
     </BaseButton>
    </Col>
   </Row>
  </div>
 )
}

export default ForgotPassword
