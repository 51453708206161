import { message, Modal, ModalFuncProps, notification, Tooltip } from 'antd'
import { IconType } from 'antd/lib/notification'
import { CSSProperties, FC, ReactNode } from 'react'
import { FCC } from 'types'

interface globalToasterProps {
 content?: string
 type?: 'info' | 'success' | 'error' | 'warning' | 'loading'
}

// const disableClass:CSSProperties = {}

export const globalConfirm = ({
 onOk,
 onCancel,
 content,
 okText = 'Confirm',
 cancelText = 'Cancel',
 title = 'Are you sure?',
 ...res
}: ModalFuncProps): void => {
 Modal.confirm({
  ...res,
  okText,
  cancelText,
  className: 'mts-confirm',
  centered: true,
  title,
  icon: (
   <div className="con-icon-block">
    <i className="far fa-exclamation-triangle" />
   </div>
  ),
  onOk,
  onCancel,
  content,
 })
}

export const globalToaster = ({
 content,
 type = 'info',
}: globalToasterProps): void => {
 message.open({
  duration: 2,
  content,
  type,
 })
}

type globalAlertProps = {
 type?: IconType
 content: string
}

export const globalAlert = ({
 content,
 type = 'info',
}: globalAlertProps): void => {
 notification.open({ type, message: content })
}

export const BtnAnch: FCC<{
 tooltipText?: string
 icon?: ReactNode
 loading?: boolean
 style?: CSSProperties
 link?: string
 className?: string
 onClick?: () => void
 title?: string
 authRequired?: boolean
}> = ({
 children,
 className,
 onClick,
 title,
 style,
 loading = false,
 icon,
 tooltipText,
}) => {
 const loader = <i className="fa fa-spin fa-spinner" />
 return (
  <Tooltip overlay={tooltipText ?? null}>
   <a
    style={style}
    title={title}
    href="#"
    className={` ${className ?? ''}`}
    onClick={(e) => {
     e.preventDefault()
     onClick?.()
    }}
   >
    {icon ? (
     <>{loading ? loader : icon}</>
    ) : (
     <>
      {children}
      {loading && loader}
     </>
    )}
   </a>
  </Tooltip>
 )
}

export const FaIcon: FC<{
 type?: 'fas' | 'fal' | 'far' | 'fab'
 className?: string
 icon?: string
}> = ({ type = 'fas', icon = '', className = '' }) =>
 icon ? <i className={`${type} ${icon} ${className}`} /> : null

FaIcon.defaultProps = {
 className: '',
 icon: '',
 type: 'fas',
}
