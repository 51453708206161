import { Input } from 'antd'
import { FC, useState } from 'react'
import { useDebounce } from 'react-use'

const MTableSearchBar: FC<{
 placeholder?: string
 onChange?: (search: string) => void
}> = ({ placeholder, onChange }) => {
 const [searchKey, setSearchKey] = useState('')

 useDebounce(
  () => {
   onChange?.(searchKey)
  },
  300,
  [searchKey],
 )

 return (
  <Input
   prefix={<i style={{ color: '#777' }} className="fal fa-search me-2" />}
   allowClear
   type="search"
   value={searchKey}
   onChange={(e) =>
    e.target.value.replace(/ /g, '').length > 0
     ? setSearchKey(e.target.value)
     : setSearchKey('')
   }
   placeholder={placeholder ?? 'Enter Search'}
   style={{ width: '100%' }}
  />
 )
}

export default MTableSearchBar

MTableSearchBar.defaultProps = {
 placeholder: 'Enter Search',
 onChange: undefined,
}
