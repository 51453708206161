import { InputNumber } from 'antd'
import React, { FC } from 'react'

const BaseNumberInput: FC<{
 placeholder?: string
 className?: string
 controls?: boolean
 onlyNumbers?: boolean
 value?: number
 onChange?: (val?: number) => void
 min?: number
 max?: number
 readOnly?: boolean
}> = ({
 placeholder = '',
 className = '',
 controls = false,
 onlyNumbers = true,
 value,
 onChange,
 max,
 min,
 readOnly,
}) => {
 return (
  <InputNumber
   min={min}
   max={max}
   readOnly={readOnly}
   value={value}
   placeholder={placeholder}
   className={className}
   onKeyDown={(event) => {
    if (onlyNumbers) {
     if (
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
     ) {
      if (!/[0-9]/.test(event.key)) {
       event.preventDefault()
      }
     }
    }
    return event
   }}
   onChange={(v) => onChange?.(v as any)}
   controls={controls}
  />
 )
}

export default BaseNumberInput
