import { ColumnDef } from '@tanstack/react-table'
import { Checkbox, Select, Dropdown, Space, Menu, Button } from 'antd'
import { ReactNode } from 'react'
import { MDataTableProps, TSelectAllDropdown } from './type'

type MTRowSelectionProps = {
 onUnSelect: (id?: any) => void
 onSelectAll?: (s: boolean) => void
 onSelectRow?: MDataTableProps<any, any>['onSelectRow']
 selectAllCustomNode?: ReactNode
}

const MTRowSelection = ({
 onSelectAll,
 onSelectRow,
 selectAllCustomNode,
}: MTRowSelectionProps): ColumnDef<any> => ({
 id: 'selection',
 //  size: selectAllCustomNode ? 115 : 40,
 //  size: selectAllCustomNode ? 45 : 40,
 size: 45,
 enableResizing: false,
 accessorKey: 'id',
 header: ({ table }) =>
  selectAllCustomNode ?? (
   <Checkbox
    {...{
     checked: table.getIsAllRowsSelected(),
     indeterminate: table.getIsAllRowsSelected()
      ? false
      : table.getIsSomeRowsSelected(),
     onChange: (e) => {
      table.getToggleAllRowsSelectedHandler()(e)
      onSelectAll?.(e.target.checked)
     },
    }}
   />
  ),

 cell: ({ row }) => (
  <Checkbox
   {...{
    checked: row.getIsSelected(),
    indeterminate: row.getIsSomeSelected(),
    onChange: (e) => {
     row.getToggleSelectedHandler()(e)
     onSelectRow?.(row.original, e.target.checked)
    },
   }}
  />
 ),
})

export default MTRowSelection
