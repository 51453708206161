import { Modal } from 'antd'
import React, { FC } from 'react'
import NonPreCondoDetails from './NonPreCondoDetails'
import PreCondoDetails from './PreCondoDetails'

const PropertyDetailsModal: FC<{
 value?: string
 visible?: boolean
 onHide?: () => void
 isPreCon?: boolean
}> = ({ visible, onHide, value, isPreCon }) => {
 return (
  <Modal
   //    title={null}
   centered
   width={1058}
   footer={null}
   onCancel={onHide}
   visible={visible}
   bodyStyle={{ background: '#f3f3f3' }}
   className="pro-details-modal"
   title="Property Details"
  >
   {/* <NonPreCondoDetails value={value} /> */}
   {isPreCon ? (
    <PreCondoDetails slug={`${value}`} />
   ) : (
    <NonPreCondoDetails value={value} />
   )}
  </Modal>
 )
}

export default PropertyDetailsModal
