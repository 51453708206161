import { Select, Modal } from 'antd'
import { TAlertFeedType, useGetAlertNamesQuery } from 'features/alert'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useAssignAlertToLeadMutation,
} from 'features/leads'
import { FC, useMemo, useState } from 'react'

type AssignAlertProp = TSelectAllP & {
 type?: TAlertFeedType
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
 extraBody?: any
 customtSelectAll?: TLeadCustomSelectAll
}
const AssignToAlertModal: FC<AssignAlertProp> = ({
 visible,
 onCancel,
 selectedKeys,
 type = 'forsale',
 selectAll,
 unSelected,
 filterInfo,
 extraBody,
 customtSelectAll,
}) => {
 const { data, isLoading } = useGetAlertNamesQuery({
  // fead: [type === 'sold' ? 'sold' : 'forsale'],
  fead: [type],
 })

 const [assignAlertLead, { isLoading: submitLoading }] =
  useAssignAlertToLeadMutation()

 const [selectedAlert, setAlert] = useState<any[]>()

 const handleSubmit = async (): Promise<void> => {
  if (selectedAlert) {
   const body: TSelectAllB & { customtSelectAll?: TLeadCustomSelectAll } = {}
   if (selectAll || customtSelectAll) {
    if (selectAll) {
     body.select_all = selectAll
    } else if (customtSelectAll) {
     body.customtSelectAll = customtSelectAll
    }
    body.unselected = unSelected
    body.filter_info = filterInfo
   }

   await assignAlertLead({
    ...body,
    lead_id: selectedKeys,
    alert_id: selectedAlert,
    type: 'lead',
    feed: type,
    ...extraBody,
   }).unwrap()
   onCancel?.()
   setAlert([])
  }
 }

 const title = useMemo(() => {
  switch (type) {
   case 'forsale':
    return 'New Listing'
   case 'sold':
    return 'Neighbourhood'
   case 'openhouse':
    return 'Open House'
   case 'price-drop':
    return 'Price Drop'
   default:
    return ''
  }
 }, [type])

 return (
  <Modal
   okButtonProps={{ disabled: !selectedAlert }}
   confirmLoading={submitLoading}
   onOk={handleSubmit}
   forceRender
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title={`Assign ${title} Alert`}
  >
   <Select
    maxTagCount={4}
    onChange={(val) => setAlert(val as any)}
    mode="multiple"
    loading={isLoading}
    placeholder="Please select an alert"
    className="w-100"
   >
    {data &&
     data.length > 0 &&
     data.map((v) => (
      <Select.Option key={v.id} value={v.id}>
       {v.name}
      </Select.Option>
     ))}
   </Select>
  </Modal>
 )
}

export default AssignToAlertModal
