import { Form, Input } from 'antd'
import { useLazyGlobalSettingsQuery } from 'api/baseApi'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch, globalToaster } from 'components/elements/global'
import useAuth from 'hooks/useAuth'

import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLazyProfileQuery, useLoginMutation } from '../api'
import { LoginType } from '../types'

const Login: FC = () => {
 const [login, { isLoading }] = useLoginMutation()
 //  const [getProfileMutation] = useGetProfileMutation()
 const [getGlobalSettingsMutation] = useLazyGlobalSettingsQuery()
 const [getProfileMutation] = useLazyProfileQuery()
 const { executeRecaptcha } = useGoogleReCaptcha()
 const [reLoading, setReLoading] = useState(false)

 const [form] = Form.useForm<LoginType>()

 const navigate = useNavigate()

 const HandleLogin = async (): Promise<void> => {
  const values = await form.validateFields()
  setReLoading(true)
  //   try {
  //    const token = await executeRecaptcha?.('login')
  //    //    const token = false
  //    await login({ ...values, ...(token ? { _recaptcha: token } : {}) }).unwrap()
  //    setReLoading(false)
  //    getProfileMutation?.()
  //    getGlobalSettingsMutation?.()
  //    window.location.reload()
  //   } catch (error) {
  //    setReLoading(false)
  //    console.log({ error })
  //    globalToaster({ content: 'reCaptcha Error', type: 'error' })
  //   }

  executeRecaptcha?.('login')
   ?.then((token) => {
    login({ ...values, ...(token ? { _recaptcha: token } : {}) })
     ?.unwrap()
     ?.then(() => {
      setReLoading(false)
      getProfileMutation?.()
      getGlobalSettingsMutation?.()
      window.location.reload()
     })
     .catch(() => {
      setReLoading(false)
     })
   })
   .catch((err) => {
    console.log(err)
    setReLoading(false)
    globalToaster({ content: 'reCaptcha Error', type: 'error' })
   })
 }

 return (
  <div className="login-block">
   <div className="login-header">
    <h3>Login</h3>
   </div>
   <Form form={form}>
    <Form.Item
     name="email"
     rules={[
      { required: true },
      { type: 'email', message: 'Enter the valid email' },
     ]}
    >
     <Input
      size="large"
      inputMode="email"
      placeholder="Enter Your Email"
      prefix={<i className="fas fa-envelope" />}
      type="email"
     />
    </Form.Item>
    <Form.Item name="password" rules={[{ required: true }]}>
     <Input.Password
      size="large"
      placeholder="Enter Your Password"
      prefix={<i className="fas fa-lock" />}
     />
    </Form.Item>
    <BaseButton
     size="large"
     htmlType="submit"
     block
     buttonType="primary"
     onClick={HandleLogin}
     loading={isLoading || reLoading}
    >
     Login
    </BaseButton>
   </Form>
   <BtnAnch
    className="forgot-pw-text"
    onClick={() => navigate('/auth/reset-password')}
   >
    Forgot Your Password?
   </BtnAnch>
  </div>
 )
}

export default Login
