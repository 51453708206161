/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import { Col, Modal, Row } from 'antd'
import { BaseSelectorOption } from 'components/elements/BaseSelector'
import { BtnAnch } from 'components/elements/global'
import { FC, useState } from 'react'
import RealtorSelector from './RealtorSelector'

type TSWToBarCommon = {
 placeholder?: string
 modalTitle?: string
}

const SWToBarRealtor: FC<{
 showAdd?: boolean
 onChange?: (v: BaseSelectorOption[]) => void
 value?: BaseSelectorOption[]
 options?: TSWToBarCommon
}> = ({
 onChange,
 value,
 showAdd = true,
 options = { placeholder: 'Select Realtor', modalTitle: 'Select Realtor' },
}) => {
 const { placeholder, modalTitle } = options
 const [show, setShow] = useState(false)
 const [selected, setSelected] = useState<BaseSelectorOption[]>([])

 return (
  <>
   <div
    className="sw-to-bar"
    onClick={() => setShow(true)}
    style={{ cursor: 'pointer' }}
   >
    <div className="input-to-area">
     <div className="sw-bar-container">
      {selected && selected?.length > 0 ? (
       <Row gutter={[6, 6]} className="m-0">
        {selected?.map?.((v, i) => (
         <Col
          key={i}
          onClick={(e) => {
           e.stopPropagation()
           setShow(false)
          }}
          style={{ height: '22px' }}
         >
          <div className="sw-to-bar-tag">
           <span>{v?.title}</span>
           <BtnAnch
            onClick={() => {
             setSelected((prv) => {
              let newData = prv
              newData = prv.filter((m) => m?.id !== v?.id)
              onChange?.(newData)
              return [...newData]
             })
            }}
           >
            <i className="fal fa-times" />
           </BtnAnch>
          </div>
         </Col>
        ))}
       </Row>
      ) : (
       <BtnAnch onClick={() => setShow(true)} className="sw-bar-placeholder">
        {placeholder}
       </BtnAnch>
      )}
     </div>
    </div>
    {showAdd && (
     <BtnAnch
      onClick={() => setShow(true)}
      className="sw-to-bar-action"
      icon={<i className="far fa-plus" />}
     />
    )}
   </div>
   {show && (
    <Modal
     onCancel={() => setShow(false)}
     visible={show}
     title="Select Realtor"
     bodyStyle={{ padding: 0 }}
     onOk={(v) => {
      setSelected(selected)
      onChange?.(selected)
      setShow(false)
     }}
    >
     <RealtorSelector value={selected} onChange={(v) => setSelected(v ?? [])} />
    </Modal>
   )}
  </>
 )
}

export default SWToBarRealtor
