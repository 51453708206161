/* eslint-disable react/require-default-props */
import { Modal, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload'
import BaseUpload from 'components/elements/BaseUpload'

import React, { FC, useEffect, useState } from 'react'
import { TFileExt } from 'types'

const supporttedFiles: TFileExt[] = [
 'pdf',
 'csv',
 'txt',
 'xls',
 'jpg',
 'jpeg',
 'png',
 'docx',
 'doc',
]

const SWAttachmentModal: FC<{
 visible?: boolean
 onCancel?: () => void
 value?: UploadFile[]
 onChange?: (val?: UploadFile[]) => void
}> = ({ onChange, value, ...rest }) => {
 const [nValue, setValue] = useState(value)

 useEffect(() => {
  let mouted = true
  if (mouted && value) {
   setValue(value)
  }
  return () => {
   mouted = false
   setValue([])
  }
 }, [value])

 return (
  <Modal
   {...rest}
   title="Attachment"
   okText="Update"
   onOk={() => {
    onChange?.(nValue)
    rest.onCancel?.()
    setValue([])
   }}
  >
   <BaseUpload
    fileList={nValue}
    type="dragger"
    multiple
    onChange={(v) => {
     setValue(v)
    }}
    supporttedFiles={supporttedFiles}
   />
  </Modal>
 )
}

export default SWAttachmentModal
