import { Empty, Badge, Col, Modal, Row } from 'antd'
// import { BaseEmpty } from 'components/elements/BaseComponents'
import PropertyCard from 'components/shared/PropertyCard'
import { TSendMailPro } from 'features/leads'
import { TPropertyListing } from 'features/property'
import { FC, useEffect, useState } from 'react'

const SWProductModal: FC<{
 count: number
 visible?: boolean
 onCancel?: () => void
 data?: TPropertyListing[]
 onChange?: (val?: TPropertyListing[]) => void
 onUpdateData: (v: TPropertyListing[]) => void
}> = ({ onChange, onUpdateData, count, data, ...rest }) => {
 const [prodcutData, setProductData] = useState<TPropertyListing[]>(data || [])

 useEffect(() => {
  if (data) {
   setProductData(data)
  }
 }, [data])

 return (
  <Modal
   width={1024}
   {...rest}
   title={
    <>
     Proprieties <Badge count={prodcutData.length || 0} />
    </>
   }
   okText="Update"
   onOk={() => {
    onUpdateData?.(prodcutData)
    rest.onCancel?.()
   }}
  >
   {/* <div style={{ maxHeight: '500px', overflowY: 'auto' }}> */}
   {!prodcutData || (prodcutData.length === 0 && <Empty />)}
   {/* <div className="container-fluid"> */}
   <Row gutter={[16, 8]}>
    {prodcutData.map((item, index) => (
     <Col span={12} key={index}>
      <PropertyCard
       onClose={
        prodcutData.length > 1
         ? () => {
            setProductData((prv) => prv.filter((v) => v.slug !== item.slug))
           }
         : undefined
       }
       item={item as any}
       shouldRedirect={false}
      />
     </Col>
    ))}
   </Row>
   {/* </div>
   </div> */}
  </Modal>
 )
}

export default SWProductModal
