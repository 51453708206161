/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import { http } from 'lib/http'
import { useMemo } from 'react'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { ApiResponce, InfinityNewType, PaginationType } from 'types'

type UseFetchPageRes<T> = {
 data?: InfiniteData<InfinityNewType<T>>
 isLoading: boolean
 fetchMore: () => void
 hasMore?: boolean
 refetch: () => void
 isRefetching?: boolean
 flatData?: T[]
 total?: any
}

type UseFetchPageProps = {
 params?: string
 body?: any
 method?: 'get' | 'post'
 enable?: boolean
}

type UseFetchPageNewRes<T> = {
 data: T[]
 links: {
  first?: string
  last?: string
  next?: null
  prev?: null
 }
 message: string
 meta: { total: number }
 status: string
}

const spllitUrl = (val: string): any => val.split('page=')?.[1]

const fetchData = async (
 props: UseFetchPageProps & { url: string },
): Promise<InfinityNewType<any>> => {
 try {
  const { data }: AxiosResponse<UseFetchPageNewRes<any>> =
   props.method === 'post'
    ? await http.post(`user/${props.url}`, props.body)
    : await http.get(`user/${props.url}`)
  if (data && data.status === 'success') {
   return {
    data: data?.data ?? [],
    next: data?.links?.next ? spllitUrl(data?.links?.next) : undefined,
    total: data?.meta?.total,
   }
  }
  return {
   data: [],
   next: undefined,
  }
 } catch (error) {
  return {
   data: [],
   next: undefined,
  }
 }
}

const useFetchPageNew = <T>(
 key: string,
 options?: UseFetchPageProps,
): UseFetchPageRes<T> => {
 const { data, isLoading, fetchNextPage, hasNextPage, refetch, isRefetching } =
  useInfiniteQuery<InfinityNewType<T>>(
   [`${key}`, options?.body, options?.params],
   ({ pageParam = 1 }) =>
    fetchData({
     method: options?.method || 'get',
     url: `${key}?page=${pageParam}${
      options?.params ? `&${options.params}` : ''
     }`,
     body: options?.body,
    }),
   {
    getNextPageParam: (lastPage) =>
     lastPage && lastPage.next && lastPage.next !== null
      ? lastPage.next
      : false,
    enabled: options?.enable,
   },
  )

 const flatData = useMemo(
  () => (data && data.pages ? data?.pages.map((v) => v.data).flat() : []),
  [data],
 )

 const total = useMemo(
  () =>
   data && data.pages && data.pages.length > 0 ? data.pages[0].total || 0 : 0,
  [data],
 )

 return {
  data,
  isLoading,
  fetchMore: fetchNextPage,
  hasMore: hasNextPage,
  refetch,
  isRefetching,
  flatData: flatData as T[],
  total,
 }
}

export default useFetchPageNew
