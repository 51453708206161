import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import LeadDetailPage from './details'
// import LeadLIst from './LeadList'
import LeadSendEmail from './LeadSendEmail'
import LeadSmsPage from './LeadSmsPage'
import AllLeads from './AllLeads'
import MyLeads from './MyLeads'

export const LeadRoutes: FC = () => {
 return (
  <Routes>
   {/* <Route path="" element={<LeadLIst />} /> */}
   <Route path="" element={<Navigate to="all-leads" />} />
   <Route path="all-leads" element={<AllLeads />} />
   <Route path="my-leads" element={<MyLeads />} />

   {/* <Route path="" element={<LeadListPage />} /> */}

   <Route path="details/:id/*" element={<LeadDetailPage />} />
   <Route path="send-sms" element={<LeadSmsPage />} />
   <Route path="send-email" element={<LeadSendEmail />} />
   {/* <Route path="*" element={<Navigate to="/admin/leads" replace />} /> */}
  </Routes>
 )
}
