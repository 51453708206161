import { Form, Input, Select } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useGetProvinceQuery } from 'api/baseApi'
import React, { FC, useEffect } from 'react'
import { TMode } from 'types'
import { useAddAddressesMutation } from '../api'
import { AddAddresses } from '../types'

const FormInit: AddAddresses = {
 city: '',
 province: '',
 street: '',
 zip: '',
 number: '',
 lead_id: '',
}

const AddAddressesModal: FC<{
 visible?: boolean
 onCancel?: () => void
 leadId?: any
 mode?: TMode
 EditData?: AddAddresses
}> = ({ visible, onCancel, leadId, mode = 'ADD', EditData }) => {
 const { data: Provinces } = useGetProvinceQuery()
 const [AddAddress, { isLoading }] = useAddAddressesMutation()

 const [form] = Form.useForm<AddAddresses>()

 const HandleSubmitAddress = async () => {
  const values = await form.validateFields()
  if (mode === 'EDIT' && leadId) {
   await AddAddress({
    ...values,
    lead_id: leadId,
    id: EditData?.id,
   }).unwrap()
   form.resetFields()
   onCancel?.()
  } else if (leadId) {
   await AddAddress({ ...values, lead_id: leadId }).unwrap()
   form.resetFields()
   onCancel?.()
  }
 }

 useEffect(() => {
  if (mode === 'EDIT' && EditData) {
   form.setFieldsValue({
    number: EditData.number,
    street: EditData.street,
    city: EditData.city,
    province: EditData.province,
    zip: EditData.zip,
   })
  }
  return () => {
   form.resetFields()
  }
 }, [mode, EditData, form])

 return (
  <Modal
   visible={visible}
   onCancel={() => {
    onCancel?.()
    form.resetFields()
   }}
   title={mode === 'ADD' ? 'Add New Address' : 'Edit New Address'}
   onOk={HandleSubmitAddress}
   confirmLoading={isLoading}
   okText={mode === 'EDIT' ? 'Update' : 'Add'}
  >
   <Form layout="vertical" initialValues={FormInit} form={form}>
    <Form.Item label="House Number" name="number" rules={[{ required: true }]}>
     <Input placeholder="Enter house number" />
    </Form.Item>
    <Form.Item label="Street" name="street" rules={[{ required: true }]}>
     <Input placeholder="Enter Sreet" />
    </Form.Item>
    <Form.Item label="City" name="city" rules={[{ required: true }]}>
     <Input placeholder="Enter City" />
    </Form.Item>
    <Form.Item label="Province" name="province" rules={[{ required: true }]}>
     <Select>
      <option value="" disabled>
       Select your province
      </option>
      {Provinces?.map((item, index) => (
       <option value={item} key={index}>
        {item}
       </option>
      ))}
     </Select>
    </Form.Item>
    <Form.Item
     label="Zip code"
     name="zip"
     rules={[{ required: true, message: 'Zip code is required' }]}
    >
     <Input placeholder="Enter Zip code" />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddAddressesModal
