import { Form, Input, Modal } from 'antd'
import { useAddNoteMutationMutation } from 'features/leads'
import { queryClient } from 'lib/react-query'
import React, { FC, useEffect } from 'react'

const Forminit = {
 content: '',
}

const AddNoteModal: FC<{
 visible?: boolean
 onCancel?: () => void
 leadId?: any
}> = ({ visible, onCancel, leadId }) => {
 const [form] = Form.useForm<{ content: string }>()

 const { TextArea } = Input

 const [addNote, { isLoading }] = useAddNoteMutationMutation()

 const HandleSubmitNote = async () => {
  const values = await form.validateFields()
  await addNote({ ...values, lead_id: leadId }).unwrap()
  queryClient.invalidateQueries(`lead/profile/${leadId}`)
  form.resetFields()
  onCancel?.()
 }
 useEffect(
  () => () => {
   form.resetFields()
  },
  [],
 )

 return (
  <Modal
   title="Add Note"
   onCancel={onCancel}
   visible={visible}
   confirmLoading={isLoading}
   okText="Submit"
   onOk={HandleSubmitNote}
  >
   <Form layout="vertical" initialValues={Forminit} form={form}>
    <Form.Item label="Content" name="content">
     <TextArea placeholder="Enter note" />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddNoteModal
