// import { Tooltip } from 'antd'
// import React, { FC, ReactNode, useState } from 'react'
// import { TColor } from 'types'
// import { BaseButton } from '../BaseButton'
// import { BtnAnch } from '../global'

// const SpeedDialBlock: FC<{
//  menus?: {
//   title?: string
//   icon?: ReactNode
//   onClick?: () => void
//   color?: TColor
//   hide?: boolean
//  }[]
// }> = ({ menus }) => {
//  const [opened, setOpened] = useState(false)
//  return (
//   <div className={`mts-speed-dial ${opened ? 'mts-speed-dial-opened' : ''}`}>
//    <div onClick={() => setOpened(false)} className="mts-speed-dial-mask" />
//    <div className="mts-speed-dial-container">
//     {opened && menus && menus.length > 0 && (
//      <ul
//       className={`speed-dial-menus ${opened ? ' speed-dial-menus-open' : ''}`}
//      >
//       {menus.map(
//        (v, i) =>
//         !v.hide && (
//          <li key={i}>
//           <span className="li-span-title">{v.title}</span>
//           <BtnAnch
//            className={`${v.color ? `a-color bg-${v.color}` : ''}`}
//            onClick={() => {
//             setOpened(false)
//             v.onClick?.()
//            }}
//           >
//            {v.icon}
//           </BtnAnch>
//          </li>
//         ),
//       )}
//      </ul>
//     )}
//     <BtnAnch onClick={() => setOpened((prv) => !prv)} className="dial-clicker">
//      <i className="fas fa-plus" />
//     </BtnAnch>
//    </div>
//   </div>
//  )
// }

// export default SpeedDialBlock

/* eslint-disable react/require-default-props */
import { FC, ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import { TColor } from 'types'
import { BtnAnch } from '../global'

const SpeedDialBlock: FC<{
 menus?: {
  title?: string
  icon?: ReactNode
  onClick?: () => void
  color?: TColor
  hide?: boolean
 }[]
}> = ({ menus }) => {
 const [opened, setOpened] = useState(false)
 return createPortal(
  <div className={`mts-speed-dial ${opened ? 'mts-speed-dial-opened' : ''}`}>
   <div onClick={() => setOpened(false)} className="mts-speed-dial-mask" />
   <div className="mts-speed-dial-container">
    {opened && menus && menus.length > 0 && (
     <ul
      className={`speed-dial-menus ${opened ? ' speed-dial-menus-open' : ''}`}
     >
      {menus.map(
       (v, i) =>
        !v.hide && (
         <li key={i}>
          <span className="li-span-title">{v.title}</span>
          <BtnAnch
           className={`${v.color ? `a-color bg-${v.color}` : ''}`}
           onClick={() => {
            setOpened(false)
            v.onClick?.()
           }}
          >
           {v.icon}
          </BtnAnch>
         </li>
        ),
      )}
     </ul>
    )}
    <BtnAnch onClick={() => setOpened((prv) => !prv)} className="dial-clicker">
     <i className="fas fa-plus" />
    </BtnAnch>
   </div>
  </div>,
  document.body,
 )
}

export default SpeedDialBlock
