import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useState } from 'react'
import ControlActionPlanModal from 'components/shared/Modals/ControlActionPlanModal'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import MarketUpdatesViewModal from 'features/automation/components/market-updates/MarketUpdatesViewModal'
import useFetchPage2 from 'hooks/useFetchPage2'
import { queryClient } from 'lib/react-query'
import BuilkActions from '../../components/action-plan/BuilkActions'
import TableColumns from '../../components/action-plan/TableColumns'
import { TActionPlanList } from '../../types'

type TControlType = 'Pause' | 'Stop' | 'Completed' | 'Running'
export type TControlAPModal = {
 type: TControlType | null
 show: boolean
 id: any
}

const MarketUpdatesList: FC = () => {
 const [searchKey, setSearchKey] = useState('')
 const [showModal, setShowModal] = useState<TControlAPModal>({
  type: null,
  show: false,
  id: null,
 })

 const [showViewModal, setShowViewModal] = useState<null | string>(null)
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  isRefetching,
  total,
 } = useFetchPage2<TActionPlanList>('newsletters', {
  params: `type=market-update${searchKey ? `&search=${searchKey}` : ''}`,
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectedRows,
  selectAll,
  unSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 return (
  <>
   <ListContentLayout
    title="Market Updates"
    actions={
     <BuilkActions
      actionRefresh={refetch}
      selectedRows={selectedRows}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey })}
      mode="market-update"
     />
    }
   >
    <MDataTable
     totalData={total || 1000}
     search={{
      placeholder: 'Enter the name of Market Update',
      onSearch: setSearchKey,
      show: true,
     }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={TableColumns({
      selectedKeys,
      selectAll,
      unSelected,
      handleClickView: (id?: any) => setShowViewModal(id),
      handleControlAP: (type: TControlType, id?: any) => {
       queryClient.removeQueries({
        queryKey: `assignlist-${id}-${type}-`,
        exact: true,
       })
       setShowModal({ id, type, show: true })
      },
      mode: 'market-updates',
      refetch,
     })}
    />
    {showModal?.show && (
     <ControlActionPlanModal
      ctrlAP={showModal}
      onCancel={() => setShowModal({ show: false, type: null, id: null })}
      refetch={refetch}
      from="market-updates"
     />
    )}
   </ListContentLayout>
   {showViewModal !== null && (
    <MarketUpdatesViewModal
     visible={showViewModal !== null}
     onToggle={() => setShowViewModal(null)}
     id={Number(showViewModal)}
     type="market-update"
    />
   )}
  </>
 )
}

export default MarketUpdatesList
