import React, { FC, CSSProperties } from 'react'
import { utils } from 'utils'
import ReactDOM from 'react-dom/client'

import {
 Cell,
 ColumnDef,
 Header,
 flexRender,
 getCoreRowModel,
 useReactTable,
} from '@tanstack/react-table'

// needed for table body level scope DnD setup
import {
 DndContext,
 KeyboardSensor,
 MouseSensor,
 TouchSensor,
 closestCenter,
 type DragEndEvent,
 useSensor,
 useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
 arrayMove,
 SortableContext,
 horizontalListSortingStrategy,
 useSortable,
} from '@dnd-kit/sortable'

// needed for row & cell level scope DnD setup
import { CSS } from '@dnd-kit/utilities'
// import { makeData, Person } from './makeData'

const dummydata = [
 {
  firstName: 'fist-1',
  lastName: 'last=1',
  age: 10,
  visits: 1,
 },
 {
  firstName: 'fist-2',
  lastName: 'last=2',
  age: 11,
  visits: 2,
 },
 {
  firstName: 'fist-3',
  lastName: 'last=3',
  age: 12,
  visits: 3,
 },
 {
  firstName: 'fist-4',
  lastName: 'last=4',
  age: 13,
  visits: 4,
 },
 {
  firstName: 'fist-5',
  lastName: 'last=5',
  age: 14,
  visits: 5,
 },
 {
  firstName: 'fist-6',
  lastName: 'last=6',
  age: 15,
  visits: 6,
 },
 {
  firstName: 'fist-7',
  lastName: 'last=7',
  age: 16,
  visits: 7,
 },
]

const DraggableTableHeader = ({ header }: { header: Header<any, unknown> }) => {
 const { attributes, isDragging, listeners, setNodeRef, transform } =
  useSortable({
   id: header.column.id,
  })

 const style: CSSProperties = {
  opacity: isDragging ? 0.8 : 1,
  position: 'relative',
  transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
  transition: 'width transform 0.2s ease-in-out',
  whiteSpace: 'nowrap',
  width: header.column.getSize(),
  zIndex: isDragging ? 1 : 0,
 }

 return (
  <th colSpan={header.colSpan} ref={setNodeRef} style={style}>
   {header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.header, header.getContext())}
   <button type="button" {...attributes} {...listeners}>
    🟰
   </button>
  </th>
 )
}

const DragAlongCell = ({ cell }: { cell: Cell<any, unknown> }) => {
 const { isDragging, setNodeRef, transform } = useSortable({
  id: cell.column.id,
 })

 const style: CSSProperties = {
  opacity: isDragging ? 0.8 : 1,
  position: 'relative',
  transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
  transition: 'width transform 0.2s ease-in-out',
  width: cell.column.getSize(),
  zIndex: isDragging ? 1 : 0,
 }

 return (
  <td style={style} ref={setNodeRef}>
   {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </td>
 )
}

const TestPage: FC = () => {
 const columns = React.useMemo<
  ColumnDef<{
   firstName: string
   lastName: string
   age: number
   visits: number
  }>[]
 >(
  () => [
   {
    accessorKey: 'firstName',
    cell: (info) => info.getValue(),
    id: 'firstName',
    size: 150,
   },
   {
    accessorFn: (row) => row.lastName,
    cell: (info) => info.getValue(),
    header: 'Last Name',
    id: 'lastName',
    size: 150,
   },
   {
    accessorKey: 'age',
    header: () => 'Age',
    id: 'age',
    size: 120,
   },
   {
    accessorKey: 'visits',
    header: 'Visits',
    id: 'visits',
    size: 120,
   },
  ],
  [],
 )

 const [data, setData] = React.useState(dummydata)
 const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
  columns.map((c) => c.id!),
 )

 const table = useReactTable({
  data,
  columns,
  getCoreRowModel: getCoreRowModel(),
  state: {
   columnOrder,
  },
  onColumnOrderChange: setColumnOrder,
  debugTable: true,
  debugHeaders: true,
  debugColumns: true,
 })

 // reorder columns after drag & drop
 const handleDragEnd = (event: DragEndEvent) => {
  const { active, over } = event
  if (active && over && active.id !== over.id) {
   setColumnOrder((columnOrder) => {
    const oldIndex = columnOrder.indexOf(active.id as string)
    const newIndex = columnOrder.indexOf(over.id as string)
    return arrayMove(columnOrder, oldIndex, newIndex) // this is just a splice util
   })
  }
 }

 const sensors = useSensors(
  useSensor(MouseSensor, {}),
  useSensor(TouchSensor, {}),
  useSensor(KeyboardSensor, {}),
 )
 return (
  // NOTE: This provider creates div elements, so don't nest inside of <table> elements
  <DndContext
   collisionDetection={closestCenter}
   modifiers={[restrictToHorizontalAxis]}
   onDragEnd={handleDragEnd}
   sensors={sensors}
  >
   <div className="p-2">
    <div className="h-4" />
    <div className="flex flex-wrap gap-2" />
    <div className="h-4" />
    <table>
     <thead>
      {table.getHeaderGroups().map((headerGroup) => (
       <tr key={headerGroup.id}>
        <SortableContext
         items={columnOrder}
         strategy={horizontalListSortingStrategy}
        >
         {headerGroup.headers.map((header) => (
          <DraggableTableHeader key={header.id} header={header} />
         ))}
        </SortableContext>
       </tr>
      ))}
     </thead>
     <tbody>
      {table.getRowModel().rows.map((row) => (
       <tr key={row.id}>
        {row.getVisibleCells().map((cell) => (
         <SortableContext
          key={cell.id}
          items={columnOrder}
          strategy={horizontalListSortingStrategy}
         >
          <DragAlongCell key={cell.id} cell={cell} />
         </SortableContext>
        ))}
       </tr>
      ))}
     </tbody>
    </table>
   </div>
  </DndContext>
 )
}

export default TestPage
