import { Divider, Spin, Modal, Empty } from 'antd'
import {
 TPreviewTemplateBody,
 usePreviewEmailLeadQuery,
 usePreviewSmsLeadQuery,
} from 'features/leads'
import React, { FC, useEffect, useState } from 'react'

const SWPreviewModal: FC<{
 type: 'SMS' | 'Email'
 body: TPreviewTemplateBody
 visible?: boolean
 onToggle: () => void
}> = ({ type, body, onToggle, visible }) => {
 const [data, setData] = useState<TPreviewTemplateBody | null>(null)
 const {
  data: emailPreview,
  isLoading: emailLoading,
  isError: emailError,
 } = usePreviewEmailLeadQuery(body, { skip: type === 'SMS' })

 const {
  data: smsPreview,
  isLoading: smsLoading,
  isError: smsError,
 } = usePreviewSmsLeadQuery(body, { skip: type === 'Email' })

 useEffect(() => {
  if (type === 'Email' && emailPreview) {
   setData(emailPreview)
  } else if (type === 'SMS' && smsPreview) {
   setData(smsPreview)
  }
 }, [emailPreview, smsPreview])

 //  if (smsError || emailError) {
 //   return <Empty />
 //  }

 return (
  <Modal
   forceRender
   width={800}
   onCancel={onToggle}
   visible={visible}
   title={`${type} Preview`}
   footer={null}
  >
   <Spin className="w-100" spinning={smsLoading || emailLoading}>
    {smsError || emailError ? (
     <Empty />
    ) : (
     data && (
      <>
       <p className="mb-1">
        <strong>To</strong>:{data?.to}{' '}
       </p>
       {type === 'Email' && data?.subject && (
        <p className="mb-1">
         <strong>Subject</strong>:{data?.subject ?? ''}{' '}
        </p>
       )}
       <Divider />
       {data?.body && (
        <div
         className="mail-preview-body"
         //  style={{ whiteSpace: 'pre-line' }}
         dangerouslySetInnerHTML={{
          __html: `${data.body}`,
         }}
        />
       )}
      </>
     )
    )}
   </Spin>
  </Modal>
 )
}

export default SWPreviewModal
