import { Card, Collapse, Empty } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { SvgNoteIcon } from 'components/icons'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TContentNote, TUserActivityNote } from 'features/leads/types'
import useFetchPage from 'hooks/useFetchPage'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { formatDate } from 'utils/functions'
import useFetchPageNew from 'hooks/useFetchpageNew'
import AllActivity from './AllActivity'
import ActivityLayout from './components/ActivityLayout'

const NoteActivity: FC = () => {
 const { leadId, realtorId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityNote>(`lead/profile/${leadId}`, {
   body: { type: 'note', ...(realtorId ? { realtor_id: realtorId } : {}) },
   method: 'post',
  })
 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({ content: flatData?.[val?.index]?.content })
   }
   totalCount={total}
   totalTitle="Notes"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    titleNode: <h3 className="mb-0 mt-0">{val?.title}</h3>,
    description: val?.content ?? '',
    updated: val?.updated,
   })}
  />
 )
}
export default NoteActivity
// const { Panel } = Collapse

// const NoteActivity: FC = () => {
//  const { leadId } = useLeadDetails()
//  const { isLoading, flatData, fetchMore, hasMore, isRefetching, total } =
//   useFetchPageNew<TContentNote>(`lead/profile/${leadId}`, {
//    body: { type: 'note' },
//    method: 'post',
//   })
//  // useFetchPage<TContentNote>(`lead/profile/react/${leadId}`, {
//  //  method: 'post',
//  //  body: { type: ['note'] },
//  // })

//  if (isLoading) {
//   return <BlockLoader />
//  }

//  if (!flatData || flatData.length === 0) {
//   return (
//    <div className="no-data-empty-compo">
//     <Empty
//      image={<i className="fal fa-sticky-note no-data-image" />}
//      description={<span className="no-data-heading">Notes Empty</span>}
//     >
//      <p className="no-data-description">No results found related to notes</p>
//     </Empty>
//    </div>
//   )
//  }

//  return (
//   <Card
//    // title="Notes"
//    //  title={`Notes ${flatData?.length > 0 ? `(${flatData?.length})` : ''}`}
//    title={`Notes (${total})`}
//    loading={isLoading}
//   >
//    <LoeadMorePagination
//     fethMore={fetchMore}
//     hasMore={hasMore}
//     loading={isRefetching}
//    >
//     <Collapse className="mts-collapse activity-collaps">
//      {flatData &&
//       flatData.length > 0 &&
//       flatData.map((item, i) => (
//        <Panel
//         showArrow={false}
//         header={
//          <AvatarStatus
//           titleNode={
//            <div style={{ display: 'flex' }}>
//             {item.nfrom_name && (
//              <h5 style={{ marginRight: '5px' }}>{item.nfrom_name}</h5>
//             )}
//             {item.nto_name && (
//              <h5>
//               {' '}
//               <i className="fal fa-angle-right" />
//              </h5>
//             )}
//             <h5 style={{ marginLeft: '5px' }}>{item.nto_name}</h5>
//            </div>
//           }
//           subTitle={
//            //  item.nupdated_at
//            //   ? //  moment(item.nupdated_at).fromNow()
//            //     moment(item.nupdated_at).format('DD-MM-YYYY hh:mm A')
//            //   : ''
//            item.updated
//             ? //  moment(item.updated).format('DD-MM-YYYY hh:mm A')
//               formatDate?.(item.updated)
//             : ''
//           }
//           avatar={{
//            size: 50,
//            icon: <SvgNoteIcon />,
//           }}
//          />
//         }
//         key={i}
//        >
//         <p>Content : {item.ncontent}</p>
//        </Panel>
//       ))}
//     </Collapse>
//    </LoeadMorePagination>
//   </Card>
//  )
// }

// export default NoteActivity
