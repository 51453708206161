import ListContentLayout from 'layout/ListContentLayout'
import { FC, useState } from 'react'
import { Menu, Table } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import {
 useDltListingItemMutation,
 useGetListingSettingQuery,
} from 'features/settings/api'
import { globalConfirm } from 'components/elements/global'
import { ColumnsType } from 'antd/lib/table'
import { useGlobal } from 'raducer/global'
import ActionBlockListingSettings from './ActionBlockListingSettings'

const ListingSettings: FC = () => {
 const [selectedKey, setKey] = useState<'brokerage' | 'city'>('brokerage')
 const { updateBrokerageData } = useGlobal()

 const { data, isLoading, refetch, isFetching } = useGetListingSettingQuery({
  url:
   selectedKey === 'brokerage'
    ? 'get-brokerage-setting'
    : 'get-neighbourhood-setting',
 })
 const [dltListingItem] = useDltListingItemMutation()

 const onDelete = async (key: string) => {
  const isBrok = selectedKey === 'brokerage'
  globalConfirm({
   cancelText: 'No',
   okText: 'Yes',
   content: `Are you sure want to delete this ${
    isBrok ? 'Brokerage' : 'City'
   }?`,
   onOk: async () => {
    await dltListingItem({
     url: isBrok ? 'delete-brokerage-listing' : 'delete-neighbourhood-listing',
     body: {
      val: key,
     },
    }).unwrap()
    refetch()
    updateBrokerageData?.({ type: 'remove', data: key })
   },
  })
 }

 const columns: ColumnsType<{ value: string }> = [
  {
   title: `${selectedKey === 'brokerage' ? 'Brokerage' : 'City'} Name`,
   dataIndex: 'value',
   width: 250,
   fixed: true,
  },
  {
   title: 'Action',
   width: 80,
   fixed: 'right',
   render: (_, r: any) => (
    <BaseButton
     onClick={() => onDelete?.(r?.value)}
     tooltipText="Delete"
     shape="circle"
     size="small"
     buttonType="danger"
     icon={<i className="fas fa-trash" />}
    />
   ),
  },
 ]

 return (
  <ListContentLayout
   title="Listing Settings"
   actions={<ActionBlockListingSettings refetch={refetch} type={selectedKey} />}
   bodyClass="lead-list-body"
  >
   <div className="lead-tile-blk">
    <Menu
     selectedKeys={[selectedKey]}
     onSelect={({ key }) => setKey(key as any)}
     mode="horizontal"
    >
     <Menu.Item key="brokerage">Brokerage</Menu.Item>
     <Menu.Item key="city">City</Menu.Item>
    </Menu>
   </div>
   <Table
    loading={isFetching || isLoading}
    pagination={false}
    columns={columns}
    dataSource={data?.map?.((i, n) => ({ value: i, key: i })) || []}
   />
  </ListContentLayout>
 )
}

export default ListingSettings
