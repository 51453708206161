import { Card } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'

import React, { FC } from 'react'
import { FCC } from 'types'

const ProInnerCard: FCC<{
 emptyText?: string
 title?: any
 onCLickAdd?: () => void
 onClickEdit?: () => void
 isEmpty?: boolean
}> = ({
 onCLickAdd,
 children,
 onClickEdit,
 title,
 isEmpty,
 emptyText = 'Not Data Found',
}) => {
 return (
  <Card
   extra={
    onCLickAdd ? (
     <AddButton onClick={onCLickAdd} />
    ) : onClickEdit ? (
     <EditButton onClick={onClickEdit} />
    ) : undefined
   }
   title={title}
   type="inner"
  >
   {isEmpty ? (
    <div className="text-center">
     <p className="m-0">{emptyText}</p>
    </div>
   ) : (
    children
   )}
  </Card>
 )
}
export default ProInnerCard

const AddButton: FC<{
 onClick?: () => void
}> = ({ onClick }) => (
 <BaseButton onClick={onClick} size="small" type="primary">
  {' '}
  <i className="fas fa-plus" />{' '}
 </BaseButton>
)

const EditButton: FC<{
 onClick?: () => void
}> = ({ onClick }) => (
 <BaseButton onClick={onClick} size="small" type="primary">
  {' '}
  <i className="fas fa-edit" />{' '}
 </BaseButton>
)
