/* eslint-disable no-nested-ternary */
import { Card, Col, Row } from 'antd'
import { SvgBed, SvgBath, SvgGarage } from 'components/icons'
import ShareBlock from 'components/shared/Common/ShareModal'
import PropertyPrice from 'components/shared/Property/PropertyPrice'
import PropertyTag from 'components/shared/Property/PropertyTag'

import { TPropertyListingDetailCommon } from 'features/property'
import useAuth from 'hooks/useAuth'
import { FC, useState } from 'react'

const MainInfoBlock: FC<{ data?: TPropertyListingDetailCommon }> = ({
 data,
}) => {
 const [showShareBlock, setShowShareBlock] = useState(false)

 const { user } = useAuth()
 const baseUrl = user?.website

 //  const dataLinkToShare = () => {
 //   if (data?.listing_type === 'sold' || data?.listing_type === 'leased') {
 //    return `${baseUrl}/sold-property-details/${data?.slug}`
 //   }

 //   return `${baseUrl}/property-details/${data?.slug}`
 //  }

 return (
  <div>
   <Card className="m-0">
    <div className="main-info">
     <Row>
      <Col sm={14}>
       <ul className="status list-inline">
        <li className="list-inline-item">
         <PropertyTag size="lg" tag={data?.listing_type} />
        </li>
        {data?.added_ts && (
         <li className="list-inline-item">
          {data?.listing_type === 'sold'
           ? `Sold ${data?.sold_date}`
           : data?.listing_type === 'leased'
           ? `Leased ${data?.sold_date}`
           : `Added ${data?.added_ts}`}
         </li>
        )}
        <li className="list-inline-item">{data?.property_type}</li>
       </ul>
       <div className="address">
        {data?.address?.unit_no ? `${data?.address?.unit_no} - ` : null}
        {data?.address?.addr}
       </div>
       <div className="location">
        {data?.address?.city}, {data?.address?.province}
       </div>
       <ul className="list-inline specs">
        {data?.bed !== null && Number(data?.bed) !== 0 && (
         <li className="list-inline-item">
          <SvgBed />
          {data?.bed ?? 0}
          {data?.bed_plus !== null &&
           Number(`${data?.bed_plus}`) !== 0 &&
           `+ ${data?.bed_plus}`}{' '}
          Beds
         </li>
        )}
        {data?.bath !== null && Number(data?.bath) !== 0 && (
         <li className="list-inline-item">
          <SvgBath />
          {data?.bath} Baths
         </li>
        )}
        {data?.garage_space !== null && Number(data?.garage_space) !== 0 && (
         <li className="list-inline-item">
          <SvgGarage />
          {Number(data?.garage_space)} Garages
         </li>
        )}
       </ul>
      </Col>
      <Col sm={10}>
       <div className="main-right text-end">
        <div className="listed-for pe-0">
         <PropertyPrice
          listing={data?.listed_price}
          sold={data?.sold_price}
          lable
          tag={data?.listing_type}
         />
        </div>
       </div>
       <div style={{ textAlign: 'right', marginTop: '1rem' }}>
        <p>
         <i
          className="fa fa-share-alt"
          style={{ fontSize: '24px', color: 'red', cursor: 'pointer' }}
          onClick={() => setShowShareBlock(true)}
         />
        </p>
       </div>
      </Col>
     </Row>
    </div>
   </Card>

   <ShareBlock
    show={showShareBlock}
    onHide={() => setShowShareBlock(false)}
    title="Share Property"
    link={`${baseUrl}${
     data?.listing_type === 'sold' || data?.listing_type === 'leased'
      ? '/sold-property-details/'
      : '/property-details/'
    }${data?.slug}`}
   />
  </div>
 )
}

export default MainInfoBlock
