import { Col, Row } from 'antd'
import BaseTag from 'components/elements/BaseTag'
import { leadApi } from 'features/leads/api'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import { TGroupType } from 'types'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import ProInnerCard from '../../ProInnerCard'

const StageBlock: FC<{
 stages?: TGroupType
 leadId?: any
 isTransferred?: boolean
}> = ({ stages, leadId, isTransferred }) => {
 const [stagesModal, setShowStageModal] = useState(false)
 const d = useAppDispatch()
 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))
 return (
  <div>
   <ProInnerCard
    emptyText="Stage is Empty"
    isEmpty={!stages}
    onCLickAdd={
     stages || !isTransferred ? undefined : () => setShowStageModal(true)
    }
    title="Stage"
   >
    <Row gutter={[7, 7]}>
     {stages && (
      <Col>
       <BaseTag
        className="m-0 p-2"
        // type={item.color}
        label={stages.name}
        isEdit={isTransferred}
        OnClickEdit={() => setShowStageModal(true)}
       />
      </Col>
     )}
    </Row>
   </ProInnerCard>
   {stagesModal && (
    <AssignTagsStagesModal
     selectedKeys={[leadId]}
     onCancel={() => setShowStageModal(false)}
     visible={stagesModal}
     refetch={refreshLead}
     assignType="stage"
    />
   )}
  </div>
 )
}

export default StageBlock
