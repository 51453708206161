import { Col, Row } from 'antd'
import { TActivityType } from 'features/leads/types'
import { FCC } from 'types'
import { BtnAnch } from 'components/elements/global'
import classNames from 'classnames'
import ActivityMenuItem from './ActivityMenu'

const ActivitySectionLayout: FCC<{
 activeId?: any
 onSelectMenu?: (id: any) => void
}> = ({ children, activeId, onSelectMenu }) => {
 const menuItems: { key: string; type: TActivityType }[] = [
  { type: 'all', key: 'all' },
  { type: 'text', key: 'textlog' },
  { type: 'email', key: 'emaillog' },
  { type: 'note', key: 'note' },
  { type: 'call', key: 'calllog' },
  { type: 'web', key: 'web' },
 ]

 return (
  <Row gutter={[10, 10]} className="activity-layout">
   <div className="inner-act-layout">
    <Col className="activity-sidemenu">
     <div className="menu-uil">
      {menuItems.map((i) => (
       <BtnAnch
        key={i.key}
        onClick={() => onSelectMenu?.(i?.key)}
        className={classNames('blk-link', {
         active: `${activeId}` === `${i?.key}`,
        })}
       >
        <ActivityMenuItem type={i.type} />
       </BtnAnch>
      ))}
     </div>
    </Col>
    <Col className="activity-content">{children}</Col>
   </div>
  </Row>
 )
}

export default ActivitySectionLayout
