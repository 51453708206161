/* eslint-disable react/jsx-no-useless-fragment */
import { Card, Col } from 'antd'
import { TPropertyListingDetailRespCommon } from 'features/property'
import { FC } from 'react'

const PropertyDetailsBlock: FC<{
 details?: {
  key?: string
  value?: TPropertyListingDetailRespCommon[]
 }[]
}> = ({ details }) => {
 return details?.length !== 0 ? (
  <>
   {details?.map?.(
    (i, n) =>
     i?.value?.length !== 0 && (
      <Col span={24} key={n}>
       <Card className="mt-1" title={i?.key} style={{ border: 'none' }}>
        <ul
         className="key-facts"
         style={{ columnCount: i?.value?.length === 1 ? 1 : 2 }}
        >
         {i.value?.map?.(
          (j, k) =>
           j?.value && (
            <li key={k} style={{ display: 'block', overflow: 'hidden' }}>
             <div style={{ fontSize: '16px' }}>
              {j?.key} :{' '}
              <span style={{ fontWeight: '600' }}>
               {j.key === 'Virtual Tour' ? (
                <a
                 className="a-tag-style"
                 href={j?.value}
                 target="_blank"
                 rel="noreferrer"
                >
                 Click to View
                </a>
               ) : (
                j?.value
               )}
              </span>
             </div>
            </li>
           ),
         )}
        </ul>
       </Card>
      </Col>
     ),
   )}
  </>
 ) : null
}

export default PropertyDetailsBlock
