import { Card } from 'antd'
import { TPropertyListingDetailRespCommon } from 'features/property'
import { FC, useEffect, useState } from 'react'

const KeyFactsBlock: FC<{
 facts?: TPropertyListingDetailRespCommon[]
 description?: TPropertyListingDetailRespCommon[]
}> = ({ description, facts }) => {
 const [allDesc, setAllDesc] = useState<any>([])

 useEffect(() => {
  if (description) {
   setAllDesc?.(
    description.map((i, n) => ({
     key: i?.key,
     length: 450,
     value: i?.value,
    })),
   )
  }
 }, [description])

 return (
  <div>
   <Card className="m-0" title="Key Facts">
    {facts?.length !== 0 && (
     <ul className="key-facts">
      {facts?.map?.(
       (i, n) =>
        i?.value && (
         <li key={n} style={{ display: 'block', overflow: 'hidden' }}>
          <div style={{ fontSize: '16px' }}>
           {i?.key} : <span style={{ fontWeight: '600' }}> {i?.value}</span>
          </div>
         </li>
        ),
      )}
     </ul>
    )}

    {allDesc?.length !== 0 && (
     <div className="description">
      {allDesc?.map?.(
       (i: any, n: any) =>
        i?.value && (
         <div key={n}>
          <h5>{i.key}</h5>

          {`${i?.value?.slice(0, i?.length)}${
           i?.value?.length > i?.length ? '...' : ''
          }`}

          {i?.value?.length >= i?.length && (
           <span
            style={{ fontSize: '15px' }}
            className="show-more"
            onClick={() => {
             const index = allDesc.findIndex((j: any) => j?.key === i?.key)
             const allData = [...allDesc]
             allData[index].length =
              i?.value?.length === i?.length ? 450 : i?.value?.length
             setAllDesc(allData)
            }}
           >
            {!(i?.value?.length === 450 && i?.length === 450) && (
             <>&nbsp; show {i?.length === 450 ? 'more' : 'less'}</>
            )}
           </span>
          )}
         </div>
        ),
      )}
     </div>
    )}
   </Card>
  </div>
 )
}

export default KeyFactsBlock
