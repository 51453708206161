import { Navigate, Route, Routes } from 'react-router-dom'
import AuthLayout from '../components/Layout'
import ForgotPassword from './ForgotPassword'
import Login from './Login'

export const AuthRoutes = () => {
 return (
  <AuthLayout>
   <Routes>
    <Route path="" element={<Navigate to="/auth/login" />} />
    <Route path="login" element={<Login />} />
    <Route path="reset-password" element={<ForgotPassword />} />
   </Routes>
  </AuthLayout>
 )
}
