import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Col, Input } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import { BtnAnch } from 'components/elements/global'
import PropertyDetailsModal from 'components/shared/PropertyDetails/PropertyDetailsModal'
import { useGetSearchPreConQuery } from '../api/precon'
import { useGetSearchSuggestionsQuery } from '../api'
import { useProListing } from '../provider/ProListingProvider'

export const ProListingSearch: FC = () => {
 const { type, search, setSearch, setSearchType, setSelectedCity } =
  useProListing()

 const [showSearchResult, setSearchResult] = useState<boolean>(false)
 const [proSlug, setProSlug] = useState<string | null>(null)

 const searchBlockRef = useRef(null)
 useClickAway(searchBlockRef, () => {
  setSearchResult(false)
 })

 useEffect(() => {
  setSearchResult(search?.length > 2)
 }, [search])

 const {
  data: listingData,
  isLoading: listingLoading,
  isFetching: listingFetching,
 } = useGetSearchSuggestionsQuery(
  {
   val: useMemo(() => search, [search]),
  },
  { skip: !search || type === 'preconstruction' },
 )

 const {
  data: preconData,
  isLoading: preConIsLoading,
  isFetching: preConIsFetching,
 } = useGetSearchPreConQuery(
  {
   search: useMemo(() => search, [search]),
  },
  { skip: !search || type === 'listing' },
 )

 const searchData = useMemo(
  () => (type === 'preconstruction' ? preconData : listingData),
  [preconData, listingData, search],
 )
 const isLoading = useMemo(
  () =>
   type === 'preconstruction'
    ? preConIsLoading || preConIsFetching
    : listingFetching || listingLoading,
  [preConIsLoading, preConIsFetching, listingFetching, listingLoading],
 )
 return (
  <>
   <Col flex={1}>
    <div ref={searchBlockRef}>
     <div className="search-sugg-block">
      <Input
       type="search"
       className="w-100"
       allowClear
       size="large"
       prefix={<i className="far fa-search" />}
       placeholder={
        type === 'listing'
         ? 'Address | MLS Num | City | Neighbourhood'
         : 'Address | City | Property Name'
       }
       value={search}
       onChange={(e) => {
        setSearch(e.target.value)
        if (e?.target?.value === '') {
         setSearchResult(false)
         setSearchType(null)
        }
       }}
       onFocus={(e) => {
        if ((search?.length ?? 0) > 0) {
         setSearch(e.target.value)
         setSearchResult(true)
        }
       }}
      />

      {showSearchResult && (
       <div className="suggestions">
        {isLoading ? (
         <BlockLoader />
        ) : (
         <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
          {searchData && searchData.length > 0 ? (
           searchData.map((item, index) => {
            return (
             <li key={index}>
              {/* {item?.type === ''} */}
              <BtnAnch
               onClick={() => {
                if (item?.type === 'city' || item?.type === 'community') {
                 setSearchType(item?.type ?? 'addr')
                 //  setSearch(
                 //   (item?.type === 'city' ? item?.city : item?.community) ?? '',
                 //  )
                 setSelectedCity(
                  item?.type === 'city'
                   ? item?.city ?? ''
                   : item?.community ?? '',
                 )
                }

                if (item.type !== 'city' && item.type !== 'community') {
                 setProSlug(item.slug)
                }

                setSearchResult(false)
               }}
              >
               {item.field}
              </BtnAnch>
             </li>
            )
           })
          ) : (
           <li className="text-center">
            <BtnAnch>Data not found</BtnAnch>
           </li>
          )}
         </ul>
        )}
       </div>
      )}
     </div>
    </div>
   </Col>
   {proSlug !== null && (
    <PropertyDetailsModal
     visible
     onHide={() => setProSlug(null)}
     value={proSlug}
     isPreCon={type === 'preconstruction'}
    />
   )}
  </>
 )
}
