import { Table } from '@tanstack/react-table'
import { createContext, useContext } from 'react'

export type MTContextProps = {
 table?: Table<any>
}

export const MTContext = createContext<MTContextProps>({})

export const useMTTable = (): MTContextProps => useContext(MTContext)
