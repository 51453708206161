import { secondAPI } from 'api/secondApi'
import { ApiResponce } from 'types'
import { baseTransformResponse3 } from 'api/baseApi'
import {
 TGetCrmFilters,
 TPropertyListingDetail,
 TSearchSuggType,
} from '../types'

export const propertyAPI = secondAPI.injectEndpoints({
 endpoints: ({ query }) => ({
  getListingDetails: query<
   TPropertyListingDetail,
   { value?: string; view_type?: string }
  >({
   query: (body) => ({
    method: 'post',
    url: 'api/get-property-details',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getCrmFilters: query<TGetCrmFilters[], string>({
   query: (body) => ({
    method: 'get',
    url: `api/get-crm-filters${body ?? ''}`,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getSearchSuggestions: query<TSearchSuggType[], { val?: string }>({
   query: ({ val }) => ({
    url: `api/get-filter-search?val=${val}`,
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getBroOrCitySuggestions: query<
   string[],
   { search?: string; url: 'get-brokerage-search' | 'city-search' }
  >({
   query: ({ url, search }) => ({
    url: `api/${url}?val=${search}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
 }),
})

export const {
 useGetListingDetailsQuery,
 useGetSearchSuggestionsQuery,
 useGetCrmFiltersQuery,
 useLazyGetBroOrCitySuggestionsQuery,
} = propertyAPI
