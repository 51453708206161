import { Result } from 'antd'
import { FC } from 'react'

export const NotFound: FC<{ message?: string; title?: string }> = ({
 message = 'Page not found!',
 title = '404',
}) => {
 return (
  <div className="d-flex align-items-center h-100 w-100 justify-content-center">
   <Result title={title} subTitle={message} status="404" />
  </div>
 )
}
