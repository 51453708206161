import { DatePicker, DatePickerProps, TimePickerProps } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import { baseTime } from 'lib'
import moment from 'moment'
import React, { FC } from 'react'

const { RangePicker } = DatePicker

export type BaseTDPickerProps = {
 disablePastTime?: boolean
 placeholder?: string
 className?: string
 value?: baseTime.Moment
 format?: string
 onChange?: (value: baseTime.Moment | null, dateString: string) => void
}

export type BaseTDRangerPickerProps = RangePickerProps & {
 disablePastTime?: boolean
 className?: string
 // onChange?: (value: RangeValue) => void
}

const getDisabledHours = (): any[] => {
 const hours = []
 for (let i = 0; i < baseTime().hour(); i++) {
  hours.push(i)
 }
 return hours
}

const disabledMinutes = (selectedHour: number, isNull?: boolean) => {
 const minutes = []
 if (selectedHour === baseTime().hour() || isNull) {
  for (let i = 0; i < baseTime().minute(); i++) {
   minutes.push(i)
  }
 }
 return minutes
}

const disabledDate = (current: baseTime.Moment) =>
 current.isBefore(moment(), 'day')

const BaseTDPicker: FC<BaseTDPickerProps> = ({
 placeholder = 'Select Date and Time',
 className,
 disablePastTime = false,
 ...rest
}) => {
 return (
  <DatePicker
   {...rest}
   showSecond={false}
   use12Hours
   showTime
   disabledDate={disablePastTime ? disabledDate : undefined}
   disabledTime={(date) => {
    return {
     disabledHours:
      date?.isSame(baseTime(), 'day') || date === null
       ? getDisabledHours
       : undefined,
     disabledMinutes:
      date?.isSame(baseTime(), 'day') || date === null
       ? (hour) => disabledMinutes(hour, date === null)
       : undefined,
    }
   }}
   placeholder={placeholder}
   format="DD-MM-YYYY h:mm A"
   className={`w-100 ${className ?? ''}`}
   onSelect={(d) => {
    if (baseTime(d).isBefore(baseTime(), 'minute')) {
     rest.value = baseTime()
     rest.onChange?.(baseTime(), '')
    }
   }}
  />
 )
}

export default BaseTDPicker

export const BaseTDRangePicker: FC<BaseTDRangerPickerProps> = ({
 //  placeholder = 'Select Date and Time',
 className,
 disablePastTime = false,
 onChange,
 value,
 //  ...rest
}) => {
 return (
  <RangePicker
   value={value}
   onChange={onChange}
   //  {...rest}
   showSecond={false}
   use12Hours
   showTime
   disabledDate={disablePastTime ? disabledDate : undefined}
   disabledTime={(date) => ({
    disabledHours: date?.isSame(baseTime(), 'day')
     ? getDisabledHours
     : undefined,
    disabledMinutes: date?.isSame(baseTime(), 'day')
     ? disabledMinutes
     : undefined,
   })}
   format="DD-MM-YYYY h:mm A"
   className={`w-100 ${className ?? ''}`}
  />
 )
}
