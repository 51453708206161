import { BtnAnch } from 'components/elements/global'
import { TGetLeads } from 'features/leads'
import LeadList from 'features/leads/components/LeadList'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import { useAppDispatch } from 'hooks'
import useFetchPage from 'hooks/useFetchPage'
import React, { FC, useEffect, useMemo } from 'react'
import { realtorsApi } from '../api'

const TransferredLeadList: FC<{
 realtorData: {
  id: number | null
  name?: string | undefined
 }
 onClose: () => void
 refetchRealtors: () => void
}> = ({ onClose, realtorData, refetchRealtors }) => {
 const { filterParams } = useLeadFilter()

 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetLeads>('transferred-lead-details', {
  method: 'post',
  body: useMemo(
   () => ({
    ...filterParams,
    ...(realtorData?.id ? { id: realtorData?.id } : {}),
   }),
   [filterParams, realtorData],
  ),
 })

 const d = useAppDispatch()
 useEffect(() => {
  d(realtorsApi.util.invalidateTags(['TRANSFERRED_LEAD_TILE']))
 }, [])

 return (
  <LeadList
   title={
    <div>
     <BtnAnch onClick={onClose}>
      <i className="fas fa-arrow-left back-btn" />
     </BtnAnch>
     <h4
      className="listcontentlayout-title"
      style={{ display: 'inline-block' }}
     >
      Transferred Leads - {realtorData?.name}
     </h4>
    </div>
   }
   realtorId={`${realtorData?.id}`}
   relatorName={realtorData?.name}
   fetchMore={fetchMore}
   refetch={() => {
    refetchRealtors()
    refetch?.()
    d(realtorsApi.util.invalidateTags(['TRANSFERRED_LEAD_TILE']))
   }}
   from="transfer-lead"
   isLoading={isLoading}
   hasMore={hasMore}
   totalCount={totalCount}
   leads={flatData}
   isRefetching={isRefetching}
  />
 )
}

export default TransferredLeadList
