import { Space } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BaseButton } from 'components/elements/BaseButton'

// import { BtnAnch } from 'components/elements/global'
import { TSSBlock, TSSBlockList } from 'components/shared/Common'
// import { ADMIN_PREFIX_PATH } from 'config'
import { FC, useMemo } from 'react'
// import { useNavigate } from 'react-router'
import useAuth from 'hooks/useAuth'
import Utils from 'utils/helper'
import { TGroupType } from 'types'
import { useLeadDetails } from '../../providers/LeadDetailsProvider'
import { TGetLead } from '../../types'

// const CURRENT_PATH = `${ADMIN_PREFIX_PATH}/leads`

const LeadDetailHeader: FC<{
 onNextLead?: () => void
 onPrevLead?: () => void
 onClose?: () => void
 data?: TGetLead
 from?: 'lead' | 'other'
 isRealtor?: boolean
 headerData: {
  stages?: TGroupType
  sources?: TGroupType
  tags?: TGroupType[]
  transferowner?: string
  name?: string
 }
}> = ({
 // data,
 //  from,
 onClose,
 onNextLead,
 onPrevLead,
 //  isRealtor,
 headerData,
}) => {
 const { leadId } = useLeadDetails()
 const { user } = useAuth()
 const { name, sources, stages, tags, transferowner } = headerData || {}
 //  const naviagate = useNavigate()

 //  const nextLead = (id?: any) => {
 //   naviagate(`${CURRENT_PATH}/details/${id}`)
 //  }

 //  const previousLead = (id?: any) => {
 //   naviagate(`${CURRENT_PATH}/details/${id}`)
 //  }

 const nameSuffix = useMemo(() => {
  if (transferowner) {
   return `(${
    user?.account_type === 'Brokerage' ? 'Assigned To' : 'Assigned By'
   } ${transferowner})`
  }
  return ''
 }, [transferowner, user?.account_type])

 return (
  <header className="lad-header">
   <div className="lead-avtr">
    <AvatarStatus
     className="avatar-lead"
     avatar={{ size: 70 }}
     //  title={data?.name}
     title={`${name} ${
      // !isRealtor ? nameSuffix : ''
      nameSuffix
     }`}
     //  avatarIconValue={Utils.shortName(data?.name || '')}
     content={
      <ul className="lead-uiblk pt-1">
       {stages?.name && (
        <li>
         Stage: <TSSBlock showTooltip type="stage" label={stages?.name} />
        </li>
       )}
       {sources?.name && (
        <li>
         Source: <TSSBlock showTooltip type="source" label={sources?.name} />
        </li>
       )}
       {tags && tags.length > 0 && (
        <li>
         Tag:{' '}
         <TSSBlockList typeId={leadId} type="lead" mode="tag" data={tags} />
        </li>
       )}
      </ul>
     }
    />
   </div>
   <div className="lead-header-action mt-0 mb-auto">
    <Space>
     {onPrevLead && (
      <BaseButton
       onClick={onPrevLead}
       tooltipText="Previous Lead"
       shape="circle"
       buttonType="primary"
       icon={<i className="fas fa-arrow-alt-to-left" />}
      />
     )}
     {onNextLead && (
      <BaseButton
       onClick={onNextLead}
       tooltipText="Next Lead"
       shape="circle"
       buttonType="primary"
       icon={<i className="fas fa-arrow-alt-to-right" />}
      />
     )}
     <BaseButton
      onClick={onClose}
      tooltipText="Back"
      shape="circle"
      buttonType="primary"
      icon={<i className="fas fa-times" />}
     />
    </Space>
   </div>
  </header>
 )
}

export default LeadDetailHeader
