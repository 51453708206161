import { Col, Form, Input, Modal, Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import EmailATUpload from 'components/shared/Common/EmailATUpload'
import EmailSubjectInput from 'components/shared/Common/EmailSubjectInput'
import EmailTextArea from 'components/shared/Common/EmailTextArea'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { TMode } from 'types'
import {
 useAddEmailTemplateMutation,
 useEditEmailTemplateMutation,
} from '../api'
import { TAddEmailTemplate, TGetEmailTemplateId } from '../types'

const EmailTemplateForm: FC<{
 editData?: TGetEmailTemplateId
 onSuccess?: () => void
 mode?: TMode
}> = ({ onSuccess, editData, mode = 'ADD' }) => {
 const [showSaveAsModal, setShowAsModal] = useState(false)
 const [form] = Form.useForm<TAddEmailTemplate>()
 const [saveAsEmailTemplate, { isLoading: saLoading }] =
  useAddEmailTemplateMutation()
 const [addEmailTemplate, { isLoading: addLoading }] =
  useAddEmailTemplateMutation()
 const [editEmailTemplate, { isLoading: editLoading }] =
  useEditEmailTemplateMutation()

 const handleSubmit = async () => {
  const values = await form.validateFields()

  const formData = connverToFormData(values)

  if (mode === 'EDIT' && editData) {
   await editEmailTemplate({ body: formData, id: editData.id }).unwrap()
   form.resetFields()
   onSuccess?.()
  } else {
   await addEmailTemplate(formData).unwrap()
   form.resetFields()
   onSuccess?.()
  }
 }

 const handleSubmitSaveAs = async (title: string) => {
  const values = await form.validateFields()

  const formData = connverToFormData({
   ...values,
   name: title,
  })
  await saveAsEmailTemplate(formData).unwrap()
  setShowAsModal(false)
  onSuccess?.()
 }

 const connverToFormData = useCallback(
  (values: TAddEmailTemplate): FormData => {
   const formData = new FormData()
   formData.append('name', `${values.name}`)
   formData.append('subject', `${values.subject}`)
   if (values.body) {
    formData.append('body', `${values.body}`)
   }

   const exData: string[] = []
   values.attachment?.forEach((v, i) => {
    if (v.type === 'url') {
     exData.push(`${v.url}`)
    } else {
     formData.append(`attachment[${i}]`, v.originFileObj as any)
    }
   })
   exData &&
    exData.length > 0 &&
    formData.append('ex-attachment', `${exData.join(',')}`)

   return formData
  },
  [],
 )

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    name: editData.name,
    subject: editData.subject,
    body: editData.body,
    attachment:
     editData.attachment && editData.attachment?.length > 0
      ? editData.attachment.map((v, i) => ({
         thumbUrl: v,
         url: v,
         fileName: 'Hello',
         name: `Attachment ${i + 1}`,
         uid: `tempId-${i}`,
         type: 'url',
        }))
      : [],
   })
  }
 }, [mode, form, editData])

 return (
  <>
   <Form form={form} layout="vertical">
    <Row gutter={15}>
     <Col span={12}>
      <Form.Item
       name="name"
       label="Template Name"
       rules={[{ required: true, message: 'Template Name is required' }]}
      >
       <Input placeholder="Template Name" />
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item
       name="subject"
       label="Subject"
       rules={[{ required: true, message: 'Subject is required' }]}
      >
       <EmailSubjectInput />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Form.Item name="body" label="Body">
       <EmailTextArea mergfield />
      </Form.Item>
     </Col>
     <Col>
      <Form.Item name="attachment">
       <EmailATUpload />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Space>
       <BaseButton
        loading={mode === 'EDIT' ? editLoading : addLoading}
        onClick={handleSubmit}
        buttonType="primary"
       >
        {mode === 'EDIT' ? 'Update Email Template' : 'Save Email Template'}
       </BaseButton>
       {mode === 'EDIT' && (
        <BaseButton onClick={() => setShowAsModal(true)} buttonType="info">
         Save As
        </BaseButton>
       )}
      </Space>
     </Col>
    </Row>
   </Form>
   <SaveAsModal
    Loading={saLoading}
    handleSubmitSaveAs={handleSubmitSaveAs}
    visible={showSaveAsModal}
    onCancel={() => setShowAsModal(false)}
   />
  </>
 )
}

export default EmailTemplateForm

const SaveAsModal: FC<{
 Loading?: boolean
 handleSubmitSaveAs?: (title: string) => void
 visible?: boolean
 onCancel?: () => void
}> = ({ visible, onCancel, handleSubmitSaveAs, Loading }) => {
 const [input, setInput] = useState('')
 return (
  <Modal
   confirmLoading={Loading}
   okButtonProps={{ disabled: !input }}
   onOk={() => handleSubmitSaveAs?.(input)}
   okText="Save As"
   title="Save As"
   onCancel={onCancel}
   visible={visible}
  >
   <Input
    value={input}
    onChange={(e) => setInput(e.target.value)}
    placeholder="Enter A New Template Name"
   />
  </Modal>
 )
}
