import React from 'react'
import ReactDOM from 'react-dom/client'
import 'styles/plugin/fonticon/_font-icon.scss'
import 'styles/less/index.less'
import 'styles/index.scss'
import { Provider } from 'react-redux'
import { store } from 'store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
 //  <React.StrictMode>
 <Provider store={store}>
  <App />
 </Provider>,
 //  </React.StrictMode>
)
serviceWorkerRegistration.register({
 onUpdate: (registration) => {
  if (window.confirm('New version available! Click OK to refresh.')) {
   registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
   window.location.reload()
  }
 },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
