import { Menu } from 'antd'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useLeadFilter } from '../slice/leadSlice'

const LeadListTitleTabsBlock: FC<{ selectedKey: 'all-leads' | 'my-leads' }> = ({
 selectedKey,
}) => {
 const { handleUpdateTab } = useLeadFilter()
 const navigate = useNavigate()

 return (
  <div className="lead-list-body p-0">
   <div className="lead-tile-blk">
    <Menu
     selectedKeys={[selectedKey]}
     onSelect={({ key }) => {
      navigate(`/admin/leads/${key}`)
      handleUpdateTab('totallead')
     }}
     mode="horizontal"
     items={[
      { label: 'All Leads', key: 'all-leads' },
      { label: 'My Leads', key: 'my-leads' },
     ]}
    />
   </div>
  </div>
 )
}

export default LeadListTitleTabsBlock
