/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox, Popover, Row, Col, Tooltip } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
// import { useGlobal } from 'raducer/global'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { TGlobalTableStructure } from 'types'
import { queryClient } from 'lib/react-query'
import { useWebSettings } from 'raducer/webSettings'
import { useUpdateTableStructureMutation } from '../api'
import { useLeadFilter } from '../slice/leadSlice'

type TSelectedFields = TGlobalTableStructure & { checked?: boolean }

const AddMoreColumnBtn: FC<{
 refetch?: () => void
 mode: 'dashboard' | 'leads'
}> = ({ mode }) => {
 const { filterParams } = useLeadFilter()

 const [openedFloatingMenu, setOpenedFloatingMenu] = useState(false)
 //  const { global, updateTableStructure: updateStructure } = useGlobal()
 //  const { all_fields, table_structure } = global
 const {
  all_fields,
  table_structure,
  updateTableStructure: updateStructure,
 } = useWebSettings()
 const [selectedFields, setSelectedFields] = useState<TSelectedFields[]>(
  all_fields?.map((v) => ({
   ...v,
   checked: table_structure?.map((i) => i?.column)?.includes(v?.column),
  })) ?? [],
 )
 const [updateTableStructure, { isLoading }] = useUpdateTableStructureMutation()
 useEffect(() => {
  setSelectedFields(
   all_fields?.map((v) => ({
    ...v,
    checked: table_structure?.map((i) => i?.column)?.includes(v?.column),
   })) ?? [],
  )
 }, [all_fields])

 const onSubmit = async (): Promise<void> => {
  const checkedFields =
   selectedFields?.filter((i) => i?.checked).map((i) => i?.column) || []

  const newData =
   table_structure?.filter((el) => checkedFields.includes(el?.column)) || []

  const newDataColumns = newData.map((i) => i?.column)

  const newFields =
   selectedFields?.filter(
    (k) => k?.checked && !newDataColumns.includes(k?.column),
   ) || []

  await updateTableStructure({
   table_structure: JSON.stringify([...newData, ...newFields]),
  })
  updateStructure?.([...newData, ...newFields])
  setOpenedFloatingMenu(false)

  queryClient.resetQueries({
   queryKey: ['get-leads-web', filterParams],
  })
 }

 const firstColumn = selectedFields.slice(
  0,
  Math.ceil(selectedFields.length / 2),
 )
 const secondColumn = selectedFields.slice(Math.ceil(selectedFields.length / 2))

 const renderColumn = (fileds: TSelectedFields[]): any => {
  return fileds?.map((item, n) => (
   <Col key={n} span={24}>
    <Checkbox
     onChange={() => {
      setSelectedFields((prv) =>
       prv?.map((k) =>
        k?.column === item?.column
         ? { ...k, checked: !item?.checked }
         : { ...k },
       ),
      )
     }}
     checked={
      ['name', 'email_address', 'phone_number']?.includes(item?.variable)
       ? true
       : item?.checked
     }
     disabled={['name', 'email_address', 'phone_number']?.includes(
      item?.variable,
     )}
    >
     <span style={{ color: 'black' }}>{item?.column}</span>
    </Checkbox>
   </Col>
  ))
 }

 return (
  <div
   //  className={`lead-list-floating-menu ${openedFloatingMenu ? 'opened' : ''}`}
   className="d-flex align-items-center"
  >
   <Popover
    content={
     <Row>
      <Col span={12}>
       <Row>{renderColumn(firstColumn)}</Row>
      </Col>
      <Col span={12}>
       <Row>{renderColumn(secondColumn)}</Row>
      </Col>

      {/* {selectedFields?.map((item, n) => (
       <Col key={n} span={12}>
        <Checkbox
         onChange={() => {
          setSelectedFields((prv) =>
           prv?.map((k) =>
            k?.column === item?.column
             ? { ...k, checked: !item?.checked }
             : { ...k },
           ),
          )
         }}
         checked={
          ['name', 'email_address', 'phone_number']?.includes(item?.variable)
           ? true
           : item?.checked
         }
         disabled={['name', 'email_address', 'phone_number']?.includes(
          item?.variable,
         )}
        >
         <span style={{ color: 'black' }}>{item?.column}</span>
        </Checkbox>
       </Col>
      ))} */}
      <div className="my-3 ms-auto d-flex flex-column align-items-end">
       <p
        style={{
         color: '#ED1C24',
         fontWeight: '500',
        }}
       >
        Note: Too many columns can impact the Listings Page loading speed.
       </p>
       <BaseButton
        loading={isLoading}
        buttonType="primary"
        onClick={() => onSubmit()}
        style={{ width: 'max-content' }}
       >
        Update
       </BaseButton>
      </div>
     </Row>
    }
    overlayStyle={{ width: '400px' }}
    title={
     <div className="d-flex justify-content-between align-items-center">
      <div>Choose Columns to Show</div>
      <i
       className="fal fa-times"
       style={{ cursor: 'pointer' }}
       onClick={() => setOpenedFloatingMenu(false)}
      />
     </div>
    }
    trigger="click"
    onVisibleChange={(v) => {
     if (!v) {
      setSelectedFields(
       all_fields?.map((v) => ({
        ...v,
        checked: table_structure?.map((i) => i?.column)?.includes(v?.column),
       })) ?? [],
      )
     }
     setOpenedFloatingMenu(v)
    }}
    visible={openedFloatingMenu}
    showArrow={false}
   >
    {/* <BaseButton className="dial-clicker" buttonType="alpha"> */}
    <Tooltip overlay="Customize Column Settings">
     <i
      className="fas fa-cog"
      style={{ color: 'black', fontSize: '20px', cursor: 'pointer' }}
     />
    </Tooltip>

    {/* </BaseButton> */}
   </Popover>
  </div>
 )
}

export default AddMoreColumnBtn
