/* eslint-disable react/jsx-no-useless-fragment */
import OverlaySection from 'layout/OverlaySection'
import React, { FC } from 'react'
import useHashRouter from 'hooks/useHashRouter'
import LeadDetailPage from '../routes/details'
import { TGetLeads } from '../types'

const LeadOverlaySection: FC<{
 leadIndex: number | null
 setLeadIndex: (value: React.SetStateAction<number | null>) => void
 fetchMore: () => void
 hasMore?: boolean
 leads?: TGetLeads[]
 isRealtor?: boolean
 refetchList?: () => void
 realtorId?: number
}> = ({
 leadIndex,
 setLeadIndex,
 fetchMore,
 leads,
 hasMore,
 isRealtor,
 refetchList,
 realtorId,
}) => {
 const { isHash } = useHashRouter()

 return (
  <>
   {leadIndex !== null && leads && leads?.length > 0 && !isHash && (
    <OverlaySection>
     <LeadDetailPage
      onPrevLead={
       leadIndex !== 0
        ? () => {
           setLeadIndex((prv) => Number(prv) - 1)
          }
        : undefined
      }
      onNextLead={
       leadIndex !== leads.length - 1
        ? () => {
           if (leadIndex === leads.length - 4 && hasMore) {
            fetchMore()
           }
           setLeadIndex((prv) => Number(prv) + 1)
          }
        : undefined
      }
      leadData={leads?.[leadIndex]}
      onClose={() => setLeadIndex(null)}
      from="lead"
      leadId={leads?.[leadIndex].id}
      isRealtor={isRealtor}
      refetchLeadList={refetchList}
      realtorId={realtorId}
      leadListIndex={leadIndex}
     />
    </OverlaySection>
   )}
  </>
 )
}

export default LeadOverlaySection
