import { Spin } from 'antd'
import { useGetTextTemplateByIdQuery } from 'features/settings/api'
import TextTemplateForm from 'features/settings/components/TextTemplateForm'
// import InnerContentLayout from 'layout/InnerContentLayout'
import React, { FC } from 'react'
// import { useNavigate, useParams } from 'react-router'

const Edit: FC<{ id: string; onCancel?: () => void }> = ({ id, onCancel }) => {
 //  const params = useParams()
 //  const navigate = useNavigate()
 const { data, isFetching } = useGetTextTemplateByIdQuery({ id })
 return (
  //   <InnerContentLayout title="Edit Text Template">
  <Spin spinning={isFetching}>
   <TextTemplateForm
    mode="EDIT"
    editData={data}
    // onSuccess={() => navigate('/admin/settings/text-templates')}
    onSuccess={onCancel}
   />
  </Spin>
  //   </InnerContentLayout>
 )
}

export default Edit
