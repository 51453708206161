/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import { PRECON_LISTNG } from 'config'
import { http } from 'lib/http'
import { useMemo } from 'react'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { RootState, store } from 'store'
import { ApiResponce, InfinityNewType, PaginationType } from 'types'

type UseFetchListingRes<T> = {
 data?: InfiniteData<InfinityNewType<T>>
 isLoading: boolean
 fetchMore: () => void
 hasMore?: boolean
 refetch: () => void
 isRefetching?: boolean
 flatData?: T[]
}

type UseFetchListingProps = {
 params?: string
 body?: any
 method?: 'get' | 'post'
 enable?: boolean
 listingType?: 'preconstruction' | 'listing'
}

const spllitUrl = (val: string): any => val.split('page=')?.[1]

// const LISTING_URL = (store.getState() as RootState).global.listing_url

const fetchData = async (
 props: UseFetchListingProps & { url: string },
): Promise<InfinityNewType<any>> => {
 const LISTING_URL =
  props.listingType === 'preconstruction'
   ? (store.getState() as RootState).global.precondo_url
   : (store.getState() as RootState).global.listing_url

 try {
  const { data }: AxiosResponse<ApiResponce<PaginationType<any>>> =
   props.method === 'post'
    ? await http.post(`api/${props.url}`, props.body, {
       baseURL: LISTING_URL,
      })
    : await http.get(`api/${props.url}`, {
       baseURL: LISTING_URL,
      })
  if (data && data.status === 'success') {
   return {
    data: data.data.data ?? [],
    next: data?.data.next_page_url
     ? spllitUrl(data?.data.next_page_url)
     : undefined,
   }
  }
  return {
   data: [],
   next: undefined,
  }
 } catch (error) {
  return {
   data: [],
   next: undefined,
  }
 }
}

const useFetchListing = <T>(
 key: string,
 options?: UseFetchListingProps,
): UseFetchListingRes<T> => {
 const { data, isLoading, fetchNextPage, hasNextPage, refetch, isRefetching } =
  useInfiniteQuery<InfinityNewType<T>>(
   [`${key}`, options?.body, options?.params],
   ({ pageParam = 1 }) =>
    fetchData({
     method: options?.method || 'get',
     url: `${key}?page=${pageParam}${
      options?.params ? `&${options.params}` : ''
     }`,
     body: options?.body,
     listingType: options?.listingType,
    }),
   {
    getNextPageParam: (lastPage) =>
     lastPage && lastPage.next && lastPage.next !== null
      ? lastPage.next
      : false,
    enabled: options?.enable,
   },
  )

 const flatData = useMemo(
  () => (data && data.pages ? data?.pages.map((v) => v.data).flat() : []),
  [data],
 )
 return {
  data,
  isLoading,
  fetchMore: fetchNextPage,
  hasMore: hasNextPage,
  refetch,
  isRefetching,
  flatData: flatData as T[],
 }
}

export default useFetchListing
