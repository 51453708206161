import { baseAPI, baseTransformResponse3 } from 'api/baseApi'
import { baseTransformResponse } from 'api/secondApi'
import { TTailTransferredLeads } from '../types'

export const realtorsApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  removeRealtor: mutation<void, { id: number }>({
   query: ({ id }) => ({
    url: `user/remove-realtor/${id}`,
    method: 'post',
   }),
   transformResponse: baseTransformResponse,
  }),
  cancelRealtorRequest: mutation<void, { id: number }>({
   query: ({ id }) => ({
    url: `user/remove-pending-request/${id}`,
    method: 'post',
   }),
   transformResponse: baseTransformResponse,
  }),
  realtorTransferRequest: mutation<void, { realtor_id: string }>({
   query: (body) => ({
    url: 'user/realtor-transfer-request',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  leadTransferType: mutation<void, { lead_transfer_type: string }>({
   query: (body) => ({
    url: 'user/lead-transfer-type',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  removeRealtorSource: mutation<void, { realtor_id: any; source_id: any }>({
   query: (body) => ({
    url: 'user/remove-realtor-source',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  addRealtorSource: mutation<void, { realtor_id: any; source_id: any }>({
   query: (body) => ({
    url: 'user/realtor-source',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  tileForTransferredLeads: query<TTailTransferredLeads, { realtor_id: string }>(
   {
    query: ({ realtor_id }) => ({
     url: 'user/tile_for_transferred_leads',
     method: 'post',
     body: { id: realtor_id },
    }),
    providesTags: ['TRANSFERRED_LEAD_TILE'],
    transformResponse: baseTransformResponse3,
   },
  ),
 }),
})

export const {
 useRemoveRealtorMutation,
 useRealtorTransferRequestMutation,
 useTileForTransferredLeadsQuery,
 useLeadTransferTypeMutation,
 useRemoveRealtorSourceMutation,
 useAddRealtorSourceMutation,
 useCancelRealtorRequestMutation,
} = realtorsApi
