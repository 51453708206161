/* eslint-disable react/require-default-props */
import { Col, Popover, Row, TooltipProps } from 'antd'
import BaseTag from 'components/elements/BaseTag'
import { globalConfirm } from 'components/elements/global'
import {
 useWithdrawActionplanBulkMutation,
 useWithdrawMarketUpdatesTagsMutation,
} from 'features/automation'
import { useWithdrawBulkAlertMutation } from 'features/alert'
import { useWithdrawTagleadMutation } from 'features/leads'
import { useRemoveRealtorSourceMutation } from 'features/realtors'
import { queryClient } from 'lib/react-query'
import React, { FC, useCallback, useMemo } from 'react'
import { TGroupType } from 'types'

export const TSSBlock: FC<{
 className?: string
 label?: string
 closable?: boolean
 type?: 'stage' | 'tag' | 'source'
 showTooltip?: boolean
 onDelete?: () => void
 removeEllips?: boolean
 showIcon?: boolean
 tooltiOptions?: TooltipProps
}> = ({
 type,
 label,
 onDelete,
 className = '',
 showTooltip,
 closable,
 removeEllips = false,
 showIcon = true,
 tooltiOptions,
 ...rest
}) => {
 const color = useMemo(
  () =>
   type === 'source' ? 'magenta' : type === 'stage' ? 'geekblue' : 'purple',
  [type],
 )

 const icon = useMemo(() => {
  if (showIcon) {
   return type === 'source'
    ? 'globe'
    : type === 'stage'
    ? 'th'
    : type === 'tag'
    ? 'tags'
    : ''
  }
 }, [type, showIcon])

 return (
  <BaseTag
   {...rest}
   icon={icon ? <i className={`far fa-${icon} me-2`} /> : null}
   color={color}
   closable={closable}
   className={`tssblock-tag ${className ?? ''}`}
   onClose={onDelete}
   showTooltip={showTooltip}
   label={
    <span
     className="tssblock-span"
     style={{
      maxWidth: removeEllips ? '100%' : '100px',
      whiteSpace: removeEllips ? 'pre-wrap' : 'nowrap',
     }}
    >
     {label}
    </span>
   }
   tooltiOptions={tooltiOptions}
  />
 )
}

export const TSSBlockList: FC<{
 closable?: boolean
 onRemoveSuccess?: () => void
 title?: string
 mode?: 'stage' | 'tag' | 'source'
 //  type: 'lead' | 'alert' | 'actionPlan'
 type: 'lead' | 'alert' | 'actionPlan' | 'realtors' | 'market-updates'
 typeId: string | number
 data?: TGroupType[]
 from?: 'lead'
 tooltiOptions?: TooltipProps
}> = ({
 type,
 data,
 mode,
 typeId,
 closable,
 onRemoveSuccess,
 from,
 tooltiOptions,
}) => {
 const [withrTagLead] = useWithdrawTagleadMutation()
 const [withdrawAlertBulk] = useWithdrawBulkAlertMutation()
 const [withdrawActionBulk] = useWithdrawActionplanBulkMutation()
 const [withdrawMarketUpdatesBulk] = useWithdrawMarketUpdatesTagsMutation()
 const [removeRealtorSource] = useRemoveRealtorSourceMutation()

 // Function
 const handleDelete = useCallback(
  (id?: any) => {
   globalConfirm({
    content: `Are you sure want to remove this ${mode}?`,
    onOk: async () => {
     if (type === 'lead') {
      await withrTagLead({ lead_id: typeId, tag_id: id }).unwrap()
      queryClient.invalidateQueries('get-leads')
      onRemoveSuccess?.()
     } else if (type === 'actionPlan') {
      await withdrawActionBulk({
       type: mode,
       type_id: id,
       plan_id: typeId,
      }).unwrap()
      queryClient.invalidateQueries('get-action-plans')
      onRemoveSuccess?.()
     } else if (type === 'market-updates') {
      await withdrawMarketUpdatesBulk({
       type: mode,
       newsletter_id: typeId,
       type_id: id,
      }).unwrap()
      onRemoveSuccess?.()
     } else if (type === 'alert') {
      await withdrawAlertBulk({
       type: mode,
       alert_id: typeId,
       type_id: id,
      }).unwrap()
      queryClient.invalidateQueries('get-alerts')
      onRemoveSuccess?.()
     } else if (type === 'realtors') {
      await removeRealtorSource({ realtor_id: typeId, source_id: id }).unwrap()
      onRemoveSuccess?.()
     }
    },
   })
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [mode, onRemoveSuccess, type, typeId],
 )
 if (!data || (data && data.length === 0)) {
  return null
 }

 return (
  <>
   <TSSBlock
    onDelete={() => handleDelete(data?.[0]?.id)}
    className="mb-1"
    label={data?.[0].name}
    type={mode}
    closable={(type === 'lead' ? data?.[0]?.is_editable : true) && closable}
    showTooltip
    tooltiOptions={tooltiOptions}
   />
   {data?.length > 1 && (
    <Popover
     placement="top"
     trigger="click"
     title={
      mode === 'source' ? 'Sources' : mode === 'stage' ? 'Stages' : 'Tags'
     }
     content={
      <Row gutter={[10, 10]} className="tag-popover">
       {data.slice(1).map((t, i) => (
        <Col key={i}>
         <TSSBlock
          onDelete={() => handleDelete(t.id)}
          //   className="mb-1"
          label={t.name}
          type={mode}
          //   color="magenta"
          closable={(type === 'lead' ? t?.is_editable : true) && closable}
          showTooltip
          tooltiOptions={tooltiOptions}
         />
        </Col>
       ))}
      </Row>
     }
    >
     <TSSBlock
      showIcon={false}
      type={mode}
      className="mb-1 cursor-pointer"
      label={`+${data.length - 1}`}
      tooltiOptions={tooltiOptions}
     />
    </Popover>
   )}
  </>
 )
}
