import { NotFound } from 'features/misc'
import { Navigate, RouteObject } from 'react-router'
import { lazyImport } from 'utils/lazyImport'

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes')

export const publicRoutes: RouteObject[] = [
 {
  path: '/auth/*',
  element: <AuthRoutes />,
 },
 {
  path: '',
  element: <Navigate to="/auth" />,
 },
 {
  path: '/admin/*',
  element: <Navigate to="/auth" />,
 },
 {
  path: '*',
  element: <NotFound />,
 },
]
