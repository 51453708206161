import { Card, Row, Col, InputNumber, Select, Input, Form } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { LoadMoreSelect } from 'components/elements/Form'
import { EmailTemplateModal } from 'components/shared/Modals'
import TextTemplateModal from 'components/shared/Modals/TextTemplateModal'
import {
 useGetEmailTemplateNamesQuery,
 useGetGroupsQuery,
 useGetTextTemplateNamesQuery,
} from 'features/settings'
import useAuth from 'hooks/useAuth'
import useFetchPage from 'hooks/useFetchPage'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { taskTypes } from 'static-data'
import { TModalState } from 'types'
import { useActionForm } from '../../providers/ActionFormProvider'
import { TActionPlanType, TGetActionPlansName } from '../../types'
import TemplateEditorSelect from './TemplateEditorSelect'

type ActionType = any

const actionList: TActionPlanType[] = [
 'Create Task',
 'Send Email',
 'Send Text',
 'Change Stage',
 'Add Note',
 'Add Tag(s)',
 'Remove Tag(s)',
 'Remove all Tags',
 'Pause all other Action plans',
 'Pause Specific Action plan',
 'Repeat Action Plan',
 'Assign Action Plan',
]

const ActionPlanFormSteps: FC<{
 //  form?: FormInstance<TActionPlanForm>
 name?: any
 title: any
 onRemove: () => void
}> = ({ onRemove, title, name }) => {
 const { mode, editData, form } = useActionForm()
 const [selectedAction, setAction] = useState<TActionPlanType>()
 const { user } = useAuth()

 /// /////////////////////////////////////////////////////

 const [searchActionKey, setAPKey] = useState('')

 const [showEmailTemplate, setEmailTemplate] = useState<TModalState<any>>({
  show: false,
  mode: 'ADD',
  data: '',
 })

 const [showTextTemplate, setTextTemplate] = useState<TModalState<any>>({
  show: false,
  mode: 'ADD',
  data: '',
 })

 const {
  flatData: apList,
  isLoading: apLoading,
  hasMore,
  fetchMore,
 } = useFetchPage<TGetActionPlansName>('get-action-plans-name', {
  method: 'post',
  enable:
   selectedAction === 'Pause Specific Action plan' ||
   selectedAction === 'Assign Action Plan',
  body: useMemo(() => ({ search: searchActionKey }), [searchActionKey]),
 })

 const { data: stageList, isLoading: stgLoading } = useGetGroupsQuery(
  { type: ['stage'] },
  { skip: selectedAction !== 'Change Stage' },
 )
 const { data: tagList, isLoading: tagLoading } = useGetGroupsQuery(
  { type: ['tag'] },
  {
   skip: selectedAction !== 'Add Tag(s)' && selectedAction !== 'Remove Tag(s)',
  },
 )

 const { data: emailTemplateNames, isLoading: emLoading } =
  useGetEmailTemplateNamesQuery(null, {
   skip: selectedAction !== 'Send Email',
  })
 const { data: textTemplateNames, isLoading: txLoading } =
  useGetTextTemplateNamesQuery(null, {
   skip: selectedAction !== 'Send Text',
  })

 useEffect(() => {
  if (mode && editData && editData.steps && editData.steps.length > 0) {
   const currentStep = editData.steps[name]
   currentStep?.action && setAction(currentStep.action)
  }
 }, [editData, mode, name])

 return (
  <>
   <Card
    title={title}
    className="action-card"
    extra={[
     <BaseButton
      key={1}
      onClick={onRemove}
      buttonType="danger"
      size="small"
      icon={<i className="fas fa-trash-alt" />}
     />,
    ]}
   >
    <Row gutter={[15, 15]}>
     <Col span={12}>
      <Form.Item
       name={[name, 'action']}
       label="Action"
       rules={[{ required: true, message: 'Action step is required' }]}
      >
       <Select
        onChange={(va: ActionType) => {
         form?.setFields([
          {
           name: ['steps', name, 'param1'],
           value: undefined,
          },
          {
           name: ['steps', name, 'param2'],
           value: undefined,
          },
         ])
         setAction(va)
        }}
        placeholder="Please select an action plan"
        className="w-50"
       >
        {actionList.map((ac, index) => (
         <Select.Option key={index} value={ac}>
          {ac}
         </Select.Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item
       initialValue={0}
       name={[name, 'run_on']}
       label="Immediately run"
      >
       <InputNumber min={0} addonAfter="Days after the previous step" />
      </Form.Item>
     </Col>
     {selectedAction === 'Create Task' && (
      <>
       <Col span={12}>
        <Form.Item
         name={[name, 'param1']}
         label="Task Name"
         rules={[{ required: true, message: 'Task name is required' }]}
        >
         <Input placeholder="Enter Task Name" className="w-100" />
        </Form.Item>
       </Col>
       <Col span={12}>
        <Form.Item
         name={[name, 'param2']}
         label="Task Type"
         rules={[{ required: true, message: 'Task type is required' }]}
        >
         <Select
          options={taskTypes}
          placeholder="Please select Task"
          className="w-50"
         />
        </Form.Item>
       </Col>
      </>
     )}
     {selectedAction === 'Send Email' && (
      <Col span={12}>
       <Form.Item
        name={[name, 'param1']}
        label="Template"
        rules={[{ required: true, message: 'Template is required' }]}
       >
        <TemplateEditorSelect
         onAdd={() => setEmailTemplate({ show: true, mode: 'ADD' })}
         onEdit={(id) =>
          setEmailTemplate({ show: true, data: id, mode: 'EDIT' })
         }
         placeholder="Please select Email Template"
         loading={emLoading}
         TemplateNames={emailTemplateNames}
        />
       </Form.Item>
      </Col>
     )}
     {selectedAction === 'Send Text' && (
      <>
       <Col span={12}>
        <Form.Item
         name={[name, 'param1']}
         label="Template"
         rules={[{ required: true, message: 'Template is required' }]}
        >
         <TemplateEditorSelect
          placeholder="Please select Text Template"
          loading={txLoading}
          TemplateNames={textTemplateNames}
          onAdd={() => setTextTemplate({ show: true, mode: 'ADD' })}
          onEdit={(id) =>
           setTextTemplate({ show: true, data: id, mode: 'EDIT' })
          }
         />
        </Form.Item>
       </Col>
       <Col span={12}>
        <Form.Item
         initialValue={user?.twilio_phone ?? ''}
         name={[name, 'param2']}
         label="From"
         rules={[{ required: true, message: 'From is required' }]}
        >
         <Input disabled />
        </Form.Item>
       </Col>
      </>
     )}
     {selectedAction === 'Change Stage' && (
      <Col span={12}>
       <Form.Item
        name={[name, 'param1']}
        label="Stage"
        rules={[{ required: true, message: 'Stage is required' }]}
       >
        <Select
         loading={stgLoading}
         // options={emailTemplateNames?.map((v) => ({}))}
         placeholder="Please select Stage"
         className="w-50"
        >
         {stageList &&
          stageList.stage &&
          stageList.stage.map((v, i) => (
           <Select.Option value={`${v.id}`} key={i}>
            {v.name}
           </Select.Option>
          ))}
        </Select>
       </Form.Item>
      </Col>
     )}
     {(selectedAction === 'Add Tag(s)' ||
      selectedAction === 'Remove Tag(s)') && (
      <Col span={12}>
       <Form.Item
        name={[name, 'param1']}
        label="Tag"
        rules={[{ required: true, message: 'Tag is required' }]}
       >
        <Select
         mode="multiple"
         showSearch
         loading={tagLoading}
         options={tagList?.tag?.map((v) => ({
          value: `${v.id}`,
          label: v.name,
         }))}
         placeholder="Please select tag"
         className="w-50"
         filterOption={(input, option) =>
          option?.label
           ? option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
           : false
         }
        />
       </Form.Item>
      </Col>
     )}
     {(selectedAction === 'Pause Specific Action plan' ||
      selectedAction === 'Assign Action Plan') && (
      <Col span={12}>
       <Form.Item
        name={[name, 'param1']}
        label="Action Plan"
        rules={[{ required: true, message: 'Action plan is required' }]}
       >
        <LoadMoreSelect
         onSearch={setAPKey}
         fetchMore={fetchMore}
         hasMore={hasMore}
         loading={apLoading}
         data={apList?.map((v) => ({ label: v.name, value: v.id }))}
         placeholder="select Action Plan"
        />
       </Form.Item>
      </Col>
     )}

     {selectedAction === 'Add Note' && (
      <Col span={24}>
       <Form.Item name={[name, 'param1']} label="Note">
        <Input.TextArea rows={5} />
       </Form.Item>
      </Col>
     )}
    </Row>
   </Card>
   {showEmailTemplate.show && (
    <EmailTemplateModal
     editId={showEmailTemplate.data}
     mode={showEmailTemplate.mode}
     onCancel={() => setEmailTemplate({ show: false })}
     visible={showEmailTemplate.show}
    />
   )}
   {showTextTemplate.show && (
    <TextTemplateModal
     editId={showTextTemplate.data}
     mode={showTextTemplate.mode}
     onCancel={() => setTextTemplate({ show: false })}
     visible={showTextTemplate.show}
    />
   )}
  </>
 )
}

export default ActionPlanFormSteps
