import ListContentLayout from 'layout/ListContentLayout'
import { FC, useMemo, useState } from 'react'
import { Table, Space } from 'antd'
import {
 useBDayTempDltMutation,
 useGetBirthdayTemplatesQuery,
} from 'features/settings/api'
import { BaseButton } from 'components/elements/BaseButton'
import { ColumnsType } from 'antd/lib/table'
import { globalConfirm } from 'components/elements/global'
import BirthDayWishesActions from './BirthDayWishesActions'
import BirthDayWishesModal from './BirthDayWishesModal'
import BDayTempViewModal from './BDayTempViewModal'

const BirthDayWishes: FC = () => {
 const { isLoading, data, refetch, isFetching } = useGetBirthdayTemplatesQuery()
 const [bdayTempDlt] = useBDayTempDltMutation()
 const [showModal, setShowModal] = useState<{
  show: 'Email' | 'SMS' | false
  type: 'Edit' | 'View' | null
 }>({
  show: false,
  type: null,
 })

 const onDltTemp = (type: string) => {
  globalConfirm({
   content: 'Are you sure you want to delete this template?',
   onOk: async () => {
    await bdayTempDlt({ type }).unwrap()
    refetch?.()
   },
  })
 }

 const setActions = (val: 'Email' | 'SMS') => (
  <Space>
   <BaseButton
    onClick={() => setShowModal({ show: val, type: 'Edit' })}
    tooltipText="Attach New Template"
    shape="circle"
    size="small"
    buttonType="danger"
    icon={<i className="fas fa-edit" />}
   />
   <BaseButton
    onClick={() => setShowModal({ show: val, type: 'View' })}
    tooltipText="View"
    shape="circle"
    size="small"
    buttonType="danger"
    icon={<i className="fas fa-eye" />}
   />
   <BaseButton
    onClick={() => onDltTemp(val.toLowerCase())}
    tooltipText="Delete"
    shape="circle"
    size="small"
    buttonType="danger"
    icon={<i className="fas fa-trash" />}
   />
  </Space>
 )

 const columns: ColumnsType<{ email?: string; sms?: string }> = [
  ...(data?.email
   ? [
      {
       title: 'Email',
       dataIndex: 'email',
       width: 125,
      },
      {
       title: 'Action',
       width: 40,
       fixed: 'right' as any,
       render: () => setActions('Email'),
      },
     ]
   : []),
  ...(data?.sms
   ? [
      {
       title: 'SMS',
       dataIndex: 'sms',
       width: 125,
      },
      {
       title: 'Action',
       width: 40,
       fixed: 'right' as any,
       render: () => setActions('SMS'),
      },
     ]
   : []),
 ]

 const datas = useMemo(() => {
  if (!data?.email && !data?.sms) {
   return undefined
  }
  return [data]?.map?.((i, n) => ({
   email: i?.email?.name,
   sms: i?.sms?.name,
  }))
 }, [data])

 return (
  <ListContentLayout
   title="Birthday Wishes"
   actions={
    <BirthDayWishesActions refetch={refetch} data={data} loading={isLoading} />
   }
   bodyClass="birthday-wishes"
  >
   <Table
    loading={isLoading || isFetching}
    pagination={false}
    columns={columns}
    dataSource={datas}
   />
   {showModal?.show && showModal?.type === 'Edit' && (
    <BirthDayWishesModal
     visible
     onCancel={() => setShowModal({ show: false, type: null })}
     type="Change"
     templateMode={showModal?.show}
     onSuccess={refetch}
     data={data}
     showItems={
      data?.email && data?.sms ? 'both' : data?.email ? 'email' : 'sms'
     }
    />
   )}
   {showModal?.show && showModal?.type === 'View' && (
    <BDayTempViewModal
     onCancel={() => setShowModal({ show: false, type: null })}
     mode={showModal?.show}
     data={data}
    />
   )}
  </ListContentLayout>
 )
}

export default BirthDayWishes
