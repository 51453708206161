import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
// import Add from './Add'
// import Edit from './Edit'
import List from './List'

const TextTemplate: FC = () => (
 <Routes>
  <Route path="" element={<List />} />
  {/* <Route path="add" element={<Add />} />
  <Route path="edit/:id" element={<Edit />} /> */}
 </Routes>
)

export default TextTemplate
