import { FC, useMemo } from 'react'
import { TListingTag, TListingType } from 'types'
import { utils } from 'utils'

const PropertyPrice: FC<{
 hideListingLabel?: boolean
 lable?: boolean
 className?: string
 spanClass?: string
 tag?: TListingTag
 type?: TListingType
 listing?: any
 sold?: any
 priceFrom?: any
 priceTo?: any
}> = ({
 tag = 'others',
 type = 'non-pre-construction',
 className,
 listing,
 priceFrom,
 priceTo,
 sold,
 spanClass,
 lable = false,
 hideListingLabel = false,
}) => {
 const listingPrice = Number(listing ?? 0)
 const soldPrice = Number(sold ?? 0)
 const priceR: any = useMemo(() => {
  if (priceFrom && priceTo) {
   return `${utils.pricefy(priceFrom)} - ${utils.pricefy(priceTo)}`
  }
  return priceFrom
   ? utils.pricefy(priceFrom)
   : priceTo && utils.pricefy(priceTo)
 }, [priceFrom, priceTo])

 const isStrickStyle = { textDecoration: 'line-through', color: '#777' }

 const isStrick = useMemo(
  () =>
   tag === 'sold' ||
   tag === 'leased' ||
   tag === 'expired' ||
   tag === 'suspended' ||
   tag === 'terminated' ||
   tag === 'sold-conditional' ||
   tag === 'leased-conditional',
  [tag],
 )

 return (
  <div
   className={`${isStrick ? 'has-strick ' : ''} ${
    tag !== 'sold' && tag !== 'leased' ? 'only-list-price' : ''
   }   ${className}`}
  >
   {type === 'non-pre-construction' ? (
    <>
     {(soldPrice !== 0 && tag === 'sold') || tag === 'leased' ? (
      <>
       {lable ? (
        <span className="is-label sld--price">
         <label htmlFor="">
          {tag === 'sold' ? 'Sold Price' : 'Leased Price'}:{' '}
         </label>
         <span className={` ms-1 text-primary ${spanClass ?? ''}`}>
          {utils.pricefy(sold)}
         </span>
        </span>
       ) : (
        <span className={`sld--price ms-2 text-primary ${spanClass ?? ''}`}>
         {utils.pricefy(sold)}
        </span>
       )}
       <br />
       {listingPrice !== 0 && (
        <>
         {lable ? (
          <span className="is-label lstd--price">
           <label style={{ color: '#777' }} htmlFor="">
            Listed Price :{' '}
           </label>
           <span
            style={isStrick ? isStrickStyle : {}}
            className={` ms-1  ${spanClass ?? ''}`}
           >
            {utils.pricefy(listingPrice)}
           </span>
          </span>
         ) : (
          <span
           style={isStrick ? isStrickStyle : {}}
           className={`lstd--price text-primary ${spanClass ?? ''}`}
          >
           {utils.pricefy(listingPrice)}
          </span>
         )}
        </>
       )}
      </>
     ) : (
      listingPrice !== 0 && (
       <>
        {lable && !hideListingLabel ? (
         <span className="is-label lstd--price">
          <label style={{ color: '#777' }} htmlFor="">
           Listed Price :{' '}
          </label>
          <span
           style={isStrick ? isStrickStyle : {}}
           className={` ms-1  ${spanClass ?? ''}`}
          >
           {utils.pricefy(listingPrice)}
          </span>
         </span>
        ) : (
         <span
          style={isStrick ? isStrickStyle : {}}
          className={`lstd--price ${spanClass ?? ''}`}
         >
          {utils.pricefy(listingPrice)}
         </span>
        )}
       </>
      )
     )}
    </>
   ) : (
    priceR &&
    type === 'pre-construction' && (
     <span className={spanClass ?? ''}>{priceR}</span>
    )
   )}
  </div>
 )
}

export default PropertyPrice
