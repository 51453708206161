import baseTime from 'lib/baseTime'
import { TFileExt } from 'types'

export default class Utils {
 static shortName = (name: string): string => {
  const splitName = name.split(' ')
  const shortName = splitName.map((e) => e.charAt(0))
  return shortName
   .join('')
   .toUpperCase()
   .slice(0, 2)
   .replace(/[^a-zA-Z0-9]/g, '')
 }

 static getExtension = (fileName: string) => {
  const extension = fileName.split('.').pop()
  return extension
 }

 static getExtNameFromUrl = (url = ''): TFileExt => {
  const temp = url.split('.')
  const filename = temp[temp.length - 1]
  const ext = filename.split(/#|\?/)[0] as TFileExt
  return ext
 }

 static fromNow = (time?: string) => {
  if (time) {
   const today = baseTime()
   const newDate = baseTime(time ?? '')
   today.diff(newDate)
   if (today.diff(newDate, 'day') < 26) {
    return newDate.fromNow()
   }
   if (today.diff(newDate, 'day') >= 26 && today.diff(newDate, 'day') < 30) {
    return `${today.diff(newDate, 'day')} days ago`
   }
   return `in ${newDate.format('MMM YYYY')}`
  }
  return null
 }
}
