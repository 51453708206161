import { Col, Row } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import ShareBlock from 'components/shared/Common/ShareModal'
import { useGetListingDetailsQuery } from 'features/property'
import useAuth from 'hooks/useAuth'
import { FC } from 'react'
import PropertyImageBlock from '../PropertyImageBlock/PropertyImageBlock'
import SimilarPropBlock from './SimilarPropBlock'
import KeyFactsBlock from './_components/KeyFactsBlock'
import ListingHistoryBlock from './_components/ListingHistoryBlock'
import MainInfoBlock from './_components/MainInfoBlock'
import OpenHousesBlock from './_components/OpenHousesBlock'
import PropertyDetailsBlock from './_components/PropertyDetailsBlock'
import RoomsBlock from './_components/RoomsBlock'
import WashRoomsBlock from './_components/WashRoomsBlock'

const NonPreCondoDetails: FC<{
 value?: string
}> = ({ value }) => {
 const { data, isLoading } = useGetListingDetailsQuery({
  value,
  view_type: 'crm',
 })

 if (isLoading) {
  return <BlockLoader />
 }

 return (
  <div className="pro-dtls-page">
   <Row gutter={[15, 15]}>
    <Col span={24} style={{ marginTop: '15px' }}>
     <PropertyImageBlock images={data?.images} />
    </Col>
    <Col span={24}>
     <MainInfoBlock data={data?.common} />
    </Col>
    <Col span={24}>
     <OpenHousesBlock openHouse={data?.open_houses} />
    </Col>
    <Col span={24}>
     <KeyFactsBlock description={data?.description} facts={data?.facts} />
    </Col>
    {/* <Col span={24}> */}
    <PropertyDetailsBlock details={data?.details} />
    {/* </Col> */}
    <Col span={24}>
     <RoomsBlock rooms={data?.rooms} />
    </Col>
    <Col span={24}>
     <WashRoomsBlock washrooms={data?.washrooms} />
    </Col>
    <Col span={24}>
     <ListingHistoryBlock histories={data?.histories} />
    </Col>
    <Col span={24}>
     <SimilarPropBlock smProps={data?.similar_properties} />
    </Col>
   </Row>
  </div>
 )
}

export default NonPreCondoDetails
